import React from "react";
import FeaturedBook from "./FeaturedBook";
import ColoredButton from "../../../components/buttons/ColoredButton";
import { useHistory } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./featuredHome.css";

const FeaturedHomeBooks = () => {
  const homeBookIds = [173, 422, 287, 155, 152, 202, 432];
  const history = useHistory();

  const navigateToBookCatalog = () => {
    history.push({
      pathname: "/bookcatalog",
    });
  };

  return (
    <div className="featured-book-home-container">
      <h2>Featured Books</h2>
      <h3 style={{ marginBottom: "25px" }}>Check out these popular books</h3>
      <div className="featured-book-img-container">
        <Swiper
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          loop={true}
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          modules={[EffectCoverflow, Autoplay]}
          className="mySwiper"
        >
          {homeBookIds.map((id, index) => {
            return (
              <SwiperSlide key={index} style={{ width: "275px" }}>
                <FeaturedBook key={index} id={id} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <ColoredButton text="browse all books" onClick={navigateToBookCatalog} />
    </div>
  );
};

export default FeaturedHomeBooks;
