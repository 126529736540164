import React, { useEffect, useState } from "react";
import UserService from "../../services/UserService";
import TokenService from "../../services/auth/TokenService";
import LoadingContentSpinner from "../../components/loading/LoadingContentSpinner";
import {
  AccountPageBillingInfo,
  AccountPageCancelSubscription,
  AccountPageEmail, DiscountDetails,
  AccountPageNewsletter,
  AccountPageSubscriptionDetails,
} from "./AccountPageComponents/AccountPageItems";
import GiftCodeRedeem from "../../components/giftSubscription/GiftCodeRedeem";
import { timeConverter, capitalizeFirstLetter } from "../../helpers/FormatFunctions";

import "./accountpageV2.css";
import LargeModal from "../../components/modal/LargeModal";
import { Link } from "react-router-dom";

const StripeFailedModal = (props) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "auto auto" }}>
      <p>There was an error retrieving your account information. Try refreshing the page.</p>
      <p>
        If it still doesn't work please{" "}
        <Link to="/contact">
          <strong>contact us</strong>
        </Link>
        .
      </p>
    </div>
  );
};

const AccountPageV2 = (props) => {
  const [accountDetails, setAccountDetails] = useState({
    active_since: 0,
    card_type: "visa",
    last_four: "1234",
    email: "email",
    newsletter: false,
    past_items: [
      {
        amount: 499,
        date: 0,
        last4: "1234",
        methodType: "visa",
      },
    ],
    tier: "monthly",
    upcoming_charge_amount: 499,
    upcoming_charge_date: 100000,
  });
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    UserService.getAccountDetails(TokenService.getUser().email, TokenService.getUser().userToken)
      .then((response) => {
        if (!response.data.message) {
          console.log("DETAILS: ", response.data);
          setAccountDetails(response.data);
        }
        setLoading(false);
      })
      .catch((e) => {
        setModalOpen(true);
        setLoading(false);
      });

  }, []);

  return (

    <div className="account-page user-loggedin-margin">

      {loading ? (
        <div className="flex-box-center" style={{ width: "90vw" }}>
          <LoadingContentSpinner />
        </div>
      ) : (
        <>
          <div className="account-page-items__title">
            <div className="fs-title">Your Account</div>
          </div>
          {accountDetails && (
            <>  <div className="account-page__items left-side-account-settings">
              <AccountPageEmail email={accountDetails.email} />


              <AccountPageNewsletter isSubscribed={accountDetails.newsletter} />
              {!accountDetails.discount && (<GiftCodeRedeem />)}
              {!accountDetails.canceled_at && accountDetails?.discount && (<DiscountDetails discount={accountDetails.discount} />)}
            </div>
              <div className="billing-info-container">
                {accountDetails.card_type !== "Paypal" && (<div className="account-page__item_manage-billing-portal ">  <p>Access your Stripe billing portal to update payment details, view invoices, and modify your subscription plans.</p>
                  <a href={process.env.REACT_APP_STRIPE_BILLING_PORTAL} className="link">Manage Subscription Portal</a></div>
                )}
                <AccountPageBillingInfo
                  cardType={accountDetails.card_type}
                  lastFour={accountDetails.last_four}
                  upcomingChargeAmount={accountDetails.upcoming_charge_amount}
                  upcomingChargeDate={accountDetails.upcoming_charge_date}
                  cancelled={accountDetails.canceled_at}
                  upcomingInvoiceAmount={accountDetails.upcoming_invoice_amount}
                  pastItems={accountDetails.past_items}
                  latestInvoice={accountDetails?.latest_invoice}
                  trial={accountDetails.trialing}
                  startDate={accountDetails.active_since}
                  tier={accountDetails.tier}
                />
                <AccountPageSubscriptionDetails
                  pausedSubscription={accountDetails?.pausedSubscriptionId}
                  upcomingChargeAmount={accountDetails.upcoming_charge_amount}
                  discount={accountDetails?.discount}
                  tier={capitalizeFirstLetter(accountDetails.tier)}
                  status={accountDetails.trialing ? "Free Trial" : accountDetails.canceled_at ? "Canceled" : "Active"}
                  startDate={timeConverter(accountDetails.active_since * 1000, false)}
                  startDateUNIX={accountDetails.active_since}
                  cancelDate={accountDetails.canceled_at}
                  cancelAt={accountDetails?.cancel_at}
                  lastDay={accountDetails.upcoming_charge_date}
                  upcomingInvoiceAmount={accountDetails.upcoming_invoice_amount}
                  upcomingChargeDate={accountDetails.upcoming_charge_date}
                /></div>

            </>
          )}</>

      )
      }
      <LargeModal isOpen={modalOpen} title="Error fetching data" content={<StripeFailedModal />} hideX />
    </div >
  );
};

export default AccountPageV2;
