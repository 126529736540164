import React, { useState, useRef } from "react";
import TokenService from "../../services/auth/TokenService";
import { ComplimentData } from "./ComplimentData";
import ComplimentVideo from "./ComplimentVideo";

import "./compliment.css";

const Compliment = () => {
  const [videoPlaying, setVideoPlaying] = useState(null);

  const handleVideoPlay = (videoId) => {
    setVideoPlaying(videoId);
  };

  return (
    <div className="compliment-container">
      <div className="compliment-video-container">
        <div className="compliment-header-video-container">
          <div className="compliment-video-shader"></div>
          <video
            className="compliment-header-video"
            autoPlay={true}
            muted={true}
            loop={true}
          >
            <source src="https://player.vimeo.com/progressive_redirect/playback/551999955/rendition/1080p/1080p.mp4?loc=external&signature=a3f7ecb7655a31c2e4d1608969ed305de6eb8107bb0696588b02a74ac388c559" />
          </video>
        </div>

        <h3>What do our master musicians say about Guitar by Masters?</h3>
        <hr
          style={{
            width: "100%",
            border: "none",
            borderTop: "2px solid var(--dark-gray)",
            marginBottom: "50px",
          }}
        />

        {ComplimentData.map((video, i) => {
          return (
            <ComplimentVideo
              key={i}
              videoId={video.videoId}
              thumbnail={video.posterLink}
              src={video.videoLink}
              artistId={video.artistId}
              artistName={video.artistName}
              handleVideoPlay={handleVideoPlay}
              videoPlaying={videoPlaying}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Compliment;
