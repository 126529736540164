import React, { useState } from "react";
import UserService from "../../../services/UserService";
import { useHistory } from "react-router-dom";
import TokenService from "../../../services/auth/TokenService";
import ColoredButton from "../../../components/buttons/ColoredButton";
import WarningIcon from "../../../components/micro_components/WarningIcon";

const CodePage = ({ email, password, fromGiftCodePath }) => {
  const [code, setCode] = useState("");
  const [focus, setFocus] = useState(false);
  const [PINError, setPINError] = useState(false);

  const history = useHistory();

  const submitCode = () => {
    setPINError(false);
    UserService.submitEmailVerifyCode(email, code)
      .then((response) => {
        // console.log(response.data);

        TokenService.setUser(response.data);
        // if payed
        if (response.data.accessToken) {
          if (fromGiftCodePath) {
            history.push(fromGiftCodePath);
            return;
          }
          history.push("/userhome");
        }
        // if not payed
        if (!response.data.accessToken) {
          if (fromGiftCodePath) {
            history.push({
              pathname: "/payment",
              state: { email: email, password: password, from: fromGiftCodePath },
            });
          } else {
            history.push({
              pathname: "/payment",
              state: { email: email, password: password },
            });
          }

        }
      })
      .catch((e) => {
        console.log("error on code: " + e);
        setPINError(true);
      });
  };

  return (
    <div className="email-sent-container">
      <div className="email-sent-text text2">
        Please enter your email confirmation token code sent to{" "}
        <span className="email-sent__email">{email}</span>
      </div>
      <div className="email-code">
        <input
          className={`email-code__input ${PINError ? "error" : ""}`}
          maxLength={6}
          value={code}
          onChange={(e) => {
            setCode(e.target.value);
          }}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") submitCode();
          }}
        />
        <WarningIcon
          label="PIN is incorrect "
          absolute
          tooltipHeight="2rem"
          left="105%"
          visible={PINError}
          tooltipVisible={!focus && PINError}
          position="above"
        />
      </div>
      <ColoredButton text="Submit" onClick={submitCode} />
    </div>
  );
};

export default CodePage;
