import React, { useState, useEffect, useLayoutEffect } from "react";
import BookCard from "../../../components/cards/BookCard";
import { SocialIcon } from "react-social-icons";
import { BiWorld } from "react-icons/bi";
import ArtistServices from "../../../services/ArtistService";
import BookService from "../../../services/BookService";
import TokenService from "../../../services/auth/TokenService";
import UserService from "../../../services/UserService";
import LoadingContentSpinner from "../../../components/loading/LoadingContentSpinner";
import BottomBannerModal from "../../../components/modal/BottomBannerModal/BottomBannerModal";
import { ComplimentData } from "../../complimentpage/ComplimentData";
import ComplimentVideo from "../../complimentpage/ComplimentVideo";
import { useHistory } from "react-router-dom";

import "./artistprofile.css";
import ImageLoader from "../../../components/image/ImageLoader";
import ReactMarkdown from "react-markdown";

const ArtistProfile = (props) => {
  const [hasSocialmedia, sethasSocialmedia] = useState(false);
  const [artist, setArtist] = useState([]);
  const [bookdata, setbookData] = useState([]);
  const [hasBooks, setHasBooks] = useState(false);
  const [artistDescription, setArtistDescription] = useState("");
  const [likedBooks, setLikedBooks] = useState([]);
  const [showBottomBanner, setShowBottomBanner] = useState(false);

  const [videoPlaying, setVideoPlaying] = useState(null);

  const handleVideoPlay = (videoId) => {
    setVideoPlaying(videoId);
  };

  const [complimentVideos, setComplimentVideos] = useState([]);

  const [loadingStatus, setLoadingStatus] = useState({
    // image: false,
    desc: false,
    books: true,
  }); //need 3
  const [loadingContent, setLoadingContent] = useState(true);

  const { artistId } = props.match.params;
  const history = useHistory();

  useEffect(() => {
    ArtistServices.getArtistbyId(artistId).then((response) => {
      setArtist(response.data);
    });
    ArtistServices.getArtistDescription(artistId)
      .then((response) => {
        setArtistDescription(response.data);
        setLoadingStatus((s) => ({ ...s, desc: true }));
      })
      .catch((e) => {
        setLoadingStatus((s) => ({ ...s, desc: true }));
      });

    const getArtistBooks = () => {
      ArtistServices.getBooksfromArtist(artistId)
        .then((response) => {
          setbookData(response.data);
          setLoadingStatus((s) => ({ ...s, books: true }));
        })
        .catch((e) => {
          console.log(e);
        });
    };

    getArtistBooks();
  }, [artistId]);

  useEffect(() => {
    const checkArtistBooks = () => {
      bookdata && bookdata !== undefined && bookdata.length === 0 ? setHasBooks(false) : setHasBooks(true);
    };
    checkArtistBooks();
  }, [bookdata]);

  useEffect(() => {
    let temp = [];
    const checkCompliments = () => {
      ComplimentData.forEach((video) => {
        if (video.artistId.toString() === artistId) {
          temp.push(video);
        }
      });
    };

    setComplimentVideos(temp);

    checkCompliments();
  }, [artistId]);

  useLayoutEffect(() => {
    const getArtistSocial = () => {
      if (
        artist.artist_website_link ||
        artist.facebook_link ||
        artist.instagram_link ||
        artist.spotify_link ||
        artist.youtube_link
      ) {
        sethasSocialmedia(true);
      } else {
        sethasSocialmedia(false);
      }
    };
    getArtistSocial();
  });

  useLayoutEffect(() => {
    const getHearts = () => {
      UserService.getLikedBooks(TokenService.getUser().id).then((response) => {
        setLikedBooks(response.data);
      });
    };
    const dontgethearts = () => {
      console.log("no likes");
    };

    TokenService.getUser() ? getHearts() : dontgethearts();
  }, []);

  const resetLikedBooks = () => {
    UserService.getLikedBooks(TokenService.getUser().id).then((response) => {
      setLikedBooks(response.data);
    });
  };

  useEffect(() => {
    if (loadingStatus.books && loadingStatus.desc) {
      setLoadingContent(false);
    }
  }, [loadingStatus]);

  const openBanner = () => {
    // set
    setShowBottomBanner(!showBottomBanner);
  };

  const goToSignUp = () => {
    history.push({
      pathname: "/login",
      fromReg: true,
    });
  };

  return (
    <div
      className={TokenService.getUser() ? "artist-details-container user-loggedin-margin" : "artist-details-container"}
    >
      {loadingContent ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "75vh",
          }}
        >
          <LoadingContentSpinner />
        </div>
      ) : (
        <div className="artist-display fadeInBottom animated">
          <div className="artist-banner">
            <div className="artist-profilepic">
              <ImageLoader
                style={{
                  flex: "1",
                  height: "100%",
                  width: "500px",
                  borderRadius: "1rem",
                }}
                src={`https://da9nh4xe5cncw.cloudfront.net/${artist.id}/${artist.mainImageURL}`}
                alt="profile pic"
              />
            </div>

            <div className="biography">
              <h2>
                {artist ? artist.name : ""} {artist && artist.nationality && `- ${artist.nationality}`}
              </h2>
              <ReactMarkdown linkTarget={"_blank"}>{artistDescription ? artistDescription : ""}</ReactMarkdown>

              {hasSocialmedia ? (
                <div className="social-icons">
                  <h3>Follow {artist.name} on:</h3>
                  <div className="social-icons-container">
                    {artist.youtube_link ? (
                      <SocialIcon url={artist.youtube_link} className="social" target="_blank" />
                    ) : (
                      ""
                    )}
                    {artist.artist_website_link ? (
                      <a href={artist.artist_website_link} target="_blank">
                        <BiWorld className="social personal" />
                      </a>
                    ) : (
                      ""
                    )}
                    {artist.instagram_link ? (
                      <SocialIcon url={artist.instagram_link} className="social" target="_blank" />
                    ) : (
                      ""
                    )}
                    {artist.facebook_link ? (
                      <SocialIcon url={artist.facebook_link} className="social" target="_blank" />
                    ) : (
                      ""
                    )}
                    {artist.spotify_link ? (
                      <SocialIcon url={artist.spotify_link} className="social" target="_blank" />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <h2>Books featuring {artist.name}</h2>
          {hasBooks ? (
            <div className="artist-books">
              {bookdata.map((book, i) => {
                const image = BookService.getBookCoverSrc(book.id, book.bookCoverLink);
                return (
                  <BookCard
                    key={i}
                    bookId={book.id}
                    image={image}
                    title={book.title}
                    roles={book.roles}
                    tags={book.categories}
                    video={book.video}
                    likedBooks={likedBooks}
                    resetLikedBooks={resetLikedBooks}
                    openBanner={openBanner}
                  />
                );
              })}
            </div>
          ) : (
            <div className="artist-books">
              <h2>Coming Soon</h2>
            </div>
          )}
          {complimentVideos.length > 0 ? (
            <div
              style={{
                width: "100%",
                height: "fit-content",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <h3 style={{ width: "100%", margin: "15px 0" }}>{artist.name} on Guitar by Masters</h3>
              {complimentVideos.map((video, i) => {
                return (
                  <ComplimentVideo
                    key={i}
                    videoId={video.videoId}
                    thumbnail={video.posterLink}
                    src={video.videoLink}
                    artistId={video.artistId}
                    artistName={video.artistName}
                    handleVideoPlay={handleVideoPlay}
                    videoPlaying={videoPlaying}
                  />
                );
              })}
            </div>
          ) : (
            ""
          )}
        </div>
      )}

      {showBottomBanner && (
        <BottomBannerModal
          isOpen={showBottomBanner}
          onClick={goToSignUp}
          buttonText={"Get Started"}
          message={
            <>
              <p>You need an account in order to favorite a book.</p>
              <p>Sign up for your free trial today! Plans start at only $6.99/month.</p>
            </>
          }
        />
      )}
    </div>
  );
};

export default ArtistProfile;
