import { FUNDING, PayPalButtons, PayPalScriptProvider, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import "./PaypalButton.css";
import PaypalService from "../../../services/stripe/PaypalService";
import { useEffect } from "react";
import { useState } from "react";

function PaypalButton(props) {
  return (
    <PayPalButtons
      className="paypal"
      onApprove={(data, actions) => {
        console.log("onApprove", data, actions);
        props.setLoading(true);
        PaypalService.createSubscription(data.orderID, data.subscriptionID).then(() => props.onSuccess("Paypal"));
      }}
      createSubscription={(data, actions) => {
        console.log(props)

        return actions.subscription
          .create({
            plan_id: props.planId,
            custom_id: (Math.floor(Math.random()) * 10000).toString(), // doesn't matter
          })
          .then((orderId) => {
            console.log("orderId", orderId);
            return orderId;
          });
      }}
      style={{
        color: "blue",
        label: "pay",
        height: 50,
        edges: "rounded",
        tagline: true,
      }}
      fundingSource={FUNDING.PAYPAL}
      // TODO add error handling
      onError={(error) => console.log(error)}
    />
  );
}

function PaypalButtonArea(props) {
  return (
    <PayPalScriptProvider
      options={{
        "client-id": process.env.REACT_APP_PAYPAL_ID,
        components: "buttons",
        intent: "subscription",
        vault: true,
      }}
    >
      {props.tier === "annually" && (
        <PaypalButton
          planId={process.env.REACT_APP_PAYPAL_YEARLY_PLAN_ID}
          setLoading={props.setLoading}
          onSuccess={props.onSuccess}
        />
      )}
      {props.tier === "monthly" && (
        <PaypalButton
          planId={props.userHasTrialed ? process.env.REACT_APP_PAYPAL_MONTHLY_NO_TRIAL_PLAN_ID : process.env.REACT_APP_PAYPAL_MONTHLY_PLAN_ID}
          setLoading={props.setLoading}
          onSuccess={props.onSuccess}
        />
      )}
    </PayPalScriptProvider>
  );
}

export default PaypalButtonArea;
