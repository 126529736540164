import React, { useState, useEffect, useContext } from 'react';
import { NotificationBarContext } from '../../context/NotificationBarContext';

import { useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from "react-router";
import './GiftComplete.css';

// const stripePromise = loadStripe('pk_test_51K9dKDH47j5FB65FRETAnEe5ggnwxQZEP2wQdoODsIPlxGOWuNtePZIf1O5YwQWcobk2ibbwga7oPgnLUaexZrLQ00LPSd8i5C');

function GiftComplete(props) {
    const [checkoutSession, setCheckoutSession] = useState(null);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const history = useHistory();
    const { hideNotificationBar, showNotificationBar } = useContext(NotificationBarContext); // Use context
    useEffect(() => {
        hideNotificationBar()
        return () => {
            showNotificationBar()
        }
    }, []);
    useEffect(() => {
        const fetchSession = async () => {
            const query = new URLSearchParams(location.search);
            const sessionId = query.get('session_id');

            if (!sessionId) {
                console.error('Session ID not found');
                return;
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/payment/retrieve-session/${sessionId}`);
                const sessionData = await response.json()
                console.log("🚀 ~ file: GiftComplete.jsx:27 ~ fetchSession ~ sessionData:", sessionData)
                // console.log("🚀 ~ file: GiftComplete.jsx:27 ~ fetchSession ~ response:", await response.json())
                // const data = await response.json();
                // const sessionData = JSON.parse(response);
                // console.log("🚀 ~ file: GiftComplete.jsx:27 ~ fetchSession ~ sessionData:", sessionData)

                if (response.ok && sessionData.payment_status === 'paid') {
                    setCheckoutSession(sessionData);
                } else {
                    console.error('Payment not successful or session not found');
                }
            } catch (error) {
                console.error('Error fetching session:', error);
            }

            setLoading(false);
        };

        fetchSession();
    }, [location.search]);
    if (loading) {
        return <div className="gift-complete-container">Loading...</div>;
    }

    if (!checkoutSession) {
        return <div className="gift-complete-container">Payment not successful or session not found.</div>;
    }

    return (
        <div className="gift-complete-container  ">
            <div className="session-details">
                <h1>Thank You for Your Purchase!</h1>
                <h2>An email confirmation has been sent to the recipient.</h2>


                {/* <p><strong>Session ID:</strong> {checkoutSession.id}</p> */}
                <p><strong>Payment Status:</strong> {checkoutSession.payment_status}</p>
                <p><strong>Total:</strong> ${checkoutSession.amount_total / 100}</p>
                {/* <p><strong>view a copy of your reciept</strong> ${checkoutSession.amount_total / 100}</p> */}
            </div>

            <button className="colored-button-gift-complete " onClick={() => { history.push("/gift/giftCenter") }}>Gift Center</button>
        </div>
    );
}

export default GiftComplete;
