import React, { useEffect, useContext } from "react";
import { Context } from "../auth/state/Store";
import ColoredButton from "../buttons/ColoredButton";
import LoadingContentSpinner from "./LoadingContentSpinner";
import "./YesNoModal.css";
import { ReactComponent as Logo } from "../../assets/GbM-logo.svg";
import { ReactComponent as XButton } from "../../assets/x.svg";

const YesNoModal = ({
  onYes,
  onNo,
  onClose,
  heading,
  prompt,
  errorMessage,
  subErrorMessage,
  successMessage,
  success,
  subSuccessMessage,
  buttonMessage,
  error,
  isLoading,
  isOpen,
}) => {
  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      dispatch({ type: "DIMMER_ON" });
    } else {
      document.body.style.overflow = null;
      dispatch({ type: "DIMMER_OFF" });
    }
  }, [isOpen]);

  useEffect(() => {
    if (!state.dimmer) onClose();
  }, [state.dimmer]);

  return (
    <div style={{ display: isOpen ? "block" : "none" }}>
      <div className={"large-modal"} style={{ height: "50vh" }}>
        {isLoading ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingContentSpinner isLoading={isLoading} />
          </div>
        ) : (
          // message
          <div className="large-modal-modal">
            {!success && !error && (
              <>
                <Logo fill={"white"} />
                <div className="large-modal-heading">
                  <div className="large-modal__primary-heading">{heading}</div>
                  <div className="large-modal__prompt">{prompt}</div>
                </div>

                <div className="large-modal-buttons">
                  <ColoredButton onClick={onYes} text={"Yes"} />
                  <ColoredButton onClick={onNo} text={"No"} />
                </div>
              </>
            )}
            {success && (
              <div className="large-modal-success">
                <div className="large-modal__success-message">
                  <div className="large-modal__success-message_main">{successMessage}</div>
                  <div className="large-modal__success-message_sub">{subSuccessMessage}</div>
                </div>
                <div className="large-modal-buttons">
                  <ColoredButton text={buttonMessage} onClick={onClose} />
                </div>
              </div>
            )}
            {error && !success && (
              <div className="large-modal-success">
                <div className="large-modal__success-message">
                  <div className="large-modal__success-message_main">{errorMessage}</div>
                  <div className="large-modal__success-message_sub">{subErrorMessage}</div>
                </div>
                <div className="large-modal-buttons">
                  <ColoredButton text={buttonMessage} onClick={onClose} />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="large-modal-x" onClick={onClose}>
          <XButton stroke={"white"} strokeWidth={75} />
        </div>
      </div>
    </div>
  );
};

export default YesNoModal;
