import { useEffect, useState } from "react";
import React from "react";
import BookDataService from "../../services/BookService";
import { Link } from "react-router-dom";
import ImageLoader from "../image/ImageLoader";
import { timeConverter } from "../../helpers/FormatFunctions";

import "./styles/SmallBookCard.css";

function SmallBookCard({ bookId, viewedBook }) {
  const [book, setBook] = useState([]);
  const [bookDetails, setBookDetails] = useState([]);
  const [currentChapter, setCurrentChapter] = useState();
  const [currentPage, setCurrentPage] = useState();

  const image = book.coverFileName ? BookDataService.getBookCoverSrc(bookId, book.coverFileName) : "";

  useEffect(() => {
    const getBook = () => {
      BookDataService.getBookbyId(bookId).then((response) => {
        setBook(response.data);
      });
    };
    getBook();
  }, [bookId]);

  useEffect(() => {
    const getDetails = () => {
      BookDataService.getPageVisitDetails(viewedBook.id).then((response) => {
        setBookDetails(response.data);
      });
    };
    getDetails();
  }, [book, viewedBook.id]);

  useEffect(() => {
    const getBookDetails = () => {
      for (let a = 0; a < bookDetails.length; a++) {
        let chapter = bookDetails[a];
        for (let b = 0; b < chapter.pages.length; b++) {
          let page = chapter.pages[b];
          if (page.id === viewedBook.page) {
            setCurrentChapter(chapter);
            setCurrentPage(page);
            break;
          }
        }
      }
    };
    getBookDetails();
  }, [bookDetails, viewedBook.page]);

  return (
    <div className="small-book-card-container">
      <Link
        style={{ height: "inherit" }}
        to={{
          pathname: `/reader/${bookId}`,
          state: { currentChapter, currentPage },
        }}
        className="no-underline"
      >
        <div className="small-book-card-contents">
          <div className="book-cover">
            <ImageLoader src={image} alt={`Guitar by Masters classical guitar book ${book.title}`} />
          </div>
          <div className="book-details">
            <span className="title text1">{book.title}</span>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {currentChapter && <span>Chapter: {currentChapter.name}</span>}
              {/* should it take you back to page or chapter you left off at */}
              <span>{currentPage ? "Page: " + currentPage.page_number : "[page number]"}</span>
            </div>
          </div>
        </div>
        <div className="date-holder">
          <span>{`Last viewed: ${timeConverter(viewedBook.date)}`}</span>
        </div>
      </Link>
    </div>
  );
}

export default SmallBookCard;
