import http from "../http-common";
import { DOMAIN_ID } from "../config.js";

const attemptRegister = (data) => {
  // console.log("U1");
  return http.post(`customer/registration`, data);
};

const submitEmailVerifyCode = (email, code) => {
  // console.log("U2");
  return http.post(`api/auth/emailconfirm`, {
    email: email,
    confirmCode: code,
    domainId: DOMAIN_ID,
  });
};

const getAccountDetails = (email, code) => {
  // console.log("U3");
  return http.post(`api/auth/accountdetails/${DOMAIN_ID}`, {
    email: email,
    token: code,
  });
};

const cancelSubscription = (email, code) => {
  // console.log("U4");
  return http.post(`payment/cancelSubscription`, {
    email: email,
    token: code,
  });
};

const likeBooks = (user_token, book_id) => {
  // console.log("U5");
  return http.post(`/customers/${user_token}/books/${book_id}/like`);
};

const unlikeBooks = (user_token, book_id) => {
  // console.log("U6");
  return http.post(`/customers/${user_token}/books/${book_id}/unlike`);
};

const saveBooks = (user_token, book_id) => {
  // console.log("U7");
  return http.post(`/my/${user_token}/books/${book_id}/save`);
};

const unsaveBooks = (user_token, book_id) => {
  // console.log("U8");
  return http.post(`/my/${user_token}/books/${book_id}/unsave`);
};

const getSavedBooks = (user_id) => {
  // console.log("U9");
  return http.get(`/my/${user_id}/saves`);
};

const getLikedBooks = (user_id) => {
  // console.log("U10");
  return http.get(`/customers/${user_id}/likes`);
};

const getSuggestedBooks = (user_id) => {
  // console.log("U11");
  return http.get(`/my/${user_id}/suggested`);
};

const unsubscribeToNewsletter = (user_id) => {
  // console.log("U12");
  return http.post(`customers/newsletter`, {
    user_id: user_id,
    newsletter: false,
  });
};

const subscribeToNewsletter = (user_id) => {
  // console.log("U13");
  return http.post(`customers/newsletter`, {
    user_id: user_id,
    newsletter: true,
  });
};

const mailchimpSubscribe = (email, name = "") => {
  // console.log("U14");
  return http.post(`/mailchimp/member`, { email_address: email, name: name, domainId: DOMAIN_ID });
};

const submitContactMessage = (name, email, message) => {
  // console.log("U15");
  return http.post("/domains/1/contact", {
    name: name,
    email: email,
    message: message,
  });
};

const getRecentlyVisitedBooks = (user_id) => {
  // console.log("U16");
  return http.get(`/getRecentlyVisitedBooksByUser/${user_id}`);
};

const UserService = {
  attemptRegister,
  submitEmailVerifyCode,
  getAccountDetails,
  cancelSubscription,
  likeBooks,
  unlikeBooks,
  saveBooks,
  unsaveBooks,
  getSavedBooks,
  getLikedBooks,
  getSuggestedBooks,
  unsubscribeToNewsletter,
  subscribeToNewsletter,
  submitContactMessage,
  mailchimpSubscribe,
  getRecentlyVisitedBooks,
};

export default UserService;
