import { Route, Redirect } from "react-router-dom";
import TokenService from "../../services/auth/TokenService";

const RestrictedComponent = ({
  component: Component,
  redirectPath,
  nonAuthComponent: NonAuthComponent,
  likedBooks: likedBooks,
  from,
  toPayment,
  ...rest
}) => {
  // const [pendingAuth, setPendingAuth] = useState(true);

  return (
    <Route
      {...rest}
      // onEnter={checkLoginStatus}
      render={(props) =>
        TokenService.getUser() ? (
          TokenService.getUser().accessToken ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        ) : (
          <>
            <Redirect
              to={{ pathname: redirectPath, state: { from: props.location } }}
            />
          </>
        )
      }
    />
  );
};

export default RestrictedComponent;
