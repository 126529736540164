import { useState, useContext, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import TokenService from "../../services/auth/TokenService";
import SearchBar from "../search/SearchBar";
import AboutDropdown from "./AboutDropdown/AboutDropdown";
import ColoredButton from "../buttons/ColoredButton";
import PlainButton from "../buttons/PlainButton";
import OutlinedButton from "../buttons/OutlinedButton";
import NotificationBar from "./NotificationBar";

//logged in state
import { Context } from "../../components/auth/state/Store";

// icons
import { HiMenu } from "react-icons/hi";
import { BsFillPersonFill } from "react-icons/bs";
import { ReactComponent as Logo } from "../../assets/GbM-logo.svg";

import "./navbar.css";

const Navbar = ({ clearCurrentGroup, currentGroup, setCurrentGroup }) => {
  const [userNav, setUserNav] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [state, dispatch] = useContext(Context);
  const [userLetter, setUserLetter] = useState(null);
  const history = useHistory();

  const toggleMenu = () => {
    if (!state.showMobileSidebar) dispatch({ type: "MOBILE_SIDEBAR_SHOW" });
    else dispatch({ type: "MOBILE_SIDEBAR_HIDE" });
  };

  const logoutClick = () => {
    dispatch({ type: "LOG_OUT" });
    TokenService.removeUser();
    setLoggedIn(false);
  };

  useEffect(() => {
    const user = TokenService.getUser();
    if (user) {
      if (user.email && user.email.length > 0) {
        setUserLetter(user.email[0]);
      }
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [state.loggedIn]);

  const userNavFalse = useCallback((e) => {
    setUserNav(false);
  }, []);

  const userNavTrue = () => setUserNav(true);

  useEffect(() => {
    setTimeout(() => {
      if (userNav) {
        window.addEventListener("click", userNavFalse);
      } else {
        window.removeEventListener("click", userNavFalse);
      }
    }, 90);
  }, [userNav]);

  const navigateToSignUp = () => {
    history.push({
      pathname: "/login",
      fromReg: true,
    });
  };

  const navigateToLogin = () => {
    if (history.location.state && history.location.state.travelto) {
      history.push({
        pathname: "/login",
        fromReg: false,
        previous: history.location.pathname,
        state: {
          travelto: history.location.state.travelto,
        },
      });
    } else {
      history.push({
        pathname: "/login",
        fromReg: false,
        previous: history.location.pathname,
      });
    }
  };

  return (
    <nav className="menu">
      <div className="nav-container">
        <div style={{ display: "flex" }}>
          <button className="hamburger-icon" onClick={toggleMenu}>
            <HiMenu />
          </button>
          <div
            className="gbm"
            style={{ pointerEvents: menuOpen ? "none" : "auto" }}
          >
            <Link to={"/"} className={`logo ${menuOpen ? "hide" : ""}`}>
              <Logo fill="white" />
            </Link>
            <div
              style={{
                width: "max-content",
                flexShrink: 0,
                marginLeft: "1rem",
              }}
            >
              <div
                className="navbar__guitar-by-masters label2"
                style={{ opacity: 0.5, letterSpacing: "1px" }}
              >
                Guitar by Masters
              </div>
            </div>
          </div>
        </div>
        {!TokenService.getUser() && (
          <div className="nav-link-container">
            <>
              <div className="nav-link">
                <AboutDropdown />
              </div>
              <hr />
              <Link to="/artistcatalog" className="nav-link no-underline">
                <PlainButton text={"Artists"} />
              </Link>
              <hr />
              <Link to="/bookcatalog" className="nav-link no-underline">
                <PlainButton text={"Catalog"} />
              </Link>
              <hr />
            </>
          </div>
        )}
        <SearchBar clearCurrentGroup={clearCurrentGroup} />
        {!TokenService.getUser() ? (
          <div className="login-signup-buttons-container">
            <>
              <ColoredButton
                text={"Sign up"}
                style={{
                  height: "1rem",
                  borderRadius: "1rem",
                }}
                className="screen-l"
                onClick={navigateToSignUp}
              >
                Sign up
              </ColoredButton>
              <hr className="screen-l" />
              <PlainButton
                className={"login-btn screen-l"}
                text={"Login"}
                onClick={navigateToLogin}
                style={{ height: "90%" }}
              />
            </>
          </div>
        ) : (
          <>
            <div
              className="screen-l"
              style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
            >
              <AboutDropdown />
            </div>
            <div className="welcome-btn" onClick={userNavTrue}>
              {userLetter ? (
                <div className="welcome-btn__letter"> {userLetter} </div>
              ) : (
                <BsFillPersonFill className="welcome-pic" fill="#fff" />
              )}
              <div className={`user-dropdown ${userNav ? "visible" : ""}`}>
                <Link to="/userhome" className="no-underline">
                  My Library
                </Link>
                <Link to="/accountpage" className="no-underline">
                  Account Settings
                </Link>
                <Link to="/" className="no-underline" onClick={logoutClick}>
                  Log Out
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
