import React, { useState } from "react";
import { Link } from "react-router-dom";
import DemoBook from "./homepageComponents/DemoBook";
import OutlinedButton from "../../components/buttons/OutlinedButton";

import "./PracticePal.css";

function PracticePal(props) {
  return (
    <div className="practice-pal">
      {" "}
      <div className="sample-book-info-container">
        <h2 style={{ width: "100%", marginBottom: 0 }}>
          Want to see how it works?{" "}
        </h2>
        <h3>
          Learn <span style={{ fontWeight: "bold" }}>Lágrima</span> with{" "}
          <span style={{ fontWeight: "bold" }}>Denis Azabagić</span> free!
        </h3>

        <Link to="/sample-book/538">
          <DemoBook id={538} />
        </Link>
        <Link to="/sample-book/538">
          <OutlinedButton className="sample-book-btn" text="Check it out" />
        </Link>
      </div>
      <div className="colored-label label1">Avoid practicing mistakes</div>
      <h3 className="declaration">
        Interactive technology for every piece, powered by Practice Pal&#xae;
      </h3>
      <div className="item-group">
        <div className="pp-item-image" id="iPad-portrait">
          <div>
            <img
              src="https://dpi-assets.s3.us-east-2.amazonaws.com/GbM/landing-page/iPad_portrait.png"
              alt="iPad-vertical"
            />
            <video playsInline={true} loop={true} autoPlay={true} muted={true}>
              <source
                src="https://dpi-assets.s3.us-east-2.amazonaws.com/GbM/landing-page/videos/iPad+portrait.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
        <div className="pp-item-description">
          <div className="screen-l">
            <h2>You got the touch. You&nbsp;got&nbsp;the&nbsp;power.</h2>
          </div>
          <div className="screen-m screen-s">
            <h2>
              You got the touch.
              <br />
              You got the power.
            </h2>
          </div>
          <p className="text2">
            Cut right to the chase. Go to any measure
            in&nbsp;the&nbsp;music&nbsp;with&nbsp;the&nbsp;touch&nbsp;of&nbsp;a&nbsp;finger.
          </p>
        </div>
      </div>
      <div className="item-group">
        <div className="pp-item-image" id="iMac">
          <div>
            <img
              src="https://dpi-assets.s3.us-east-2.amazonaws.com/GbM/landing-page/iMac-gray.png"
              alt="iMac"
            />
            <video playsInline={true} loop={true} autoPlay={true} muted={true}>
              <source
                src="https://dpi-assets.s3.us-east-2.amazonaws.com/GbM/landing-page/videos/iMac.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
        <div className="pp-item-description">
          <h2>Zero in on problem areas.</h2>
          <p className="text2">
            With a couple of taps / clicks, repeat any passage
            that&nbsp;has&nbsp;been&nbsp;keeping&nbsp;you&nbsp;back.
          </p>
        </div>
      </div>
      <div className="item-group">
        <div className="pp-item-image" id="macbook">
          <div>
            <img
              src="https://d9fedpdsh4r16.cloudfront.net/GbM/landing-page/MacBook_Pro.png"
              alt="macbook_pro"
            />
            <video playsInline={true} loop={true} autoPlay={true} muted={true}>
              <source
                src="https://d9fedpdsh4r16.cloudfront.net/GbM/landing-page/videos/MacBook_Pro.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
        <div className="pp-item-description">
          <div>
            <h2>Take your time.</h2>
            <p className="text2">
              Slow things down and take a
              close&nbsp;look&nbsp;at&nbsp;how&nbsp;it's&nbsp;done.
            </p>
          </div>
        </div>
      </div>
      <div className="item-group">
        <div className="pp-item-image" id="GS20U">
          <div>
            <img
              src="https://d9fedpdsh4r16.cloudfront.net/GbM/landing-page/Galaxy_S20_Ultra.png"
              alt="galaxy_s20_ultra"
            />
            <video playsInline={true} loop={true} autoPlay={true} muted={true}>
              <source
                src="https://d9fedpdsh4r16.cloudfront.net/GbM/landing-page/videos/Galaxy_S20_Ultra.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
        <div className="pp-item-description">
          <h2>Help at your fingertips.</h2>
          <p className="text2">
            Don't waste time looking for a single point in an
            hour&#8288;-&#8288;long video. Efficient and
            to&#8288;-&#8288;the&#8288;-&#8288;point instructions are available
            where you need them, when&nbsp;you&nbsp;need&nbsp;them.
          </p>
        </div>
      </div>
      <div className="item-group">
        <div className="pp-item-image" id="iPad-landscape">
          <div>
            <img
              src="https://dpi-assets.s3.us-east-2.amazonaws.com/GbM/landing-page/iPad_landscape.png"
              alt="iPad-horizontal"
            />
            <video playsInline={true} loop={true} autoPlay={true} muted={true}>
              <source
                src="https://d9fedpdsh4r16.cloudfront.net/GbM/landing-page/videos/iPad+landscape.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
        <div className="pp-item-description">
          <h2>Translations? We&nbsp;have&nbsp;them.</h2>
          <p className="text2" style={{ marginBottom: "0.5rem" }}>
            Music may be a universal language, but&nbsp;words&nbsp;aren't.
          </p>
          <p className="text2">
            Fortunately, we offer each lesson in multiple languages,
            and&nbsp;we&nbsp;are&nbsp;always&nbsp;adding&nbsp;more.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PracticePal;

// <div className="switcher">
//   <div
//     className={`switch ${tab === 0 ? "active" : ""}`}
//     onClick={() => {
//       switchContent(0);
//     }}
//   >
//     Interactive Scores
//   </div>
//   <div
//     className={`switch ${tab === 1 ? "active" : ""}`}
//     onClick={() => {
//       switchContent(1);
//     }}
//   >
//     Effective Lessons
//   </div>
// </div>
// <hr />
// <div className="practice-pal-body">
//   <div className="left-side">
//     <div
//       className={`switchable-content ${
//         tab === 0 ? "visible" : ""
//       } ${tab1LeftAnimation}`}
//     >
//       Content 1
//     </div>
//     <div
//       className={`switchable-content ${
//         tab === 1 ? "visible" : ""
//       } ${tab2LeftAnimation}`}
//     >
//       Content 2
//     </div>
//   </div>
//   <div className="right-side">
//     <div
//       className={`switchable-content ${
//         tab === 0 ? "visible" : ""
//       } ${tab1RightAnimation}`}
//     >
//       <div className="hype-item">
//         <div className="screen-l">
//           <h2>You got the touch. You got the power.</h2>
//         </div>
//         <div className="screen-m">
//           <h2>
//             You got the touch.
//             <br />
//             You got the power.
//           </h2>
//         </div>
//         <p>
//           Cut right to the chase. Go to any point in the music with the
//           touch of a finger.
//         </p>
//       </div>
//       <div className="hype-item">
//         <h2>Zero in on problem areas.</h2>
//         <p>
//           With a couple of taps / clicks, repeat any passage that has been
//           keeping you back.
//         </p>
//       </div>
//       <div className="hype-item">
//         <h2>Take your time.</h2>
//         <p>Slow things down and take a close look at how it’s done.</p>
//       </div>
//     </div>
//     <div
//       className={`switchable-content ${
//         tab === 1 ? "visible" : ""
//       } ${tab2RightAnimation}`}
//     >
//       <div className="hype-item">
//         <h2>Call up the teacher.</h2>
//         <p>
//           Don’t waste time looking for a single point in an hour-long
//           video. Efficient and to-the-point instructions are available
//           where you need them, when you need them.
//         </p>
//       </div>
//       <div className="hype-item">
//         <h2>Translations? We have them.</h2>
//         <p>
//           Music may be a universal language, but words aren't.
//           Fortunately, we offer each lesson in multiple languages, and we
//           are always adding more.
//         </p>
//       </div>
//     </div>
//   </div>
