import { DOMAIN_ID } from "../config";
import http from "../http-common";

const getArtistbyId = (artistId) => {
  // used in artist profile
  return http.get(`/creators/${artistId}/${DOMAIN_ID}`);
};

const getAllCreatorsDetails = (_) => {
  // used in dedicated search page
  return http.get("/creators/1/lastname");
};

const getArtistDescription = (Id) => {
  //used in profile
  return http.get(`/creators/${Id}/description/download`);
};

const getBooksfromArtist = (artistId) => {
  //used in multiple places
  return http.get(`/creators/${artistId}/books/${DOMAIN_ID}`);
};

const getArtistGalleryImage = (artistId, link) => {
  return `https://da9nh4xe5cncw.cloudfront.net/${artistId}/${link}`;
};

const getArtistMainImage = (artistId, Link) => {
  // console.log("A7");
  return `https://da9nh4xe5cncw.cloudfront.net/${artistId}/${Link}`;
};

const getArtsitByLastName = () => {
  // console.log("A8");
  return http.get(`/creators/${DOMAIN_ID}/lastname`);
};

const searchArtists = (searchText) => {
  // console.log("A8");
  return http.get(`/creators/search/${searchText}`);
};

const getFeaturedArtists = () => {
  //used in artist page and home
  return http.get(`/getFeaturedArtists/${DOMAIN_ID}`);
};

const ArtistService = {
  getArtistbyId,
  getAllCreatorsDetails,
  getArtistDescription,
  getBooksfromArtist,
  getArtistGalleryImage,
  getArtistMainImage,
  getArtsitByLastName,
  getFeaturedArtists,
  searchArtists,
};

export default ArtistService;
