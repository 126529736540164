import TokenService from "../../../services/auth/TokenService";

const Reducer = (state, action) => {
  switch (action.type) {
    case "LOG_OUT":
      TokenService.removeUser();
      return {
        ...state,
        loggedIn: false,
      };
    case "LOG_IN":
      return {
        ...state,
        loggedIn: true,
        showSidebar: true,
      };
    case "ENTER_READER":
      return {
        ...state,
        inReader: true,
      };
    case "EXIT_READER":
      return {
        ...state,
        inReader: false,
      };
    case "DIMMER_ON":
      return {
        ...state,
        dimmer: true,
      };
    case "DIMMER_OFF":
      return {
        ...state,
        dimmer: false,
      };
    case "SIDEBAR_HIDE":
      return {
        ...state,
        showSidebar: false,
      };
    case "SIDEBAR_SHOW":
      return {
        ...state,
        showSidebar: true,
      };
    case "MOBILE_SIDEBAR_HIDE":
      return {
        ...state,
        showMobileSidebar: false,
        dimmer: false,
      };
    case "MOBILE_SIDEBAR_SHOW":
      return {
        ...state,
        showMobileSidebar: true,
        dimmer: true,
      };
    default:
      return state;
  }
};

export default Reducer;
