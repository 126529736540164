import React from 'react'
import "./patentCss.css"

const Patentpage = () => {
    return (
        <div className="patentpage">
            <div className="patentpage-container">
                <div>
                    All content within this site is the sole property of Guitar by Masters LLC. and Digital Publishing Inc. 
                    and is protected by patent, trademark, copyright and other intellectual property laws. 
                    Written consent from Guitar by Masters LLC. and Digital Publishing Inc. 
                    is required if content is to be used outside of the restrictions included in Terms of Use policy. 
                </div>
                <div>Practice Pal technology is patented under U.S. Patent law. We welcome licensing requests for the use of the software. </div>
                <div>We also welcome requests from music publishers to present their published music on Guitar by Masters. </div>
                <div>
                    Please reach out to our legal department at {""} 
                    <a href="mailto:info@guitarbymasters.com" style={{color: "var(--primary-color-light)"}}>
                    info@guitarbymasters.com</a> for further information.
                </div>
            </div>
        </div>
    )
}

export default Patentpage
