import React from "react";

function ArtistLetterButton({ i, artistData, filter, setFilter, letter, setViewAll }) {
  let temp = artistData.filter((artist) => {
    return artist?.lastName?.[0] === letter;
  });
  const disabled = temp.length === 0;

  const selected = filter === letter;

  return (
    <div
      key={i}
      className={`letter-button ${selected ? "selected" : ""} ${disabled ? "disabled" : ""}`}
      onClick={() => {
        setFilter(letter);
        setViewAll(false);
      }}
    >
      {letter}
    </div>
  );
}

export default ArtistLetterButton;
