import React, { useContext } from "react";
import { Context } from "../../../components/auth/state/Store";
import { Link, useHistory } from "react-router-dom";
import ColoredButton from "../../../components/buttons/ColoredButton";
import TokenService from "../../../services/auth/TokenService";
import { useEffect } from "react";

function PaymentErrorModal({ type, close }) {
  const [state, dispatch] = useContext(Context);

  let history = useHistory();

  const logout = () => {
    dispatch({ type: "LOG_OUT" });
    history.push({ pathname: "/login", fromReg: false });
    TokenService.removeUser();
  };

  // useEffect(() => {
  //   console.log("error type: ", type);
  // }, [type]);

  return (
    <>
      {type === "pre-payment" && (
        <>
          <div className="modal-dimmer" />
          <div className="payment-page-modal">
            <div className="payment-modal-header" style={{ marginBottom: "3rem" }}>
              <h1 style={{ marginBottom: "3rem" }}>Uh oh!</h1>
              <span className="text1">
                Our system ran into a problem. But don't worry; your credit card has not been charged.
              </span>
            </div>
            <span className="text2" style={{ marginBottom: "3rem" }}>
              Please log out and log in again.
            </span>
            <ColoredButton text="Return to login page" onClick={logout} />
            <span className="text3" style={{ margin: "1rem 0 0 0" }}>
              If you continue to see this error, please{" "}
              <a
                href="mailto:support@guitarbymasters.com?subject=Issue During Payment"
                style={{
                  textDecoration: "underline",
                  color: "var(--primary-color)",
                }}
              >
                contact us
              </a>
              .
            </span>
          </div>
        </>
      )}
      {type === "login" && (
        <>
          <div className="modal-dimmer" />
          <div className="payment-page-modal">
            <div className="payment-modal-header">
              <h1>Thank you!</h1>
              <h2>Your payment is complete.</h2>
              <h2>Happy practicing!</h2>
            </div>
            <span className="text2">Please click the button below to head to the login page.</span>
            <ColoredButton text="Return to login page" onClick={logout} />
          </div>
        </>
      )}
      {type === "payment" && (
        <>
          <div className="modal-dimmer" />
          <div className="payment-page-modal">
            <span className="text2" style={{ marginBottom: "unset" }}>
              There was an error submitting your payment.
            </span>
            <span className="text2">
              Please verify your information or{" "}
              <a
                href="mailto:support@guitarbymasters.com?subject=Issue During Payment"
                style={{
                  textDecoration: "underline",
                  color: "var(--primary-color)",
                }}
              >
                contact support
              </a>
              .
            </span>
            <ColoredButton text="Close" style={{ marginTop: "1rem" }} onClick={close} />
          </div>
        </>
      )}
    </>
  );
}

export default PaymentErrorModal;
