import TokenService from "../../services/auth/TokenService";

import "./privacy.css";

const Privacy = () => {
  return (
    <div
      className={
        TokenService.getUser()
          ? "privacy-container user-loggedin-margin"
          : "privacy-container"
      }
    >
      <div id="privacy-top" className="privacy">
        <p>Privacy Policy</p>
        <p>Last updated: June 29, 2020</p>
        <p>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </p>
        <p>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </p>
        <h1>Interpretation and Definitions</h1>
        <div></div>
        <h2>Interpretation</h2>
        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
        <h2>Definitions</h2>
        <p>For the purposes of this Privacy Policy:</p>
        <ul>
          <li>
            You means the individual accessing or using the Service, or the
            company, or other legal entity on behalf of which such individual is
            accessing or using the Service, as applicable.
          </li>
          <li>
            Under GDPR (General Data Protection Regulation), You can be referred
            to as the Data Subject or as the User as you are the individual
            using the Service.
          </li>
          <li>
            Company (referred to as either &quot;the Company&quot;,
            &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement)
            refers to Digital Publication Inc., 12900 Preston Road Suite 804.
          </li>
          <li>
            For the purpose of the GDPR, the Company is the Data Controller.
          </li>
          <li>
            Affiliate means an entity that controls, is controlled by or is
            under common control with a party, where &quot;control&quot; means
            ownership of 50% or more of the shares, equity interest or other
            securities entitled to vote for election of directors or other
            managing authority.
          </li>
          <li>
            Account means a unique account created for You to access our Service
            or parts of our Service.
          </li>
          <li>
            Website refers to Guitar by Masters LLC., accessible from
            <a href="http://www.guitarbymasters.com">
              {" "}
              http://www.guitarbymasters.com
            </a>{" "}
            , and other websites managed by Guitar by Masters LLC.
          </li>
          <li>Service refers to the Website.</li>
          <li>Country refers to: Texas, United States</li>
          <li>
            Service Provider means any natural or legal person who processes the
            data on behalf of the Company. It refers to third-party companies or
            individuals employed by the Company to facilitate the Service, to
            provide the Service on behalf of the Company, to perform services
            related to the Service or to assist the Company in analyzing how the
            Service is used. For the purpose of the GDPR, Service Providers are
            considered Data Processors.
          </li>
          <li>
            Third-party Social Media Service refers to any website or any social
            network website through which a User can log in or create an account
            to use the Service.
          </li>
          <li>
            Facebook Fan Page is a public profile named Guitar by Masters,
            specifically created by the Company on the Facebook social network,
            accessible from
            <a
              href="https://www.facebook.com/Guitar-by-Masters-109388588095895
                    "
            >
              {" "}
              https://www.facebook.com/Guitar-by-Masters-109388588095895
            </a>
          </li>
          <li>
            Personal Data is any information that relates to an identified or
            identifiable individual.
          </li>
          <li>
            For the purposes for GDPR, Personal Data means any information
            relating to You such as a name, an identification number, location
            data, online identifier or to one or more factors specific to the
            physical, physiological, genetic, mental, economic, cultural or
            social identity.
          </li>
          <li>
            For the purposes of the CCPA, Personal Data means any information
            that identifies, relates to, describes or is capable of being
            associated with, or could reasonably be linked, directly or
            indirectly, with You.
          </li>
          <li>
            Cookies are small files that are placed on Your computer, mobile
            device or any other device by a website, containing the details of
            Your browsing history on that website among its many uses.
          </li>
          <li>
            Device means any device that can access the Service such as a
            computer, a cellphone or a digital tablet.
          </li>
          <li>
            Usage Data refers to data collected automatically, either generated
            by the use of the Service or from the Service infrastructure itself
            (for example, the duration of a page visit).
          </li>
          <li>
            Data Controller, for the purposes of the GDPR (General Data
            Protection Regulation), refers to the Company as the legal person
            which alone or jointly with others determines the purposes and means
            of the processing of Personal Data.
          </li>
          <li>
            Do Not Track (DNT) is a concept that has been promoted by US
            regulatory authorities, in particular the U.S. Federal Trade
            Commission (FTC), for the Internet industry to develop and implement
            a mechanism for allowing internet users to control the tracking of
            their online activities across websites.
          </li>
          <li>
            Business, for the purpose of the CCPA (California Consumer Privacy
            Act), refers to the Company as the legal entity that collects
            Consumers&#39; personal information and determines the purposes and
            means of the processing of Consumers&#39; personal information, or
            on behalf of which such information is collected and that alone, or
            jointly with others, determines the purposes and means of the
            processing of consumers&#39; personal information, that does
            business in the State of California.
          </li>
          <li>
            Consumer, for the purpose of the CCPA (California Consumer Privacy
            Act), means a natural person who is a California resident. A
            resident, as defined in the law, includes (1) every individual who
            is in the USA for other than a temporary or transitory purpose, and
            (2) every individual who is domiciled in the USA who is outside the
            USA for a temporary or transitory purpose.
          </li>
          <li>
            Sale, for the purpose of the CCPA (California Consumer Privacy Act),
            means selling, renting, releasing, disclosing, disseminating, making
            available, transferring, or otherwise communicating orally, in
            writing, or by electronic or other means, a Consumer&#39;s Personal
            information to another business or a third party for monetary or
            other valuable consideration.
          </li>
        </ul>
        <h1 id="collecting-and-using-personal-data">
          Collecting and using Your Personal Data
        </h1>
        <div></div>
        <h2>Types of Data Collected</h2>
        <h3>Personal Data</h3>
        <p>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </p>
        <p>Email address</p>
        <p>First name and last name</p>
        <p>Phone number</p>
        <p>Address, State, Province, ZIP/Postal code, City</p>
        <p>
          Bank account information in order to pay for products and/or services
          within the Service
        </p>
        <p>Usage Data</p>
        <p>
          When You pay for a product and/or a service via bank transfer, We may
          ask You to provide information to facilitate this transaction and to
          verify Your identity. Such information may include, without
          limitation:
        </p>
        <p>Date of birth</p>
        <p>Passport or National ID card</p>
        <p>Bank card statement</p>
        <p>Other information linking You to an address</p>
        <h3>Usage Data</h3>
        <p>Usage Data is collected automatically when using the Service.</p>
        <p>
          Usage Data may include information such as Your Device&#39;s Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </p>
        <p>
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </p>
        <p>
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </p>
        <h3>Tracking Technologies and Cookies</h3>
        <p>
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. Tracking technologies
          used are beacons, tags, and scripts to collect and track information
          and to improve and analyze Our Service.
        </p>
        <p>
          You can instruct Your browser to refuse all Cookies or to indicate
          when a Cookie is being sent. However, if You do not accept Cookies,
          You may not be able to use some parts of our Service.
        </p>
        <p>
          Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies.
          Persistent Cookies remain on your personal computer or mobile device
          when You go offline, while Session Cookies are deleted as soon as You
          close your web browser. Learn more about cookies: All About Cookies .
        </p>
        <p>
          We use both session and persistent Cookies for the purposes set out
          below:
        </p>
        <ul>
          <li>Necessary / Essential Cookies</li>
          <li>Type: Session Cookies</li>
          <li>Administered by: Us</li>
          <li>
            Purpose: These Cookies are essential to provide You with services
            available through the Website and to enable You to use some of its
            features. They help to authenticate users and prevent fraudulent use
            of user accounts. Without these Cookies, the services that You have
            asked for cannot be provided, and We only use these Cookies to
            provide You with those services.
          </li>
          <li>Cookies Policy / Notice Acceptance Cookies</li>
          <li>Type: Persistent Cookies</li>
          <li>Administered by: Us</li>
          <li>
            Purpose: These Cookies identify if users have accepted the use of
            cookies on the Website.
          </li>
          <li>Functionality Cookies</li>
          <li>Type: Persistent Cookies</li>
          <li>Administered by: Us</li>
          <li>
            Purpose: These Cookies allow us to remember choices You make when
            You use the Website, such as remembering your login details or
            language preference. The purpose of these Cookies is to provide You
            with a more personal experience and to avoid You having to re-enter
            your preferences every time You use the Website.
          </li>
          <li>Tracking and Performance Cookies</li>
          <li>Type: Persistent Cookies</li>
          <li>Administered by: Third-Parties</li>
          <li>
            Purpose: These Cookies are used to track information about traffic
            to the Website and how users use the Website. The information
            gathered via these Cookies may directly or indirectly identify you
            as an individual visitor. This is because the information collected
            is typically linked to a pseudonymous identifier associated with the
            device you use to access the Website. We may also use these Cookies
            to test new advertisements, pages, features or new functionality of
            the Website to see how our users react to them.
          </li>
          <li>Targeting and Advertising Cookies</li>
          <li>Type: Persistent Cookies</li>
          <li>Administered by: Third-Parties</li>
          <li>
            Purpose: These Cookies track your browsing habits to enable Us to
            show advertising which is more likely to be of interest to You.
            These Cookies use information about your browsing history to group
            You with other users who have similar interests. Based on that
            information, and with Our permission, third party advertisers can
            place Cookies to enable them to show adverts which We think will be
            relevant to your interests while You are on third party websites.
          </li>
        </ul>
        <p>
          For more information about the cookies we use and your choices
          regarding cookies, please visit our Cookies Policy or the Cookies
          section of our Privacy Policy.
        </p>
        <h3>Use of Your Personal Data</h3>
        <p>The Company may use Personal Data for the following purposes:</p>
        <p>
          To provide and maintain our Service, including to monitor the usage of
          our Service.
        </p>
        <p>
          To manage Your Account: to manage Your registration as a user of the
          Service. The Personal Data You provide can give You access to
          different functionalities of the Service that are available to You as
          a registered user.
        </p>
        <p>
          For the performance of a contract: the development, compliance and
          undertaking of the purchase contract for the products, items or
          services You have purchased or of any other contract with Us through
          the Service.
        </p>
        <p>
          To contact You: To contact You by email, telephone calls, SMS, or
          other equivalent forms of electronic communication, such as a mobile
          application&#39;s push notifications regarding updates or informative
          communications related to the functionalities, products or contracted
          services, including the security updates, when necessary or reasonable
          for their implementation.
        </p>
        <p>
          To provide You with news, special offers and general information about
          other goods, services and events which we offer that are similar to
          those that you have already purchased or enquired about unless You
          have opted not to receive such information.
        </p>
        <p>
          To manage Your requests: To attend and manage Your requests to Us.
        </p>
        <p>
          We may share your personal information in the following situations:
        </p>
        <p>
          With Service Providers: We may share Your personal information with
          Service Providers to monitor and analyze the use of our Service, to
          show advertisements to You to help support and maintain Our Service,
          to advertise on third party websites to You after You visited our
          Service, for payment processing, to contact You.
        </p>
        <p>
          For Business transfers: We may share or transfer Your personal
          information in connection with, or during negotiations of, any merger,
          sale of Company assets, financing, or acquisition of all or a portion
          of our business to another company.
        </p>
        <p>
          With Affiliates: We may share Your information with Our affiliates, in
          which case we will require those affiliates to honor this Privacy
          Policy. Affiliates include Our parent company and any other
          subsidiaries, joint venture partners or other companies that We
          control or that are under common control with Us.
        </p>
        <p>
          With Business partners: We may share Your information with Our
          business partners to offer You certain products, services or
          promotions.
        </p>
        <p>
          With other users: when You share personal information or otherwise
          interact in the public areas with other users, such information may be
          viewed by all users and may be publicly distributed outside. If You
          interact with other users or register through a Third-Party Social
          Media Service, Your contacts on the Third-Party Social Media Service
          may see Your name, profile, pictures and description of Your activity.
          Similarly, other users will be able to view descriptions of Your
          activity, communicate with You and view Your profile.
        </p>
        <h3>Retention of Your Personal Data</h3>
        <p>
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </p>
        <p>
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </p>
        <h3>Transfer of Your Personal Data</h3>
        <p>
          Your information, including Personal Data, is processed at the
          Company&#39;s operating offices and in any other places where the
          parties involved in the processing are located. It means that this
          information may be transferred to — and maintained on — computers
          located outside of Your state, province, country or other governmental
          jurisdiction where the data protection laws may differ than those from
          Your jurisdiction.
        </p>
        <p>
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </p>
        <p>
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </p>
        <h3>Disclosure of Your Personal Data</h3>
        <h4>Business Transactions</h4>
        <p>
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </p>
        <h4>Law enforcement</h4>
        <p>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </p>
        <h4>Other legal requirements</h4>
        <p>
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </p>
        <p>Comply with a legal obligation</p>
        <p>Protect and defend the rights or property of the Company</p>
        <p>
          Prevent or investigate possible wrongdoing in connection with the
          Service
        </p>
        <p>Protect the personal safety of Users of the Service or the public</p>
        <p>Protect against legal liability</p>
        <h3>Security of Your Personal Data</h3>
        <p>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </p>
        <h3>Deletion of Your Personal Data</h3>
        <p>
          To delete the personal data stored, access your account settings page
          after logging in. Then, select "delete account". If this option is
          unavailable, email{" "}
          <a href="mailto:support@guitarbymasters.com">
            support@guitarbymasters.com{" "}
          </a>{" "}
          with details of your request.
        </p>
        <h1 className="detailed-information-processing-your-personal-data">
          Detailed Information on the Processing of Your Personal Data
        </h1>
        <div></div>
        <p>
          Service Providers have access to Your Personal Data only to perform
          their tasks on Our behalf and are obligated not to disclose or use it
          for any other purpose.
        </p>
        <h3>Analytics</h3>
        <p>
          We may use third-party Service providers to monitor and analyze the
          use of our Service.
        </p>
        <ul>
          <li>Google Analytics</li>
          <li>
            Google Analytics is a web analytics service offered by Google that
            tracks and reports website traffic. Google uses the data collected
            to track and monitor the use of our Service. This data is shared
            with other Google services. Google may use the collected data to
            contextualize and personalize the ads of its own advertising
            network.
          </li>
          <li>
            You can opt-out of having made your activity on the Service
            available to Google Analytics by installing the Google Analytics
            opt-out browser add-on. The add-on prevents the Google Analytics
            JavaScript (ga.js, analytics.js and dc.js) from sharing information
            with Google Analytics about visits activity.
          </li>
          <li>
            For more information on the privacy practices of Google, please
            visit the Google Privacy &amp; Terms web page:{" "}
            <a href="https://policies.google.com/privacy">
              https://policies.google.com/privacy
            </a>
          </li>
        </ul>
        <h3>Advertising</h3>
        <p>
          We may use Service providers to show advertisements to You to help
          support and maintain Our Service.
        </p>
        <ul>
          <li>Google AdSense &amp; DoubleClick Cookie</li>
          <li>
            Google, as a third party vendor, uses cookies to serve ads on our
            Service. Google&#39;s use of the DoubleClick cookie enables it and
            its partners to serve ads to our users based on their visit to our
            Service or other websites on the Internet.
          </li>
          <li>
            You may opt out of the use of the DoubleClick Cookie for
            interest-based advertising by visiting the Google Ads Settings web
            page:
            <a href="http://www.google.com/ads/preferences/">
              http://www.google.com/ads/preferences/
            </a>
          </li>
          <li>AdMob by Google</li>
          <li>AdMob by Google is provided by Google Inc.</li>
          <li>
            You can opt-out from the AdMob by Google service by following the
            instructions described by Google:{" "}
            <a href="https://support.google.com/ads/answer/2662922?hl=en">
              https://support.google.com/ads/answer/2662922?hl=en
            </a>
          </li>
          <li>
            For more information on how Google uses the collected information,
            please visit the &quot;How Google uses data when you use our
            partners&#39; sites or app&quot; page:
            <a href="https://policies.google.com/technologies/partner-sites">
              https://policies.google.com/technologies/partner-sites
            </a>{" "}
            or visit the Privacy Policy of Google:{" "}
            <a href="https://policies.google.com/privacy">
              https://policies.google.com/privacy
            </a>
          </li>
          <li>Bing Ads</li>
          <li>Bing Ads is an advertising service provided by Microsoft Inc.</li>
          <li>
            You can opt-out from Bing Ads by following the instructions on Bing
            Ads Opt-out page:{" "}
            <a
              href="https://advertise.bingads.microsoft.com/en-
                    us/resources/policies/personalized-ads"
            >
              https://advertise.bingads.microsoft.com/en-
              us/resources/policies/personalized-ads
            </a>
          </li>
          <li>
            For more information about Bing Ads, please visit their Privacy
            Policy:
            <a href="https://privacy.microsoft.com/en-us/PrivacyStatement">
              https://privacy.microsoft.com/en-us/PrivacyStatement
            </a>
          </li>
          <li>Flurry</li>
          <li>Flurry is provided by Yahoo! Inc.</li>
          <li>
            You can opt-out of the Flurry service and prevent it from using and
            sharing your information by visiting the Flurry Opt-out page:
            <a href="https://developer.yahoo.com/flurry/end-user-opt-out/">
              https://developer.yahoo.com/flurry/end-user-opt-out/
            </a>
          </li>
          <li>
            For more information on the privacy practices policies of Yahoo!,
            please visit their Privacy Policy:{" "}
            <a href="https://policies.yahoo.com/xa/en/yahoo/privacy/index.htm">
              https://policies.yahoo.com/xa/en/yahoo/privacy/index.htm
            </a>
          </li>
          <li>InMobi</li>
          <li>InMobi is provided by InMobi Pte Ltd.</li>
          <li>
            You can opt-out from InMobi service by following the instructions as
            described on the InMobi Opt-out page:{" "}
            <a href="http://www.inmobi.com/page/opt-out/">
              http://www.inmobi.com/page/opt-out/
            </a>
          </li>
          <li>
            For more information on the privacy practices and policies of
            InMobi, please visit the InMobi Privacy Policy:{" "}
            <a href="http://www.inmobi.com/privacy-policy/">
              http://www.inmobi.com/privacy-policy/
            </a>
          </li>
          <li>MoPub</li>
          <li>MoPub is provided by MobPub Inc.</li>
          <li>
            You can opt-out from MobPub service by following the instructions on
            MoPub Opt- out page:{" "}
            <a href="http://www.mopub.com/optout/">
              http://www.mopub.com/optout/
            </a>
          </li>
          <li>
            For more information on what information MobPub collects and how it
            is used, please read MoPub Privacy Policy:{" "}
            <a href="http://www.mopub.com/legal/privacy/">
              {" "}
              http://www.mopub.com/legal/privacy/
            </a>
          </li>
          <li>StartApp</li>
          <li>StartApp is provided by StartApp Inc.</li>
          <li>
            You can opt-out from interest-based ads served by StartApp by
            following the instructions on their Privacy Policy, section &quot;8.
            Your Choices and Controls&quot;:
            <a href="https://www.startapp.com/policy/privacy-policy/">
              https://www.startapp.com/policy/privacy-policy/
            </a>
          </li>
          <li>
            For more information on what information StartApp collects and how
            it is used, please read their Privacy Policy:{" "}
            <a href="https://www.startapp.com/policy/privacy-policy/">
              https://www.startapp.com/policy/privacy-policy/
            </a>
          </li>
          <li>AdColony</li>
          <li>AdColony is provided by Jirbo Inc.</li>
          <li>
            You can opt-out from AdColony service by visiting the AdColony
            Opt-out page:
            <a href="https://www.adcolony.com/privacy-policy/">
              https://www.adcolony.com/privacy-policy/
            </a>
          </li>
          <li>
            You can also opt-out from AdColony through the Digital Advertising
            Alliance in the USA{" "}
            <a href="http://www.aboutads.info/choices/">
              http://www.aboutads.info/choices/
            </a>{" "}
            or opt-out using your mobile device settings.
          </li>
          <li>
            For more information AdColony, please visit the Privacy Policy of
            AdColony:
            <a href="http://www.adcolony.com/privacy-policy/">
              http://www.adcolony.com/privacy-policy/
            </a>
          </li>
          <li>AppLovin</li>
          <li>AppLovin is provided by the AppLovin Corporation.</li>
          <li>
            If you&#39;d like to opt-out from AppLovin service, please visit the
            AppLovin Opt-out page:{" "}
            <a href="https://www.applovin.com/optout">
              https://www.applovin.com/optout
            </a>
          </li>
          <li>
            If you&#39;d like to learn more about AppLovin, please visit the
            Privacy Policy page of AppLovin:{" "}
            <a href="https://www.applovin.com/privacy">
              https://www.applovin.com/privacy
            </a>
          </li>
          <li>Vungle</li>
          <li>Vungle is provided by Vungle Inc.</li>
          <li>
            You can opt-out from Vungle service by following the instructions as
            described by Vungle on their Privacy Policy page:{" "}
            <a href="http://vungle.com/privacy/">http://vungle.com/privacy/</a>
          </li>
          <li>
            For more information about Vungle, please visit Vungle Privacy
            Policy:
            <a href="http://vungle.com/privacy/">http://vungle.com/privacy/</a>
          </li>
          <li>AdButler</li>
          <li>
            AdButler is an advertising service provided by Sparklit Networks
            Inc.
          </li>
          <li>
            For more information about AdButler, please visit their Privacy
            Policy:
            <a href="https://www.sparklit.com/agreements.spark?agreement=privacy">
              https://www.sparklit.com/agreements.spark?agreement=privacy
            </a>
          </li>
          <li>Unity Ads</li>
          <li>Unity Ads is provided by Unity Technologies.</li>
          <li>
            You can opt-out from Unity Ads service by following the instructions
            as described by Unity Technologies on their Privacy Policy page:{" "}
            <a
              href="https://unity3d.com/legal/privacy-
                    policy"
            >
              https://unity3d.com/legal/privacy- policy
            </a>
          </li>
          <li>
            For more information about Unity Technologies, please visit Unity
            Technologies Privacy Policy:{" "}
            <a href="https://unity3d.com/legal/privacy-policy">
              https://unity3d.com/legal/privacy-policy
            </a>
          </li>
        </ul>
        <h3>Email Marketing</h3>
        <p>
          We may use Your Personal Data to contact You with newsletters,
          marketing or promotional materials and other information that may be
          of interest to You. You may opt-out of receiving any, or all, of these
          communications from Us by following the unsubscribe link or
          instructions provided in any email We send or by contacting Us.
        </p>
        <p>
          We may use Email Marketing Service Providers to manage and send emails
          to You.
        </p>
        <ul>
          <li>Mailchimp</li>
          <li>
            Mailchimp is an email marketing sending service provided by The
            Rocket Science Group LLC.
          </li>
          <li>
            For more information on the privacy practices of Mailchimp, please
            visit their Privacy policy:{" "}
            <a href="https://mailchimp.com/legal/privacy/">
              https://mailchimp.com/legal/privacy/
            </a>
          </li>
          <li>AWeber</li>
          <li>
            AWeber is an email marketing sending service provided by AWeber
            Communications.
          </li>
          <li>
            For more information on the privacy practices of AWeber, please
            visit their Privacy policy:{" "}
            <a href="https://www.aweber.com/privacy.htm">
              https://www.aweber.com/privacy.htm
            </a>
          </li>
          <li>GetResponse</li>
          <li>
            GetResponse is an email marketing sending service provided by
            GetResponse.
          </li>
          <li>
            For more information on the privacy practices of GetResponse, please
            visit their Privacy policy:{" "}
            <a href="https://www.getresponse.com/legal/privacy.html">
              https://www.getresponse.com/legal/privacy.html
            </a>
          </li>
        </ul>
        <h3>Payments</h3>
        <p>
          We may provide paid products and/or services within the Service. In
          that case, we may use third-party services for payment processing
          (e.g. payment processors).
        </p>
        <p>
          We will not store or collect Your payment card details. That
          information is provided directly to Our third-party payment processors
          whose use of Your personal information is governed by their Privacy
          Policy. These payment processors adhere to the standards set by
          PCI-DSS as managed by the PCI Security Standards Council, which is a
          joint effort of brands like Visa, Mastercard, American Express and
          Discover. PCI-DSS requirements help ensure the secure handling of
          payment information.
        </p>
        <ul>
          <li>Apple Store In-App Payments</li>
          <li>
            Their Privacy Policy can be viewed at{" "}
            <a
              href="https://www.apple.com/legal/privacy/en-
                    ww/"
            >
              https://www.apple.com/legal/privacy/en- ww/
            </a>
          </li>
          <li>Google Play In-App Payments</li>
          <li>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://www.google.com/policies/privacy/">
              https://www.google.com/policies/privacy/
            </a>
          </li>
          <li>Stripe</li>
          <li>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://stripe.com/us/privacy">
              https://stripe.com/us/privacy
            </a>
          </li>
          <li>WePay</li>
          <li>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://go.wepay.com/privacy-policy">
              https://go.wepay.com/privacy-policy
            </a>
          </li>
          <li>WorldPay</li>
          <li>
            Their Privacy Policy can be viewed at{" "}
            <a
              href="https://www.worldpay.com/en-gb/privacy-
                    policy"
            >
              https://www.worldpay.com/en-gb/privacy- policy
            </a>
          </li>
          <li>PayPal</li>
          <li>
            Their Privacy Policy can be viewed at
            <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full">
              {" "}
              https://www.paypal.com/webapps/mpp/ua/privacy-full
            </a>
          </li>
          <li>Braintree</li>
          <li>
            Their Privacy Policy can be viewed at
            <a href="https://www.braintreepayments.com/legal/braintree-privacy-policy">
              {" "}
              https://www.braintreepayments.com/legal/braintree-privacy-policy
            </a>
          </li>
          <li>FastSpring</li>
          <li>
            Their Privacy Policy can be viewed at{" "}
            <a href="http://fastspring.com/privacy/">
              http://fastspring.com/privacy/
            </a>
          </li>
          <li>2Checkout</li>
          <li>
            Their Privacy Policy can be viewed at
            <a href="https://www.2checkout.com/policies/privacy-policy">
              {" "}
              https://www.2checkout.com/policies/privacy-policy
            </a>
          </li>
          <li>Sage Pay</li>
          <li>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://www.sagepay.co.uk/policies">
              {" "}
              https://www.sagepay.co.uk/policies
            </a>
          </li>
          <li>Square</li>
          <li>
            Their Privacy Policy can be viewed at{" "}
            <a
              href="https://squareup.com/legal/privacy-no-
                    account"
            >
              {" "}
              https://squareup.com/legal/privacy-no- account
            </a>
          </li>
          <li>Go Cardless</li>
          <li>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://gocardless.com/en-eu/legal/privacy/">
              https://gocardless.com/en-eu/legal/privacy/
            </a>
          </li>
          <li>Elavon</li>
          <li>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://www.elavon.com/privacy-pledge.html">
              https://www.elavon.com/privacy-pledge.html
            </a>
          </li>
          <li>Verifone</li>
          <li>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://www.verifone.com/en/us/legal">
              https://www.verifone.com/en/us/legal
            </a>
          </li>
          <li>Moneris</li>
          <li>
            Their Privacy Policy can be viewed at{" "}
            <a href="https://www.moneris.com/en/Privacy-Policy">
              https://www.moneris.com/en/Privacy-Policy
            </a>
          </li>
          <li>WeChat</li>
          <li>
            Their Privacy Policy can be viewed at
            <a href="https://www.wechat.com/en/privacy_policy.html">
              {" "}
              https://www.wechat.com/en/privacy_policy.html
            </a>
          </li>
          <li>Alipay</li>
          <li>
            Their Privacy Policy can be viewed at
            <a href="https://render.alipay.com/p/f/agreementpages/alipayglobalprivacypolicy.html">
              {" "}
              https://render.alipay.com/p/f/agreementpages/alipayglobalprivacypolicy.html
            </a>
          </li>
        </ul>
        <p>
          When You use Our Service to pay a product and/or service via bank
          transfer, We may ask You to provide information to facilitate this
          transaction and to verify Your identity.
        </p>
        <h3>Behavioral remarketing</h3>
        <p>
          The Company uses remarketing services to advertise on third party
          websites to You after You visited our Service. We and Our third-party
          vendors use cookies to inform, optimize and serve ads based on Your
          past visits to our Service.
        </p>
        <ul>
          <li>Google Ads (AdWords)</li>
          <li>
            Google Ads (AdWords) remarketing service is provided by Google Inc.
          </li>
          <li>
            You can opt-out of Google Analytics for Display Advertising and
            customise the Google Display Network ads by visiting the Google Ads
            Settings page:
            <a href="http://www.google.com/settings/ads">
              http://www.google.com/settings/ads
            </a>
          </li>
          <li>
            Google also recommends installing the Google Analytics Opt-out
            Browser Add-on -
            <a href="https://tools.google.com/dlpage/gaoptout">
              https://tools.google.com/dlpage/gaoptout
            </a>{" "}
            - for your web browser. Google Analytics Opt-out Browser Add-on
            provides visitors with the ability to prevent their data from being
            collected and used by Google Analytics.
          </li>
          <li>
            For more information on the privacy practices of Google, please
            visit the Google Privacy &amp; Terms web page:{" "}
            <a href="https://policies.google.com/privacy">
              https://policies.google.com/privacy
            </a>
          </li>
          <li>Bing Ads Remarketing</li>
          <li>Bing Ads remarketing service is provided by Microsoft Inc.</li>
          <li>
            You can opt-out of Bing Ads interest-based ads by following their
            instructions:
            <a
              href="https://advertise.bingads.microsoft.com/en-us/resources/policies
                    "
            >
              https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-
              ads
            </a>
          </li>
          <li>
            You can learn more about the privacy practices and policies of
            Microsoft by visiting their Privacy Policy page:{" "}
            <a href="https://privacy.microsoft.com/en-us/PrivacyStatement">
              https://privacy.microsoft.com/en-us/PrivacyStatement
            </a>
          </li>
          <li>Twitter</li>
          <li>Twitter remarketing service is provided by Twitter Inc.</li>
          <li>
            You can opt-out from Twitter&#39;s interest-based ads by following
            their instructions:
            <a href="https://support.twitter.com/articles/20170405">
              https://support.twitter.com/articles/20170405
            </a>
          </li>
          <li>
            You can learn more about the privacy practices and policies of
            Twitter by visiting their Privacy Policy page:{" "}
            <a href="https://twitter.com/privacy">
              https://twitter.com/privacy
            </a>
          </li>
          <li>Facebook</li>
          <li>Facebook remarketing service is provided by Facebook Inc.</li>
          <li>
            You can learn more about interest-based advertising from Facebook by
            visiting this page:{" "}
            <a href="https://www.facebook.com/help/164968693837950">
              https://www.facebook.com/help/164968693837950
            </a>
          </li>
          <li>
            To opt-out from Facebook&#39;s interest-based ads, follow these
            instructions from Facebook:{" "}
            <a href="https://www.facebook.com/help/568137493302217">
              https://www.facebook.com/help/568137493302217
            </a>
          </li>
          <li>
            Facebook adheres to the Self-Regulatory Principles for Online
            Behavioural Advertising established by the Digital Advertising
            Alliance. You can also opt-out from Facebook and other participating
            companies through the Digital Advertising Alliance in the USA{" "}
            <a href="http://www.aboutads.info/choices/ ">
              http://www.aboutads.info/choices/
            </a>{" "}
            , the Digital Advertising Alliance of Canada in Canada{" "}
            <a href="http://youradchoices.ca/">http://youradchoices.ca/</a> or
            the European Interactive Digital Advertising Alliance in Europe{" "}
            <a href="http://www.youronlinechoices.eu/">
              http://www.youronlinechoices.eu/
            </a>{" "}
            , or opt- out using your mobile device settings.
          </li>
          <li>
            For more information on the privacy practices of Facebook, please
            visit Facebook&#39;s Data Policy:{" "}
            <a href="https://www.facebook.com/privacy/explanation">
              https://www.facebook.com/privacy/explanation
            </a>
          </li>
          <li>Pinterest</li>
          <li>Pinterest remarketing service is provided by Pinterest Inc.</li>
          <li>
            You can opt-out from Pinterest&#39;s interest-based ads by enabling
            the &quot;Do Not Track&quot; functionality of your web browser or by
            following Pinterest instructions:
            <a href="http://help.pinterest.com/en/articles/personalization-and-data">
              http://help.pinterest.com/en/articles/personalization-and-data
            </a>
          </li>
          <li>
            You can learn more about the privacy practices and policies of
            Pinterest by visiting their Privacy Policy page:{" "}
            <a href="https://about.pinterest.com/en/privacy-policy">
              https://about.pinterest.com/en/privacy-policy
            </a>
          </li>
          <li>AdRoll</li>
          <li>
            AdRoll remarketing service is provided by Semantic Sugar, Inc.
          </li>
          <li>
            You can opt-out of AdRoll remarketing by visiting this AdRoll
            Advertising Preferences web page:{" "}
            <a href="http://info.evidon.com/pub_info/573?v=1&amp;nt=1&amp;nw=false">
              http://info.evidon.com/pub_info/573?v=1&amp;nt=1&amp;nw=false
            </a>
          </li>
          <li>
            For more information on the privacy practices of AdRoll, please
            visit the AdRoll Privacy Policy web page:{" "}
            <a href="http://www.adroll.com/about/privacy">
              http://www.adroll.com/about/privacy
            </a>
          </li>
          <li>Perfect Audience</li>
          <li>
            Perfect Audience remarketing service is provided by NowSpots Inc.
          </li>
          <li>
            You can opt-out of Perfect Audience remarketing by visiting these
            pages: Platform Opt-out ({" "}
            <a href="http://pixel.prfct.co/coo">http://pixel.prfct.co/coo</a> )
            and Partner Opt-out ({" "}
            <a href="http://ib.adnxs.com/optout">http://ib.adnxs.com/optout</a>{" "}
            ).
          </li>
          <li>
            For more information on the privacy practices of Perfect Audience,
            please visit the Perfect Audience Privacy Policy &amp; Opt-out web
            page:
            <a href="https://www.perfectaudience.com/privacy/">
              https://www.perfectaudience.com/privacy/
            </a>
          </li>
        </ul>
        <h1 id="gdpr-privacy">GDPR Privacy</h1>
        <div></div>
        <h2>Legal Basis for Processing Personal Data under GDPR</h2>
        <p>We may process Personal Data under the following conditions:</p>
        <p>
          Consent: You have given Your consent for processing Personal Data for
          one or more specific purposes.
        </p>
        <p>
          Performance of a contract: Provision of Personal Data is necessary for
          the performance of an agreement with You and/or for any
          pre-contractual obligations thereof.
        </p>
        <p>
          Legal obligations: Processing Personal Data is necessary for
          compliance with a legal obligation to which the Company is subject.
        </p>
        <p>
          Vital interests: Processing Personal Data is necessary in order to
          protect Your vital interests or of another natural person.
        </p>
        <p>
          Public interests: Processing Personal Data is related to a task that
          is carried out in the public interest or in the exercise of official
          authority vested in the Company.
        </p>
        <p>
          Legitimate interests: Processing Personal Data is necessary for the
          purposes of the legitimate interests pursued by the Company.
        </p>
        <p>
          In any case, the Company will gladly help to clarify the specific
          legal basis that applies to the processing, and in particular whether
          the provision of Personal Data is a statutory or contractual
          requirement, or a requirement necessary to enter into a contract.
        </p>
        <h2>Your Rights under the GDPR</h2>
        <p>
          The Company undertakes to respect the confidentiality of Your Personal
          Data and to guarantee You can exercise Your rights.
        </p>
        <p>
          You have the right under this Privacy Policy, and by law if You are
          within the EU, to:
        </p>
        <p>
          Request access to Your Personal Data. The right to access, update or
          delete the information We have on You. Whenever made possible, you can
          access, update or request deletion of Your Personal Data directly
          within Your account settings section. If you are unable to perform
          these actions yourself, please contact Us to assist You. This also
          enables You to receive a copy of the Personal Data We hold about You.
        </p>
        <p>
          Request correction of the Personal Data that We hold about You. You
          have the right to to have any incomplete or inaccurate information We
          hold about You corrected.
        </p>
        <p>
          Object to processing of Your Personal Data. This right exists where We
          are relying on a legitimate interest as the legal basis for Our
          processing and there is something about Your particular situation,
          which makes You want to object to our processing of Your Personal Data
          on this ground. You also have the right to object where We are
          processing Your Personal Data for direct marketing purposes.
        </p>
        <p>
          Request erasure of Your Personal Data. You have the right to ask Us to
          delete or remove Personal Data when there is no good reason for Us to
          continue processing it.
        </p>
        <p>
          Request the transfer of Your Personal Data. We will provide to You, or
          to a third-party You have chosen, Your Personal Data in a structured,
          commonly used, machine-readable format. Please note that this right
          only applies to automated information which You initially provided
          consent for Us to use or where We used the information to perform a
          contract with You.
        </p>
        <p>
          Withdraw Your consent. You have the right to withdraw Your consent on
          using your Personal Data. If You withdraw Your consent, We may not be
          able to provide You with access to certain specific functionalities of
          the Service.
        </p>
        <h2>Exercising of Your GPR Data Protection Rights</h2>
        <p>
          You may exercise Your rights of access, rectification, cancellation
          and opposition by contacting Us. Please note that we may ask You to
          verify Your identity before responding to such requests. If You make a
          request, We will try our best to respond to You as soon as possible.
        </p>
        <p>
          You have the right to complain to a Data Protection Authority about
          Our collection and use of Your Personal Data. For more information, if
          You are in the European Economic Area (EEA), please contact Your local
          data protection authority in the EEA.
        </p>
        <h1 id="facebook">Facebook Fan Page</h1>
        <div></div>
        <h2>Data Controller for the Facebook Fan Page</h2>
        <p>
          The Company is the Data Controller of Your Personal Data collected
          while using the Service. As operator of the Facebook Fan Page{" "}
          <a href="https://facebook.com/digitalpublishinginc">
            https://facebook.com/digitalpublishinginc
          </a>{" "}
          , the Company and the operator of the social network Facebook are
          Joint Controllers.
        </p>
        <p>
          The Company has entered into agreements with Facebook that define the
          terms for use of the Facebook Fan Page, among other things. These
          terms are mostly based on the Facebook Terms of Service:{" "}
          <a href="https://www.facebook.com/terms.php">
            https://www.facebook.com/terms.php
          </a>
        </p>
        <p>
          Visit the Facebook Privacy Policy{" "}
          <a href="https://www.facebook.com/policy.php">
            https://www.facebook.com/policy.php
          </a>{" "}
          for more information about how Facebook manages Personal data or
          contact Facebook online, or by mail: Facebook, Inc. ATTN, Privacy
          Operations, 1601 Willow Road, Menlo Park, CA 94025, United States.
        </p>
        <h2>Facebook Insights</h2>
        <p>
          We use the Facebook Insights function in connection with the operation
          of the Facebook Fan Page and on the basis of the GDPR, in order to
          obtain anonymized statistical data about Our users.
        </p>
        <p>
          For this purpose, Facebook places a Cookie on the device of the user
          visiting Our Facebook Fan Page. Each Cookie contains a unique
          identifier code and remains active for a period of two years, except
          when it is deleted before the end of this period.
        </p>
        <p>
          Facebook receives, records and processes the information stored in the
          Cookie, especially when the user visits the Facebook services,
          services that are provided by other members of the Facebook Fan Page
          and services by other companies that use Facebook services.
        </p>
        <p>
          For more information on the privacy practices of Facebook, please
          visit Facebook Privacy Policy here:{" "}
          <a href="https://www.facebook.com/full_data_use_policy">
            https://www.facebook.com/full_data_use_policy
          </a>
        </p>
        <h1 id="ccpa-privacy">CCPA Privacy</h1>
        <div></div>
        <h2>Your Rights under the CCPA</h2>
        <p>
          Under this Privacy Policy, and by law if You are a resident of
          California, You have the following rights:
        </p>
        <p>
          The right to notice. You must be properly notified which categories of
          Personal Data are being collected and the purposes for which the
          Personal Data is being used.
        </p>
        <p>
          The right to access / the right to request. The CCPA permits You to
          request and obtain from the Company information regarding the
          disclosure of Your Personal Data that has been collected in the past
          12 months by the Company or its subsidiaries to a third-party for the
          third party&#39;s direct marketing purposes.
        </p>
        <p>
          The right to say no to the sale of Personal Data. You also have the
          right to ask the Company not to sell Your Personal Data to third
          parties. You can submit such a request by visiting our &quot;Do Not
          Sell My Personal Information&quot; section or web page.
        </p>
        <p>
          The right to know about Your Personal Data. You have the right to
          request and obtain from the Company information regarding the
          disclosure of the following:
        </p>
        <p>The categories of Personal Data collected</p>
        <p>The sources from which the Personal Data was collected</p>
        <p>
          The business or commercial purpose for collecting or selling the
          Personal Data
        </p>
        <p>Categories of third parties with whom We share Personal Data</p>
        <p>The specific pieces of Personal Data we collected about You</p>
        <p>
          The right to delete Personal Data. You also have the right to request
          the deletion of Your Personal Data that have been collected in the
          past 12 months.
        </p>
        <p>
          The right not to be discriminated against. You have the right not to
          be discriminated against for exercising any of Your Consumer&#39;s
          rights, including by:
        </p>
        <p>Denying goods or services to You</p>
        <p>
          Charging different prices or rates for goods or services, including
          the use of discounts or other benefits or imposing penalties
        </p>
        <p>
          Providing a different level or quality of goods or services to You
        </p>
        <p>
          Suggesting that You will receive a different price or rate for goods
          or services or a different level or quality of goods or services.
        </p>
        <h2>Exercising Your CCPA Data Protection Rights</h2>
        <p>
          In order to exercise any of Your rights under the CCPA, and if you are
          a California resident, You can email or call us or visit our &quot;Do
          Not Sell My Personal Information&quot; section or web page.
        </p>
        <p>
          The Company will disclose and deliver the required information free of
          charge within 45 days of receiving Your verifiable request. The time
          period to provide the required information may be extended once by an
          additional 45 days when reasonable necessary and with prior notice.
        </p>
        <h2>Do Not Sell My Personal Information</h2>
        <p>
          We do not sell personal information. However, the Service Providers we
          partner with (for example, our advertising partners) may use
          technology on the Service that &quot;sells&quot; personal information
          as defined by the CCPA law.
        </p>
        <p>
          If you wish to opt out of the use of your personal information for
          interest-based advertising purposes and these potential sales as
          defined under CCPA law, you may do so by following the instructions
          below.
        </p>
        <p>
          Please note that any opt out is specific to the browser You use. You
          may need to opt out on every browser that you use.
        </p>
        <h3>Website</h3>
        <p>
          You can opt out of receiving ads that are personalized as served by
          our Service Providers by following our instructions presented on the
          Service:
        </p>
        <p>From Our &quot;Cookie Consent&quot; notice banner</p>
        <p>Or from Our &quot;CCPA Opt-out&quot; notice banner</p>
        <p>
          Or from Our &quot;Do Not Sell My Personal Information&quot; notice
          banner
        </p>
        <p>Or from Our &quot;Do Not Sell My Personal Information&quot; link</p>
        <p>
          The opt out will place a cookie on Your computer that is unique to the
          browser You use to opt out. If you change browsers or delete the
          cookies saved by your browser, you will need to opt out again.
        </p>
        <h3>Mobile Devices</h3>
        <p>
          Your mobile device may give you the ability to opt out of the use of
          information about the apps you use in order to serve you ads that are
          targeted to your interests:
        </p>
        <p>
          &quot;Opt out of Interest-Based Ads&quot; or &quot;Opt out of Ads
          Personalization&quot; on Android devices
        </p>
        <p>&quot;Limit Ad Tracking&quot; on iOS devices</p>
        <p>
          You can also stop the collection of location information from Your
          mobile device by changing the preferences on your mobile device.
        </p>
        <h1 id="do-not-track">
          "Do Not Track" policy as Required by California Online Privacy
          Protection Act (CalOPPA)
        </h1>
        <div></div>
        <p>Our Service does not respond to Do Not Track signals.</p>
        <p>
          However, some third party websites do keep track of Your browsing
          activities. If You are visiting such websites, You can set Your
          preferences in Your web browser to inform websites that You do not
          want to be tracked. You can enable or disable DNT by visiting the
          preferences or settings page of Your web browser.
        </p>
        <h1 id="california">
          Your California Privacy Rights (California's Shine the Light law)
        </h1>
        <div></div>
        <p>
          Under California Civil Code Section 1798 (California&#39;s Shine the
          Light law), California residents with an established business
          relationship with us can request information once a year about sharing
          their Personal Data with third parties for the third parties&#39;
          direct marketing purposes.
        </p>
        <p>
          If you&#39;d like to request more information under the California
          Shine the Light law, and if you are a California resident, You can
          contact Us using the contact information provided below.
        </p>
        <h1>
          California Privacy Rights for Minor Users (California Business and
          Professions Code Section 22581)
        </h1>
        <div></div>
        <p>
          California Business and Professions Code section 22581 allow
          California residents under the age of 18 who are registered users of
          online sites, services or applications to request and obtain removal
          of content or information they have publicly posted.
        </p>
        <p>
          To request removal of such data, and if you are a California resident,
          You can contact Us using the contact information provided below, and
          include the email address associated with Your account.
        </p>
        <p>
          Be aware that Your request does not guarantee complete or
          comprehensive removal of content or information posted online and that
          the law may not permit or require removal in certain circumstances.
        </p>
        <h1>Links to Other Websites</h1>
        <div></div>
        <p>
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party&#39;s site. We strongly advise You to review the
          Privacy Policy of every site You visit.
        </p>
        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>
        <h1>Changes to this Privacy Policy</h1>
        <div></div>
        <p>
          We may update our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <p>
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the
          &quot;Last updated&quot; date at the top of this Privacy Policy.
        </p>
        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <h1>Contact us</h1>
        <div></div>
        <p>
          If you have any questions about this Privacy Policy, You can contact
          us:
        </p>
        <p>
          By email:{" "}
          <a href="mailto:support@guitarbymasters.com">
            support@guitarbymasters.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default Privacy;
