import { Link } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import TokenService from "../../services/auth/TokenService";

import "./footer.css";

const Footer = ({ setNewsletterPopupVisible }) => {
  return (
    <div className="footer-container">
      <div className="footer-content">
        <div className="help-footer">
          <h2>Help</h2>
          <ul>
            <li>
              <Link to="/terms">Terms and Conditions</Link>
            </li>
            <div className="customer-support"></div>
            <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li>
            <div className="faq-section"></div>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
        </div>
        <div className="about-footer">
          <h2>About</h2>
          <ul>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/testimonials">Testimonials</Link>
            </li>
            <li>
              <a href="/#partners">Our Partners</a>
            </li>
          </ul>
        </div>
        <div className="explore-footer">
          <h2>Explore</h2>
          <ul>
            <li>
              <Link to={TokenService.getUser() ? "/userhome" : "/"}>Home</Link>
            </li>
            <li>
              <Link to="/bookcatalog">Catalog</Link>
            </li>
            <li>
              <Link to="/artistcatalog">Artists</Link>
            </li>
          </ul>
        </div>
        <div className="patent">
          <span onClick={setNewsletterPopupVisible} style={{ cursor: "pointer", margin: "10px" }}>
            Sign up for our newsletter
          </span>
          <Link style={{ margin: "10px" }} to="/patent">
            Patent, Licensing, and Collaborations
          </Link>
        </div>
      </div>
      <div className="copyright">
        <p>&copy; 2021 - 2022 Guitar By Masters</p>
        <div className="footer-socialmedia-container">
          Follow us:
          <SocialIcon
            style={{
              maxWidth: "2rem",
              maxHeight: "2rem",
              margin: "0.5rem",
            }}
            url="https://www.youtube.com/channel/UC_x7WXjoa5bUxDPCfo-PFWQ"
            target="_blank"
          />
          <SocialIcon
            style={{
              maxWidth: "2rem",
              maxHeight: "2rem",
              margin: "0.5rem",
            }}
            url="https://www.facebook.com/Guitar-by-Masters-109388588095895"
            target="_blank"
          />
          <SocialIcon
            style={{
              maxWidth: "2rem",
              maxHeight: "2rem",
              margin: "0.5rem",
            }}
            url="http://www.instagram.com/guitarbymasters"
            target="_blank"
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
