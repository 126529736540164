import React from "react";
import { ReactComponent as Spinner } from "../../assets/spinner.svg";
import "./styles/GenericSpinner.css";

function GenericSpinner({ absolute, top, right, down, left, width = "1.5rem", height = "1.5rem" }) {
  return (
    <div
      className="generic-spinner-container"
      style={{
        position: absolute ? "absolute" : null,
        top: top,
        right: right,
        down: down,
        left: left,
      }}
    >
      <Spinner stroke="#fff" strokeWidth={50} width={width} height={height} />
    </div>
  );
}

export default GenericSpinner;
