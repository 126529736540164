import React, { useState, useContext, useEffect } from "react";

import "./ChooseSubscription.css";
import SubscriptionCard from "./subscriptionCard/SubscriptionCard";
import PaymentArea from "./paymentArea/PaymentArea";
import { useLocation, useHistory, Router } from "react-router-dom";
import StripeService from "../../../services/stripe/StripeService";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import AuthService from "../../../services/auth/AuthService";
import TokenService from "../../../services/auth/TokenService";
import LoadingModal from "../../../components/loading/LoadingModal";
import ColoredButton from "../../../components/buttons/ColoredButton";
import { Context } from "../../../components/auth/state/Store";
import { centsToString } from "../../../helpers/FormatFunctions";
import LoadingContentSpinner from "../../../components/loading/LoadingContentSpinner";
import { toast } from "react-toastify";

import PaymentErrorModal from "./PaymentErrorModal";
import "./PaymentModal.css";
import UnderlinedTextInput from "../../../components/auth/underlinedText/UnderlinedTextInput";
import PaypalButtonArea from "./PaypalButton";
import { Helmet } from "react-helmet";
import UserService from "../../../services/UserService"
// NEED TO CHANGE THE PROPS HERE - EITHER ENCRYT PASSWORD OR DONT PASS.
// its not a huge deal, but it feels weird to be passing around the password like that
import { NotificationBarContext } from "../../../context/NotificationBarContext";
const ChooseSubscription = () => {
  const [selectedPlan, setSelectedPlan] = useState("monthly");
  const [monthlyPrice, setMonthlyPrice] = useState();
  const [yearlyPrice, setYearlyPrice] = useState();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const stripe = useStripe();
  const elements = useElements();

  const location = useLocation();

  const [canPay, setCanPay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState("");
  const [state, dispatch] = useContext(Context);

  const [cardState, setCardState] = useState(null);
  const [message, setMessage] = useState("Please enter your credit card information");
  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [takingTooLong, setTakingTooLong] = useState(false);
  const [timeoutID, setTimeoutID] = useState(null);

  const [discountCode, setDiscountCode] = useState("");
  const [disableDiscount, setDisableDiscount] = useState(false);

  const [userInfo, setUserInfo] = useState(null);
  const [giftCode, setGiftCode] = useState("")
  const [paymentComplete, setPaymentComplete] = useState(false);
  const [noPaymentMethodRequiredForGift, setNoPaymentMethodRequiredForGift] = useState(false)
  const [loadingPrices, setLoadingPrices] = useState(true);
  const [userHasTrialed, setUserHasTrialed] = useState(false);
  let history = useHistory();
  useEffect(() => {
    checkIfTrialed()
  }, []);

  const { hideNotificationBar, showNotificationBar } = useContext(NotificationBarContext); // Use context
  useEffect(() => {
    hideNotificationBar()
    return () => {
      showNotificationBar()
    }
  }, []);
  async function checkIfTrialed() {
    const user = await TokenService.getUser()
    if (user.hasTrialed) {
      console.log("user Has trialed")
      setUserHasTrialed(true)
    }
  }
  // Get prices
  useEffect(() => {
    dispatch({ type: "ENTER_READER" }); // Only to remove the header and footer

    StripeService.fetchPrices()
      .then((response) => {
        setLoadingPrices(false);
        // setPrices(response.data.prices);

        setMonthlyPrice(JSON.parse(response.data.monthlyPrice));
        setYearlyPrice(JSON.parse(response.data.yearlyPrice));
      })
      .catch((e) => console.log("error getting prices: ", e));

    return () => {
      dispatch({ type: "EXIT_READER" });
    };
  }, []);

  // If we dont have their email and password log them out
  useEffect(() => {
    if (location && location.state && location.state.email && location.state.password) {
      setEmail(location.state.email);
      setPassword(location.state.password);
    } else if (TokenService.getUser() && TokenService.getUser().email != null) {
      setEmail(TokenService.getUser().email);
      // dispatch({ type: "LOG_OUT" });
      // history.push({ pathname: "/login", fromReg: false });
      // TokenService.removeUser();
    }
    if (!TokenService.getUser()) {
      history.push({ pathname: "/login" });
    }
  }, [location, location.state]);

  useEffect(() => {
    if (paymentComplete) history.push("/thankyou");
  }, [paymentComplete]);

  useEffect(() => {
    if (loading) {
      // Begin a timeout and set the timeout ID (so we can clear it if we need to)
      setTimeoutID(setTimeout(() => setTakingTooLong(true), 15000));
    } else {
      clearTimeout(timeoutID);
    }
  }, [loading]);
  useEffect(() => {
    let isMounted = true; // flag to check if component is still mounted

    // Ensure location.state and location.state.from are defined
    if (location.state && location.state.from) {
      const fromPath = typeof location.state.from === 'string' ? location.state.from.toLowerCase() : '';


      // Check if the path starts with the specified string
      if (fromPath.startsWith("/gift/giftcenter")) {

        const spitByquery = fromPath.split('?')[1]
        // Extract the query parameters from the URL
        const queryParams = new URLSearchParams(spitByquery);

        // Check if the 'giftCenter' query parameter exists
        if (queryParams.has('giftcode')) {
          const giftCenterValue = queryParams.get('giftcode');
          // Only update state if the component is still mounted
          if (isMounted) {
            setGiftCode(giftCenterValue);
          }
        }
      }
    }
    return () => {
      isMounted = false; // Set the flag to false when the component unmounts
    };

  }, [location?.state]);
  useEffect(() => {
    setDiscountCode("");
    setShowMessage(false);
    setMessageType(null);
    setMessage("");

    if (selectedPlan === "annually") setDisableDiscount(true);
    else setDisableDiscount(false);
  }, [selectedPlan]);

  useEffect(() => {


    if (giftCode) {
      setDiscountCode(giftCode)

    }
  }, [giftCode]);
  useEffect(() => {
    if (giftCode && loadingPrices && discountCode) {
      autoSubmitDiscountCode()
      console.log("autoSubmitDiscountCode")
    }
  }, [discountCode, selectedPlan, loadingPrices]);

  useEffect(() => {
    console.log(discountCode)
  }, [discountCode]);


  const autoSubmitDiscountCode = async () => {
    try {
      let priceId = null;
      const response = await StripeService.fetchPrices();

      // setPrices(response.data.prices);

      priceId = JSON.parse(response.data.monthlyPrice).id;
      setMonthlyPrice(JSON.parse(response.data.monthlyPrice));

      setLoading(true);
      const paymentResponse = await StripeService.createSubscription(
        priceId,
        selectedPlan,
        discountCode,
        userHasTrialed,
        true
      );


      if (paymentResponse.data.isGiftedSubscription) {
        handleResultAfterPay(paymentResponse.data);
      }
    } catch (error) {
      console.log(error)
      console.log("Error pre payment");
      // 4. Error before payment, but needs login (Modal should logout user and navigate to /login)
      setErrorType("pre-payment");
      setLoading(false);
      setError(true);
    }
  };
  // Set client secret based on plan -- uses token, may be triggering mutliple times?
  const submitSubscription = () => {
    let priceId = null;
    if (selectedPlan === "monthly") {
      priceId = monthlyPrice.id;
    } else {
      priceId = yearlyPrice.id;
    }
    console.log("🚀 ~ file: ChooseSubscription.jsx:229 ~ submitSubscription ~ canPay:", canPay)

    if (canPay || noPaymentMethodRequiredForGift) {
      setLoading(true);
      StripeService.createSubscription(priceId, selectedPlan, discountCode, userHasTrialed, noPaymentMethodRequiredForGift)
        .then((response) => {
          console.log("🚀 ~ file: ChooseSubscription.jsx:234 ~ .then ~ response:", response)



          if (response.data.clientSecret) {
            console.log("pay  240")
            pay(response.data.clientSecret);
          } else if (response.data.isGiftedSubscription) {
            console.log("isGiftedSubscription 242")
            handleResultAfterPay(response.data)

          } else if (response.data.setup_clientSecret) {
            console.log("setupPay 247")
            setupPay(response.data.setup_clientSecret);
          }
        })
        .catch((e) => {
          console.log("Error pre payment");
          // 4. Error before payment, but needs login (Modal should logout user and navigate to /login)
          setErrorType("pre-payment");
          setLoading(false);
          setError(true);
        });
    } else {

      displayError(cardState);

    }
  };

  const handleCardInfoChange = (e) => {
    setCardState(e);
    if (e.complete && !e.error) setCanPay(true);
  };
  const displayError = (e) => {
    setMessageType("error");
    switch (true) {
      case !e:
      case e.empty: {
        setMessage("Please enter your credit card information");
        break;
      }
      case e.error && e.error.code === "invalid_number": {
        setMessage("Card number is invalid");
        break;
      }
      default:
        setMessage(
          "There was an error submitting your payment.  Make sure all the fields in the credit card info are not empty."
        );
        break;
    }
    setShowMessage(true);
  };
  const pay = async (clientSec) => {
    if (clientSec && canPay) {
      try {

        const cardElement = elements.getElement(CardElement);
        const result = await stripe.confirmCardPayment(clientSec, {
          payment_method: {
            card: cardElement,

          },
        });
        const { error, paymentIntent } = result;
        console.log("🚀 ~ file: ChooseSubscription.jsx:300 ~ pay ~ paymentIntent:", paymentIntent)


        if (error) {
          throw new Error(error.message);
        }
        handleResultAfterPay(result);

      } catch (error) {
        console.error("Payment failed:", error);
        setLoading(false);
        setError(true);
      }
    }
  };


  const setupPay = (setup_sec) => {
    const cardElement = elements.getElement(CardElement);

    stripe
      .confirmCardSetup(setup_sec, {
        payment_method: {
          card: cardElement,
          // billing_details: {
          //   name: "Jenny Rosen",
          // },
        },
      })

      .then(function (result) {

        // Handle result.error or result.setupIntent
        console.log("setup result: ", result);
        if (result.error) {
          setLoading(false);
          displayError(result.error);
          setError(true);
          throw new Error(result.error.message);
        }
        if (result.setupIntent) {
          console.log("🚀 ~ file: ChooseSubscription.jsx:342 ~ .then ~ result.setupIntent:", result.setupIntent)

          StripeService.attachPaymentToCustomer(email, result.setupIntent.payment_method)
            .then((r) => {
              console.log("🚀 ~ file: ChooseSubscription.jsx:345 ~ .then ~ r repsone form attach customer:", r)
              // WHERE TRIAL END WILL BE FOUND
              // console.log(r.data.trial_end);
              // pay(r.newClientSecret);
              handleResultAfterPay(result);
            })
            .catch((e) => {
              console.log("error attaching payment to customer", e);
            });
        }
      });
  };

  const handleResultAfterPay = (result) => {
    console.log("🚀 ~ file: ChooseSubscription.jsx:259 ~ handleResultAfterPay ~ result:", result)
    // console.log("reuslt: ", result);
    if (result.paymentIntent || result.setupIntent || result === "Paypal" || result.isGiftedSubscription) {
      console.log(TokenService.getOAuthMethod())
      if (TokenService.getOAuthMethod() != null) {
        AuthService.loginThroughOAuth()
          .then((response) => {
            setLoading(false);
            setUserInfo(response.data);
            TokenService.setUser(response.data);
            setPaymentComplete(true);
          })
          .catch((e) => {
            // DONE
            // 2: Error after payment, during authentication
            //    (Modal should logout user and navigate to /login)
            // console.log("error after payment logging in: ", e);
            setLoading(false);
            setErrorType("login");
            setError(true);
          });
      } else {
        AuthService.login(email, password)
          .then((response) => {
            console.log("🚀 ~ file: ChooseSubscription.jsx:377 ~ .then ~ response:", response)

            // DONE
            // 1: Successful Payment (Should have modal that notifies
            //    success and gives button to go to /bookcatalog)
            setLoading(false);
            setUserInfo(response.data);
            TokenService.setUser(response.data);
            setPaymentComplete(true);
          })
          .catch((e) => {
            // DONE
            // 2: Error after payment, during authentication
            //    (Modal should logout user and navigate to /login)
            // console.log("error after payment logging in: ", e);
            setLoading(false);
            setErrorType("payment");
            setError(true);
          });
      }
    }
    if (result.error) {
      // DONE
      // 3: Error during payment. This already is handled but you
      //    could move some functionality to the modal instead
      //    of how it is now.

      console.log("result error: ", result.error);
      displayError(result);
      setLoading(false);
      setError(true);

    }
  };
  useEffect(() => {
    if (noPaymentMethodRequiredForGift) { submitSubscription(); }
  }, [noPaymentMethodRequiredForGift]);


  const attemptApplyDiscountCode = () => {
    if (!discountCode || discountCode === "") return;
    StripeService.getDiscountStatus(discountCode)
      .then((res) => {
        console.log(res.data)
        const { name, duration, percentOff, durationInMonths } = res.data;

        if ((percentOff === 100 && durationInMonths === 12) || (percentOff === 100)) {
          setNoPaymentMethodRequiredForGift(true)


        }


        toast.success(`Discount for ${percentOff} off for ${durationInMonths} has been successfully applied`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setShowMessage(true);
        setMessageType("success");
        if (userHasTrialed) {

          setMessage(
            `${name} applied! Your card will be charged ${centsToString(
              monthlyPrice.unit_amount - (monthlyPrice.unit_amount * percentOff) / 100
            )} on your next billing cycle.`
          );
        } else {


          setMessage(

            `${name} applied! Your trial will last for 7 days and then your discount will be applied for ${duration === 1 ? "1 month" : `${durationInMonths} months`
            }. After that you will be required to update your payment information to continue your subscription.  `
          );
        }
      })
      .catch((e) => {

        setMessageType("error");
        setMessage("Discount code is invalid.");
        setShowMessage(true);
      });
  };

  return (
    <>
      <Helmet>
        <script async src="https://tag.simpli.fi/sifitag/28f43f90-3b95-013b-a6e2-0cc47abd0334"></script>
      </Helmet>
      <div className="subscription-chooser-page">
        <div className="subscription-chooser">
          <div className="subscription-choose-content">
            {!(monthlyPrice && yearlyPrice) ? (
              <div>
                <LoadingContentSpinner />
              </div>
            ) : (
              <>
                <h1 className="subscription-chooser__title">Choose your plan</h1>
                <div className="subscription-chooser__cards">
                  <SubscriptionCard

                    isSelected={selectedPlan === "monthly" ? true : false}
                    label="Monthly"
                    userHasTrialed={userHasTrialed}
                    period="month"
                    price={centsToString(monthlyPrice.unit_amount)}
                    billed="monthly"
                    setSelected={setSelectedPlan}
                  />
                  <SubscriptionCard
                    isSelected={selectedPlan === "annually" ? true : false}
                    label="Yearly"
                    period="year"
                    price={centsToString(yearlyPrice.unit_amount)}
                    billed="annually"
                    setSelected={setSelectedPlan}
                  />
                </div>
                <div className={`tier-descriptor ${selectedPlan}`}>
                  {selectedPlan === "monthly" && monthlyPrice && (
                    <span className="text3" style={{ color: "#000" }}>
                      {userHasTrialed ? (
                        `We'll process your payment today, and your subscription will automatically renew each month. You can choose to cancel anytime before your next billing cycle begins.`
                      ) : (
                        `After your 7-day trial your credit card will be charged ${centsToString(monthlyPrice.unit_amount)}
                    . Your subscription will renew monthly unless you cancel before your next payment is due.`
                      )}
                    </span>
                  )}
                  {selectedPlan === "annually" && yearlyPrice && (
                    <>
                      <span className="text3" style={{ color: "#ee2b19", fontWeight: 800 }}>
                        We currently do not offer a free trial for Yearly. Your credit card will be charged as soon as
                        you submit your card info.{" "}
                      </span>
                      <span className="text3" style={{ color: "#000" }}>
                        Your subscription will renew once a year for ${centsToString(yearlyPrice.unit_amount)} unless
                        you cancel before your next payment is due.
                      </span>
                    </>
                  )}
                </div>
                <div className="message-center" style={{ height: showMessage ? 80 : 0 }}>
                  <div className={`message-text ${showMessage ? "visible" : ""} ${messageType}`}>{message} </div>
                </div>
                <div className="discount-code-container">
                  <UnderlinedTextInput
                    label="Got a discount or gift code? Use it here!"
                    value={discountCode}
                    onValueChange={(e) => setDiscountCode(e.target.value)}
                    func={attemptApplyDiscountCode}
                    containerStyle={{ marginTop: "0.5rem" }}
                    disabled={disableDiscount}
                  />
                  <ColoredButton
                    text={`${noPaymentMethodRequiredForGift ? "Submit" : "Apply"}`}
                    style={{ padding: "0.5rem 1rem", margin: "0 0 2.4rem 1rem", borderRadius: "0.5rem" }}
                    onClick={attemptApplyDiscountCode}
                    disabled={disableDiscount}
                  />
                </div>
                {!noPaymentMethodRequiredForGift && (<div className="subscription-chooser__payment-section">
                  <PaymentArea handleCardInfoChange={handleCardInfoChange} />
                </div>)}
                <span className="stripe-attribution">
                  Powered by <a href="https://stripe.com/">Stripe</a>
                </span>
                <ColoredButton
                  text="Submit"
                  onClick={submitSubscription}
                  disabled={loading}
                  style={{ width: "70%", marginTop: "1rem" }}
                />
                <div className="or-hr">
                  <hr />
                  <span>OR</span>
                  <hr />
                </div>
                <PaypalButtonArea onSuccess={handleResultAfterPay} setLoading={setLoading} tier={selectedPlan} userHasTrialed={userHasTrialed} />
                <div className="subscription-chooser__submit-button-container">
                  {/* <button className="submit-button" >
                Submit
              </button> */}
                </div>
                {/* {paymentComplete && (
                  <PaymentCompleteModal
                    user={userInfo}
                    history={history}
                    trial={selectedPlan === "monthly" ? true : false}
                  />
                )} */}
                {error && (
                  <PaymentErrorModal
                    type={errorType}
                    close={() => {
                      setError(false);
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {loading && (
        <LoadingModal
          isLoading={loading}
          message={
            <>
              <span className="label1" style={{ marginTop: "1rem" }}>
                Processing...
              </span>
              <br />
              <span className="text2">Please do not use your browser's back or reload buttons.</span>
            </>
          }
          takingTooLong={takingTooLong}
        />
      )}
    </>
  );
};

export default ChooseSubscription;
