import React, { createContext, useState } from 'react';

// Create context
export const NotificationBarContext = createContext();

// Provider component
export const NotificationBarProvider = ({ children }) => {
    const [isVisible, setIsVisible] = useState(true);

    const showNotificationBar = () => setIsVisible(true);
    const hideNotificationBar = () => setIsVisible(false);

    return (
        <NotificationBarContext.Provider value={{ isVisible, showNotificationBar, hideNotificationBar }}>
            {children}
        </NotificationBarContext.Provider>
    );
};
