import React, { useState, useEffect } from 'react';
import { nanoid } from 'nanoid';

function SearchResults({
  visible,
  results,
  inputRef,
  searchText,
  setSearchText,
  goToItemPage,
  capitalizeFirstLetter,
}) {
  const [index, setIndex] = useState(-1);
  const resultsLength = results.length;

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!visible) {
        window.removeEventListener('keydown', handleKeyDown);
        return;
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        setIndex((i) => i - 1);
      }
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setIndex((i) => i + 1);
      }
      if (e.key === 'Escape') inputRef.current.blur();
      // ENTER
      if (e.keyCode === 13) {
        // console.log("current results:", results);
        // console.log("current index:", index);
        // console.log("Going to item:", results[index]);
        var clonedItem = { ...results[index] };
        goToItemPage(clonedItem);
        setIndex(-1);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [results, index, visible]);

  // useEffect(() => {
  //   if (visible) window.addEventListener("keydown", handleKeyDown);
  //   else window.removeEventListener("keydown", handleKeyDown);
  // }, [visible, handleKeyDown, results]);

  // This is the only way I could get this to work even remotely.
  useEffect(() => {
    if (resultsLength > 0) {
      if (index < -1) setIndex(-1);
      if (index > resultsLength - 1) setIndex(resultsLength - 1);
    }
  }, [index, resultsLength]);

  return (
    <>
      {visible && (
        <div className="search-results">
          {results.map((r, i) => {
            return (
              <div
                key={nanoid()}
                className={`search-result__item selectable ${
                  index === i ? 'highlighted' : ''
                }`}
                onClick={() => goToItemPage(r)}
                onMouseEnter={() => {
                  setIndex(i);
                }}
              >
                <div className="search-result__item-title">{r.title}</div>
                <div className="search-result__item-type">
                  {capitalizeFirstLetter(r.type)}
                </div>
              </div>
            );
          })}
          {results.length === 0 && searchText.length >= 3 && (
            <div className="search-result__item">
              <div className="search-result__item-title">No Results Found</div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default SearchResults;
