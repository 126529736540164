import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Context } from "../auth/state/Store";
import TokenService from "../../services/auth/TokenService";
import { toast } from "react-toastify";

// Icons
import { AiFillHome, AiFillInfoCircle } from "react-icons/ai";
import { FiChevronDown } from "react-icons/fi";
import { FaUserCircle } from "react-icons/fa";
import { BsFillPersonFill } from "react-icons/bs";
import { IoIosLogOut, IoIosLogIn } from "react-icons/io";
import { ImBooks, ImGift } from "react-icons/im";
import { HiBookmark } from "react-icons/hi";

import "./MobileMenu.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function MobileMenu(props) {
  const [state, dispatch] = useContext(Context);
  const [aboutExpanded, setAboutExpanded] = useState(false);

  const [accountPopup, setAccountPopup] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!state.dimmer) dispatch({ type: "MOBILE_SIDEBAR_HIDE" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.dimmer]);

  useEffect(() => {
    setAccountPopup(false);
  }, [state.showMobileSidebar]);

  const closeMenu = () => {
    dispatch({ type: "MOBILE_SIDEBAR_HIDE" });
  };

  const navigateToLogin = (_) => {
    if (history.location.state && history.location.state.travelto) {
      history.push({
        pathname: "/login",
        fromReg: false,
        previous: history.location.pathname,
        state: {
          travelto: history.location.state.travelto,
        },
      });
    } else {
      history.push({
        pathname: "/login",
        fromReg: false,
        previous: history.location.pathname,
      });
    }

    closeMenu();
  };

  return (
    <div
      className={`mobile-menu-container ${
        state.showMobileSidebar ? "visible" : ""
      }`}
    >
      <div className="mm-header">
        <span>
          Guitar <span style={{ color: "var(--primary-color)" }}>by</span>{" "}
          Masters
        </span>
      </div>

      <section className="navIconGrid">
        <Link to="/accountpage" className="iconBtn" onClick={closeMenu}>
          <div className="iconContainer">
            <FaUserCircle className="icon" /> <p>Account</p>
          </div>
        </Link>
        <Link to="/userhome" className="iconBtn" onClick={closeMenu}>
          <div className="iconContainer">
            <ImBooks className="icon" /> <p>Library</p>
          </div>
        </Link>

        {TokenService.getUser() ? (
          <button
            className="iconBtn"
            onClick={() => {
              dispatch({ type: "LOG_OUT" });
              closeMenu();

              toast.success("Logged out succesfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }}
          >
            <div className="iconContainer">
              <IoIosLogOut className="icon" /> <p>Logout</p>
            </div>
          </button>
        ) : (
          <button className="iconBtn" onClick={navigateToLogin}>
            <div className="iconContainer">
              <IoIosLogIn className="icon" /> <p>Login</p>
            </div>
          </button>
        )}
      </section>

      {TokenService.getUser() ? (
        <Link to="/userhome" className="mm-item" onClick={closeMenu}>
          <HiBookmark fill="var(--primary-color)" />
          <div className="text3 mm-item-label">My Library</div>
        </Link>
      ) : (
        <Link to="/" className="mm-item" onClick={closeMenu}>
          <AiFillHome fill="var(--primary-color)" />
          <div className="text3 mm-item-label">Home</div>
        </Link>
      )}
      <Link to="/artistcatalog" className="mm-item" onClick={closeMenu}>
        <BsFillPersonFill fill="var(--primary-color)" />
        <div className="text3 mm-item-label">Artists</div>
      </Link>
      <Link to="/bookcatalog" className="mm-item" onClick={closeMenu}>
        <ImBooks fill="var(--primary-color)" />
        <div className="text3  mm-item-label">Full Catalog</div>
      </Link>
      <Link to="/gift/giftCenter" className="mm-item" onClick={closeMenu}>
        <ImGift fill="var(--primary-color)" />
        <div className="text3  mm-item-label">Gift Center</div>
      </Link>
      <div
        className="mm-item"
        id="information"
        style={{
          borderBottom: aboutExpanded
            ? "1px solid var(--dark-gray)"
            : "1px solid transparent",
        }}
        onClick={() => {
          setAboutExpanded((e) => !e);
        }}
      >
        <AiFillInfoCircle fill="var(--primary-color)" />
        <div className="text3  mm-item-label">Information</div>
        <FiChevronDown
          className={`mm-chevron ${aboutExpanded ? "active" : ""}`}
        />
      </div>
      {aboutExpanded && (
        <>
          <Link
            to="/about"
            className="mm-item dropped-item"
            onClick={closeMenu}
          >
            <div className="text3 mm-item-label">About Us</div>
          </Link>
          <Link
            to="/compliments"
            className="mm-item dropped-item"
            onClick={closeMenu}
          >
            <div className="text3 mm-item-label">Testimonials</div>
          </Link>

          {!TokenService.getUser() && (
            <Link
              to="/#membership-benefits"
              className="mm-item dropped-item"
              onClick={closeMenu}
            >
              <div className="text3 mm-item-label">Membership</div>
            </Link>
          )}
        </>
      )}
    </div>
  );
}

export default MobileMenu;
