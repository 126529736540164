import {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback,
} from "react";
import bookServices from "../../../services/BookService";
import { Link } from "react-router-dom";
import TokenService from "../../../services/auth/TokenService";
import UserService from "../../../services/UserService";
import FavButton from "../../../components/micro_components/FavButton";
import BookListPopup from "../../../components/micro_components/BookListPopup";
import { ReactComponent as CloseIcon } from "../../..//assets/x.svg";

import "./bookprofile.css";

import LoadingContentSpinner from "../../../components/loading/LoadingContentSpinner";
import { useHistory } from "react-router";
import BottomBannerModal from "../../../components/modal/BottomBannerModal/BottomBannerModal";
import OutlinedButton from "../../../components/buttons/OutlinedButton";
import ColoredButton from "../../../components/buttons/ColoredButton";
import ReactMarkdown from "react-markdown";

const BookProfile = (props) => {
  const [currentBook, setCurrentBook] = useState([]);
  const [description, setDescription] = useState("");
  const [saveBookPopup, setSaveBookPopup] = useState(false);
  const [likedBooks, setLikedBooks] = useState([]);
  const [expandDescription, setExpandDescription] = useState(false);
  const descriptionRef = useRef();
  const [showFadeTop, setShowFadeTop] = useState();
  const [showFadeBottom, setShowFadeBottom] = useState(true);
  const [moreLessButtonDisplay, setMoreLessButtonDisplay] = useState();
  const [fav, setFav] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [listPopupVisible, setListPopupVisible] = useState(false);
  const [viewPreview, setViewPreview] = useState(false);
  const [loadingContent, setLoadingContent] = useState(true);
  const history = useHistory();
  const [interludeBook, setInterludeBook] = useState(null);

  const [bottomBannerOpen, setBottomBannerOpen] = useState(false);
  const [bottomBannerMessage, setBottomBannerMessage] = useState("");
  const [hideInterlude, setHideInterlude] = useState(false);

  const booksWithoutPDF = [371, 390];

  const descriptionFadeTopStyle = {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "2rem",
    marginTop: "-1px",
    background: "linear-gradient(180deg, #0e0e0eff 0%, #0e0e0e00 100%)",
    opacity: showFadeTop ? 1 : 0,
    pointerEvents: "none",
    transition: "opacity 0.3s ease",
  };
  const descriptionFadeBottomStyle = {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "2rem",
    marginBottom: "-1px",
    background: "linear-gradient(0deg, #0e0e0eff 0%, #0e0e0e00 100%)",
    opacity: showFadeBottom ? 1 : 0,
    pointerEvents: "none",
    transition: "opacity 0.3s ease",
  };

  // This function checks the height of the description
  // container and detwemines whether to show the fade div
  const checkFade = () => {
    if (descriptionRef.current) {
      setShowFadeTop(descriptionRef.current.scrollTop > 0);
      // (attempted fix for mobile)
      // if the scroll is within 2 pixels of the bottom of the available space,
      // instead of checking that it's exactly at the bottom
      setShowFadeBottom(
        !(
          descriptionRef.current.clientHeight +
            descriptionRef.current.scrollTop +
            2 >=
          descriptionRef.current.scrollHeight
        )
      );
      setMoreLessButtonDisplay(
        descriptionRef.current &&
          descriptionRef.current.scrollHeight >
            descriptionRef.current.clientHeight
      );
    }
  };

  const { bookId } = props.match.params;
  const bookCoverLink = currentBook.coverFileName;
  const bookCover = bookServices.getBookCoverSrc(bookId, bookCoverLink);
  const saveBook = useRef();
  const [bgColor, setBgColor] = useState("#000");

  useEffect(() => {
    // get book from Interlude Scores if it exists there
    try {
      bookServices
        .getInterludeBookbyId(bookId)
        .then((res) => setInterludeBook(res.data));
    } catch (e) {
      console.log(e);
    }

    bookServices.getBookWithCategoryDTO(bookId).then((response) => {
      setCurrentBook(response.data);
    });

    bookServices
      .getBookDescription(bookId)
      .then((response) => {
        setDescription(response.data);
        setLoadingContent(false);
      })
      .catch((e) => {
        setLoadingContent(false);
      });
    // console.log(currentBook);

    checkFade();
  }, [bookId]);

  useEffect(() => {
    let isYamandou = false;
    if (currentBook) {
      currentBook.roleDtos?.forEach((role) => {
        if (role.creator_name === "Yamandu Costa") isYamandou = true;
      });
      setHideInterlude(isYamandou);
    }
  }, [currentBook]);

  useEffect(() => {
    setTimeout(() => {
      if (saveBookPopup) {
        window.addEventListener("click", closeSaveBookDropdown);
      } else {
        window.removeEventListener("click", closeSaveBookDropdown);
      }
    }, 90);
  }, [saveBookPopup]);

  const closeSaveBookDropdown = useCallback((e) => {
    if (saveBook.current && !saveBook.current.contains(e.target)) {
      setSaveBookPopup(false);
    }
  }, []);

  useLayoutEffect(() => {
    const getHearts = () => {
      UserService.getLikedBooks(TokenService.getUser().id).then((response) => {
        setLikedBooks(response.data);
      });
    };
    const dontgethearts = () => {};

    TokenService.getUser() ? getHearts() : dontgethearts();
  }, [window.onload]);

  useEffect(() => {
    const checkLikedBooks = () => {
      likedBooks.forEach((book) => {
        if (book.id.toString() === bookId) {
          setIsLiked(true);
        }
      });
    };

    const dontchecklikedBooks = () => {
      console.log("not logged in to check");
    };

    TokenService.getUser() ? checkLikedBooks() : dontchecklikedBooks();
  }, [likedBooks]);

  const resetLikedBooks = () => {
    UserService.getLikedBooks(TokenService.getUser().id).then((response) => {
      setLikedBooks(response.data);
      console.log("reseting liked books");
    });
  };

  useEffect(() => {
    checkFade();
  }, [description, expandDescription]);

  const handleDescriptionExpand = () => {
    if (expandDescription) {
      setExpandDescription(false);
      descriptionRef.current.scrollTop = 0;
    } else setExpandDescription(true);
  };

  useEffect(() => {
    for (const book of likedBooks) {
      if (book.id.toString() === bookId) {
        setIsLiked(true);
        break;
      } else {
        setIsLiked(false);
      }
    }
  }, [bookId, resetLikedBooks]);

  const previewBookFunc = () => {
    setViewPreview(!viewPreview);
  };

  const handleStartLearning = () => {
    if (TokenService.getUser()) {
      history.push({
        pathname: `/reader/${bookId}`,
        query: { bookId: bookId },
      });
    } else {
      history.push({
        state: {
          travelto: `/reader/${bookId}`,
        },
      });
      setBottomBannerOpen(true);
      setBottomBannerMessage(
        <>
          <p>{`Want to check out ${currentBook.title}?`}</p>
          <p>
            Sign up for your free trial today! Plans start at only $6.99/month.
          </p>
        </>
      );
    }
  };

  const openBannerFav = () => {
    setBottomBannerOpen(true);
    setBottomBannerMessage(
      <>
        <p>You need an account in order to favorite a book.</p>
        <p>
          Sign up for your free trial today! Plans start at only $6.99/month.
        </p>
      </>
    );
  };

  const goToLogin = () => {
    history.push({
      pathname: "/login",
      fromReg: true,
      state: {
        travelto: `/reader/${bookId}`,
      },
    });
  };

  const groupedCategories = {};
  if (currentBook.categories) {
    currentBook.categories.forEach((category) => {
      const groupName = category.group.name;
      if (!groupedCategories[groupName]) {
        groupedCategories[groupName] = [];
      }
      groupedCategories[groupName].push(category);
    });
  }

  return (
    <div style={{ minHeight: "75vh" }}>
      {loadingContent ? (
        <div style={{ minheight: "75vh" }} className="loading-spinner">
          <LoadingContentSpinner />
        </div>
      ) : (
        <div
          className="book-profile-page screen-l fadeInBottom"
          style={{
            // backgroundImage: `linear-gradient(to bottom, rgb(0, 0, 0) 0%, transparent 50%)`,
            minHeight: "75vh",
          }}
        >
          <div className="book-profile-container">
            <div className="book-detail-container animated fadeInBottom">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  onClick={handleStartLearning}
                  alt="book cover"
                  className="book-cover"
                  src={bookCover}
                  style={{ marginBottom: "1rem", cursor: "pointer" }}
                />

                <ColoredButton
                  text="Start Learning"
                  onClick={handleStartLearning}
                  style={{ width: "100%", marginBottom: "1rem" }}
                />

                {currentBook.sampleVideoLink ? (
                  <OutlinedButton
                    style={{ width: "100%" }}
                    text={"Book Preview"}
                    onClick={previewBookFunc}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="book-details">
                <div
                  style={{
                    display: "flex",
                    direction: "row",
                    alignItems: "center",
                    gap: "1.5rem",
                  }}
                >
                  <FavButton
                    fav={fav}
                    setFav={setFav}
                    isLiked={isLiked}
                    setIsLiked={setIsLiked}
                    listPopupVisible={listPopupVisible}
                    resetLikedBooks={resetLikedBooks}
                    likedBooks={likedBooks}
                    bookId={bookId}
                    openBanner={openBannerFav}
                  />

                  <div>
                    <h2>{currentBook ? currentBook.title : "Book Title"}</h2>

                    <div className="role-container">
                      {currentBook.roleDtos
                        ? currentBook.roleDtos.map((role, i) => {
                            return (
                              <div key={i} className="role-links">
                                <div className="text2">
                                  {role.title.replace(",", ", ")}:{" "}
                                </div>
                                <Link
                                  key={i}
                                  to={{
                                    pathname: `/artistprofile/${role.creator_id}`,
                                  }}
                                >
                                  <div
                                    className="text2"
                                    style={{ marginLeft: "0.2rem" }}
                                  >
                                    {" "}
                                    {role.creator_name}{" "}
                                  </div>
                                </Link>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                </div>

                <hr />
                <ReactMarkdown linkTarget={"_blank"}>
                  {description}
                </ReactMarkdown>
                <div className="book-misc-details">
                  <div style={{ display: "flex" }}>
                    <div className="text3">Commentary: </div>
                    <div className="text2" style={{ marginLeft: "0.2rem" }}>
                      {currentBook ? currentBook.commentary : ""}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="text3">Performance: </div>
                    <div className="text2" style={{ marginLeft: "0.2rem" }}>
                      {currentBook ? currentBook.score : ""}
                    </div>
                  </div>

                  <br />
                  {currentBook.third_party_PDF_store ? (
                    <div className="pdf-section">
                      <p>
                        A printable version of the score can be purchased from
                        <span>
                          <a
                            href={currentBook.third_party_PDF_store}
                            className="pdf-btn"
                            target="blank"
                          >
                            the original publisher.
                          </a>
                        </span>{" "}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  {currentBook.dpi_PDF_link ? (
                    <div className="pdf-section">
                      <p>
                        A printable version of the score can be purchased from
                        <a
                          href={currentBook.dpi_PDF_link}
                          className="pdf-btn"
                          target="blank"
                        >
                          the DPI store.
                        </a>
                      </p>
                      {TokenService.getUser() && currentBook.pdf_coupon ? (
                        <p>
                          Type code{" "}
                          <span
                            style={{
                              color: "var(--primary-color)",
                              fontWeight: "bold",
                            }}
                          >
                            {currentBook.pdf_coupon}
                          </span>{" "}
                          at checkout to receive discount for this score.
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  <div style={{ display: "flex" }}>
                    <div
                      className="text2 tags"
                      style={{ marginLeft: "0.2rem" }}
                    >
                      {[
                        "Composers",
                        "Difficulty Levels",
                        "Eras",
                        "Forms and Styles",
                        "Regions and Countries",
                        "Series",
                        "Languages",
                      ].map((category, i) => {
                        return (
                          <span key={i}>
                            {currentBook && currentBook.categories ? (
                              <>
                                {groupedCategories[category]?.map((pill) => (
                                  <button
                                    key={pill.id}
                                    className="categoryPill"
                                    onClick={() => {
                                      history.push("/bookcatalog", {
                                        category: pill.name,
                                        categoryId: pill.id,
                                      });
                                    }}
                                  >
                                    <p className="categoryPillText">
                                      {pill.name}
                                    </p>
                                  </button>
                                ))}
                              </>
                            ) : null}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                  {!hideInterlude && interludeBook?.isPublishing ? (
                    <div className="interlude-invitation">
                      <a
                        href={`https://interludescores.com/books/${interludeBook.slug}`}
                        className="interlude-icon-link"
                      >
                        <svg viewBox="0 0 32.886 32.828">
                          <path
                            d="M17.441 14.602h-1.882c-.401 0-.753-.245-.9-.614l-.785-1.963a.9626.9626 0 0 1 .27-1.096l1.669-1.44a.9703.9703 0 0 1 1.276 0l1.693 1.464c.303.262.417.687.278 1.063l-.687 1.939c-.147.394-.515.647-.932.647z"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            fill="#f36b3c"
                          />
                          <path
                            d="M0 13.89C1.44 6.266 7.788 0 16.443 0s15.003 6.266 16.443 13.89c-.016.605-1.685.72-1.832 0-1.8-5.366-5.955-10.945-14.61-10.945S3.632 8.524 1.832 13.89c-.204.712-1.824.581-1.832 0z"
                            fill="#f36b3c"
                          />
                          <path d="M15.003 23.363c.057-6.307.057-1.415.057-2.79 0-1.374-1.129-.867-1.129-1.432 0-.769 1.824-1.865 2.487-1.865 1.448 0 1.947.499 1.89 2.454-.18 6.307-.18 2.233-.18 6.389 0 1.652.033 2.724.033 3.73 0 1.718 1.963 2.978.36 2.978h-3.984c-1.153 0 .409-2.184.409-3.19-.008-1.005.057-4.474.057-6.274z" />
                        </svg>
                      </a>
                      <div>
                        For lifetime access to this book,{" "}
                        {currentBook.length === 0 ||
                        booksWithoutPDF?.some(
                          (i) => i === currentBook.id
                        ) ? null : (
                          <span>
                            as well as a downloadable PDF of the score,{" "}
                          </span>
                        )}
                        check it out on{" "}
                        <a
                          href={`https://interludescores.com/books/${interludeBook.slug}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          interludescores.com{" "}
                        </a>
                      </div>
                    </div>
                  ) : null}

                  <br />
                </div>
              </div>
            </div>
          </div>
          {currentBook.sampleVideoLink && viewPreview ? (
            <div className="book-preview-container">
              <CloseIcon
                stroke="#FFF"
                strokeWidth={50}
                className="close-book-preview-btn"
                style={{
                  cursor: "pointer",
                  height: "40px",
                  width: "auto",
                  padding: "0.4rem",
                }}
                onClick={previewBookFunc}
              />
              <video
                className="book-preview"
                controls
                preload="metadata"
                autoPlay
              >
                <source src={currentBook.sampleVideoLink} />
              </video>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      <div
        className="book-profile-page screen-m screen-s"
        style={{
          backgroundImage: `linear-gradient(180deg, ${bgColor} 0%, transparent 50%)`,
        }}
      >
        <div className="book-profile-container">
          <div className="book-details">
            <div className="book-main-details">
              <h2>{currentBook ? currentBook.title : "Book Title"}</h2>
              <div
                className="image-and-description"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Link to={`/reader/${bookId}`}>
                  <img
                    alt="book cover"
                    className="book-cover"
                    src={bookCover}
                  />
                </Link>
                <div className="role-and-description">
                  {currentBook.roleDtos
                    ? currentBook.roleDtos.map((role, i) => {
                        return (
                          <div key={i} className="role-links">
                            <p>{role.title}: </p>
                            <Link
                              to={{
                                pathname: `/artistprofile/${role.creator_id}`,
                              }}
                            >
                              <strong>{role.creator_name}</strong>
                            </Link>
                          </div>
                        );
                      })
                    : ""}
                  <hr style={{ marginBottom: "0 0 1rem 0" }} />
                  <div style={{ position: "relative", marginBottom: "1.5rem" }}>
                    <div
                      ref={descriptionRef}
                      className="book-description"
                      style={{
                        height: expandDescription ? "auto" : "100px",
                        overflowY: expandDescription ? "auto" : "hidden",
                      }}
                      onScroll={checkFade}
                    >
                      <ReactMarkdown linkTarget={"_blank"}>
                        {description}
                      </ReactMarkdown>
                      <div style={descriptionFadeTopStyle}></div>
                      <div style={descriptionFadeBottomStyle}></div>
                      <div
                        className="more-less-button"
                        style={{
                          marginTop: expandDescription ? "1rem" : 0,
                          display: moreLessButtonDisplay,
                        }}
                        onClick={handleDescriptionExpand}
                      >
                        <span>{!expandDescription ? "More" : "Less"}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="book-misc-details">
              <h3>Details</h3>
              <div className="detail-item-container">
                <div className="detail-item">
                  <p>Languages:</p>
                  {/* split the string of languages into an array so they can be listed as a <ul> */}
                  <ul className="language-list">
                    {currentBook && currentBook.language
                      ? currentBook.language.split(", ").map((language, i) => {
                          return <li key={i}>{language}</li>;
                        })
                      : ""}
                  </ul>
                </div>

                <div className="detail-item">
                  <p>Commentary:</p>
                  <p id="value">{currentBook ? currentBook.commentary : ""}</p>
                </div>
                <div className="detail-item">
                  <p>Performance:</p>
                  <p id="value">{currentBook.score}</p>
                </div>
                <div className="detail-item">
                  <p>Difficulty:</p>
                  <p id="value">{currentBook ? currentBook.difficulty : ""}</p>
                </div>
              </div>
            </div>
            {currentBook.dpi_PDF_link ? (
              <div className="pdf-section" style={{ margin: "1rem 0" }}>
                <p>
                  A printable version of the score can be purchased from
                  <a
                    href={currentBook.dpi_PDF_link}
                    className="pdf-btn"
                    target="blank"
                  >
                    the DPI store.
                  </a>
                </p>
                {TokenService.getUser() && currentBook.pdf_coupon ? (
                  <p>
                    Type code{" "}
                    <span
                      style={{
                        color: "var(--primary-color)",
                        fontWeight: "bold",
                      }}
                    >
                      {currentBook.pdf_coupon}
                    </span>{" "}
                    at checkout to receive discount for this score.
                  </p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {interludeBook !== null ? (
              <div className="interlude-invitation">
                <a
                  href={`https://interludescores.com/books/${interludeBook.slug}`}
                  className="interlude-icon-link"
                >
                  <svg viewBox="0 0 32.886 32.828">
                    <path
                      d="M17.441 14.602h-1.882c-.401 0-.753-.245-.9-.614l-.785-1.963a.9626.9626 0 0 1 .27-1.096l1.669-1.44a.9703.9703 0 0 1 1.276 0l1.693 1.464c.303.262.417.687.278 1.063l-.687 1.939c-.147.394-.515.647-.932.647z"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      fill="#f36b3c"
                    />
                    <path
                      d="M0 13.89C1.44 6.266 7.788 0 16.443 0s15.003 6.266 16.443 13.89c-.016.605-1.685.72-1.832 0-1.8-5.366-5.955-10.945-14.61-10.945S3.632 8.524 1.832 13.89c-.204.712-1.824.581-1.832 0z"
                      fill="#f36b3c"
                    />
                    <path d="M15.003 23.363c.057-6.307.057-1.415.057-2.79 0-1.374-1.129-.867-1.129-1.432 0-.769 1.824-1.865 2.487-1.865 1.448 0 1.947.499 1.89 2.454-.18 6.307-.18 2.233-.18 6.389 0 1.652.033 2.724.033 3.73 0 1.718 1.963 2.978.36 2.978h-3.984c-1.153 0 .409-2.184.409-3.19-.008-1.005.057-4.474.057-6.274z" />
                  </svg>
                </a>
                <div>
                  For lifetime access to this book, as well as a downloadable
                  PDF of the score, check it out on{" "}
                  <a
                    href={`https://interludescores.com/books/${interludeBook.slug}`}
                  >
                    interludescores.com
                  </a>
                </div>
              </div>
            ) : null}
            <div className="preview-container">
              <h3 className="book-preview-header">Preview</h3>
              {currentBook.sampleVideoLink ? (
                <video
                  className="book-preview"
                  width="100"
                  height="80"
                  controls
                >
                  <source src={currentBook.sampleVideoLink} />
                </video>
              ) : (
                ""
              )}
            </div>
            <div className="book-profile-btn-container">
              <ColoredButton
                text="Start Learning"
                onClick={handleStartLearning}
                style={{ marginRight: "1rem" }}
              />
              <FavButton
                fav={fav}
                setFav={setFav}
                isLiked={isLiked}
                setIsLiked={setIsLiked}
                listPopupVisible={listPopupVisible}
                resetLikedBooks={resetLikedBooks}
                likedBooks={likedBooks}
                bookId={bookId}
                openBanner={openBannerFav}
                strokeWidth={1}
              />
            </div>
          </div>
        </div>
      </div>
      {bottomBannerOpen && (
        <BottomBannerModal
          onClick={goToLogin}
          buttonText={"Get Started"}
          message={bottomBannerMessage}
        />
      )}
    </div>
  );
};

export default BookProfile;
