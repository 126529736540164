import React, { useState } from 'react';
import UnderlinedTextInput from '../../../components/auth/underlinedText/UnderlinedTextInput';
import ColoredButton from '../../../components/buttons/ColoredButton';
import { useHistory } from 'react-router-dom';
import AuthService from '../../../services/auth/AuthService';
import ConfirmationModal from '../../../components/loading/ConfirmationModal';

import './EnterEmail.css';

const FP_EnterEmail = ({ setStage, email, setEmail }) => {
  // email
  const [invalidMessage, setInvalidMessage] = useState('');
  const [emailInvalid, setEmailInvalid] = useState(false);

  // modal stuff
  const [loading, setLoading] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [success, setSuccess] = useState(false);

  // navigation
  const history = useHistory();

  const goToLogin = () => {
    history.push('/login');
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const submit = () => {
    if (checkEmail()) {
      setEmailInvalid(true);
      setInvalidMessage(checkEmail());
      return;
    }
    // console.log("sending password reset email.");
    setLoading(true);
    setConfirmationModalOpen(true);
    AuthService.sendPasswordResetEmail(email)
      .then((response) => {
        // console.log("send password reset email: ", response.data);
        setSuccess(true);
        setLoading(false);
        setSubmitMessage(
          `Success! An email to reset your password has been sent to ${email}.`
        );
        // setStage("LINK_SENT");
      })
      .catch((e) => {
        console.log('error sending password reset email: ', e);
        setSubmitMessage(
          <>
            <p>Uh oh! There was an error sending your password reset email.</p>
            <br />
            <p>Please verify your email is typed correctly.</p>
          </>
        );
        setLoading(false);
        setSuccess(false);
      });
  };

  const checkEmail = () => {
    if (!(email && email.length > 1)) {
      return 'Email is required.';
    } else {
      return false;
    }
  };

  const closeLoadingModal = () => {
    setConfirmationModalOpen(false);
  };

  return (
    <>
      <div className="enter-email-container">
        <div className="enter-email__title">
          <h1 className="enter-email__main-title">Forgot your password?</h1>
          <span className="enter-email__subtitle">
            Please enter the email address you would like the password reset
            information sent to
          </span>
        </div>
        <div className="enter-email__email-field">
          <UnderlinedTextInput
            label={'Email'}
            value={email}
            onValueChange={handleEmailChange}
            isInvalid={emailInvalid}
            func={submit}
          />
        </div>

        {invalidMessage && invalidMessage.length > 1 && (
          <div className="enter-email__invalid-message">{invalidMessage}</div>
        )}

        <ColoredButton
          text={'Send Email'}
          onClick={submit}
          fontSize={'1.3rem'}
        />

        <span className="enter-email__back-to-login text2" onClick={goToLogin}>
          Back to login
        </span>
      </div>
      {confirmationModalOpen && (
        <ConfirmationModal
          isLoading={loading}
          message={submitMessage}
          onClose={success ? goToLogin : closeLoadingModal}
          buttonMessage={success ? 'Go to login' : 'Close'}
        />
      )}
    </>
  );
};

export default FP_EnterEmail;
