import React from "react";
import "../loading/YesNoModal.css";
import { ReactComponent as XButton } from "../../assets/x.svg";

const LargeModal = ({ content, onX, title, subtitle, isOpen, hideX }) => {
  return (
    isOpen && (
      <>
        <div className="large-modal-dimmer" onClick={onX || null} />
        <div className="large-modal">
          <div className="large-modal-content">
            <div className="large-modal-heading">
              <h2 className="large-modal__primary-heading">{title}</h2>
              <div className="label2 large-modal__prompt">{subtitle}</div>
            </div>
            {content}
            {!hideX && (
              <div className="large-modal-x" onClick={onX}>
                <XButton stroke={"white"} strokeWidth={75} />
              </div>
            )}
          </div>
        </div>
      </>
    )
  );
};

export default LargeModal;
