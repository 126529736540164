import React from "react";

import TeamCard from "./TeamCard";

import "./AboutUs.css";

function AboutUs(props) {
  return (
    <>
      <div className="about-us">
        <div className="text-section">
          <h1>About Us</h1>
          <div className="divider">
            <hr />
            <h2>What we do</h2>
            <hr />
          </div>
          <p>
            Guitar by Masters publishes music composed or arranged by renowned guitar musicians and performed by master
            performers from around the world. Whether they are composers, arrangers, performers or teachers, these
            musicians are true masters of their trade who are willing to share their art, knowledge and talent. The
            unique content in Guitar by Masters, combined with the innovative technology of interactive scores, puts
            users in close virtual contact with the greatest contemporary guitarists.
          </p>
          <p>
            In addition to publishing a wide variety of contemporary guitar music, Guitar by Masters also offers
            carefully crafted tutorials on some of the most essential pieces in the established classical guitar
            repertoire. These tutorials are taught by prominent teachers and academics, with the purpose of adding
            professional insight and expressive richness to your guitar playing.
          </p>
          <div className="divider">
            <hr />
            <h2>Practice Pal</h2>
            <hr />
          </div>
          <p>
            Powering all Guitar by Masters music scores is Practice Pal, our patented interactive platform designed to
            transform how sheet music is published and used.
          </p>
          <p>
            Practice Pal's innovative learning method integrates music scores with synchronized video or audio playback,
            as well as tips, comments, and instructions from the composer, performer, or teacher in multiple languages.
            You can navigate the playback by touching each measure in the score, loop a certain section, and even create
            a call-and-response experience with adjustable pauses between each repetition.
          </p>
          <p>
            Every single practice session matters, and Practice Pal provides the tools needed to make any practice
            session efficient, fun and effective.
          </p>
        </div>
        <div className="image-section">
          <h1>Our Team</h1>
          <TeamCard
            name="Dr. Hossein Zamanian"
            id="hz"
            title={
              <span>
                Founder and CEO
                <br />
                Software Entrepeneur / Inventor
                <br />
                Dallas, Texas, USA
              </span>
            }
            image="https://d9fedpdsh4r16.cloudfront.net/GbM/about-us/Hossein.jpg"
          />
          <div className="divider">
            <hr />
            <h2>Artistic Consultants</h2>
            <hr />
          </div>
          <div className="card-container">
            {/* <TeamCard
              name="Dr. Juan Albarracin-Jordan"
              image="https://d9fedpdsh4r16.cloudfront.net/GbM/about-us/Juan.jpg"
            /> */}
            <TeamCard name="Sergio Assad" image="https://d9fedpdsh4r16.cloudfront.net/GbM/about-us/Sergio.jpg" />
            <TeamCard name="Denis Azabagić" image="https://d9fedpdsh4r16.cloudfront.net/GbM/about-us/Denis.jpg" />
            <TeamCard name="Dušan Bogdanović" image="https://d9fedpdsh4r16.cloudfront.net/GbM/about-us/Dusan.jpg" />
            <TeamCard name="Elodie Bouny" image="https://d9fedpdsh4r16.cloudfront.net/GbM/about-us/Elodie.jpg" />
            <TeamCard name="Yamandu Costa" image="https://d9fedpdsh4r16.cloudfront.net/GbM/about-us/Yamandu.jpg" />
            <TeamCard name="Peppino D'Agostino" image="https://d9fedpdsh4r16.cloudfront.net/GbM/about-us/Peppino.jpg" />
            <TeamCard name="Stephanie Jones" image="https://d9fedpdsh4r16.cloudfront.net/GbM/about-us/Stephanie.jpg" />
            <TeamCard name="William Kanengiser" image="https://d9fedpdsh4r16.cloudfront.net/GbM/about-us/Bill.jpg" />
            <TeamCard name="David Russell" image="https://d9fedpdsh4r16.cloudfront.net/GbM/about-us/David.jpg" />
          </div>
          <div className="divider">
            <hr />
            <h2>Publishing</h2>
            <hr />
          </div>
          <div className="card-container">
            <TeamCard
              name="Dr. Ehsan Shafiee Zargar"
              title="Artistic Director"
              image="https://d9fedpdsh4r16.cloudfront.net/GbM/about-us/Ehsan.jpg"
            />
            <TeamCard
              name="Aaron Stang"
              title="Director of Publishing"
              image="https://d9fedpdsh4r16.cloudfront.net/GbM/about-us/Aaron.jpg"
            />
          </div>
          <div className="divider">
            <hr />
            <h2>Development</h2>
            <hr />
          </div>
          <div className="card-container">
            <TeamCard
              name="Nick Magee"
              title="Back-end / API Developer"
              image="https://d9fedpdsh4r16.cloudfront.net/GbM/about-us/Nick.jpg"
            />
            <TeamCard
              name="Cody Moy"
              title="Director of Development"
              image="https://d9fedpdsh4r16.cloudfront.net/GbM/about-us/Cody.jpg"
            />
            <TeamCard
              name="Cameron Verver"
              title="Front-end Developer"
              image="https://d9fedpdsh4r16.cloudfront.net/GbM/about-us/Cameron.jpg"
            />
          </div>
          <div className="divider">
            <hr />
            <h2>Production</h2>
            <hr />
          </div>
          <div className="card-container">
            <TeamCard
              name="Cole Adams"
              title="Media Producer"
              image="https://d9fedpdsh4r16.cloudfront.net/GbM/about-us/Cole.jpg"
            />
            <TeamCard
              name="Crystal Enns"
              title="Media Producer"
              image="https://d9fedpdsh4r16.cloudfront.net/GbM/about-us/Crystal.jpg"
            />
            <TeamCard
              name="Hannah Hughes"
              title={
                <span>
                  Media Producer,
                  <br />
                  Graphic Designer
                </span>
              }
              image="https://d9fedpdsh4r16.cloudfront.net/GbM/about-us/Hannah.jpg"
            />
          </div>
          <div className="divider">
            <hr />
            <h2>Marketing</h2>
            <hr />
          </div>
          <div className="card-container">
            <TeamCard name="Karmen Stendler" image="https://d9fedpdsh4r16.cloudfront.net/GbM/about-us/Karmen.jpg" />
            <TeamCard name="Merce Font" image="https://d9fedpdsh4r16.cloudfront.net/GbM/about-us/Merce.jpg" />
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
