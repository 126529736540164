const cloudFrontURL = "https://d9fedpdsh4r16.cloudfront.net/GbM/book-video-assets/Intros";
const posterURL = "https://dpi-assets.s3.us-east-2.amazonaws.com/GbM/artist-testomimonial-thumbnails";

// ´´´´¶¶¶¶¶¶´´´´´´¶¶¶¶¶¶
// ´´¶¶¶¶¶¶¶¶¶¶´´¶¶¶¶¶¶¶¶¶¶
// ´¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶´´´´¶¶¶¶
// ¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶´´´´¶¶¶¶
// ¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶´´¶¶¶¶¶
// ¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶ ´¶¶¶¶¶´
// ´´¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶
// ´´´´´¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶¶
// ´´´´´´´¶¶¶¶¶¶¶¶¶¶¶¶¶
// ´´´´´´´´´¶¶¶¶¶¶¶¶
// ´´´´´´´´´´´¶¶¶¶

export const ComplimentData = [
  {
    videoId: 1,
    posterLink: `${posterURL}/Antigoni-Goni-Intro+video+thumbnail.jpg`,
    videoLink: `${cloudFrontURL}/Antigoni-Goni-Intro.mp4`,
    artistId: 38,
    artistName: "Antigoni Goni",
    language: "English",
  },
  {
    videoId: 2,
    posterLink: `${posterURL}/Atanas-Ourkouzounov-Intro+video+thumbnail.jpg`,
    videoLink: `${cloudFrontURL}/Atanas-Intro.mp4`,
    artistId: 39,
    artistName: "Atanas Ourkouzounov",
    language: "English",
  },
  {
    videoId: 3,
    posterLink: `${posterURL}/Bosko-Intro+video+thumbnail-+bos.jpg`,
    videoLink: `${cloudFrontURL}/Bosko_Intro_Bos.mp4`,
    artistId: 42,
    artistName: "Boško Jović",
    language: "Bosanski / босански / بۉسانسقى",
  },
  {
    videoId: 4,
    posterLink: `${posterURL}/Bosko-Intro+video+thumbnail-+en.jpg`,
    videoLink: `${cloudFrontURL}/Bosko_Intro_ENG.mp4`,
    artistId: 42,
    artistName: "Boško Jović",
    language: "English",
  },
  {
    videoId: 5,
    posterLink: `${posterURL}/Brad-Richter-Intro+video+thumbnail.jpg`,
    videoLink: `${cloudFrontURL}/Brad-Richter-Intro.mp4`,
    artistId: 43,
    artistName: "Brad Richter",
    language: "English",
  },
  {
    videoId: 6,
    posterLink: `${posterURL}/Carlos-Moscardini-Intro+video+thumbnail.jpg`,
    videoLink: `${cloudFrontURL}/Carlos-Moscardini-Intro.mp4`,
    artistId: 45,
    artistName: "Carlos Moscardini",
    language: "Español (English subtitles)",
  },
  {
    videoId: 7,
    posterLink: `${posterURL}/cecilia-siqueira-Intro+video+thumbnail.jpg`,
    videoLink: `${cloudFrontURL}/Cecilia-Siqueira-sp.ensubs.mp4`,
    artistId: 47,
    artistName: "Cecilia Siqueira",
    language: "Español",
  },
  {
    videoId: 8,
    posterLink: `${posterURL}/Daniel-Murray-Intro+video+thumbnail-fr.jpg`,
    videoLink: `${cloudFrontURL}/Daniel-Murray-fr.mp4`,
    artistId: 51,
    artistName: "Daniel Murray", //french
    language: "Français",
  },
  {
    videoId: 9,
    posterLink: `${posterURL}/Daniel-Murray-Intro+video+thumbnail-port.jpg`,
    videoLink: `${cloudFrontURL}/Daniel-Murray-por.mp4`,
    artistId: 51,
    artistName: "Daniel Murray", //portuguese
    language: "Português",
  },
  {
    videoId: 10,
    posterLink: `${posterURL}/Daniel-Murray-Intro+video+thumbnail-span.jpg`,
    videoLink: `${cloudFrontURL}/Daniel-Murray-sp.mp4`,
    artistId: 51,
    artistName: "Daniel Murray", //spanish
    language: "Español",
  },
  {
    videoId: 11,
    posterLink: `${posterURL}/Daniel-Murray-Intro+video+thumbnail-en.jpg`,
    videoLink: `${cloudFrontURL}/Daniel-Murray-en.mp4`,
    artistId: 51,
    artistName: "Daniel Murray", //english
    language: "English",
  },
  {
    videoId: 12,
    posterLink: `${posterURL}/Daniel-Saboya-Intro+video+thumbnail-span.jpg`,
    videoLink: `${cloudFrontURL}/Daniel-Saboya-Intro-sp.mp4`,
    artistId: "",
    artistName: "Daniel Saboya",
    language: "Español",
  },
  {
    videoId: 13,
    posterLink: `${posterURL}/Danniel-Wolff-Intro+video+thumbnail.jpg`,
    videoLink: `${cloudFrontURL}/Daniel-Wolff-Intro.mp4`,
    artistId: 143,
    artistName: "Daniel Wolff",
    language: "English",
  },
  {
    videoId: 14,
    posterLink: `${posterURL}/Daniela-Rossi-Intro+video+thumbnail.jpg`,
    videoLink: `${cloudFrontURL}/DanielaRossi-intro.en.mp4`,
    artistId: 118,
    artistName: "Daniela Rossi", //spanish
    language: "Engish",
  },
  {
    videoId: 15,
    posterLink: `${posterURL}/Daniela-Rossi-Intro+video+thumbnail+sp.jpg`,
    videoLink: `${cloudFrontURL}/DanielaRossi-intro.sp.mp4`,
    artistId: 118,
    artistName: "Daniela Rossi", //spanish
    language: "Español",
  },
  {
    videoId: 16,
    posterLink: `${posterURL}/Denis-Azabagic-Intro+video+thumbnail-en.jpg`,
    videoLink: `${cloudFrontURL}/DenisA-intro-en.mp4`,
    artistId: 22,
    artistName: "Denis Azabagic", //english
    language: "English",
  },
  {
    videoId: 17,
    posterLink: `${posterURL}/Denis-Azabagic-Intro+video+thumbnail-span.jpg`,
    videoLink: `${cloudFrontURL}/DenisA-Intro-sp.mp4`,
    artistId: 22,
    artistName: "Denis Azabagic", //spanish
    language: "Español",
  },
  {
    videoId: 18,
    posterLink: `${posterURL}/Douglas-Lora-Intro+video+thumbnail.jpg`,
    videoLink: `${cloudFrontURL}/Douglas-Lora-intro.mp4`,
    artistId: 56,
    artistName: "Douglas Lora",
    language: "English",
  },
  {
    videoId: 19,
    posterLink: `${posterURL}/Dusan-Bogdanovic-Intro+video+thumbnail.jpg`,
    videoLink: `${cloudFrontURL}/DusanB-Intro.mp4`,
    artistId: 57,
    artistName: "Dušan Bogdanović",
    language: "English",
  },
  {
    videoId: 20,
    posterLink: `${posterURL}/Edson-Lopes-Intro+video+thumbnail-por.jpg`,
    videoLink: `${cloudFrontURL}/Edson-Lopes-Intro-ensubs.mp4`,
    artistId: 58,
    artistName: "Edson Lopes",
    language: "Português (English subtitles)",
  },
  {
    videoId: 21,
    posterLink: `${posterURL}/Elody-Bouny-Intro+video+thumbnail-en.jpg`,
    videoLink: `${cloudFrontURL}/Elodie-Bouny-Intro-en.mp4`,
    artistId: 5,
    artistName: "Elodie Bouny", //english
    language: "English",
  },
  {
    videoId: 22,
    posterLink: `${posterURL}/Elody-Bouny-Intro+video+thumbnail-fr.jpg`,
    videoLink: `${cloudFrontURL}/Elodie-Bouny-Intro-fr.mp4`,
    artistId: 5,
    artistName: "Elodie Bouny", //french
    language: "Français",
  },
  {
    videoId: 23,
    posterLink: `${posterURL}/Elody-Bouny-Intro+video+thumbnail-por.jpg`,
    videoLink: `${cloudFrontURL}/Elodie-Bouny-Intro-po.mp4`,
    artistId: 5,
    artistName: "Elodie Bouny", //portuguese
    language: "Português",
  },
  {
    videoId: 24,
    posterLink: `${posterURL}/Elody-Bouny-Intro+video+thumbnail-sp.jpg`,
    videoLink: `${cloudFrontURL}/Elodie-Bouny-Intro-sp.mp4`,
    artistId: 5,
    artistName: "Elodie Bouny", //spanish
    language: "Español",
  },
  {
    videoId: 25,
    posterLink: `${posterURL}/Guy-Cuyvers-Intro video thumbnail.jpg`,
    videoLink: `${cloudFrontURL}/Guy-Cuyvers-Intro.mp4`,
    artistId: 180,
    artistName: "Guy Cuyvers",
    language: "English",
  },
  {
    videoId: 26,
    posterLink: `${posterURL}/Fred-Intro-video-thumbnail.jpg`,
    videoLink: `${cloudFrontURL}/Fred-intro.mp4`,
    artistId: 141,
    artistName: "Fred Hamilton",
    Language: "",
  },
  {
    videoId: 27,
    posterLink: `${posterURL}/Javier-Contreras-Intro+video+thumbnail.jpg`,
    videoLink: `${cloudFrontURL}/JavierContreras-Intro.mp4`,
    artistId: 65,
    artistName: "Javier Contreras",
    language: "Español",
  },
  {
    videoId: 28,
    posterLink: `${posterURL}/joaocamarero-thumb-po.jpg`,
    videoLink: `${cloudFrontURL}/JoaoCam-intro.mp4`,
    artistId: 124,
    artistName: "João Camarero",
    Language: "",
  },
  {
    videoId: 29,
    posterLink: `${posterURL}/Karmen-Stendler-Intro+video+thumbnail-en.jpg`,
    videoLink: `${cloudFrontURL}/Karmen-Stendler-en.mp4`,
    artistId: 29,
    artistName: "Karmen Stendler", //english
    language: "English",
  },
  {
    videoId: 30,
    posterLink: `${posterURL}/Karmen-Stendler-Intro+video+thumbnail-sp.jpg`,
    videoLink: `${cloudFrontURL}/Karmen-Stendler-sp.mp4`,
    artistId: 29,
    artistName: "Karmen Stendler", //spanish
    language: "Español",
  },
  {
    videoId: 31,
    posterLink: `${posterURL}/Kevin-Callahan-Intro+video+thumbnail.jpg`,
    videoLink: `${cloudFrontURL}/Kevin-Intro.mp4`,
    artistId: 26,
    artistName: "Kevin Callahan",
    language: "English",
  },
  {
    videoId: 32,
    posterLink: `${posterURL}/thumbnail-Lucio-Matarazzo.jpg`,
    videoLink: `${cloudFrontURL}/Lucio-intro.mp4`,
    artistId: 123,
    artistName: "Lucio Matarazzo",
    Language: "English",
  },
  {
    videoId: 33,
    posterLink: `${posterURL}/Marc-Teicholz-Intro+video+thumbnail.jpg`,
    videoLink: `${cloudFrontURL}/Marc-Teicholz-Intro.mp4`,
    artistId: 76,
    artistName: "Marc Teicholz",
    language: "English",
  },
  {
    videoId: 34,
    posterLink: `${posterURL}/Mateusz-Kowalski-Intro+video+thumbnail.jpg`,
    videoLink: `${cloudFrontURL}/Mateusz-Kowalski-Intro.mp4`,
    artistId: 79,
    artistName: "Mateusz Kowalski",
    language: "English",
  },
  {
    videoId: 35,
    posterLink: `${posterURL}/Peter-Graneis-Intro+video+thumbnail.jpg`,
    videoLink: `${cloudFrontURL}/Peter-Graneis-intro.mp4`,
    artistId: 4,
    artistName: "Peter Graneis",
    language: "English",
  },
  {
    videoId: 36,
    posterLink: `${posterURL}/Rafael-Padron-Intro+video+thumbnail-en.jpg`,
    videoLink: `${cloudFrontURL}/Rafael-Padron-en.mp4`,
    artistId: 89,
    artistName: "Rafael Padrón", //english
    language: "English",
  },
  {
    videoId: 37,
    posterLink: `${posterURL}/Rafael-Padron-Intro+video+thumbnail-sp.jpg`,
    videoLink: `${cloudFrontURL}/Rafael-Padron-sp.mp4`,
    artistId: 89,
    artistName: "Rafael Padrón", //spanish
    language: "Español",
  },
  {
    videoId: 38,
    posterLink: `${posterURL}/Stephanie-Jones-Intro+video+thumbnail.jpg`,
    videoLink: `${cloudFrontURL}/StephanieJones-Intro.mp4`,
    artistId: 2,
    artistName: "Stephanie Jones",
    language: "English",
  },
  {
    videoId: 39,
    posterLink: `${posterURL}/Stephen-Krishnan-Intro+video+thumbnail.jpg`,
    videoLink: `${cloudFrontURL}/Stephen-Krishnan-Intro.mp4`,
    artistId: 94,
    artistName: "Stephen Krishnan",
    language: "English",
  },
  {
    videoId: 40,
    posterLink: `${posterURL}/Thu-Le-Intro+video+thumbnail-en.jpg`,
    videoLink: `${cloudFrontURL}/Thu-Le-en.mp4`,
    artistId: 97,
    artistName: "Thu Le", //english
    language: "English",
  },
  {
    videoId: 41,
    posterLink: `${posterURL}/Thu-Le-Intro+video+thumbnail-viet.jpg`,
    videoLink: `${cloudFrontURL}/Thu-Le-vt.mp4`,
    artistId: 97,
    artistName: "Thu Le", //vietnamese
    language: "Tiếng Việt",
  },
  {
    videoId: 42,
    posterLink: `${posterURL}/William-Kanengiser-Intro+video+thumbnail.jpg`,
    videoLink: `${cloudFrontURL}/William-Kanegiser-Intro.mp4`,
    artistId: 99,
    artistName: "William Kanengiser",
    language: "English",
  },
  {
    videoId: 43,
    posterLink: `${posterURL}/Xie-Yuchi-Intro+video+thumbnail-ch.jpg`,
    videoLink: `${cloudFrontURL}/Xie-Yuchi-ch.mp4`,
    artistId: "",
    artistName: "Xie Yuchi", //chinese
    language: "中国人",
  },
  {
    videoId: 44,
    posterLink: `${posterURL}/Xie-Yuchi-Intro+video+thumbnail-en.jpg`,
    videoLink: `${cloudFrontURL}/Xie-Yuchi-en.mp4`,
    artistId: "",
    artistName: "Xie Yuchi", //english
    language: "English",
  },
  {
    videoId: 45,
    posterLink: `${posterURL}/Yamandu-Costa-Intro+video+thumbnail-por.jpg`,
    videoLink: `${cloudFrontURL}/Yamandu-intro-po.mp4`,
    artistId: 3,
    artistName: "Yamandu Costa", //portuguese
    language: "Português",
  },
  {
    videoId: 46,
    posterLink: `${posterURL}/Yamandu-Costa-Intro+video+thumbnail-sp.jpg`,
    videoLink: `${cloudFrontURL}/Yamandu-intro-sp.mp4`,
    artistId: 3,
    artistName: "Yamandu Costa", //spanish
    language: "Español",
  },
];
