import React from "react";
import { useEffect } from "react";
import "./artistcard.css";
import ImageLoader from "../image/ImageLoader";

const ArtistCard = ({ artist, location }) => {
  // useEffect(() => {
  //   console.log({ artist, location });
  // }, [artist, location]);

  return (
    <>
      {artist && (
        <div className="artist-container-container">
          <div className="artist-container">
            <ImageLoader
              src={`https://da9nh4xe5cncw.cloudfront.net/${artist.id}/${artist.galleryImageURL}`}
              alt={`Guitar by Masters Classical Guitarist ${artist.name}`}
              draggable={false}
              style={{ width: "300px", height: "300px" }}
            />
            <h1 className="artist-name">{artist.name}</h1>
            <div className="artist-overview">
              {location && <p>{artist.location}</p>}
            </div>
          </div>
          <div className="artist-card-heading">
            <div>
              <h3>{artist.name}</h3>
              <p style={{ marginLeft: "0.25rem" }}>
                {artist.num_books >= 1
                  ? "Books: " + artist.num_books
                  : "Books coming soon"}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ArtistCard;
