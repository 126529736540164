import React from "react";
import { Link } from "react-router-dom";
import OutlinedButton from "../../../components/buttons/OutlinedButton";

import "./testimonials.css";

const Testimonials = () => {
  return (
    <div className="testimonials">
      <h3>See what our artists say about Guitar by Masters!</h3>
      <div className="testimonial-container">
        <div className="testimonial-description">
          <div className="testimonial-artist-container">
            <div className="testimonial-artist-compliment">
              <p>
                "I love Practice Pal! It is a perfect tool to deliver artists'
                insights and tips to the aspiring players."
              </p>

              <div className="testimonial-artist-link">
                <Link to="/artistprofile/22" className="no-underline">
                  <img
                    className="testimonial-image"
                    src={`${process.env.REACT_APP_BACKEND_URL}/creators/22/galleryImage/download`}
                    alt="Denis Azabgic"
                  />
                  <div>
                    <h3>Denis Azabagić</h3>
                    <p>Classical guitarist, performer, and teacher</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonial-description">
          <div className="testimonial-artist-container">
            <div className="testimonial-artist-compliment">
              <p>
                "The products at Guitar by Masters are going to improve your
                playing and allow you to get real insight into performance."
              </p>

              <div className="testimonial-artist-link">
                <Link to="/artistprofile/99" className="no-underline">
                  <img
                    className="testimonial-image"
                    src={`${process.env.REACT_APP_BACKEND_URL}/creators/99/mainImage/download`}
                    alt="William Kanengiser"
                  />
                  <div>
                    <h3>William Kanengiser</h3>
                    <p>Classical guitarist, performer, and teacher</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonial-description">
          <div className="testimonial-artist-container">
            <div className="testimonial-artist-compliment">
              <p>
                "Practice Pal helps you really absorb and study pieces in
                depth... go measure by measure, master challenge by challenge!"
              </p>

              <div className="testimonial-artist-link">
                <Link to="/artistprofile/57" className="no-underline">
                  <img
                    className="testimonial-image"
                    src={`${process.env.REACT_APP_BACKEND_URL}/creators/57/mainImage/download`}
                    alt="Dusan Bogdanovic"
                  />
                  <div>
                    <h3>Dušan Bogdanović</h3>
                    <p>Classical guitarist and composer</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a id="partners"></a>
      <Link to="/testimonials" className="no-underline">
        <OutlinedButton text={"SEE MORE"} />
      </Link>
    </div>
  );
};

export default Testimonials;
