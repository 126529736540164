import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import "./LoadingModal.css";

const loadingColor = "#fff";

const LoadingModal = ({ isLoading, message, takingTooLong }) => {
  return (
    <>
      {isLoading && (
        <>
          <div className="modal-dimmer" />
          <div className="loading-spinner" id="payment">
            <ScaleLoader color={loadingColor} loading={isLoading} />
            <div
              className="loading-spinner-message"
              style={{ opacity: !takingTooLong ? 1 : 0.6 }}
            >
              {message}
            </div>
            {takingTooLong && (
              <>
                <hr
                  style={{
                    width: "100%",
                    border: "none",
                    borderTop: "1px solid var(--middle-gray",
                    margin: "1rem 0 0 0",
                  }}
                />
                <div
                  className="loading-spinner-message"
                  style={{ fontWeight: 600, color: "var(--primary-color)" }}
                >
                  This is taking longer than we expected.
                </div>
                <div
                  className="loading-spinner-message"
                  style={{ display: "block" }}
                >
                  If this message is still here after another minute, please{" "}
                  <a
                    href="mailto:support@guitarbymasters.com?subject=Issue During Payment"
                    style={{
                      textDecoration: "underline",
                      color: "var(--primary-color)",
                    }}
                  >
                    send us an email
                  </a>{" "}
                  describing what happened in detail.
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default LoadingModal;
