import http from "../http-common";
import { publishingApi } from "../http-common";
import { DOMAIN_ID } from "../config";

const getCategoryGroups = () => {
  //used in book catalog to get all groups
  return http.get(`/domain/${DOMAIN_ID}/categorygroups`);
};

const getBookbyId = (id) => {
  //used to get book
  return http.get(`/books/${id}/${DOMAIN_ID}`);
};

const getBookWithCategoryDTO = (id) => {
  //used to get book
  return http.get(`/bookWithCategoryDTO/${id}/${DOMAIN_ID}`);
};

const getInterludeBookbyId = (id) => {
  //used to get book fron interludescores.com
  return publishingApi.get(`/books/${id}/authoring`);
};

const getRecentlyAddedBooks = (_) => {
  return http.get(`/books/${DOMAIN_ID}/recent`);
};

const getRecentlyAddedBooksTop15 = (_) => {
  return http.get(`/books/${DOMAIN_ID}/recentBooks`);
};

const getBookDescription = (bookId) => {
  // used in book profile - outdated
  return http.get(`/books/${bookId}/description/download/${DOMAIN_ID}`);
};

const getBooksByGroupId = (id) => {
  // used in book catalog
  return http.get(`/categorygroup/${id}`);
};

const getBooksInCategory = (categoryId) => {
  //used in userhome
  return http.get(`categories/booksin/${categoryId}`);
};

const getAllBooks = (domainId) => {
  // used in searchPage
  return http.get(`books/${domainId}/all/list`);
};

const getBooksByTitle = (title, domainId) => {
  // used in searchPage
  return http.get(`/books/search/${title}/${domainId}`);
};

const getBookChaptersAndPages = (id) => {
  //  used in reader
  return http.get(`books/${id}/insides/${DOMAIN_ID}`);
};

const getBookCoverSrc = (bookId, bookCoverLink) => {
  //used alot
  return `https://d24hdgytax3x03.cloudfront.net/${bookId}/${bookCoverLink}`;
};

const constructBookAssetLink = (bookId, link) => {
  // used in coaching point
  return `https://d24hdgytax3x03.cloudfront.net/${bookId}/CoachingPoints/${link}`;
};

const constructBookAudioLink = (bookId, link) => {
  //used in reader
  return `https://d24hdgytax3x03.cloudfront.net/${bookId}/ChapterAudios/${link}`;
};

const getSampleBook = (bookId) => {
  // used in sample book
  return http.get(`books/sample/insides/${bookId}`);
};

const loadSearchOptions = (domainId) => {
  //used in search
  return http.get(`/search/options/${domainId}`);
};

const logBookVisits = (userId, bookId) => {
  // used here and there
  return http.post(`/logBookVisited/user/${userId}/book/${bookId}`);
};

const logPageVisits = (bookId, pageId, userId) => {
  // used in reader
  return http.post(
    `/logPageVisited/bookId/${bookId}/pageId/${pageId}/userId/${userId}`
  );
};

const getPageVisitDetails = (pageId) => {
  // used in small book card
  return http.get(`/getRecentlyVisitedBookInsides/${pageId}`);
};

const BookDataService = {
  getCategoryGroups,
  getBookbyId,
  getBookWithCategoryDTO,
  getBookDescription,
  getBooksByGroupId,
  getBooksInCategory,
  getRecentlyAddedBooks,
  getBookChaptersAndPages,
  getBookCoverSrc,
  getBooksByTitle,
  getAllBooks,
  constructBookAssetLink,
  constructBookAudioLink,
  getSampleBook,
  loadSearchOptions,
  logBookVisits,
  logPageVisits,
  getPageVisitDetails,
  getInterludeBookbyId,
  getRecentlyAddedBooksTop15,
};

export default BookDataService;
