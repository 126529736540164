import React from "react";

const GroupCard = ({ group, onClickFunction, selected }) => {
  return (
    <div className={`category-group-button ${selected ? "selected" : ""} text3`} onClick={onClickFunction}>
      {group.name}
    </div>
  );
};

export default GroupCard;
