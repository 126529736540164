import React, { useState, useEffect } from "react";
import UnderlinedTextInput from "../../../components/auth/underlinedText/UnderlinedTextInput";
import AuthService from "../../../services/auth/AuthService";
import "./SignUp.css";
import CodePage from "../codePage/CodePage";
import ColoredButton from "../../../components/buttons/ColoredButton";
import ColoredCheckbox from "../../../components/buttons/ColoredCheckbox";
import Tooltip from "../../../components/micro_components/Tooltip";
import OAuthSection from "../../../components/auth/oauth2/oauthsection/OAuthSection";
import { useHistory } from "react-router";

const SignUp = ({ fromGiftCodePath, setEmailSent, setRegisterEmail, setDisplayToggle, setRegisterMode, setLoading }) => {
  // fields
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [newsletter, setNewsletter] = useState(true);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  //error states
  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);

  // password-specific requirements
  const [passwordLengthValid, setPasswordLengthValid] = useState(false);
  const [passwordCaseValid, setPasswordCaseValid] = useState(false);
  const [passwordSpecialValid, setPasswordSpecialValid] = useState(false);

  const [emailMessage, setEmailMessage] = useState(null);
  const [passwordMessage, setPasswordMessage] = useState(null);

  const [stage, setStage] = useState("ENTER_INFO"); //ENTER INFO, EMAIL_SENT,

  // final state to allow registration
  const [ready, setReady] = useState(false);

  const history = useHistory();

  // state
  // const [state, dispatch] = useContext(Context);

  // FINAL CHECK
  useEffect(() => {
    if (emailValid && passwordValid && passwordMatch) setReady(true);
    else setReady(false);
  }, [emailValid, passwordValid, passwordMatch]);

  // email validation
  const checkEmail = (value) => {
    if (!value || value.length < 1) {
      setEmailMessage("Please enter an email address");
      setEmailValid(false);
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      setEmailMessage("Email address is invalid");
      setEmailValid(false);
    } else {
      setEmailValid(true);
      setEmailMessage("");
    }
  };

  // password validation
  const checkPassword = (value) => {
    if (value) {
      if (value.length >= 8) setPasswordLengthValid(true);
      else setPasswordLengthValid(false);
      if (/^(?=.*?[A-Z])(?=.*?[a-z])/.test(value)) setPasswordCaseValid(true);
      else setPasswordCaseValid(false);
      if (/(?=.*?[#?!@$%^&*-])/.test(value)) setPasswordSpecialValid(true);
      else setPasswordSpecialValid(false);

      // 8+ characters, UPPER + lower, $pec!al character
      if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).{8,}$/.test(value)) setPasswordValid(true);
      else setPasswordValid(false);
    } else {
      //if there is nothing in the password field
      setPasswordLengthValid(false);
      setPasswordCaseValid(false);
      setPasswordSpecialValid(false);
      setPasswordValid(false);
    }
  };

  // password match validation
  useEffect(() => {
    if (repeatPassword && repeatPassword.length && repeatPassword === password) {
      setPasswordMatch(true);
    } else setPasswordMatch(false);
  }, [password, repeatPassword]);

  const submit = () => {
    if (ready) {
      setLoading(true);
      AuthService.register(email, password, newsletter)
        .then((response) => {
          setLoading(false);
          //console.log(response.data);
          // history.push({
          //   pathname: "/userhome",
          // });
          // dispatch({ type: "LOG_IN" });
          setDisplayToggle(false);
          setStage("EMAIL_SENT");
        })
        .catch((e) => {
          setLoading(false);
          if (e.response.data.message === "Error: Email is already in use!") {
            setEmailValid(false);
            setEmailMessage("Email is already in use.");
          }
          // console.log(e.response);
          // console.log("Error logging in:", e.response.data.message);
        });
    }
  };

  return (
    <>
      {stage === "ENTER_INFO" && (
        <>
          <div className="login-signup-form-container" id="signup">
            <UnderlinedTextInput
              // autoFocus={history.location.fromReg ? true : false}
              label={"Email"}
              value={email}
              onValueChange={(e) => {
                setEmail(e.target.value);
                setRegisterEmail(e.target.value);
                checkEmail(e.target.value);
              }}
              func={submit}
              showWarning={!emailValid}
              warningMessage={emailMessage}
              showCheck={emailValid}
              enableWarningIcon
            />
            <UnderlinedTextInput
              label={"Password"}
              value={password}
              onValueChange={(e) => {
                setPassword(e.target.value);
                checkPassword(e.target.value);
              }}
              type={"password"}
              func={submit}
              tooltip
              showWarning={!passwordValid}
              warningMessage={passwordMessage}
              passwordLengthValid={passwordLengthValid}
              passwordCaseValid={passwordCaseValid}
              passwordSpecialValid={passwordSpecialValid}
              showCheck={passwordValid}
              enableWarningIcon
            />
            <UnderlinedTextInput
              label={"Confirm Password"}
              value={repeatPassword}
              onValueChange={(e) => {
                setRepeatPassword(e.target.value);
              }}
              type={"password"}
              func={submit}
              confirmMatch={passwordMatch}
              showWarning={!passwordMatch}
              warningMessage={!passwordMatch ? "Password and confirmation do not match" : null}
              showCheck={passwordMatch}
              enableWarningIcon
            />
            <ColoredCheckbox
              initial={true}
              setChecked={setNewsletter}
              checked={newsletter}
              fontSize={"1rem"}
              label="Subscribe to our newsletter to stay up-to-date about new books, artists, and features."
            />
            <div className="login-button-container" id="signup">
              <div
                style={{ position: "relative" }}
                onMouseEnter={() => {
                  if (!ready) setTooltipVisible(true);
                }}
                onMouseLeave={() => {
                  setTooltipVisible(false);
                }}
              >
                <ColoredButton onClick={submit} disabled={!ready} text="Sign Up" />
                <Tooltip
                  label="Please fill out this form to continue"
                  visible={tooltipVisible}
                  opacity={1}
                  backgroundColor="red"
                />
              </div>
            </div>
            <div className="oauth-container">
              <div className="or-hr">
                <hr />
                <span>OR</span>
                <hr />
              </div>
              <OAuthSection />
            </div>
          </div>
        </>
      )}
      {stage === "EMAIL_SENT" && <CodePage fromGiftCodePath={fromGiftCodePath} email={email} password={password} />}
    </>
  );
};

export default SignUp;
