import React, { useState, useLayoutEffect, useEffect } from "react";
import DropDown from "./components/DropDown";
import bookServices from "../../../services/BookService";
import BookCard from "../../../components/cards/BookCard";
import BottomBannerModal from "../../../components/modal/BottomBannerModal/BottomBannerModal";
import UserService from "../../../services/UserService";
import BookCatalogSwiper from "./components/BookCatalogSwiper";
import NewsletterPopup from "../../homepage/homepageComponents/NewsletterPopup";
import {
  GridCarousel,
  GridCarouselItem,
} from "../../../components/carousel/GridCarousel";
import { ReactComponent as X } from "../../../assets/x.svg";
import Tooltip from "../../../components/micro_components/Tooltip";
import bookCatalogHeader from "../../../assets/bookCatalogHeader.png";
import ColoredButton from "../../../components/buttons/ColoredButton";

import TokenService from "../../../services/auth/TokenService";

import "./bookcatalog.css";
import GroupCard from "../../../components/cards/GroupCard";
import LoadingContentSpinner from "../../../components/loading/LoadingContentSpinner";
import { useHistory } from "react-router-dom";
import PlainButton from "../../../components/buttons/PlainButton";
import CustomSwiper from "./CustomSwiper";
import SongTable from "./components/SongTable";

function BookCatalog() {
  const [data, setData] = useState([]);
  const [sortBy, setSortBy] = useState("category");
  const [dimmerVisible, setDimmerVisible] = useState(false);
  const history = useHistory();
  const [viewingCategory, setViewingCategory] = useState(
    history &&
      history.location &&
      history.location.state &&
      history.location.state.categoryId
      ? history.location.state.categoryId
      : null
  );
  const [viewingAllCategories, setViewingAllCategories] = useState(false);
  const [currentCategoryName, setCurrentCategoryName] = useState(
    history &&
      history.location &&
      history.location.state &&
      history.location.state.category
      ? history.location.state.category
      : ""
  );
  const [booksToDisplay, setBooksToDisplay] = useState([]);
  const [filterTooltip, setFilterTooltip] = useState(false);
  const [likedBooks, setLikedBooks] = useState([]);
  const [isLiked, setIsLiked] = useState(false);
  const [groupsDetails, setGroupsDetails] = useState([]);
  const [showBottomBanner, setShowBottomBanner] = useState(false);
  const [beginnerBooks, setBeginnerBooks] = useState([]);
  const [tableVisible, setTableVisible] = useState(false);

  const [popupVisible, setPopupVisible] = useState(false);

  const handleNewsletterSignupClick = () => {
    setPopupVisible(true);
  };

  const [hideNewTableTip, setHideNewTableTip] = useState(
    localStorage.getItem("hideNewCatalogTableNotice") || false
  );

  // category groups
  const [groups, setGroups] = useState([]);

  // selected group
  const [currentGroupId, setCurrentGroupId] = useState(0);
  const [currentGroup, setCurrentGroup] = useState();

  // loading books?
  const [loadingContent, setLoadingContent] = useState(true);

  // temporary stuff
  const sortOptions = ["Title", "Difficulty"];

  // GET FEATURED CATEGORIES
  useEffect(() => {
    bookServices
      .getCategoryGroups()
      .then((response) => {
        setGroups(response.data);
        setCurrentGroupId(response.data[0].id);
        setCurrentGroup(response.data[0]);
      })
      .catch((e) => {
        console.log("error getting groups: ", e);
      });
    bookServices.getBooksInCategory(272).then((res) => {
      setBeginnerBooks(res.data);
    });
  }, []);

  //get data for selected group
  useEffect(() => {
    setLoadingContent(true);
    if (currentGroupId) {
      bookServices
        .getBooksByGroupId(currentGroupId)
        .then((res) => {
          setData(res.data.categories);
          setLoadingContent(false);
          setCurrentGroup(res.data);
        })
        .catch((catRetrieveError) => {
          console.log("Error getting categories:", catRetrieveError);
        });
    }
  }, [currentGroupId]);

  const handleGroupClicked = (groupId) => {
    if (groupId === 101) {
      clearFilter();
      setCurrentGroupId(groupId);
      setTableVisible((t) => !t);
      return;
    }

    clearFilter();
    setCurrentGroupId(groupId);
    setViewingAllCategories(false);
    setTableVisible(false);
  };

  const clearFilter = () => {
    setViewingCategory(null);
  };

  useEffect(() => {
    if (viewingCategory) {
      bookServices
        .getBooksInCategory(viewingCategory)
        .then((res) => {
          // console.log(res.data);
          setBooksToDisplay(res.data);
        })
        .catch((e) => {
          console.log(
            `Error getting books in category ${viewingCategory}: ${e}`
          );
        });
    }
  }, [viewingCategory]);

  // for liked books
  useLayoutEffect(() => {
    const getHearts = () => {
      UserService.getLikedBooks(TokenService.getUser().id).then((response) => {
        setLikedBooks(response.data);
      });
    };

    if (TokenService.getUser()) getHearts();
  }, []);

  const resetLikedBooks = () => {
    UserService.getLikedBooks(TokenService.getUser().id).then((response) => {
      setLikedBooks(response.data);
    });
  };

  const viewAllCatgories = () => {
    setViewingAllCategories(true);
  };

  const viewCurrentGroup = () => {
    setViewingAllCategories(false);
  };

  // this is getting all groups and all categories in groups
  useEffect(() => {
    let tempArray = [];
    const getAllGroupsAndCategories = () => {
      groups.map((group) => {
        bookServices.getBooksByGroupId(group.id).then((response) => {
          tempArray.push(response.data);
        });
      });
      setGroupsDetails(tempArray);
    };

    getAllGroupsAndCategories();
  }, [loadingContent]);

  const openBanner = () => {
    // set
    setShowBottomBanner(true);
  };

  const viewSingleCategory = (category) => {
    setCurrentCategoryName(category.name);
    setViewingCategory(category.id);
    setFilterTooltip(false);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  const goToLogin = () => {
    history.push({
      pathname: "/login",
      fromReg: true,
    });
  };

  function dismissNewNotification() {
    setHideNewTableTip(true);
    localStorage.setItem("hideNewCatalogTableNotice", true);
  }

  return (
    <div className="catalog">
      <div
        className="bookCatalogHeader-container"
        style={{
          backgroundImage: `url(${bookCatalogHeader})`,
          backgroundRepeat: "repeat",
          backgroundSize: "50rem",
          backgroundPosition: "center",
          margin: "auto",
          marginBottom: "25px",
        }}
      >
        {/* <img className="bookCatalog-header" src={bookCatalogHeader}/> */}
        <div className="bookCatalogHeader-shader" />
        <div className="bookCatalog-label">
          <h1>Our Catalog</h1>
        </div>
      </div>
      {!TokenService.getUser() && (
        <>
          <div className="promo-container screen-l">
            <div className="book-catalog-promo">
              <div className="text-container">
                <h2>
                  You're just a few clicks away from accessing seriously
                  powerful guitar lessons
                </h2>
                <p>
                  Learn from the best guitarists around the world any time,
                  anywhere.
                </p>
                <p>All you need is a GbM subscription.</p>
                <p>
                  Want to see how it works? Check out the free demo{" "}
                  <a href="/sample-book/538">
                    <strong>here</strong>
                  </a>
                  !
                </p>
                <div className="subscribe-container">
                  <p>If you're ready to get started...</p>
                  <div className="subscribe-button">
                    <strong
                      onClick={() =>
                        history.push({
                          pathname: "/login",
                          fromReg: true,
                        })
                      }
                    >
                      Start your 7-day free trial today
                    </strong>
                  </div>
                </div>
              </div>
              <div className="promo-image-container">
                <img
                  className="promo-image"
                  src="https://dpi-assets.s3.us-east-2.amazonaws.com/GbM/miscellaneous/catalog+promo+image.png"
                  alt="catalog-promo"
                />
              </div>
            </div>
          </div>
          <div className="promo-container screen-m screen-s">
            <div className="book-catalog-promo">
              <div className="promo-image-container">
                <img
                  className="promo-image"
                  src="https://dpi-assets.s3.us-east-2.amazonaws.com/GbM/miscellaneous/catalog+promo+image.png"
                  alt="catalog-promo"
                />
              </div>
              <div className="text-container">
                <h2>
                  Get access to our expanding catalog with a GbM subscription
                </h2>
                <p>
                  Learn from the best guitarists around the world any time,
                  anywhere. All for just $6.99/month.
                </p>
                <p>
                  Want to see how it works?
                  Check&nbsp;out&nbsp;the&nbsp;free&nbsp;demo&nbsp;
                  <a href="/sample-book/538">
                    <strong>here</strong>
                  </a>
                  !
                </p>
                <p>If you're ready to get started...</p>
                <ColoredButton
                  text="Start your free trial"
                  style={{ alignSelf: "center" }}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div className="main-container">
        <h2 className="main-container-header">
          Our catalog is updated weekly!{" "}
          <span
            className="newsletter-link"
            onClick={handleNewsletterSignupClick}
          >
            Sign up to our newsletter
          </span>{" "}
          to stay up to date on the latest additions.
        </h2>
        <div className="catolog-carousel-container fadeInBottom animated">
          <div
            style={{
              fontSize: "1.5rem",
              fontWeight: "200",
              padding: "0.5rem 2rem",
            }}
          >
            Sort By
          </div>
          {groups && groups.length > 0 && (
            <GridCarousel
              items={[
                ...groups,
                {
                  id: 101,
                  name: tableVisible
                    ? "Hide List of Pieces"
                    : "Show List of Pieces",
                  domain_id: 1,
                  domainName: "Guitar by Masters",
                },
              ].map((g, i) => (
                <GridCarouselItem
                  key={i}
                  content={
                    <GroupCard
                      group={g}
                      onClickFunction={() => handleGroupClicked(g.id)}
                      selected={currentGroupId === g.id ? true : false}
                    />
                  }
                />
              ))}
            />
          )}
        </div>

        {tableVisible ? (
          <div className="book-catalog__table-container">
            <SongTable />
          </div>
        ) : null}

        {!tableVisible && (
          <>
            {beginnerBooks && currentGroupId === 7 ? (
              <>
                <h2 className="beginner-books-title">
                  Absolute beginner? Try these books first!
                </h2>
                <div className="beginner-books-container">
                  <div>
                    {beginnerBooks.map((b, i) => {
                      const image = bookServices.getBookCoverSrc(
                        b.id,
                        b.bookCoverLink
                      );

                      return (
                        <BookCard
                          key={i}
                          bookId={b.id}
                          image={image}
                          title={b.title}
                          roles={b.roles}
                          tags={b.categories}
                          video={b.video}
                          likedBooks={likedBooks}
                          resetLikedBooks={resetLikedBooks}
                          isLiked={isLiked}
                          setIsLiked={setIsLiked}
                          openBanner={openBanner}
                        />
                      );
                    })}
                  </div>
                </div>
              </>
            ) : null}
            <div className="tab-container fadeInBottom animated">
              <div>
                <div
                  className={`catalog-tab label2 ${
                    viewingAllCategories ? "" : "active"
                  }`}
                  onClick={() => viewCurrentGroup()}
                >
                  {currentGroup ? currentGroup.name : ""}
                </div>
                <div
                  className={`catalog-tab label2 ${
                    viewingAllCategories ? "active" : ""
                  }`}
                  onClick={() => viewAllCatgories()}
                >
                  All Categories
                </div>
              </div>
              <hr style={{ margin: 0 }} />
              {viewingAllCategories && (
                <div className="list-allCategories-container">
                  {groupsDetails.map((group, index) => {
                    let ulColumns = 1;
                    const checkLengthFunc = () => {
                      if (
                        group.categories.length > 15 &&
                        window.innerWidth > 800
                      ) {
                        ulColumns =
                          Math.round(group.categories.length / 15) + 1;
                      } else if (
                        group.categories.length > 15 &&
                        window.innerWidth < 800
                      ) {
                        ulColumns = 2;
                      }
                    };
                    checkLengthFunc();
                    return (
                      <div className="allCategory-list-container">
                        <h3 style={{ fontWeight: "300" }}>{group.name}</h3>
                        <ul
                          style={{
                            columnCount: ulColumns,
                            height: "fit-content",
                          }}
                        >
                          {group.categories.map((category, index) => {
                            return (
                              category.books.length > 0 && (
                                <li
                                  onClick={() => {
                                    setCurrentCategoryName(category.name);
                                    setViewingCategory(category.id);
                                    setFilterTooltip(false);
                                    setViewingAllCategories(false);
                                    document.body.scrollTop = 0; // For Safari
                                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                                  }}
                                >
                                  {category.name}
                                </li>
                              )
                            );
                          })}
                        </ul>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            {loadingContent ? (
              <div className="loading-spinner" id="catalog">
                <LoadingContentSpinner />
                <span>Loading categories</span>
              </div>
            ) : (
              !viewingAllCategories &&
              !viewingCategory &&
              data &&
              data.length > 0 &&
              data.map((category, i) => {
                if (category.books.length > 0 && category.id !== 272) {
                  return (
                    <div
                      key={i}
                      className="swiper-parent-container fadeInBottom animated"
                    >
                      <div className="swiper-header">
                        <div className="category-info-label">
                          <h3> {category.name} </h3>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span id="book-quantity">
                              Showing{" "}
                              <span style={{ fontWeight: 400 }}>
                                {category.books.length}
                              </span>{" "}
                              of{" "}
                              <span style={{ fontWeight: 400 }}>
                                {category.numberOfBooks}
                              </span>{" "}
                              {category.numberOfBooks === 1 ? "book" : "books"}
                            </span>
                            <div
                              className="view-all-button"
                              onClick={() => {
                                viewSingleCategory(category);
                              }}
                            >
                              <PlainButton text="View All" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <CustomSwiper
                        category={category}
                        likedBooks={likedBooks}
                        resetLikedBooks={resetLikedBooks}
                        isLiked={isLiked}
                        setIsLiked={setIsLiked}
                        openBanner={openBanner}
                        viewSingleCategory={viewSingleCategory}
                      />
                    </div>
                  );
                } else {
                  return "";
                }
              })
            )}
            {!viewingAllCategories &&
              viewingCategory &&
              booksToDisplay.length &&
              booksToDisplay.length > 0 && (
                <>
                  <div className="filter-and-search">
                    <div className="drop-down-container">
                      <DropDown
                        label="Sort"
                        height={String(sortOptions.length * 2 + "rem")}
                        contents={sortOptions.map((item, i) => {
                          return (
                            <li
                              key={i}
                              className={sortBy === item ? "active" : null}
                              onClick={() => {
                                setSortBy(item);
                              }}
                            >
                              {sortOptions[i]}
                            </li>
                          );
                        })}
                        setDimmerVisible={setDimmerVisible}
                      />
                      {/* <DropDown
                    label="Filter"
                    height="300px"
                    contents={<FilterList filters={filterOptions} />}
                    additionalClass="filter-list"
                    setDimmerVisible={setDimmerVisible}
                  /> */}
                      <div className="search-rule-label">
                        <span>
                          Showing all books for{" "}
                          <span className="text1">{currentCategoryName}</span>
                        </span>
                        <div
                          className="clear-filter"
                          onClick={clearFilter}
                          onMouseEnter={() => {
                            setFilterTooltip(true);
                          }}
                          onMouseLeave={() => {
                            setFilterTooltip(false);
                          }}
                        >
                          <X stroke="#fff" strokeWidth={75} />
                          <Tooltip
                            label="Clear filter"
                            visible={filterTooltip}
                            position="right"
                            backgroundColor="#fff"
                            color="#000"
                            opacity={0.5}
                          />
                        </div>
                        {/* <span>put current search / filter description here</span> */}
                      </div>
                    </div>
                  </div>
                  <hr style={{ margin: 0 }} />
                  <div className="results-container">
                    <div className="all-books-container">
                      {booksToDisplay.map((book, i) => {
                        const image = bookServices.getBookCoverSrc(
                          book.id,
                          book.bookCoverLink
                        );
                        return (
                          <BookCard
                            key={i}
                            bookId={book.id}
                            image={image}
                            title={book.title}
                            roles={book.roles}
                            tags={book.categories}
                            video={book.video}
                            likedBooks={likedBooks}
                            resetLikedBooks={resetLikedBooks}
                            isLiked={isLiked}
                            setIsLiked={setIsLiked}
                            openBanner={openBanner}
                          />
                        );
                      })}
                    </div>
                    <div
                      className={`books-container-dimmer ${
                        dimmerVisible ? "visible" : "hidden"
                      }`}
                    />
                  </div>
                </>
              )}
          </>
        )}
      </div>
      {showBottomBanner && (
        <BottomBannerModal
          onClick={goToLogin}
          isOpen={showBottomBanner}
          message={
            <>
              <p>You need an account in order to favorite a book.</p>
              <p>
                Sign up for your free trial today! Plans start at only
                $6.99/month.
              </p>
            </>
          }
          buttonText={"Get started"}
        />
      )}

      <NewsletterPopup visible={popupVisible} setVisible={setPopupVisible} />
    </div>
  );
}

export default BookCatalog;
