import React, { useEffect } from "react";
import BookService from "../../services/BookService";

import ArtistCard from "../../components/cards/ArtistCard";
import { Link } from "react-router-dom";
import SearchCard from "./SearchCard";

const Books = ({
  booksThatPassQueryCheck,
  resetLikedBooks,
  isLiked,
  artistsThatPassQueryCheck,
  likedBooks,
  openBanner,
  currentGroup,
  setCurrentGroup,
  currentCategoryName,
  setCurrentCategoryName,
  clearFilter,
  setFilterTooltip,
  searchText,
}) => {
  useEffect(() => {
    console.log("______________booksThatPassQueryCheck______________");
    console.log(booksThatPassQueryCheck);
  }, [booksThatPassQueryCheck]);

  return (
    <>
      <p className="ml-10 filterText">
        Showing all (
        {booksThatPassQueryCheck.length + artistsThatPassQueryCheck.length})
        results for{" "}
        <span className="text1">
          {currentCategoryName ? currentCategoryName : searchText}
        </span>
      </p>
      <div
        className="clear-filter"
        onClick={clearFilter}
        onMouseEnter={() => {
          setFilterTooltip(true);
        }}
        onMouseLeave={() => {
          setFilterTooltip(false);
        }}
      ></div>
      {booksThatPassQueryCheck.length > 0 && (
        <div>
          <h2 className="ml-10">Books</h2>
          <div className="searchContainer">
            {/* map through the books array and check to see if the title contains the searchQuery */}
            {booksThatPassQueryCheck.map((book, _) => {
              const image = BookService.getBookCoverSrc(
                book.id,
                book.bookCoverLink
              );

              return (
                <div key={book.id} id={book.id}>
                  <SearchCard
                    key={book.id}
                    bookId={book.id}
                    image={image}
                    title={book.title}
                    roles={book.roleDtos || book.roles}
                    tags={book.categories}
                    video={book.video}
                    likedBooks={likedBooks}
                    resetLikedBooks={resetLikedBooks}
                    isLiked={isLiked}
                    setIsLiked={!isLiked}
                    openBanner={openBanner}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}

      {artistsThatPassQueryCheck.length > 0 && (
        <div>
          <h2 className="ml-10">Artist</h2>
          <div className="artistContainer">
            {artistsThatPassQueryCheck.map((artist, i) => {
              return (
                <div key={i} className="artist-card-container">
                  <Link
                    key={i}
                    to={{ pathname: `/artistprofile/${artist.id}` }}
                  >
                    <ArtistCard index={i} location={false} artist={artist} />
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default Books;
