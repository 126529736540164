// This will be the parent component for forgot password.
//
// The flow will be:
// 1) send email
// 2) close tab
// 3) click email link
// 4) double type password
// 5) send to backend new password along with token from email
// 6) login and go to userhome

import React, { useState, useEffect } from 'react';
import FP_EnterEmail from './enterEmail/FP_EnterEmail';
import FP_LinkSent from './linkSent/FP_LinkSent';
import FP_RecreatePassword from './recreatePassword/FP_RecreatePassword';

import './ForgotPassword.css';

const ForgotPasswordParent = (props) => {
  const [email, setEmail] = useState(null);
  const [stage, setStage] = useState('ENTER_EMAIL'); //ENTER_EMAIL, LINK_SENT, RECREATE_PASSWORD
  const [token, setToken] = useState(null);

  useEffect(() => {
    // Detect if we are coming from email
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let t = params.get('token');
    setToken(t);
  }, []);

  useEffect(() => {
    if (token) {
      setStage('RECREATE_PASSWORD');
    }
  }, [token]);

  return (
    <div className="forgot-password-page">
      <div className="forgot-password-container">
        <div className="forgot-password-content">
          {stage === 'ENTER_EMAIL' && (
            <FP_EnterEmail
              setStage={setStage}
              email={email}
              setEmail={setEmail}
            />
          )}
          {stage === 'LINK_SENT' && (
            <FP_LinkSent setStage={setStage} email={email} />
          )}
          {stage === 'RECREATE_PASSWORD' && (
            <FP_RecreatePassword token={token} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordParent;
