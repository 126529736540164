import React, { useState, useEffect, useContext } from "react";
import Tooltip from "./Tooltip";
import { ReactComponent as HeartIcon } from "../../assets/heart.svg";
import { ReactComponent as HeartFillIcon } from "../../assets/heart-fill.svg";
import { Context } from "../../components/auth/state/Store";

import "./styles/cardButtons.css";
import UserService from "../../services/UserService";
import TokenService from "../../services/auth/TokenService";

function FavButton({
  openBanner,
  listPopupVisible,
  isLiked,
  setIsLiked,
  resetLikedBooks,
  bookId,
  strokeWidth,
  disableTooltip,
}) {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [isOnBookProfile, setIsOnBookProfile] = useState(false);
  const [state, dispatch] = useContext(Context);

  let user_token = "";

  TokenService.getUser() ? (user_token = TokenService.getUser().userToken) : (user_token = "");

  const updateLikes = () => {
    isLiked
      ? UserService.unlikeBooks(user_token, bookId).then((response) => {
          resetLikedBooks();
          setIsLiked(false);
        })
      : UserService.likeBooks(user_token, bookId).then((response) => {
          resetLikedBooks();
          setIsLiked(true);
        });
  };

  useEffect(() => {
    const checkBookProfile = () => {
      window.location.pathname.includes("bookprofile") ? setIsOnBookProfile(true) : setIsOnBookProfile(false);
    };
    checkBookProfile();
  }, []);

  return (
    <div
      style={{ maxWidth: "55px", position: "relative" }}
      className={!isOnBookProfile ? "card-button heart" : "bookprofile-card-btn heart"}
      onClick={() => {
        state.loggedIn ? updateLikes() : openBanner();
      }}
      onMouseEnter={() => {
        setTooltipVisible(true);
      }}
      onMouseLeave={() => {
        setTooltipVisible(false);
      }}
    >
      <div className={`heart-fill ${isLiked ? "fav" : "not-fav"}`}>
        <HeartFillIcon fill="var(--primary-color-light)" />
      </div>
      <div className="heart-stroke">
        <HeartIcon stroke={isLiked ? "var(--primary-color-light)" : "#eee"} strokeWidth={strokeWidth || 1} />
      </div>

      {!disableTooltip && (
        <Tooltip
          label={`${!isLiked ? "Add to" : "Remove from"} favorites`}
          position="above"
          visible={!listPopupVisible && tooltipVisible}
        />
      )}
    </div>
  );
}

export default FavButton;
