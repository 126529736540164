import React from "react";
import "./NotificationScreen.css";

const NotificationScreen = ({ text, buttonText, onButtonClick, onCancel }) => {
  return (
    <div className="notification-container">
      <div className="notification-text">{text}</div>
      <div className="notification-button" onClick={onButtonClick}>
        {buttonText}
      </div>
      <div className="notification-cancel" onClick={onCancel}>
        Return to Home
      </div>
    </div>
  );
};

export default NotificationScreen;
