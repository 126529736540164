import React from "react";
import ColoredButton from "../../buttons/ColoredButton";

import "./BottomBannerModal.css";

const BottomBannerModal = ({ isOpen, message, buttonText, onClick }) => {
  return (
    <div
      className={!isOpen ? "bottom-banner-modal view-bottombanner fadeInBottom" : "bottom-banner-modal fadeInBottom"}
    >
      <p className="bottom-banner__text">{message}</p>
      <div style={{ paddingLeft: "1rem" }}>
        <ColoredButton onClick={onClick} text={buttonText} />
      </div>
    </div>
  );
};

export default BottomBannerModal;
