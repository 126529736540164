import React, { useEffect, useState } from "react";

/* label: the text for the 'tab'
// height: this is passed as a prop so we can have an exact height when it's expanded
// contents: should be an array of <li> elements
// additionalClass: this is for the content container so we can further control styles
*/

function DropDown({
  label,
  height,
  contents,
  additionalClass,
  dimmerVisible,
  setDimmerVisible,
}) {
  const [open, setOpen] = useState(false);
  const [style, setStyle] = useState(); // for animating the expansion of the drop-down

  useEffect(() => {
    setStyle(
      open
        ? {
            height: height,
            visibility: "visible",
          }
        : {
            height: 0,
            visibility: "hidden",
          }
    );
  }, [open]);

  return (
    <div
      className="drop-down"
      onMouseEnter={() => {
        setOpen(true);
        setDimmerVisible(true);
      }}
      onMouseLeave={() => {
        setOpen(false);
        setDimmerVisible(false);
      }}
    >
      <div className="label">
        <p>
          <strong>{label}</strong>
        </p>
        <div className="arrow">{"▼"}</div>
      </div>
      <ul className={`contents ${additionalClass}`} style={style}>
        {contents}
      </ul>
    </div>
  );
}

export default DropDown;
