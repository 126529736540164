import { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Context } from "../../components/auth/state/Store";
import AuthService from "../../services/auth/AuthService";
import TokenService from "../../services/auth/TokenService";

export default function useLogin() {
  const [state, dispatch] = useContext(Context);
  const history = useHistory();
  const location = useLocation();
  const fromState = location.state?.from;

  const login = (data, password) => {
    TokenService.setUser(data);
    if (data.accessToken) {
      dispatch({ type: "LOG_IN" });

      if (fromState) {
        console.log("from gift center in  useLogin startwith");
        history.push(fromState);
      } else {
        history.push("/userhome");
      }
    }
    if (!data.accessToken) {
      history.push({
        pathname: "/payment",
        state: { email: data.email, password: password, from: fromState },
      });
    }
  };
  return login;
}
