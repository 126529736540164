import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../components/auth/state/Store";

import { ReactComponent as Logo } from "../../assets/GbM-logo.svg";
import { ReactComponent as LoadingSpinner } from "../../assets/spinner.svg";

import "./AdLandingPage.css";
import ColoredButton from "../../components/buttons/ColoredButton";
import UserService from "../../services/UserService";
import { Link, useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import SwiperNavButton from "../catalog/books/components/SwiperNavButton";
import { Helmet } from "react-helmet";

function AdLandingPage(props) {
  const [state, dispatch] = useContext(Context);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");
  SwiperCore.use([Autoplay, Pagination]);

  const history = useHistory();

  useEffect(() => {
    dispatch({ type: "ENTER_READER" });

    return () => {
      dispatch({ type: "EXIT_READER" });
    };
  }, []);

  const submitEmail = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setLoading(true);
      UserService.mailchimpSubscribe(email, "")
        .then((res) => {
          setLoading(false);
          setSubmitError(false);
          setSubmitMessage("Success! Welcome to the mailing list.");
        })
        .catch((res) => {
          console.log("Error adding email address to mailing list:", res);
          setLoading(false);
          setSubmitError(true);
          setSubmitMessage("Error sending your email. Please try again later.");
        });
    } else {
      setSubmitError(true);
      setSubmitMessage("Please enter a valid email.");
    }
  };

  const navToSignup = () => {
    history.push({
      pathname: "/login",
      fromReg: true,
    });
  };

  const navToCatalog = () => {
    history.push({
      pathname: "/bookcatalog",
      fromReg: true,
    });
  };

  return (
    <div className="ad-landing-page">
      <div className="ad-bg-image" />
      <Helmet>
        <script async src="https://tag.simpli.fi/sifitag/3f2dc990-3b94-013b-5c51-0cc47a8ffaac"></script>
      </Helmet>
      <div className="logo-wrapper">
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <Link to="/" style={{ width: "5rem" }}>
            <Logo fill="#fff" />
          </Link>
          <p style={{ fontSize: "1.2rem", fontWeight: 700 }}>Guitar by Masters</p>
        </div>
        <span style={{ margin: "-1rem 0 1rem 0" }}>Guitar scores and tutorials, reimagined.</span>
      </div>
      <p style={{ fontSize: "1.4rem", fontWeight: 700, marginTop: "2rem" }}>
        Sign up today and access an expanding library of guitar scores and books.
      </p>
      <p style={{ display: "block", fontWeight: 700, fontSize: "2rem", padding: 0 }}>Your first week is FREE!</p>
      <ColoredButton text="Start today" onClick={navToSignup} />
      <div className="ad-page__section" style={{ marginTop: "4rem", paddingTop: 0 }}>
        <Swiper
          className="ad-page__swiper"
          spaceBetween={50}
          loop={true}
          threshold={10}
          autoplay={{ delay: 10000, disableOnInteraction: true, pauseOnMouseEnter: true }}
          pagination={{ el: ".swiper-pagination", type: "bullets", clickable: true }}
        >
          <SwiperNavButton type="previous" />
          <SwiperSlide className="ad-page__swiper-slide">
            <h2>Take full control over your guitar lessons.</h2>
            <video playsInline={true} loop={true} autoPlay={true} muted={true}>
              <source
                src="https://dpi-assets.s3.us-east-2.amazonaws.com/GbM/landing-page/videos/iMac.mp4"
                type="video/mp4"
              />
            </video>
          </SwiperSlide>
          <SwiperSlide className="ad-page__swiper-slide">
            <h2>We have translations for most of our books, and we're adding more all the time.</h2>
            <video playsInline={true} loop={true} autoPlay={true} muted={true}>
              <source
                src="https://d9fedpdsh4r16.cloudfront.net/GbM/landing-page/videos/iPad+landscape.mp4"
                type="video/mp4"
              />
            </video>
          </SwiperSlide>
          <SwiperSlide className="ad-page__swiper-slide">
            <h2>Whatever device you have, we've probably got it covered.</h2>
            <img
              className="promo-image"
              src="https://dpi-assets.s3.us-east-2.amazonaws.com/GbM/miscellaneous/catalog+promo+image.png"
              alt="catalog-promo"
            />
          </SwiperSlide>
          <div className="swiper-pagination" />
          <SwiperNavButton type="next" />
        </Swiper>
      </div>
      <div className="ad-page__section landing-page-book-carousel-container">
        <h2>Check out some of our most popular books</h2>
        <video className="landing-page-book-carousel" playsInline={true} loop={true} autoPlay={true} muted={true}>
          <source
            src="https://dpi-assets.s3.us-east-2.amazonaws.com/GbM/ad-landing-page/book-cover-loop.mp4"
            type="video/mp4"
          />
        </video>
        <p style={{ padding: 0, marginBottom: "-1rem" }}>Want to see all of our books?</p>
        <div style={{ marginTop: "2rem", width: "100%" }}>
          <ColoredButton text="Full catalog" onClick={navToCatalog} style={{ margin: "0 auto" }} />
        </div>
      </div>
      <ArtistQuotes navToSignup={navToSignup} />
      <div className="ad-page__section landing-page-newsletter-container">
        <h2>Still not sure? Drop your email here and we'll keep you in the loop!</h2>
        <div
          style={{
            padding: "1rem",
            border: "1px solid #222c43",
            backgroundColor: "#000",
            width: "70%",
            display: "flex",
            flexDirection: "column",
            borderRadius: "0.25rem",
          }}
        >
          <div className="ad-page-email-input-container">
            <p className="ad-page-email-label">E-mail address</p>
            <div className="ad-page-email-input-wrapper">
              <input
                type="text"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") submitEmail();
                }}
                style={{ borderColor: submitError ? "red" : "gray" }}
              />
              <span className="ad-page-email-status" style={{ color: submitError ? "red" : "#fff" }}>
                {submitMessage}
              </span>
              {loading && (
                <div className="newsletter-loading-spinner">
                  <LoadingSpinner width={"1.5rem"} height={"1.5rem"} stroke={"#fff"} strokeWidth={50} />
                </div>
              )}
            </div>
          </div>
          <ColoredButton
            text="Submit"
            disabled={loading}
            onClick={submitEmail}
            style={{ marginTop: submitMessage.length > 0 ? "3rem" : "1rem" }}
          />
        </div>
      </div>
      <div className="ad-landing-page-footer">
        <p>&copy; 2022 Guitar By Masters</p>
      </div>
    </div>
  );
}

function ArtistQuotes({ navToSignup }) {
  return (
    <div className="ad-page__section">
      <h2>Our artists love GbM and Practice&nbsp;Pal, the software that drives it</h2>
      <Swiper
        className="ad-page__swiper"
        id="artist-testimonials"
        spaceBetween={50}
        loop={true}
        threshold={10}
        autoplay={{ delay: 10000, disableOnInteraction: true, pauseOnMouseEnter: true }}
        pagination={{ el: "#artist-pagination", type: "bullets", clickable: true }}
      >
        <SwiperNavButton type="previous" />
        <SwiperSlide className="ad-page__swiper-slide">
          <div className="ad-page__artist-testimonial">
            <p className="ad-page__artist-quote">
              "I love Practice&nbsp;Pal! It is a perfect tool to deliver artists' insights and tips to the aspiring
              players."
            </p>
            <hr className="ad-page__testimonial-hr" />
            <div className="ad-page__artist-image-and-name">
              <img
                className="ad-page__artist-image"
                src="https://dpi-authoring-creator-image.s3.us-west-1.amazonaws.com/22/galleryImage22"
                alt="Denis Azabagić sitting in a theater seat and holding his guitar"
              />
              <div className="ad-page__artist-name-and-profession">
                <p className="ad-page__artist-name">Denis Azabagić</p>
                <p className="ad-page__artist-profession">Classical guitarist, performer, and teacher</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="ad-page__swiper-slide">
          <div className="ad-page__artist-testimonial">
            <p className="ad-page__artist-quote">
              "The products at Guitar by Masters are going to improve your playing and allow you to get real insight
              into performance."
            </p>
            <hr className="ad-page__testimonial-hr" />
            <div className="ad-page__artist-image-and-name">
              <img
                className="ad-page__artist-image"
                src="https://dpi-authoring-creator-image.s3.us-west-1.amazonaws.com/99/galleryImage99"
                alt="William Kanengiser posing with his guitar and smiling"
              />
              <div className="ad-page__artist-name-and-profession">
                <p className="ad-page__artist-name">William Kanengiser</p>
                <p className="ad-page__artist-profession">Classical guitarist, performer, and teacher</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="ad-page__swiper-slide">
          <div className="ad-page__artist-testimonial">
            <p className="ad-page__artist-quote">
              "Practice&nbsp;Pal helps you really absorb and study pieces in depth... go measure by measure, master
              challenge by challenge!"
            </p>
            <hr className="ad-page__testimonial-hr" />
            <div className="ad-page__artist-image-and-name">
              <img
                className="ad-page__artist-image"
                src="https://dpi-authoring-creator-image.s3.us-west-1.amazonaws.com/57/galleryImage57"
                alt="View from down low, looking up at Dušan Bogdanović as he performs a song on his guitar"
              />
              <div className="ad-page__artist-name-and-profession">
                <p className="ad-page__artist-name">Dušan Bogdanović</p>
                <p className="ad-page__artist-profession">Classical guitarist and composer</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="ad-page__swiper-slide">
          <div className="ad-page__artist-testimonial">
            <p className="ad-page__artist-quote">
              "After many years of teaching, it’s really great to be able to offer you mini private lessons through
              Guitar by Masters!"
            </p>
            <hr className="ad-page__testimonial-hr" />
            <div className="ad-page__artist-image-and-name">
              <img
                className="ad-page__artist-image"
                src="https://da9nh4xe5cncw.cloudfront.net/141/galleryImage141ygsdjk"
                alt="Fred Hamilton posing with his guitar, its neck resting on his shoulder"
              />
              <div className="ad-page__artist-name-and-profession">
                <p className="ad-page__artist-name">Fred Hamilton</p>
                <p className="ad-page__artist-profession">Former director of Jazz guitar at UNT</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="ad-page__swiper-slide">
          <div className="ad-page__artist-testimonial">
            <p className="ad-page__artist-quote">
              “I think Guitar by Masters is the best classical guitar learning platform available on the web today."
            </p>
            <hr className="ad-page__testimonial-hr" />
            <div className="ad-page__artist-image-and-name">
              <img
                className="ad-page__artist-image"
                src="https://da9nh4xe5cncw.cloudfront.net/123/galleryImage123smbyzh"
                alt="Lucio Matarazzo playing against a black backdrop and concentrating on his left hand"
              />
              <div className="ad-page__artist-name-and-profession">
                <p className="ad-page__artist-name">Lucio Matarazzo</p>
                <p className="ad-page__artist-profession">Classical guitarist and educator</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="ad-page__swiper-slide">
          <div className="ad-page__artist-testimonial">
            <p className="ad-page__artist-quote">
              “This platform makes new compositions not only more easily accessible but also significantly easier to
              learn."
            </p>
            <hr className="ad-page__testimonial-hr" />
            <div className="ad-page__artist-image-and-name">
              <img
                className="ad-page__artist-image"
                src="https://dpi-authoring-creator-image.s3.us-west-1.amazonaws.com/2/galleryImage2"
                alt="Side view of Stephanie Jones in front of a red backdrop, holding her guitar and looking slightly upward"
              />
              <div className="ad-page__artist-name-and-profession">
                <p className="ad-page__artist-name">Stephanie Jones</p>
                <p className="ad-page__artist-profession">Guitar soloist and chamber musician</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <div className="swiper-pagination" id="artist-pagination" />
        <SwiperNavButton type="next" />
      </Swiper>
      <p style={{ margin: 0, marginBottom: "-2rem" }}>
        Sign up today and see what your routine has been missing all along.
      </p>
      <div style={{ marginTop: "2rem", width: "100%" }}>
        <ColoredButton text="Start today" onClick={navToSignup} style={{ margin: "0 auto" }} />
      </div>
    </div>
  );
}

export default AdLandingPage;
