import axios from "axios";
import { DOMAIN_ID } from "../../config";
import http from "../../http-common";

class OAuth2Service {
  loginWithGoogleAccessToken(access_token) {
    // console.log("oAU1");
    return http.get(`/api/auth/oauth2/google/verify?token=${access_token}&domainId=${DOMAIN_ID}`);
  }

  loginWithFacebookAccessToken(token) {
    // console.log("oAU2");
    return http.get(`/api/auth/oauth2/facebook/verify?token=${token}&domainId=${DOMAIN_ID}`);
  }
}

export default new OAuth2Service();
