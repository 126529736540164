// App.js

import React from "react";
import "./GiftCenterComponent.scss";
import BookService from "../../services/BookService";
import PricingTable from "./PricingTable";
import { useEffect, useState, useLayoutEffect } from "react";
import gbmimage from "../../assets/gbm.png";
import gbmRedPresent from "../../assets/gbmPresent.png";
import gbmOrangePresent from "../../assets/gbmPrimaryPresent.png";
import gbmGiftCardDark from "../../assets/darkCard.png";
import gbmGiftCardBlue from "../../assets/blueCard.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Accordion from "./FAQAccordion";
import GiftCodeRedeem from "./GiftCodeRedeem";
import TokenService from "../../services/auth/TokenService";

gsap.registerPlugin(ScrollTrigger);
const GiftCenterComponent = () => {
    const textRef = React.useRef();

    const root = React.useRef();

    const [recentlyAddedBooks, setRecentlyAddedBooks] = useState([]);

    useEffect(() => {

        BookService.getRecentlyAddedBooksTop15().then((response) => {
            console.log(response.data);
            const formattedBookData = response.data.map((book) => {
                const imageLink = `https://d24hdgytax3x03.cloudfront.net/${book.id}/${book.bookCoverLink}`;

                return { ...book, bookCoverLink: imageLink };
            });

            const bookGroups = [];
            const groupSize = 3;

            for (let i = 0; i < formattedBookData.length; i += groupSize) {
                const bookGroup = formattedBookData.slice(i, i + groupSize);
                bookGroups.push(bookGroup);
            }

            console.log(
                "🚀 ~ file: GiftCenter omponent.jsx:74 ~  BookService.getRecentlyAddedBooks ~ bookGroups:",
                bookGroups
            );
            setRecentlyAddedBooks(bookGroups);
        });
    }, []);
    useEffect(() => {

        const columns = gsap.utils.toArray(".column-gift");
        // Scroll animations
        const ctx = gsap.context(() => {

            columns.forEach((column, index) => {
                const direction = index % 2 === 0 ? -150 : 150;
                gsap.to(column, {
                    y: direction,
                    scrollTrigger: {
                        trigger: column,
                        start: "top bottom",
                        end: "bottom top",
                        scrub: 1,
                        // stagger: 0.5,
                    },
                });
            });
            gsap.fromTo(
                textRef.current,
                { scale: 0, opacity: 0, y: 30 }, // Starting properties
                { scale: 1, opacity: 1, y: 0, duration: 1, ease: "power2.out" }
            );


        },);
        gsap.from('.gift-center-container__gift-membership', {
            y: 30,

            scrollTrigger: {
                trigger: '.gift-center-container__gift-membership',
                start: 'top bottom',
                end: 'bottom top',
                toggleActions: 'play none none reverse',
            },
            duration: 1.5,
            ease: 'power3.out'
        });
        return () => ctx.revert();
    }, [recentlyAddedBooks]);


    return (
        <div style={{ minHeight: "100vh" }} ref={root}>
            {recentlyAddedBooks && recentlyAddedBooks.length > 0 && (<div className="gift-center-wrapper" >
                <section className="gift-center-container ">
                    <div className="gift-center-container__header-wrapper gradient-wrapper"></div>
                    <div
                        className="gift-center-container__header-wrapper--left "
                        ref={textRef}
                    >

                        <h1 className="title-shadow ">Holiday Gifting, Tuned to Perfection!  </h1>
                        <p>
                            {/* Delight the guitar lover in your life with a GBM gift subscription. */}
                            Unlock a world of guitar mastery, from exclusive tutorials to
                            expert-led courses. Perfect for every strumming style and skill
                            level. Visit Guitar by Masters for the ideal musical gift!
                        </p>
                        {TokenService.getUser() && (<GiftCodeRedeem />)}
                    </div>
                    <div className="gift-center-container__header-wrapper--right">

                        <GridSection recentlyAddedBooks={recentlyAddedBooks} />
                    </div>
                    <section className="gift-center-container__gift-membership">
                        <h1>Gift a Subscription</h1>
                        <p>
                            Gift the joy of unlimited access! With our subscription gift, recipients can enjoy a wide range of exclusive benefits.
                        </p>
                        <div className="membership-options">
                            <PricingTable />
                        </div>
                    </section>


                </section>
            </div>)}
        </div>
    );
};

export default GiftCenterComponent;


// Grid section component
const GridSection = ({ recentlyAddedBooks }) => {


    return (

        <div className="grid-section"  >
            {recentlyAddedBooks && (
                <>
                    <div className="column-gift grid-section__column-1">
                        {recentlyAddedBooks[0]?.map((book, index) => {

                            return (
                                <>
                                    <div
                                        key={book.id}
                                        className="grid-item margin-bottom-gift margin-bottom-gift"
                                    >
                                        <img
                                            src={book.bookCoverLink}
                                            className="grid-image book-card-image-container"
                                            alt={`Book titled ${book.title}`}
                                            loading="eager"
                                        />
                                    </div>
                                    <div
                                        key={`filler-${index}`}
                                        className="grid-filler-image logo-gift-block margin-bottom-gift"
                                        style={{ backgroundImage: `url(${gbmOrangePresent})` }}
                                    ></div>
                                </>
                            );
                        })}
                    </div>
                    <div className="column-gift grid-section__column-2">
                        {recentlyAddedBooks[1]?.map((book, index) => {

                            return (
                                <>
                                    <div
                                        key={`filler-${index}`}
                                        className="grid-filler-image logo-gift-block margin-bottom-gift"
                                        style={{ backgroundImage: `url(${gbmGiftCardDark})` }}
                                    ></div>{" "}
                                    <div
                                        key={`gift-${index}`}
                                        className="logo-gift-block margin-bottom-gift"
                                        style={{
                                            backgroundImage: `url(${gbmimage})`,
                                            backgroundColor: `${index % 2 === 0 ? "red" : "green"}`,
                                        }}
                                    ></div>{" "}
                                    <div key={book.id} className="grid-item margin-bottom-gift">
                                        <img
                                            src={book.bookCoverLink}
                                            className="grid-image"
                                            alt={`Book titled ${book.title}`}
                                        />
                                    </div>
                                </>
                            );
                        })}
                    </div>
                    <div className=" column-gift grid-section__column-3">
                        {" "}
                        {recentlyAddedBooks[2]?.map((book, index) => {

                            return (
                                <>
                                    <div key={book.id} className="grid-item margin-bottom-gift">
                                        <img
                                            src={book.bookCoverLink}
                                            className="grid-image book-card-image-container"
                                            alt={`Book titled ${book.title}`}
                                        />
                                    </div>
                                    <div
                                        key={`filler-${index}`}
                                        className="grid-filler-image logo-gift-block margin-bottom-gift"
                                        style={{ backgroundImage: `url(${gbmRedPresent})` }}
                                    ></div>
                                </>
                            );
                        })}
                    </div>{" "}
                    <div className="column-gift grid-section__column-4">
                        {recentlyAddedBooks[3]?.map((book, index) => {
                            if (index === recentlyAddedBooks[3].length - 1) { return null }
                            return (
                                <>
                                    <div
                                        key={`filler-${index}`}
                                        className="grid-filler-image logo-gift-block margin-bottom-gift"
                                        style={{ backgroundImage: `url(${gbmGiftCardBlue})` }}
                                    ></div>
                                    <div
                                        key={`gift-${index}`}
                                        className="logo-gift-block margin-bottom-gift"
                                        style={{
                                            backgroundImage: `url(${gbmimage})`,
                                            backgroundColor: `${index % 2 === 0 ? "red" : "green"}`,
                                        }}
                                    >

                                    </div>
                                    <div key={book.id} className="grid-item margin-bottom-gift">
                                        <img
                                            src={book.bookCoverLink}
                                            className="grid-image margin-bottom-gift"
                                            alt={`Book titled ${book.title}`}
                                        />
                                    </div>
                                </>
                            );
                        })}{" "}
                    </div>
                </>
            )}
            {/* {recentlyAddedBooks && (recentlyAddedBooks.map((book) => {
                return <div key={book.id} className="grid-item">
                    <img src={book.bookCoverLink} className='test-image' alt="" />
                </div>
            }))} */}
        </div>

    );
};
