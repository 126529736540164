import React from "react";
import { useEffect } from "react";
import ColoredButton from "../../../components/buttons/ColoredButton";
import OutlinedButton from "../../../components/buttons/OutlinedButton";
import { BsArrowRight } from "react-icons/bs";
import { VscArrowRight } from "react-icons/vsc";
const UpgradeSubscriptionModal = () => {
  useEffect(() => {
    console.group("hello");
  }, []);
  return (
    <div className="trans-history-modal">
      <h2 className="large-modal-heading">Upgrade Your Subscription</h2>
      <div className="large-modal__prompt text3">
        Upgrade to a yearly subscription for $59.99 charged annually.
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          whiteSpace: "nowrap",
        }}
      >
        <h3 style={{ fontSize: "3rem", padding: "2rem" }}>
          6.99 / <span style={{ fontSize: "1.4rem" }}>month</span>
        </h3>{" "}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <VscArrowRight size={20} />
        </div>
        <h3 style={{ fontSize: "3rem", padding: "2rem" }}>
          59.99 / <span style={{ fontSize: "1.4rem" }}>year</span>
        </h3>
      </div>
      <div
        style={{
          display: "flex",
          width: "50%",
          justifyContent: "space-around",
          minWidth: "15rem",
        }}
      >
        <OutlinedButton text="Cancel" />
        <ColoredButton text="Confirm" />
      </div>
    </div>
  );
};

export default UpgradeSubscriptionModal;
