function timeConverter(UNIX_timestamp, excludeYear) {
  var a = new Date(UNIX_timestamp);
  // console.log(a);
  var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getUTCDate();

  var time = excludeYear ? date + " " + month : date + " " + month + " " + year;
  return time;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function centsToString(cents) {
  return (Math.round(cents) / 100).toFixed(2);
}

function daysUntil(start, end) {
  if (end.toString().length === 10) end *= 1000;
  return Math.round((end - start) / 86400000); // last number is milliseconds in a day
}

export { timeConverter, capitalizeFirstLetter, centsToString, daysUntil };
