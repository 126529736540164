import React, { useState } from "react";
import UnderlinedTextInput from "../../../components/auth/underlinedText/UnderlinedTextInput";
import ConfirmationModal from "../../../components/loading/ConfirmationModal";
import { useHistory } from "react-router-dom";
import AuthService from "../../../services/auth/AuthService";

import "./ForgotPassword.css";
import ColoredButton from "../../../components/buttons/ColoredButton";

const FP_RecreatePassword = ({ token }) => {
  // password stuff
  const [password, setPassword] = useState("");
  const [secondPassword, setSecondPassword] = useState("");

  // confirmation modal stuff
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [success, setSuccess] = useState(false);

  // navigation
  const history = useHistory();

  const submit = () => {
    setConfirmationModalOpen(true);
    setLoading(true);
    if (checkPassword()) {
      // setPasswordInvalid(true);
      setMessage(checkPassword());
      setLoading(false);
      return;
    }
    AuthService.sendResetPasswordRequest(password, token)
      .then((response) => {
        // console.log("reset password: ", response.data);
        setSuccess(true);
        setLoading(false);
        setMessage(`Success! Your password has been reset. Login to enter Guitar by Masters.`);
        // setStage("LINK_SENT");
      })
      .catch((e) => {
        console.log("error sending password reset email: ", e);
        setTitle("Uh oh!");
        setMessage(
          "There was an error resetting your password.  Try again later or contact support at support@guitarbymasters.com."
        );
        setLoading(false);
        setSuccess(false);
      });
    // console.log("submit");
  };

  const checkPassword = () => {
    if (!(password && password.length > 7)) {
      return "Password must be above 8 characters.";
    } else if (password !== secondPassword) {
      return "Passwords must match.";
    } else {
      return false;
    }
  };

  const closeLoadingModal = () => {
    setConfirmationModalOpen(false);
  };

  const goToLogin = () => {
    history.push("/login");
  };

  return (
    <>
      <div className="recreate-password__content">
        <div className="recreate-password__text">
          <h1 className="recreate-password__main-text">Reset Your Password</h1>
          <div className="recreate-password__sub-text">
            Type a new password with at least 8 characters to reset your password.
          </div>
        </div>
        <div className="recreate-password__fields">
          <div className="recreate-password__field">
            <UnderlinedTextInput
              label={"New password"}
              value={password}
              type={"password"}
              func={submit}
              onValueChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="recreate-password__field">
            <UnderlinedTextInput
              label={"Repeat new password"}
              value={secondPassword}
              type={"password"}
              func={submit}
              onValueChange={(e) => setSecondPassword(e.target.value)}
            />
          </div>
        </div>
        <div className="recreate-password__button">
          <ColoredButton onClick={submit} text={"Reset Password"} />
        </div>
      </div>
      {confirmationModalOpen && (
        <ConfirmationModal
          isLoading={loading}
          title={title}
          message={message}
          onClose={success ? goToLogin : closeLoadingModal}
          buttonMessage={success ? "Go to login" : "Close"}
        />
      )}
    </>
  );
};

export default FP_RecreatePassword;
