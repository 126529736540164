import React from "react";

function TeamCard({ id, name, title, image }) {
  return (
    <div className="team-card" id={id}>
      <div className="image-container">
        <img src={image} alt={`${name}_image`}></img>
      </div>
      <span className="team-card-name">{name}</span>
      {title && <span>{title}</span>}
    </div>
  );
}

export default TeamCard;
