import React, { useState, useRef, useCallback } from "react";
import FavButton from "../micro_components/FavButton";
import { Link } from "react-router-dom";
import { AiFillSound } from "react-icons/ai";
import { GiClapperboard } from "react-icons/gi";
import ImageLoader from "../image/ImageLoader";
import { useHistory } from "react-router";

const BookCardFull = ({
  bookId,
  image,
  title,
  roles,
  tags,
  video,
  likedBooks,
  resetLikedBooks,
  openBanner,
}) => {
  const [fav, setFav] = useState(false);
  const [fadeInOpacity, setFadeInOpacity] = useState(0);
  const [fadeOutOpacity, setFadeOutOpacity] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const history = useHistory();
  const scrollRef = useRef();

  let oldX;

  const handleMouseUp = useCallback(() => {
    window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (scrollRef.current && scrollRef.current.scrollLeft) {
      scrollRef.current.scrollLeft -= e.pageX - oldX;
    }
    oldX = e.pageX;
  }, []);

  const handleMouseDown = useCallback((e) => {
    oldX = e.pageX;
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp, { once: true });
  }, []);

  const handleScroll = () => {
    const leftDistance = scrollRef.current.scrollLeft;
    const rightDistance =
      scrollRef.current.scrollWidth -
      scrollRef.current.clientWidth -
      scrollRef.current.scrollLeft;

    if (leftDistance <= 20) {
      setFadeInOpacity(leftDistance / 20);
    } else {
      setFadeInOpacity(1);
    }

    if (rightDistance > 20) {
      setFadeOutOpacity(1);
    } else if (rightDistance > 0) {
      setFadeOutOpacity(rightDistance / 20);
    } else {
      setFadeOutOpacity(0);
    }
  };

  return (
    <>
      <Link to={`/bookprofile/${bookId}`} className="book-card-image-container">
        <ImageLoader
          src={image}
          style={{ width: "136px", height: "181px" }}
          alt={`Guitar by Masters classical guitar book ${title}`}
        />
      </Link>
      <Link to={`/bookprofile/${bookId}`} className="no-underline">
        <div className="text1 title">{title}</div>
      </Link>
      <p className="text2 roles">
        {roles.map((role, i) => (
          <Link key={role.creator_id} to={`/artistprofile/${role.creator_id}`}>
            {role.creator_name + (i < roles.length - 1 ? ", " : "")}
          </Link>
        ))}
      </p>
      <hr
        style={{
          margin: "0.25rem 0 0 0",
          border: "none",
          borderTop: "1px solid var(--middle-gray)",
        }}
      />
      <div className="tags-fade-container swiper-no-swiping">
        <div
          ref={scrollRef}
          className="tags-container"
          onScroll={handleScroll}
          onMouseEnter={() => {
            window.addEventListener("mousedown", handleMouseDown);
          }}
          onMouseLeave={() => {
            window.removeEventListener("mousedown", handleMouseDown);
          }}
        >
          {tags &&
            tags.map((tag, i) => (
              <div
                key={i}
                className="text3 book-tag"
                onClick={() => {
                  history.push("/bookcatalog", {
                    category: tag.name,
                    categoryId: tag.id,
                  });
                }}
              >
                {tag.name}
              </div>
            ))}
        </div>
        <div className="fade-container">
          <div className="fade-in" style={{ opacity: fadeInOpacity }} />
          <div className="fade-out" style={{ opacity: fadeOutOpacity }} />
        </div>
      </div>
      <div className="card-button-container">
        <div className="book-card-footer" style={{ userSelect: "none" }}>
          {video ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <GiClapperboard fill="#71a5f5" size={20} />
              <span
                className="text3"
                style={{
                  marginLeft: "0.25rem",
                  color: "#71a5f5",
                  textTransform: "uppercase",
                  fontSize: "0.9rem",
                  fontWeight: 700,
                }}
              >
                Video
              </span>
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <AiFillSound fill="#f56056" size={20} />
              <span
                className="text3"
                style={{
                  marginLeft: "0.25rem",
                  color: "#f56056",
                  textTransform: "uppercase",
                  fontSize: "0.9rem",
                  fontWeight: 700,
                }}
              >
                Audio
              </span>
            </div>
          )}
          <div className="list-buttons">
            <FavButton
              fav={fav}
              setFav={setFav}
              isLiked={isLiked}
              setIsLiked={setIsLiked}
              resetLikedBooks={resetLikedBooks}
              likedBooks={likedBooks}
              bookId={bookId}
              openBanner={openBanner}
              strokeWidth={1.5}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BookCardFull;
