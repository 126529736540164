import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import OAuth2Service from "../../../../services/auth/OAuth2Service";
import useLogin from "../../../../helpers/customHooks/useLogin";
import OAuthCallbackModal from "../callback/OAuthCallbackModal";
import TokenService from "../../../../services/auth/TokenService";

// import AuthService from "../../../services/auth/AuthService";

// http://localhost:3000/#state=state_parameter_passthrough_value
// &access_token=ya29.A0ARrdaM9N4ZiX5a7YxsZce6ZKg3xDnn4asPscMoT9psn1sXo0zBfHb34J4gZmosoKCsMGuP_rOdpfQ-KR7rDauPnD6psypz0mNFvTl3GaMkMsq7KyMiz3yviBCK6bW5q67QnYYXDE_2aSfd7wRub-EGRDKWnx
// &token_type=Bearer&expires_in=3599
// &scope=email%20https://www.googleapis.com/auth/userinfo.email%20openid&authuser=1&prompt=consent

// Component to come back to when google has logged in
const GoogleCallback = () => {
  const [successState, setSuccessState] = useState(""); // will be 'error' or 'success'

  const [error, setError] = useState(false);

  //need to get the params from the URL
  let { hash } = useLocation();

  const loginFunction = useLogin();

  useEffect(() => {
    if (hash) {
      // const search = hash.search;
      let copy = hash.replace("#", "&");
      const params = new URLSearchParams(copy);
      const access_token = params.get("access_token");
      const intent = params.get("state");
      console.log(intent);

      TokenService.setOAuthMethod("google");
      TokenService.setOAuthRenewalToken(access_token);

      OAuth2Service.loginWithGoogleAccessToken(access_token)
        .then((r) => {
          // console.log(r.data);
          loginFunction(r.data, "");
        })
        .catch((e) => {
          console.log("error logging in through google");
          setError(true);
        });
    }
  }, [hash]);

  return <OAuthCallbackModal error={error} />;
};

export default GoogleCallback;
