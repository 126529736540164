import React, { useEffect, useContext, useCallback } from "react";
import { Context } from "../../components/auth/state/Store";

const ChapterReader = () => {
  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    dispatch({ type: "ENTER_READER" });
    // get the title of the tab / window so that when we navigate away we can restore it
    const oldTitle = document.title;
    setAppHeight();

    // restore the old title
    return () => {
      document.title = oldTitle;
      dispatch({ type: "EXIT_READER" });
    };
  }, []);

  // fix for mobile browsers whose browser menu bars cause issues with page height
  const setAppHeight = useCallback(() => {
    const doc = document.documentElement;
    doc.style.setProperty("--app-height", `${window.innerHeight - 64}px`);
  }, []);

  return (
    <div className="header-and-reader" style={{ userSelect: "none" }}>
      <iframe
        title="reader"
        allow="fullscreen"
        allowFullScreen
        src="https://reader-v2.netlify.app/?mode=testing&href=https://dpi-authoring-image.s3.us-west-1.amazonaws.com/577/bookData&book=577"

        /* FOR TESTING WITH LOCALHOST */
        // src={`http://10.0.0.18:3002/?mode=gbm&href=https://dpi-authoring-image.s3.us-west-1.amazonaws.com/${bookId}/bookData&book=${bookId}&token=${btoa(
        //   JSON.stringify(AuthService.getCurrentUser())
        // )}`}
      />
    </div>
  );
};

export default ChapterReader;
