import TokenService from "../auth/TokenService";
import http from "../../http-common";

const createSubscription = (orderId, subscriptionId) => {
  const token = TokenService.getUser().userToken;
  const data = {
    orderId: orderId,
    subscriptionId: subscriptionId,
    userToken: token,
  };
  return http.post(`/payment/paypal/submit`, data);
};

const PaypalService = {
  createSubscription,
};

export default PaypalService;
