import React, { useState, useContext } from "react";
import { useEffect } from "react";
import Login from "../login/Login";
import SignUp from "../signup/SignUp";
import { Context } from "../../../components/auth/state/Store";
import { ReactComponent as Logo } from "../../../assets/GbM-logo.svg";

import "./LoginAndSignUp.css";
import "./Toggle.css";

// TODO: implement Nick's upcoming backend call for getting the prices

const LoginAndSignUp = ({ setEmailSent, setRegisterEmail, registerMode, setRegisterMode, setLoading, fromGiftCodePath }) => {
  const [displayToggle, setDisplayToggle] = useState(true);
  const [state, dispatch] = useContext(Context);

  //cant be on this page if signed in
  useEffect(() => {
    dispatch({ type: "LOG_OUT" });
  }, []);

  return (
    <>
      {/* DESKTOP VIEW */}
      <div className="desktop-screen-override screen-l">
        <div className={`master-login-signup-container ${registerMode ? "shifted" : ""}`}>
          <div className={`signup-tier-information screen-l ${registerMode ? "visible" : ""}`}>
            <div className="logo" id="signup">
              <Logo fill="#fff" />
            </div>
            <hr />
            <div className="signup-tier-details" id="monthly">
              <div>
                <span className="signup-tier-type label2">Monthly</span>
                <span className="trial-addition">7-day free trial included!</span>
                <div>
                  <span className="signup-card-price">$6.99</span>
                  <span style={{ marginLeft: "0.25rem" }}>/ month</span>
                </div>
              </div>
            </div>
            <div className="signup-tier-details" id="annual">
              <div>
                <span className="signup-tier-type label2">Annual</span>
                <span className="notrial-addition">No free trial for annual</span>
                <div>
                  <span className="signup-card-price">$59.99</span>
                  <span style={{ marginLeft: "0.25rem" }}>/ year</span>
                </div>
                <span
                  style={{
                    marginLeft: "0.25rem",
                    fontSize: "0.9rem",
                    color: "var(--middle-gray)",
                  }}
                >
                  Billed annually (averages $4.99 / month)
                </span>
              </div>
            </div>
            <div className="signup-tier-list">
              <ul>
                <li>
                  <span className="text3">Learn from the world's top guitarists</span>
                </li>
                <li>
                  <span className="text3">Build and expand your guitar repertoire</span>
                </li>
                <li>
                  <span className="text3">Explore different guitar styles from around the world</span>
                </li>
                <li>
                  <span className="text3">Access an expanding library of contents</span>
                </li>
                <li>
                  <span className="text3">Enhance your practice!</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="login-sign-up-container">
            <div className="login-signup-toggle-container">
              <div className="login-signup-toggle">
                <div
                  className={`login-signup-toggle-button ${registerMode ? "" : "active"}`}
                  id="login"
                  onClick={() => {
                    setRegisterMode(false);
                  }}
                >
                  <span className="login-signup-toggle-button-text label2">Login</span>
                </div>
                <div
                  className={`login-signup-toggle-button ${registerMode ? "active" : ""}`}
                  id="signup"
                  onClick={() => {
                    setRegisterMode(true);
                  }}
                >
                  <span className="login-signup-toggle-button-text label2">Sign up</span>
                </div>
              </div>
            </div>
            <div
              className={`login-sign-up-inner-container ${registerMode ? "signup" : "login"}`}
              style={{ left: registerMode ? "-500px" : "0px" }}
            >
              <div className="login-signup-modal-inner-contents" id="login">
                <div className="logo-container">
                  <div className="logo" id="login">
                    <Logo fill="#fff" />
                  </div>
                  <hr />
                </div>
                <Login setDisplayToggle={setDisplayToggle} setLoading={setLoading} />
              </div>
              <div className="login-signup-modal-inner-contents" id="signup">
                <div>
                  <h3
                    style={{
                      textAlign: "center",
                      marginTop: registerMode ? "1rem" : null,
                      marginBottom: "3rem",
                    }}
                  >
                    Sign up for a GbM account
                  </h3>
                </div>
                <SignUp
                  fromGiftCodePath={fromGiftCodePath}
                  setRegisterEmail={setRegisterEmail}
                  setEmailSent={setEmailSent}
                  setDisplayToggle={setDisplayToggle}
                  setRegisterMode={setRegisterMode}
                  setLoading={setLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* TABLET AND MOBILE VIEW */}
      <div
        className="mobile-screen-override screen-m screen-s"
        style={{ width: "100%", height: registerMode ? "100%" : null }}
      >
        <div
          style={{
            width: "100%",
            height: registerMode ? "100%" : null,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className={`master-login-signup-container ${registerMode ? "shifted" : ""}`}>
            <div className={`login-sign-up-container ${registerMode ? "signup" : ""}`}>
              <div className="login-signup-toggle-container">
                <div className="login-signup-toggle">
                  <div
                    className={`login-signup-toggle-button ${registerMode ? "" : "active"}`}
                    id="login"
                    onClick={() => {
                      setRegisterMode(false);
                    }}
                  >
                    <span className="login-signup-toggle-button-text label2">Login</span>
                  </div>
                  <div
                    className={`login-signup-toggle-button ${registerMode ? "active" : ""}`}
                    id="signup"
                    onClick={() => {
                      setRegisterMode(true);
                    }}
                  >
                    <span className="login-signup-toggle-button-text label2">Sign up</span>
                  </div>
                </div>
              </div>
              <div className={`login-sign-up-inner-container ${registerMode ? "signup" : "login"}`}>
                {registerMode ? (
                  <div className="login-signup-modal-inner-contents" id="signup">
                    <div>
                      <h3
                        style={{
                          textAlign: "center",
                          marginTop: registerMode ? "1rem" : null,
                        }}
                      >
                        Sign up for a GbM account
                      </h3>
                    </div>
                    <div className={`signup-tier-information ${registerMode ? "visible" : ""}`}>
                      <hr />
                      <div style={{ display: "flex" }}>
                        <div className="signup-tier-details" id="monthly">
                          <div>
                            <span className="signup-tier-type label2">Monthly</span>
                            <span className="trial-addition">7-day free trial included!</span>
                            <div>
                              <span className="signup-card-price">$6.99</span>
                              <span style={{ marginLeft: "0.25rem" }}>/ month</span>
                            </div>
                          </div>
                        </div>
                        <div className="signup-tier-details" id="annual">
                          <div>
                            <span className="signup-tier-type label2">Annual</span>
                            <span className="notrial-addition">No free trial for annual</span>
                            <div>
                              <span className="signup-card-price">$59.99</span>
                              <span style={{ marginLeft: "0.25rem" }}>/ year</span>
                            </div>
                            <span
                              style={{
                                marginLeft: "0.25rem",
                                fontSize: "0.9rem",
                                color: "var(--middle-gray)",
                              }}
                            >
                              Billed annually (averages $4.99 / month)
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="signup-tier-list">
                        <ul>
                          <li>
                            <span className="text3">Learn from the world's top guitarists</span>
                          </li>
                          <li>
                            <span className="text3">Build and expand your guitar repertoire</span>
                          </li>
                          <li>
                            <span className="text3">Explore different guitar styles from around the world</span>
                          </li>
                          <li>
                            <span className="text3">Access an expanding library of contents</span>
                          </li>
                          <li>
                            <span className="text3">Enhance your practice!</span>
                          </li>
                        </ul>
                      </div>
                      <hr />
                    </div>
                    <SignUp
                      fromGiftCodePath={fromGiftCodePath}
                      setRegisterEmail={setRegisterEmail}
                      setEmailSent={setEmailSent}
                      setDisplayToggle={setDisplayToggle}
                      setRegisterMode={setRegisterMode}
                      setLoading={setLoading}
                    />
                  </div>
                ) : (
                  <div className="login-signup-modal-inner-contents" id="login">
                    <div className="logo-container">
                      <div className="logo" id="login">
                        <Logo fill="#fff" />
                      </div>
                      <hr />
                    </div>
                    <Login setDisplayToggle={setDisplayToggle} setLoading={setLoading} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginAndSignUp;
