import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div style={pageNotFound}>
      <h1 style={{ margin: "15px" }}>
        <span style={{ color: "var(--primary-color-light)" }}>
          404
          <span />
        </span>{" "}
        Error
      </h1>
      <h2>Sorry, the page you requested was not found.</h2>
      <h3>
        there's nothing to see here{" "}
        <Link style={{ color: "var(--primary-color-light)" }} to="">
          {" "}
          GO BACK
        </Link>
      </h3>
    </div>
  );
};

export default PageNotFound;

const pageNotFound = {
  minHeight: "70vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  padding: "20px",
};
