import React from "react";
import "./search.css";

const Categories = ({
  groupsDetails,
  setCurrentCategoryName,
  setViewingCategory,
  setViewingAllCategories,
  setFilterTooltip,
  setCurrentGroupId,
  searchText,
  currentCategoryName,
}) => {
  const handleGroupClicked = (groupId) => {
    clearFilter();
    setCurrentGroupId(groupId);
    setViewingAllCategories(false);
  };

  const clearFilter = () => {
    setViewingCategory(null);
  };

  return (
    <div className="list-allCategories-container">
      {groupsDetails.map((group, index) => {
        let ulColumns = 1;
        const checkLengthFunc = () => {
          if (group.categories.length > 15 && window.innerWidth > 800) {
            ulColumns = Math.round(group.categories.length / 15) + 1;
          } else if (group.categories.length > 15 && window.innerWidth < 800) {
            ulColumns = 2;
          }
        };
        checkLengthFunc();
        return (
          <div className="allCategory-list-container">
            <h3 style={{ fontWeight: "300" }}>{group.name}</h3>
            <ul style={{ columnCount: ulColumns, height: "fit-content" }}>
              {group.categories.map((category, _) => {
                return (
                  category.books.length > 0 && (
                    <li
                      className="categoryListItem"
                      onClick={() => {
                        setCurrentCategoryName(category.name);
                        setViewingCategory(category.id);
                        setFilterTooltip(false);
                        setViewingAllCategories(false);
                        // setSearchText('');
                        document.body.scrollTop = 0; // For Safari
                        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                      }}
                    >
                      {category.name}
                    </li>
                  )
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default Categories;
