import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ArtistCard from '../../../components/cards/ArtistCard';
import LoadingContentSpinner from '../../../components/loading/LoadingContentSpinner';

import './artistcatalog.css';

function ArtistCardContainer({
  filteredArtists,
  searching,
  viewAll,
  artistData,
}) {
  const [artistsToDisplay, setArtistsToDisplay] = useState([]);

  useEffect(() => {
    setArtistsToDisplay(null);
    setTimeout(() => {
      setArtistsToDisplay(filteredArtists);
    }, 10);
  }, [filteredArtists]);

  return (
    <div className="artist-filter-results">
      {searching && (
        <div>
          <LoadingContentSpinner loading={searching} />
          <h3>Loading Artists</h3>
        </div>
      )}
      {!viewAll
        ? artistsToDisplay &&
          artistsToDisplay.map((artist, i) => {
            return (
              <div key={i} className="artist-card-container">
                <Link key={i} to={{ pathname: `/artistprofile/${artist.id}` }}>
                  <ArtistCard index={i} location={false} artist={artist} />
                </Link>
              </div>
            );
          })
        : artistData.map((artist, b) => {
            return (
              <div key={b} className="artist-card-container">
                <Link key={b} to={{ pathname: `/artistprofile/${artist.id}` }}>
                  <ArtistCard index={b} location={false} artist={artist} />
                </Link>
              </div>
            );
          })}
      {!searching && filteredArtists.length === 0 && (
        <h1>There doesn't seem to be anything here...</h1>
      )}
    </div>
  );
}

export default ArtistCardContainer;
