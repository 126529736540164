import "./search.css";

import { useEffect, useState, useLayoutEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Books from "./Books";
import bookCatalogHeader from "../../assets/bookCatalogHeader.png";
import BookService from "../../services/BookService";
import UserService from "../../services/UserService";
import ArtistService from "../../services/ArtistService";
import TokenService from "../../services/auth/TokenService";
import LoadingContentSpinner from "../../components/loading/LoadingContentSpinner";
import ColoredButton from "../../components/buttons/ColoredButton";
import { Link } from "react-router-dom";
import Categories from "./Categories";

const Search = ({ currentCategoryName, setCurrentCategoryName }) => {
  const [loadingContent, setLoadingContent] = useState(true);
  const [booksThatPassQueryCheck, setBooksThatPassQueryCheck] = useState([]);
  const [artistsThatPassQueryCheck, setArtistsThatPassQueryCheck] = useState(
    []
  );
  const [currentGroupId, setCurrentGroupId] = useState(0);
  const [beginnerBooks, setBeginnerBooks] = useState([]);
  const history = useHistory();
  const [viewingAllCategories, setViewingAllCategories] = useState(false);
  const [likedBooks, setLikedBooks] = useState([]);
  const [isLiked, setIsLiked] = useState(false);
  const [showBottomBanner, setShowBottomBanner] = useState(false);
  const [groupsDetails, setGroupsDetails] = useState([]);
  const [groups, setGroups] = useState([]);
  const [currentGroup, setCurrentGroup] = useState();

  const [viewingCategory, setViewingCategory] = useState(
    history &&
      history.location &&
      history.location.state &&
      history.location.state.categoryId
      ? history.location.state.categoryId
      : null
  );
  const [filterTooltip, setFilterTooltip] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchText = queryParams.get("query") || "";

  const openBanner = () => {
    // set
    setShowBottomBanner(true);
  };

  const resetLikedBooks = () => {
    UserService.getLikedBooks(TokenService.getUser().id).then((response) => {
      setLikedBooks(response.data);
    });
  };

  const viewAllCatgories = () => {
    setViewingAllCategories(true);
  };

  const viewCurrentGroup = () => {
    setViewingAllCategories(false);
  };

  const clearFilter = () => {
    setViewingCategory(null);
  };

  useEffect(() => {
    BookService.getCategoryGroups()
      .then((response) => {
        setGroups(response.data);
        setCurrentGroupId(response.data[0].id);
        setCurrentGroup(response.data[0]); // MIGHT BREAK
      })
      .catch((e) => {
        console.log("error getting groups: ", e);
      });
    BookService.getBooksInCategory(272).then((res) => {
      setBeginnerBooks(res.data);
    });
  }, []);

  // get books that match search query and set state
  useEffect(
    (_) => {
      const fetchData = async (_) => {
        setLoadingContent(true);

        try {
          const response = await BookService.getBooksByTitle(searchText, 1);

          // console.log("response.data");
          // console.log(response.data);

          setBooksThatPassQueryCheck(response.data);
          setLoadingContent(false);
        } catch (error) {
          setBooksThatPassQueryCheck([]);
          setArtistsThatPassQueryCheck([]);
          setLoadingContent(false);

          console.log(error.message);
          console.log(error);
        }
      };

      fetchData();
    },
    [searchText]
  );

  // get all artists and set state
  useEffect(() => {
    setLoadingContent(true);
    const fetchArtistData = async (_) => {
      try {
        const response = await ArtistService.searchArtists(searchText);

        console.log("SEARCH ARTIST RESPONSE");
        console.log(response.data);

        const artists = [];

        response.data.forEach((artist) => {
          if (artist.domain_id === 1) {
            artists.push(artist);
          }
        });

        setArtistsThatPassQueryCheck(artists);

        setLoadingContent(false);
      } catch (error) {
        console.log(error);
        setLoadingContent(false);
      }
    };

    // ArtistService.getAllCreatorsDetails()
    //   .then((response) => {
    //     const artists = [];

    //     response.data.forEach((artist) => {
    //       artists.push(artist);
    //     });

    //     const filteredArtists = response.data.filter((artist) =>
    //       artist.name
    //         .toLowerCase()
    //         .normalize("NFD")
    //         .replace(/[\u0300-\u036f]/g, "")
    //         .includes(searchText.toLowerCase().trim())
    //     );

    //     setArtistsThatPassQueryCheck(filteredArtists);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });

    fetchArtistData();
  }, [searchText]);

  // get all group and categories and set state
  useEffect(() => {
    let tempArray = [];
    const getAllGroupsAndCategories = () => {
      groups.map((group) => {
        BookService.getBooksByGroupId(group.id).then((response) => {
          tempArray.push(response.data);
        });
      });
      setGroupsDetails(tempArray);
    };

    getAllGroupsAndCategories();
  }, [loadingContent]);

  // setBooksThatPassQuery if a viewing cateogy has been selected
  useEffect(() => {
    if (viewingCategory) {
      BookService.getBooksInCategory(viewingCategory)
        .then((res) => {
          // Create a new array with modified book objectsz
          const booksWithCategoryNames = res.data.map((book) => {
            // Ensure categoryNames is initialized as an empty array
            book.categoryNames = [];
            book.categories.forEach((category) => {
              book.categoryNames.push(category.name);
            });
            return book;
          });

          setBooksThatPassQueryCheck(booksWithCategoryNames);
          setArtistsThatPassQueryCheck([]); // clear artists if a category is selected
        })
        .catch((e) => {
          console.log(
            `Error getting books in category ${viewingCategory}: ${e}`
          );
        });
    }
  }, [viewingCategory]);

  // for liked books test
  useLayoutEffect(() => {
    const getHearts = (_) => {
      UserService.getLikedBooks(TokenService.getUser().id).then((response) => {
        setLikedBooks(response.data);
      });
    };

    if (TokenService.getUser()) getHearts();
  }, []);

  return (
    <div className="searchResults">
      <div className="about-info">
        {loadingContent ? (
          <div className="loading-spinner" id="catalog">
            <LoadingContentSpinner />
            <span>Loading Books</span>
          </div>
        ) : (
          <>
            <div
              className="bookCatalogHeader-container"
              style={{
                backgroundImage: `url(${bookCatalogHeader})`,
                backgroundRepeat: "repeat",
                backgroundSize: "50rem",
                backgroundPosition: "center",
                margin: "auto",
                marginBottom: "25px",
              }}
            >
              <div className="bookCatalogHeader-shader" />
              <div className="bookCatalog-label">
                <h1>Search Results</h1>
              </div>
            </div>

            {!loadingContent &&
              booksThatPassQueryCheck.length === 0 &&
              artistsThatPassQueryCheck.length === 0 && (
                <div style={{ textAlign: "center" }}>
                  <h2>No results match your search!</h2>
                  <h3>
                    Please enter another search query or visit our catalog page.
                  </h3>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Link
                      to={`/bookcatalog`}
                      className="book-card-image-container"
                    >
                      <ColoredButton
                        text="Catalog"
                        style={{
                          marginTop: "2rem",
                          marginBottom: TokenService.getUser() ? 0 : "1rem",
                        }}
                      />
                    </Link>
                  </div>
                </div>
              )}

            <div className="maxWidthContainer">
              <div className="tab-container fadeInBottom animated">
                <div>
                  <button
                    onClick={() => viewCurrentGroup()}
                    className={`catalog-tab label2 ${viewingAllCategories ? "" : "active"
                      }`}
                  >
                    Search Results
                  </button>
                  <button
                    onClick={() => viewAllCatgories()}
                    className={`catalog-tab label2 ${viewingAllCategories ? "active" : ""
                      }`}
                  >
                    All Categories
                  </button>
                </div>
              </div>
              <hr style={{ margin: 0 }} />

              {viewingAllCategories && (
                <Categories
                  groupsDetails={groupsDetails}
                  setCurrentCategoryName={setCurrentCategoryName}
                  setViewingCategory={setViewingCategory}
                  setFilterTooltip={setFilterTooltip}
                  searchText={searchText}
                  setViewingAllCategories={setViewingAllCategories}
                />
              )}

              {!viewingAllCategories && (
                <Books
                  booksThatPassQueryCheck={booksThatPassQueryCheck}
                  artistsThatPassQueryCheck={artistsThatPassQueryCheck}
                  likedBooks={likedBooks}
                  resetLikedBooks={resetLikedBooks}
                  isLiked={isLiked}
                  openBanner={openBanner}
                  currentCategoryName={currentCategoryName}
                  clearFilter={clearFilter}
                  setFilterTooltip={setFilterTooltip}
                  searchText={searchText}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Search;
