import React, { useState, useRef, useEffect, useCallback } from "react";

import { useHistory } from "react-router-dom";

import "../../components/cards/bookcard.css";

import BookCardMinimal from "../../components/cards/BookCardMinimal";
import BookCardFull from "../../components/cards/BookCardFull";

function SearchCard({
  bookId,
  image,
  title,
  roles,
  tags,
  video,
  likedBooks,
  resetLikedBooks,
  openBanner,
}) {
  const [fadeInOpacity, setFadeInOpacity] = useState(0);
  const [fadeOutOpacity, setFadeOutOpacity] = useState(0);
  const [isLiked, setIsLiked] = useState(false);

  const scrollRef = useRef();
  const cardRef = useRef();
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 601);

  const updateMedia = () => {
    setIsMobileScreen(window.innerWidth < 601);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  useEffect(() => {
    for (const book of likedBooks) {
      if (book.id === bookId) {
        setIsLiked(true);
        break;
      }
    }
  }, [bookId, likedBooks]);

  useEffect(() => {
    if (
      scrollRef.current &&
      scrollRef.current.clientWidth < scrollRef.current.scrollWidth
    ) {
      setFadeOutOpacity(1);
    }
  }, []);

  return (
    <div ref={cardRef}>
      {!isMobileScreen && (
        <div className="book-card-main-container">
          <BookCardFull
            bookId={bookId}
            image={image}
            title={title}
            roles={roles}
            tags={tags}
            video={video}
            likedBooks={likedBooks}
            resetLikedBooks={resetLikedBooks}
            openBanner={openBanner}
          />
        </div>
      )}

      {isMobileScreen && (
        <div className="book-card-minimal-container">
          <BookCardMinimal
            bookId={bookId}
            image={image}
            title={title}
            roles={roles}
            tags={tags}
            video={video}
            likedBooks={likedBooks}
            resetLikedBooks={resetLikedBooks}
            openBanner={openBanner}
          />
        </div>
      )}
    </div>
  );
}

export default SearchCard;
