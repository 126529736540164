import React from "react";
import "./GridCarousel.css";

const GridCarousel = ({ items }) => {
  return <div className={"grid-carousel__container"}>{items}</div>;
};

const GridCarouselItem = ({ content }) => {
  return <div className={"grid-carousel__item"}>{content}</div>;
};

export { GridCarousel, GridCarouselItem };
