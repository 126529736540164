import { useEffect } from "react";
import { Link } from "react-router-dom";
import FavButton from "../micro_components/FavButton";
import ImageLoader from "../image/ImageLoader";
import { AiFillSound } from "react-icons/ai";
import { GiClapperboard } from "react-icons/gi";
import { useState } from "react";
import "./BookCardMinimal.css";

const BookCardMinimal = ({
  bookId,
  image,
  title,
  roles,
  tags,
  video,
  likedBooks,
  resetLikedBooks,
  openBanner,
}) => {
  const [loading, setLoading] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [fav, setFav] = useState(false);

  useEffect(() => {}, []);

  return (
    <Link to={`/bookprofile/${bookId}`} className="group">
      <div className="minimalCardWrapper">
        <div className="relative">
          <ImageLoader
            src={image}
            style={{
              width: "136px",
              height: "181px",
              borderRadius: "12px",
            }}
            alt={`Guitar by Masters classical guitar book ${title}`}
          />
          {loading && <div className="loader" />}
          <div className="titleMinimal gradient" title={title}>
            <div className="text">
              <p className="truncate-text" title={title}>
                {title}
              </p>
              <div className="flex">
                <p>
                  {" "}
                  {video ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <GiClapperboard fill="#71a5f5" size={20} />
                      <span
                        className="text3"
                        style={{
                          marginLeft: "0.25rem",
                          color: "#71a5f5",
                          textTransform: "uppercase",
                          fontSize: "0.9rem",
                          fontWeight: 700,
                        }}
                      >
                        Video
                      </span>
                    </div>
                  ) : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <AiFillSound fill="#f56056" size={20} />
                      <span
                        className="text3"
                        style={{
                          marginLeft: "0.25rem",
                          color: "#f56056",
                          textTransform: "uppercase",
                          fontSize: "0.9rem",
                          fontWeight: 700,
                        }}
                      >
                        Audio
                      </span>
                    </div>
                  )}
                </p>
                <FavButton
                  fav={fav}
                  setFav={setFav}
                  isLiked={isLiked}
                  setIsLiked={setIsLiked}
                  resetLikedBooks={resetLikedBooks}
                  likedBooks={likedBooks}
                  bookId={bookId}
                  openBanner={openBanner}
                  strokeWidth={1.5}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BookCardMinimal;
