import React from "react";
import "./buttonStyles/ColoredCheckbox.css";

const ColoredCheckbox = (props) => {
  const { label, setChecked, checked } = props;

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <div className="checkbox-container" style={{ ...props }} onClick={handleChange}>
      {label}
      <input type="checkbox" checked={checked} onChange={() => {}} />
      <span className="checkmark" />
    </div>
  );
};

export default ColoredCheckbox;
