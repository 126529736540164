import http from "../../http-common";
import TokenService from "../auth/TokenService";
import { DOMAIN_ID } from "../../config";

const fetchPrices = () => {
  // console.log("Str1");
  return http.get(`/payment/prices/${DOMAIN_ID}`);
};

// Add user token here
const createSubscription = (
  priceId,
  selectedPlan,
  discountCode,
  hasUserTrialed,
  isGiftedSubscription
) => {
  // console.log("Str2");
  const token = TokenService.getUser().userToken;
  const data = {
    token: token,
    priceId: priceId,
    tier: selectedPlan,
    hasUserTrialed,
    isGiftedSubscription: isGiftedSubscription ? true : false,
  };

  if (discountCode !== "") {
    data.discountCode = discountCode;
  }
  console.log(data);
  // console.log(data);
  // console.log("sending: ", data)
  return http.post(`/payment/submit-subscription-test`, data);
};

const getUserSubscriptions = (userToken) => {
  // console.log("Str3");
  return http.get(`/payment/subscriptions/${userToken}`);
};

const cancelSubscription = (userToken) => {
  // console.log("Str4");
  return http.delete(`/payment/subscriptions/${userToken}`);
};

const getDiscountStatus = (code) => {
  return http.get(`/payment/discountstatus?code=${code}`);
};
const redeemGiftSubscription = async (code) => {
  const token = TokenService.getUser().userToken;
  const data = {
    discountCode: code,
    token: token,
  };
  return await http.post(`/payment/redeem-gift`, data);
};
const attachPaymentToCustomer = (email, pm, siid) => {
  // console.log("Str5");
  return http.post(`payment/attach`, {
    email: email,
    paymentMethod: pm,
    domainId: DOMAIN_ID,
  });
};

const StripeService = {
  fetchPrices,
  createSubscription,
  getUserSubscriptions,
  cancelSubscription,
  attachPaymentToCustomer,
  getDiscountStatus,
  redeemGiftSubscription,
};

export default StripeService;
