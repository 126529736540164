import React, { useEffect, useState, useRef } from "react";
import "./SearchBar.css";
import { BiSearch } from "react-icons/bi";
import BookService from "../../services/BookService";
import { capitalizeFirstLetter } from "../../helpers/FormatFunctions";
import { useHistory } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../assets/x.svg";

import SearchResults from "./SearchResults";

const SearchBar = ({ clearCurrentGroup }) => {
  const [searchText, setSearchText] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [resultsVisible, setResultsVisible] = useState(false);

  const inputRef = useRef();
  const history = useHistory();

  const onTextChange = (e) => {
    const value = e.target.value;

    setSearchText(value);

    if (value === "" || value.length < 3) {
      setSearchResults([]);
      return;
    }

    // if search results already exist
    var value_norm = value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();

    setSearchResults(
      searchOptions.filter((s) => {
        var title_norm = s.title
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();

        return title_norm.includes(value_norm);
      })
    );
  };

  useEffect(() => {
    let isSubscribed = true; // for preventing "Can't perform a React state update on an unmounted component" error

    //   LOAD SEARCH DATA
    BookService.loadSearchOptions(1)
      .then((r) => {
        if (isSubscribed) {
          const formatted = [];
          for (var i in r.data) {
            for (let j = 0; j < r.data[i].length; j++) {
              if (i === "books") {
                formatted.push({
                  type: "book",
                  title: r.data[i][j].title,
                  id: r.data[i][j].id,
                });
              } else {
                formatted.push({
                  type: "artist",
                  title: r.data[i][j].name,
                  id: r.data[i][j].id,
                });
              }
            }
          }
          // console.log("formatted: ", formatted);
          setSearchOptions(formatted);
        }
      })
      .catch((e) => console.log(e));

    return () => (isSubscribed = false); // this cleanup function prevents the error mentioned above
  }, []);

  useEffect(() => {
    if (searchText.length >= 3) setResultsVisible(true);
  }, [searchText]);

  // useEffect(() => {
  //   console.log(
  //     "_________________________searchOptions_________________________"
  //   );
  //   console.log(searchOptions);
  // }, [searchOptions]);

  // useEffect(() => {
  //   console.log(
  //     "searchResults__________________________________________________"
  //   );
  //   console.log(searchResults);
  // }, [searchResults]);

  const goToItemPage = (item) => {
    //when we clear results, item is deleted
    // var save_item = item;
    // console.log("goToItemPage was passed the following item:", item);
    if (item.type === "book") {
      history.push({ pathname: `/bookprofile/${item.id}` });
    } else if (item.type === "artist") {
      history.push(`/artistprofile/${item.id}`);
    }
    clearResults();
  };

  const clearResults = () => {
    setSearchResults([]);
    setSearchText("");
    // inputRef.current.focus();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    clearCurrentGroup();
    history.push(`/search?query=${encodeURIComponent(searchText)}`);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onSubmit(e);
    }
  };
  return (
    <div className="searchbar">
      <BiSearch
        size={20}
        style={{ height: "100%", width: "auto", padding: "0.4rem" }}
      />
      <form onSubmit={onSubmit} className="searchbar-input">
        <input
          className="searchbar-input"
          ref={inputRef}
          type="text"
          id="name"
          name="name"
          required
          minLength="4"
          maxLength="20"
          // defaultValue="pol"
          placeholder="Search artists, books, etc."
          size="10"
          autoComplete="off"
          onFocus={() => {
            setResultsVisible(true);
          }}
          onChange={onTextChange}
          value={searchText}
          onKeyDown={handleKeyDown}
        />

        <button type="submit" style={{ display: "none" }}></button>
      </form>
      <SearchResults
        visible={resultsVisible}
        results={searchResults}
        inputRef={inputRef}
        searchText={searchText}
        setSearchText={setSearchText}
        goToItemPage={goToItemPage}
        capitalizeFirstLetter={capitalizeFirstLetter}
      />
      <CloseIcon
        stroke="#FFF"
        onClick={clearResults}
        strokeWidth={50}
        style={{
          cursor: "pointer",
          height: "90%",
          width: "auto",
          padding: "0.4rem",
        }}
      />
      <div className="searchbar-border" />
    </div>
  );
};

export default SearchBar;
