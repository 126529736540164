import React from "react";
import "./buttonStyles/buttons.css";

const OutlinedButton = ({ onClick, text, className, style, labelStyle }) => {
  return (
    <div
      className={`${className} outlined-button`}
      onClick={onClick}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="label2" style={{ ...labelStyle }}>
        {text}
      </div>
    </div>
  );
};

export default OutlinedButton;
