import React from "react";
import "./buttonStyles/buttons.css";

const PlainButton = ({ onClick, text, className, style }) => {
  return (
    <div
      className={`${className} plain-button`}
      onClick={onClick}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
      }}
    >
      <div className="label2 plain-button__text">{text}</div>
    </div>
  );
};

export default PlainButton;
