import React from "react";
import { Link } from "react-router-dom";
import "./TransactionHistoryModal.css";
import "../../../components/loading/YesNoModal.css";
import { useEffect } from "react";
import { centsToString, timeConverter } from "../../../helpers/FormatFunctions";
import ColoredButton from "../../../components/buttons/ColoredButton";
const TransactionHistoryModal = ({ pastItems, cardType }) => {
  useEffect(() => {
    if (pastItems) {
      console.log(pastItems.length);
    }
  }, [pastItems]);


  return (
    <div className="trans-history-modal">
      <div className="transaction-history-items">
        <div className="transaction-history-item">
          <div className="th-item__date fw-bold">Date</div>
          <div className="th-item__amount fw-bold">Amount</div>
          <div className="th-item__amount fw-bold">Receipt</div>
        </div>

        {pastItems &&
          pastItems.length > 0 &&
          pastItems.map((p, i) => {
            return (
              <div className="transaction-history-item" key={i}>
                <div className="th-item__date">
                  {timeConverter(p.date * 1000)}
                </div>
                <div className="th-item__amount">
                  ${centsToString(p.amount)}
                </div>
                {cardType !== "Paypal" && (<div className="th-item__amount fw-bold">
                  <a
                    className="link"
                    href={p.receiptUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View
                  </a>
                </div>)}
              </div>
            );
          })}
      </div>
      <div className="th-contact">
        Need help?{" "}
        <Link className="link" to="/contact">
          Contact us
        </Link>
      </div>
    </div>
  );
};

export default TransactionHistoryModal;
