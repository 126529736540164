import React from "react";
import "./styles/ProgressBar.css";
import { timeConverter } from "../../helpers/FormatFunctions";

function ProgressBar({ min, max, current, height = 10, showDateLabels }) {
  const barStyle = {
    position: "relative",
    width: "100%",
    height: `${height}px`,
    borderRadius: `${Math.ceil(height / 2) + 1}px`,
    backgroundColor: "var(--dark-gray)",
  };

  const progressStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: `${Math.round(((current - min) / (max - min)) * 100)}%`,
    height: `${height}px`,
    borderRadius: `${Math.ceil(height / 2)}px`,
    backgroundColor: "green",
  };

  return (
    <div className="progress-bar-container">
      <div className="progress-bar-total" style={barStyle}>
        <div className="progress-bar-current" style={progressStyle} />
      </div>
      {showDateLabels && (
        <>
          <div className="progress-bar-date-label left">
            <span text={timeConverter(min * 1000, true)}>
              {timeConverter(min * 1000, true)}
            </span>
          </div>
          <div className="progress-bar-date-label right">
            <span text={timeConverter(max * 1000, true)}>
              {timeConverter(max * 1000, true)}
            </span>
          </div>
        </>
      )}
    </div>
  );
}

export default ProgressBar;
