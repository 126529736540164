import React, { useState } from "react";

const ImageLoader = ({ src, className, alt, style }) => {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && <div className="shimmer" style={style}></div>}

      <img
        // className={`shimmer`}
        style={{
          ...style,
          width: loading ? "0px" : "",
          fontSize: "4px",
          opacity: loading ? "0" : "1",
          transition: loading ? "" : "opacity 0.3s ease",
        }}
        // className="gradient"
        src={src}
        alt={alt}
        onLoad={() => setLoading(false)}
      />
    </>
  );
};

export default ImageLoader;
