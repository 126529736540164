import React from "react";
import ColoredButton from "../../../../components/buttons/ColoredButton";
import OutlinedButton from "../../../../components/buttons/OutlinedButton";
import "./SubscriptionCard.css";

const SubscriptionCard = ({ isSelected, label, price, billed, setSelected, period, userHasTrialed }) => {
  return (
    <div className="subscription-card" onClick={() => setSelected(billed)}>
      <div className={`subscription-card-inner ${isSelected && "selected"}`}>
        <h3>{label}</h3>
        <div className="subscription-card__billing-info">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {label === "Monthly" ? (
              !(userHasTrialed) && (
                <span className="trial-addition">7-day free trial, then</span>
              )
            ) : (
              <span className="notrial-addition">No free trial for annual plans</span>
            )}
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <h2>${price}</h2>
              <span style={{ marginLeft: "0.5rem" }}>{`/ ${period}`}</span>
            </div>
          </div>
          {label === "Yearly" && (
            <div className="subscription-card__billing-description">
              Billed annually
              <br />
              (averages $4.99 / month)
            </div>
          )}
        </div>
      </div>
      <div className="screen-m screen-l">
        {isSelected ? (
          <ColoredButton className={"subscription-card__button"} text="Selected" onClick={() => setSelected(billed)} />
        ) : (
          <OutlinedButton className={"subscription-card__button"} text="Select" onClick={() => setSelected(billed)} />
        )}
      </div>
    </div>
  );
};

export default SubscriptionCard;
