import React, { useState } from "react";
import "./LoginRegisterPage.css";
import { useLocation, useHistory, Router } from "react-router-dom";

import LoginAndSignUp from "./loginAndSignUp/LoginAndSignUp";
import NotificationScreen from "./notification/NotificationScreen";
import { useEffect, useContext } from "react";
import LoadingModal from "../../components/loading/LoadingModal";
import { Helmet } from "react-helmet";
import { NotificationBarContext } from "../../context/NotificationBarContext";
const LoginRegisterPage = ({ initialRegisterMode }) => {
  const [emailSent, setEmailSent] = useState(false);
  const [registerEmail, setRegisterEmail] = useState("");

  const { fromReg } = useLocation();
  const location = useLocation();

  const [registerMode, setRegisterMode] = useState(true);

  const [loading, setLoading] = useState(false);
  const [fromGiftCodePath, setFromGiftCodePath] = useState("")

  const handleResendEmail = () => {
    // console.log("resending email");
  };

  useEffect(() => {
    if (fromReg !== undefined) setRegisterMode(fromReg);
  }, [fromReg]);
  const { hideNotificationBar, showNotificationBar } = useContext(NotificationBarContext); // Use context
  useEffect(() => {
    hideNotificationBar()
    return () => {
      showNotificationBar()
    }
  }, []);

  useEffect(() => {
    let isMounted = true; // flag to check if component is still mounted

    // Ensure location.state and location.state.from are defined
    if (location.state && location.state.from) {
      const fromPath = typeof location.state.from === 'string' ? location.state.from.toLowerCase() : '';


      // Check if the path starts with the specified string
      if (fromPath.startsWith("/gift/giftcenter")) {

        const spitByquery = fromPath.split('?')[1]
        // Extract the query parameters from the URL
        const queryParams = new URLSearchParams(spitByquery);

        // Check if the 'giftCenter' query parameter exists
        if (queryParams.has('giftcode')) {
          const giftCenterValue = queryParams.get('giftcode');
          // Only update state if the component is still mounted
          if (isMounted) {
            setFromGiftCodePath(fromPath);
          }
        }
      }
    }
    return () => {
      isMounted = false; // Set the flag to false when the component unmounts
    };

  }, [location?.state]);
  return (
    <>
      <Helmet>
        <script async src='https://tag.simpli.fi/sifitag/2cd79200-3b94-013b-5653-0cc47a1f72a4'>
        </script>
      </Helmet>
      <LoadingModal isLoading={loading} />
      <div className="login-register-container ">
        {/* <div className="login-heading">Login or Register to Get Started.</div> */}
        {!emailSent ? (
          <LoginAndSignUp
            setEmailSent={setEmailSent}
            fromGiftCodePath={fromGiftCodePath}
            setRegisterEmail={setRegisterEmail}
            registerMode={registerMode}
            setRegisterMode={setRegisterMode}
            setLoading={setLoading}
          />
        ) : (
          <NotificationScreen
            buttonText={"Resend Email"}
            onButtonClick={handleResendEmail}
            text={`Confirmation Email Sent to ${registerEmail}`}
          />
        )}
      </div>
    </>
  );
};

export default LoginRegisterPage;
