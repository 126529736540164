import axios from "axios";
import TokenService from "./services/auth/TokenService";
// import { useHistory } from "react-router";

const http = axios.create({
  // baseURL: "http://localhost:5000",
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-type": "application/json",
  },
});

const httpUnAuthenicated = axios.create({
  // baseURL: "http://localhost:5000",
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-type": "application/json",
  },
});
const publishingApi = axios.create({
  baseURL: process.env.REACT_APP_PUBLISHING_URL,
  headers: {
    "Content-type": "application/json",
  },
});

http.interceptors.request.use(
  async (config) => {
    const token = TokenService.getLocalAccessToken();
    const checkedToken = await handleJWT(token);

    if (checkedToken) {
      config.headers["Authorization"] = "Bearer " + checkedToken; // Updated with checkedToken
    }
    return config;
  },
  (error) => {
    // window.location.href = "/login";

    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (res) => {
    // console.log("intercept res: ", res);
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    // console.log("ogconfig: ", originalConfig);
    if (
      originalConfig &&
      originalConfig.url !== "/api/auth/signin" &&
      err.response
    ) {
      // console.log("error", err);
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        window.location.href = "/login";
        originalConfig._retry = true;

        // try {
        //   // console.log(
        //   //   "sending refresh token: ",
        //   //   TokenService.getLocalRefreshToken()
        //   // );
        //   const rs = await http.post("/api/auth/refreshtoken", {
        //     refreshToken: TokenService.getLocalRefreshToken(),
        //   });

        //   // console.log("response: ", rs);

        //   const { accessToken, refreshToken } = rs.data;
        //   TokenService.updateLocalAccessToken(accessToken);
        //   TokenService.updateRefreshToken(refreshToken);

        //   return http(originalConfig);
        // } catch (_error) {
        //   // TokenService.removeUser();
        //   console.log("A: ", _error);
        //   return Promise.reject(_error);
        // }
      }
    }

    return Promise.reject(err);
  }
);

export { publishingApi };
export default http;

async function handleJWT(token) {
  if (!token) {
    return null;
  }

  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  const parsedToken = JSON.parse(jsonPayload);
  // console.log("🚀 ~ handleJWT ~ parsedToken:", parsedToken);

  if (!parsedToken) {
    return token;
  }

  const expDate = parsedToken.exp * 1000;

  if (expDate <= new Date().getTime()) {
    try {
      const rs = await httpUnAuthenicated.post("/api/auth/refreshtoken", {
        refreshToken: TokenService.getLocalRefreshToken(),
      });
      const { accessToken, refreshToken } = rs.data;
      TokenService.updateLocalAccessToken(accessToken);
      TokenService.updateRefreshToken(refreshToken);

      return accessToken;
    } catch (error) {
      window.location.href = "/login";
      throw error;
    }
  }

  return token;
}
