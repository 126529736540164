import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ArtistService from "../../../services/ArtistService";
import BookDataService from "../../../services/BookService";
import { FeaturedArtistText } from "../../catalog/artists/FeaturedArtistText";

const FeaturedHomeArtistSlider = ({ artist }) => {
  const [bookdata, setbookData] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState({
    // image: false,
    desc: false,
    books: false,
  }); //need 3

  const sampleImage = `https://da9nh4xe5cncw.cloudfront.net/${artist.id}/${artist.galleryImageURL}`;

  useEffect(() => {
    const getArtistBooks = () => {
      ArtistService.getBooksfromArtist(artist.id).then((response) => {
        setbookData(response.data);
        setLoadingStatus((s) => ({ ...s, books: true }));
      });
    };

    getArtistBooks();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <Link to={`/artistprofile/${artist.id}`} style={{ width: "100%", textDecoration: "none" }}>
        <h3>{artist.name}</h3>
      </Link>
      <div className="featured-artist-home-content-container">
        <Link to={`/artistprofile/${artist.id}`}>
          <img className="featured-artist-home-img" src={sampleImage} alt="artist pic" />
        </Link>
        <div className="featuredArtist-home-book-container">
          {bookdata && bookdata.length > 0 ? (
            <>
              {bookdata.slice(0, 5).map((book, index) => {
                const image = BookDataService.getBookCoverSrc(book.id, book.bookCoverLink);
                return (
                  <Link key={index} to={`/bookprofile/${book.id}`}>
                    <img className="featuredArtist-home-books" src={image} alt="artist book cover" />
                  </Link>
                );
              })}
              {bookdata.length > 5 && (
                <span style={{ fontSize: "1.2rem", fontWeight: 500, marginLeft: "0.5rem" }}>
                  plus {bookdata.length - 5} more
                </span>
              )}
            </>
          ) : (
            <p style={{ fontSize: "2rem", paddingLeft: "25px" }}>Coming Soon</p>
          )}
        </div>
      </div>
      {FeaturedArtistText.map((description, index) => {
        if (description.artistId == artist.id) {
          return (
            <div key={index} className="featuredArtist-description">
              <p>{description.featuredText}</p>
            </div>
          );
        }
      })}
    </div>
  );
};

export default FeaturedHomeArtistSlider;
