import React from "react";
import "./buttonStyles/buttons.css";

const ColoredButton = ({ onClick, text, className, style, disabled, additionalClass }) => {
  return (
    <button
      className={`${className} colored-button ${disabled ? "disabled" : ""} ${additionalClass ? additionalClass : ""}`}
      onClick={onClick}
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="label2">
        <p>{text}</p>
      </div>
    </button>
  );
};

export default ColoredButton;
