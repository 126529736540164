import {
  useState,
  useLayoutEffect,
  useEffect,
  useCallback,
  useRef,
} from "react";
import bookServices from "../../services/BookService";
import BookCard from "../../components/cards/BookCard";
import TokenService from "../../services/auth/TokenService";
import UserService from "../../services/UserService";
import LoadingContentSpinner from "../../components/loading/LoadingContentSpinner";
import SmallBookCard from "../../components/micro_components/SmallBookCard";
import { Link } from "react-router-dom";
import ColoredButton from "../../components/buttons/ColoredButton";
import "./userhome.css";

const UserHome = () => {
  const [likedBooks, setLikedBooks] = useState([]);
  const [suggestBooks, setSuggestBooks] = useState([]);
  const [recentlyVisited, setRecentlyVisited] = useState([]);
  const [suggestedBooks, setSuggestedBooks] = useState([]);

  const [loading, setLoading] = useState(true);
  const [loadingLikes, setLoadingLikes] = useState(true);
  const [loadingSuggested, setLoadingSuggested] = useState(true);

  const [fadeInOpacity, setFadeInOpacity] = useState(0);
  const [fadeOutOpacity, setFadeOutOpacity] = useState(0);
  const scrollRef = useRef();
  let oldX;

  const [suggestedLabel, setSuggestedLabel] = useState(
    "Here are some books to get you started!"
  );

  useLayoutEffect(() => {
    const getHearts = () => {
      UserService.getLikedBooks(TokenService.getUser().id)
        .then((response) => {
          setLoadingLikes(false);
          setLikedBooks(response.data);
          if (response.data.length > 0) {
            setSuggestedLabel("Suggested for You");
          }
        })
        .catch((e) => {
          setLoadingLikes(false);
          console.log("error getting likes: ", e);
        });

      UserService.getSuggestedBooks(TokenService.getUser().id)
        .then((response) => {
          setSuggestedBooks(response.data);
          setLoadingSuggested(false);
        })
        .catch((e) => {
          console.log("error getting suggested books: ", e);
        });
    };

    const getRecentlyVisitedBooks = () => {
      UserService.getRecentlyVisitedBooks(TokenService.getUser().id)
        .then((response) => {
          function sortByDateDescending(data) {
            return data.sort((a, b) => b.date - a.date);
          }
          const sortedRecentBooks = sortByDateDescending(response.data);
          // console.log(response);
          setRecentlyVisited(sortedRecentBooks);
        })
        .catch((e) => {
          console.log("error getting recently visited books");
        });
    };

    const dontgethearts = () => {};
    getRecentlyVisitedBooks();

    TokenService.getUser() ? getHearts() : dontgethearts();
  }, []);

  useEffect(() => {
    const fetchSuggestedBooks = () => {
      bookServices.getBooksInCategory(97).then((response) => {
        setSuggestBooks(response.data);
      });
    };

    fetchSuggestedBooks();

    if (
      scrollRef.current &&
      scrollRef.current.clientWidth < scrollRef.current.scrollWidth
    )
      setFadeOutOpacity(1);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  const handleMouseDown = useCallback((e) => {
    oldX = e.pageX;
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp, { once: true });
  }, []);

  const handleMouseUp = useCallback(() => {
    window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  const handleMouseMove = useCallback((e) => {
    scrollRef.current.scrollLeft -= e.pageX - oldX;
    oldX = e.pageX;
  }, []);

  const handleScroll = () => {
    const leftDistance = scrollRef.current.scrollLeft;

    const rightDistance =
      scrollRef.current.scrollWidth -
      scrollRef.current.clientWidth -
      scrollRef.current.scrollLeft;

    // fade-in handling
    if (leftDistance <= 20) setFadeInOpacity(leftDistance / 20);
    else setFadeInOpacity(1);

    // fade-out handling
    if (rightDistance > 20) setFadeOutOpacity(1);
    else if (rightDistance > 0) setFadeOutOpacity(rightDistance / 20);
    else setFadeOutOpacity(0);
  };

  const resetLikedBooks = () => {
    UserService.getLikedBooks(TokenService.getUser().id).then((response) => {
      setLikedBooks(response.data);
    });
  };

  //check if things are loaded
  useEffect(() => {
    if (!loadingSuggested && !loadingLikes) {
      setLoading(false);
    }
  }, [loadingLikes, loadingSuggested]);

  return (
    <div className="user-home user-loggedin-margin">
      {loading ? (
        <div
          className="loading-spinner"
          id="catalog"
          style={{ margin: "auto", textAlign: "center" }}
        >
          <LoadingContentSpinner />
          <span>Welcome back, hang on while we get your books...</span>
        </div>
      ) : (
        <div className="user-home-container">
          <div className="inner-user-home-container">
            <div className="user-home-module" id="resume">
              {" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                {" "}
                <h3>Continue learning </h3>
              </div>
              {recentlyVisited ? (
                <div
                  ref={scrollRef}
                  className="resume-books-container"
                  onMouseEnter={() => {
                    window.addEventListener("mousedown", handleMouseDown);
                  }}
                  onMouseLeave={() => {
                    window.removeEventListener("mousedown", handleMouseDown);
                  }}
                >
                  {recentlyVisited.map((book, i) => {
                    return (
                      <SmallBookCard
                        key={i}
                        viewedBook={book}
                        bookId={book.book}
                      />
                    );
                  })}
                </div>
              ) : (
                <div
                  className="resume-books-container"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <h3>Your recently opened books will appear here.</h3>
                </div>
              )}
            </div>
            <div className="user-home-module" id="favorites">
              <h3>Favorites</h3>
              <div className="favorite-books">
                {likedBooks.length > 0 ? (
                  likedBooks.map((book, i) => {
                    const image = bookServices.getBookCoverSrc(
                      book.id,
                      book.bookCoverLink
                    );
                    return (
                      <BookCard
                        key={i}
                        bookId={book.id}
                        image={image}
                        title={book.title}
                        roles={book.roles}
                        tags={book.categories}
                        video={book.video}
                        likedBooks={likedBooks}
                        resetLikedBooks={resetLikedBooks}
                      />
                    );
                  })
                ) : (
                  <h3>Your favorite books will appear here.</h3>
                )}
              </div>
            </div>{" "}
            <div
              // className="resume-books-container"
              style={{
                height: "auto",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Link to="/bookcatalog" className="no-underline ">
                <ColoredButton
                  text="View our Catalog"
                  style={{
                    margin: "2rem",
                  }}
                />
              </Link>
            </div>
            <div className="user-home-module" id="suggested">
              <h3 style={{ padding: "15px" }}>{suggestedLabel}</h3>
              {likedBooks.length > 0 && (
                <span className="text3">Based on the books you favorited</span>
              )}
              <div className="suggested-books">
                {suggestedBooks.length > 0 ? (
                  suggestedBooks.map((book, i) => {
                    const image = bookServices.getBookCoverSrc(
                      book.id,
                      book.bookCoverLink
                    );
                    return (
                      <BookCard
                        key={i}
                        bookId={book.id}
                        image={image}
                        title={book.title}
                        roles={book.roles}
                        tags={book.categories}
                        video={book.video}
                        likedBooks={likedBooks}
                        resetLikedBooks={resetLikedBooks}
                      />
                    );
                  })
                ) : (
                  <h3>Suggested books will appear here</h3>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserHome;
