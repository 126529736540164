import React from "react";
import { ScaleLoader } from "react-spinners";
import "./ConfirmationModal.css";
import ColoredButton from "../buttons/ColoredButton";

const ConfirmationModal = ({ isLoading, title, message, onClose, buttonMessage = "Close", style }) => {
  return (
    <>
      <div className="modal-dimmer" />
      <div className="confirmation-modal" style={style}>
        {isLoading ? (
          <ScaleLoader color={"#fff"} loading={isLoading} style={{ marginLeft: "auto auto" }} />
        ) : (
          // message
          <>
            <h1 className="confirmation-modal__title">{title}</h1>
            <h4 className="confirmation-modal__message">{message}</h4>
            <div className="confirmation-modal__button-container">
              <ColoredButton text={buttonMessage} onClick={onClose} style={{ margin: "2rem 0" }} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ConfirmationModal;
