import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../components/auth/state/Store";
import ColoredButton from "../../components/buttons/ColoredButton";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import TokenService from "../../services/auth/TokenService";

function ThankYou() {
  const [oldTitle, setOldTitle] = useState(document.title);
  const [state, dispatch] = useContext(Context);
  const history = useHistory();

  useEffect(() => {
    document.title = "Thank You";

    return () => (document.title = oldTitle);
  }, []);

  function navigate(dest) {
    history.push(dest);
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "1rem", minHeight: "80vh" }}>
      <h1>Thank you!</h1>
      <h2>Your subscription has started. Let's practice!</h2>
      <ColoredButton text="Show me the books" onClick={() => navigate("/bookcatalog")} style={{ margin: "1rem 0" }} />
      <p style={{ marginTop: "5rem" }} className="text2">
        You can also check out...
      </p>
      <div style={{ display: "flex", gap: "1rem" }}>
        <OutlinedButton text="Artists" onClick={() => navigate("/artistcatalog")} />
        <OutlinedButton text="Dashboard" onClick={() => navigate("/userhome")} />
      </div>
    </div>
  );
}

export default ThankYou;
