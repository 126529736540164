import React from 'react';
import ClipLoader from 'react-spinners/ScaleLoader';
import './LoadingModal.css';

const LoadingContentSpinner = ({ isLoading, loadingColor = '#fff' }) => {
  return (
    <div>
      <ClipLoader
        size={100}
        color={loadingColor}
        loading={isLoading}
        style={{ marginLeft: 'auto auto' }}
      />
    </div>
  );
};

export default LoadingContentSpinner;
