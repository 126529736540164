import React, { useState, useRef, useEffect } from 'react';
import gsap from 'gsap';
import './FAQAccordion.scss'; // Assuming your CSS is in this file

const AccordionGroup = ({ title, children, index, isOpen, onClick }) => {

    const contentRef = useRef(null);
    const animationRef = useRef(null);

    useEffect(() => {
        // Initialize the animation but paused
        animationRef.current = gsap.fromTo(contentRef.current,
            { height: 0 },
            { height: () => contentRef.current.scrollHeight + 10, duration: 0.5, ease: 'power1.inOut', paused: true }
        );
    }, []);


    useEffect(() => {
        if (isOpen) {
            animationRef.current.play();
        } else {
            animationRef.current.reverse();
        }
    }, [isOpen]); // Trigger animation when isOpen changes
    return (
        <div className="accordion-group">
            <div className={`accordion-menu ${index === 0 ? "accordion-top-br" : ""}`} onClick={onClick}>
                {title}
            </div>
            <div className="accordion-content" ref={contentRef} style={{ overflow: 'hidden' }}>
                {children}

            </div>
        </div>
    );
};

const Accordion = () => {
    const faqs = [
        {
            title: "How do I use my gifted subscription?",
            content: "To use your gifted subscription code, you need to be logged in. If you don't have an account, you can easily sign up on our website. Once logged in, select the monthly subscription plan and enter your gift code. You won’t need to enter any payment information, and your subscription will start immediately.", button: "/login"
        },
        {
            title: "How does the gifting process work?",
            content: "Gifting a subscription is simple! You can purchase a 6-month or 1-year gift subscription. After the purchase, we'll email a unique code to the recipient, which they can use to activate their subscription."
        },
        {
            title: "What if the gift recipient already has an account?",
            content: "If the recipient already has an account, they can enter the gifted subscription code in their account settings page or the gift center page to activate it."
        },
        {
            title: "I don't have an account. Can I still use a gifted subscription?",
            content: "Yes! If you receive a gift subscription but don't have an account, you can quickly create one and enter the gift code during the signup process."
        },
        {
            title: "What payment methods do you accept?",
            content: "We accept payments with all major credit cards through Stripe. You can choose your preferred payment method at checkout."
        },
        {
            title: "I already have a subscription, and I have received a gift subscription. What happens now?",
            content: "You can still redeem your gift by applying the gift code on the Gift Center page or your Account Settings page. Your upcoming payments will be set to 0 for the duration of the gift subscription. Depending on how many days are left from your original subscription when you apply the gift, a pro-rated amount will be kept as credit for your subsequent payments"
        },
        {
            title: "What happens when my gifted subscription ends?",
            content: "Once your gifted subscription ends, your original subscription will resume, if you had one. If you don’t have any payment information on your account, you will be prompted to add that to continue your subscription."
        },

        {
            title: "Is my payment information secure?",
            content: "Absolutely. We use secure payment gateways like Stripe and PayPal to ensure your payment information is protected and encrypted."
        },
        {

            title: "Can I pause my gift subscription for a while and resume it later?",
            content: "No. Once you redeem your gift code, the gift subscription will stop immediately and cannot be paused."

        }
    ];

    const [openIndex, setOpenIndex] = useState(null);
    const titleRef = useRef(null);
    const handleAccordionClick = index => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="accordion">
            <h2 ref={titleRef}>Frequently Asked Questions</h2> {/* Title element */}

            {faqs.map((faq, index) => (
                <AccordionGroup
                    key={index}
                    title={faq.title}
                    isOpen={openIndex === index}
                    onClick={() => handleAccordionClick(index)}

                >
                    <p>{faq.content}</p>
                </AccordionGroup>
            ))}
        </div>
    );
};

export default Accordion;