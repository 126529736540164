import http from "../../http-common";
import TokenService from "./TokenService";
import { DOMAIN_ID } from "../../config";
class AuthService {

  login = (email, password) => {
    // console.log("AU1");
    return http.post("/api/auth/signin", {
      email: email,
      password: password,
      domainId: DOMAIN_ID,
    });
  };
  logout = () => {
    TokenService.removeUser();
  };

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  isEditor() {
    if (JSON.parse(localStorage.getItem("user").roles.includes("ROLE_EDITOR"))) {
      return true;
    } else {
      return false;
    }
  }

  register(email, password, newsletter) {
    // console.log("AU2");
    // console.log({ email: email, password: password, roles: [] });
    return http.post("/api/auth/signup", {
      email: email,
      password: password,
      role: [],
      domain_id: DOMAIN_ID,
      newsletter: newsletter,
    });
  }

  sendPasswordResetEmail(email) {
    // console.log("AU3");
    return http.post("/api/auth/sendreset", {
      email: email,
      domainId: DOMAIN_ID,
    });
  }

  sendResetPasswordRequest(password, token) {
    // console.log("AU4");
    return http.post("/api/auth/resetpassword", {
      password: password,
      token: token,
    });
  }

  sendOauth2Token(token, type, url) {
    // console.log("AU5");
    return http.post("/api/auth/oauth2/verify", {
      token: token,
      type: type,
      urlToRequest: url,
    });
  }

  loginThroughOAuth = () => {
    // console.log("AU6");
    const method = TokenService.getOAuthMethod();
    const token = TokenService.getOAuthRenewalToken();
    return http.post("/api/auth/oauth2/reverify", {
      method: method,
      token: token,
    });
  };
}

export default new AuthService();

const parseJwt = (token) => {
  try {
    return JSON.parse(window.atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};
