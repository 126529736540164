import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import PracticePal from "./PracticePal";
import { useHistory } from "react-router";
import OurPartners from "./homepageComponents/OurPartners";
import Testimonials from "./homepageComponents/Testimonials";
import Membership from "./homepageComponents/Membership";
import Featured from "./homepageComponents/FeaturedHome";
import FeaturedHomeBooks from "./homepageComponents/FeaturedHomeBooks";
import { Link } from "react-router-dom";
import TokenService from "../../services/auth/TokenService";
import DemoBook from "./homepageComponents/DemoBook";
import { useState } from "react";
import UserService from "../../services/UserService";
// buttons
import ColoredButton from "../../components/buttons/ColoredButton";
import OutlinedButton from "../../components/buttons/OutlinedButton";
import GiftCenterComponent from "../../components/giftSubscription/GiftCenterSmallerComponent";
import "./home.css";
import "./MembershipSection.css";
import { Helmet } from "react-helmet";
import RecentlyAddedBooks from "../catalog/books/components/RecentlyAddedBooks";
import PricingTable from "../../components/giftSubscription/PricingTable";
const Home = () => {
  SwiperCore.use([Autoplay, Pagination, Navigation]);

  const history = useHistory();

  const navigateToSignUp = () => {
    history.push({
      pathname: "/login",
      fromReg: true,
    });
  };

  const [likedBooks, setLikedBooks] = useState([]);
  const [isLiked, setIsLiked] = useState(false);
  const [filterTooltip, setFilterTooltip] = useState(false);

  const resetLikedBooks = () => {
    UserService.getLikedBooks(TokenService.getUser().id).then((response) => {
      setLikedBooks(response.data);
    });
  };

  const [currentCategoryName, setCurrentCategoryName] = useState(
    history &&
      history.location &&
      history.location.state &&
      history.location.state.category
      ? history.location.state.category
      : ""
  );

  const [viewingCategory, setViewingCategory] = useState(
    history &&
      history.location &&
      history.location.state &&
      history.location.state.categoryId
      ? history.location.state.categoryId
      : null
  );

  const [showBottomBanner, setShowBottomBanner] = useState(false);

  const openBanner = () => {
    setShowBottomBanner(true);
  };

  const viewSingleCategory = (category) => {
    setCurrentCategoryName(category.name);
    setViewingCategory(category.id);
    setFilterTooltip(false);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  return (
    <div className="home-container ">
      {/* <Helmet>
        <script
          async
          src="https://tag.simpli.fi/sifitag/d5b451a0-3b93-013b-5653-0cc47a1f72a4"
        ></script>
      </Helmet> */}

      <div className="home-header fadeInBottom">
        <img
          className="header-image"
          alt="Guitar by Masters Classical guitar artists"
          src={"https://d9fedpdsh4r16.cloudfront.net/GbM/gbm-header.webp"}
        />
        <div className="header-content">
          <h1 className="screen-l" style={{ margin: "25px 0" }}>
            Learn new pieces.
            <br />
            Master new skills.
          </h1>
          <h1 className="screen-m screen-s" style={{ margin: "25px 0" }}>
            Learn new pieces.
            <br />
            Master new skills.
          </h1>
          <p className="header-sub">
            An expanding library of classical, acoustic, and jazz guitar
          </p>
        </div>
      </div>

      {!TokenService.getUser() && (
        <>
          <ColoredButton
            text="Start your free trial"
            onClick={navigateToSignUp}
            additionalClass="free-trial-button"
          />
          <p
            className="text3"
            style={{ marginTop: "1rem", textAlign: "center" }}
          >
            Plans start at only $6.99/month.
            <br />
            Cancel anytime.
          </p>
        </>
      )}

      <Link to="/bookcatalog" className="no-underline">
        <ColoredButton
          text="View our Catalog"
          style={{
            marginTop: "2rem",
            marginBottom: TokenService.getUser() ? 0 : "1rem",
          }}
        />
      </Link>
      <div className="home">
        <PracticePal />

        {/* <PricingTable /> */}

        <h2 style={{ marginTop: "1rem" }}>Recently Added Books</h2>
        <RecentlyAddedBooks
          likedBooks={likedBooks}
          resetLikedBooks={resetLikedBooks}
          isLiked={isLiked}
          setIsLiked={setIsLiked}
          openBanner={openBanner}
          viewSingleCategory={viewSingleCategory}
        />
        <FeaturedHomeBooks />

        {!TokenService.getUser() && (
          <>
            <Membership />
          </>
        )}
        <Featured />

        <a id="testimonials" />
        <Testimonials />
        <OurPartners />
      </div>
    </div>
  );
};

export default Home;
