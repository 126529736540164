import React from "react";
import "./AboutDropdown.css";
import PlainButton from "../../buttons/PlainButton";
import { FiChevronDown } from "react-icons/fi";
import { useHistory } from "react-router";
import TokenService from "../../../services/auth/TokenService";

const AboutDropdown = () => {
  const history = useHistory();

  const addedItemStyle = {
    textAlign: "start",
    justifyContent: "start",
    padding: "1rem",
  };

  const navigateTo = (pathname) => {
    history.push(pathname);
  };

  return (
    <div className="about-dropdown">
      <PlainButton
        className="about-dropdown__button"
        text={
          <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
            {" "}
            about <FiChevronDown />{" "}
          </div>
        }
      />
      <div className="about-dropdown__content">
        {!TokenService.getUser() && (
          <div className="about-dropdown__item">
            <a href={"/#membership-benefits"} className="no-underline">
              <PlainButton className="about-item__button" style={addedItemStyle} text="Membership" />
            </a>
          </div>
        )}
        <div className="about-dropdown__item">
          <PlainButton
            className="about-item__button"
            style={addedItemStyle}
            text="About Us"
            onClick={() => navigateTo("/about")}
          />
        </div>
        <div className="about-dropdown__item">
          <PlainButton
            className="about-item__button"
            style={addedItemStyle}
            text="Testimonials"
            onClick={() => navigateTo("/testimonials")}
          />
        </div>
        <div className="about-dropdown__item">
          <PlainButton
            className="about-item__button"
            style={addedItemStyle}
            text="Gift Center"
            onClick={() => navigateTo("/gift/giftCenter")}
          />
        </div>
        {/* <div className="about-dropdown__item">
          <PlainButton
            className="about-item__button"
            style={addedItemStyle}
            text="Help"
          />
        </div> */}
      </div>
    </div>
  );
};

export default AboutDropdown;
