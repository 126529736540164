import React from "react";
import LoadingContentSpinner from "../../../loading/LoadingContentSpinner";
import LargeModal from "../../../modal/LargeModal";
import ColoredButton from "../../../buttons/ColoredButton";
import OutlinedButton from "../../../buttons/OutlinedButton";
import { useHistory } from "react-router-dom";

const OAuthCallbackModal = ({ error }) => {
  const history = useHistory();

  const navigateToLogin = () => {
    history.push("/login");
  };

  const navigateToContactUs = () => {
    history.push("/contact");
  };

  return (
    <div style={{ height: "100vh" }}>
      <LargeModal
        isOpen={true}
        title={"Logging you in"}
        subtitle={
          error ? (
            <div>
              <p style={{ marginBottom: "0.5rem" }}>Uh oh, there was an error signing you in.</p>
              <p>
                If you didn't create your account using Google or Facebook, you need to type your email address and
                password directly
              </p>
            </div>
          ) : (
            "This may take just a moment..."
          )
        }
        hideX={true}
        content={
          <div
            style={{
              display: "flex",
              flex: "1",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {error ? (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <h3
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    padding: "1rem",
                  }}
                >
                  There was an error signing you in.{" "}
                </h3> */}
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                    justifyContent: "space-around",
                  }}
                >
                  <ColoredButton text="Back to Login" onClick={navigateToLogin} />
                  <OutlinedButton text="Contact Us" onClick={navigateToContactUs} />
                </div>
              </div>
            ) : (
              <LoadingContentSpinner />
            )}
          </div>
        }
      />
    </div>
  );
};

export default OAuthCallbackModal;
