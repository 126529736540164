
import React, { useState, useEffect } from "react";

import "./PricingTable.css"

function PricingTable(props) {




    // Select all matching divs

    return (
        < div className="pricing-table-container" >
            <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
            <stripe-pricing-table pricing-table-id={process.env.REACT_APP_STRIPE_GIFT_PRICING_TABLE_ID}
                publishable-key={process.env.REACT_APP_STRIPE_KEY}>
            </stripe-pricing-table>
        </div>
    );
}

export default PricingTable;



