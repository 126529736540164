import React from "react";

// logos
import { ReactComponent as MelBayLogo } from "../../../assets/Mel-Bay-logo.svg";
import { ReactComponent as DobermanLogo } from "../../../assets/Doberman-logo.svg";
import { ReactComponent as DMP } from "../../../assets/DMP-logo.svg";
import { ReactComponent as GSI } from "../../../assets/GSI-logo.svg";
import { ReactComponent as Singidunum } from "../../../assets/Singidunum.svg";
import { ReactComponent as GFA } from "../../../assets/GFA.svg";
import { ReactComponent as Doberman } from "../../../assets/Doberman.svg";
import { ReactComponent as Metropolis } from "../../../assets/Metropolis.svg";
import { ReactComponent as Alfred } from "../../../assets/Alfred.svg";
import { ReactComponent as Tonebase } from "../../../assets/Tonebase.svg";
import { ReactComponent as UE } from "../../../assets/UE.svg";

import "./ourPartners.css";

//add universal edition with logo https://www.universaledition.com/assets/img/logos/uelogo.png

const OurPartners = () => {
  return (
    <div className="partners-container">
      <div className="partners">
        <h3 style={{ padding: "2rem", width: "100%", textAlignq: "center" }}>
          Our Partners
        </h3>
        <div className="partners-logo-container">
          <div className="partner-icon horizontal-logo">
            <a
              href="https://productionsdoz.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <DobermanLogo fill="#fff" />
            </a>
          </div>

          <div className="partner-icon horizontal-logo">
            <a
              href="https://www.tonebase.co/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {/* <img
                src="/images/tonebase.jpg"
                height="20px"
                className="tonebase"
              /> */}
              <Tonebase />
            </a>
          </div>

          <div className="partner-icon horizontal-logo">
            <a
              href="https://ummpstore.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Metropolis fill="#fff" />
            </a>
          </div>

          <div className="partner-icon">
            <a
              href="https://www.singidunumusic.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Singidunum fill="#fff" stroke="#fff" />
            </a>
          </div>
          <div className="partner-icon">
            <a
              href="https://www.guitarfoundation.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GFA fill="#fff" />
            </a>
          </div>
          <div className="partner-icon">
            <a
              href="https://gsifoundation.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GSI fill="#fff" stroke="#fff" />
            </a>
          </div>
          <div className="partner-icon">
            <a
              href="https://www.facebook.com/DigitalMusicPrint"
              target="_blank"
              rel="noopener noreferrer"
            >
              <DMP fill="#fff" />
            </a>
          </div>

          <div className="partner-icon">
            <a
              href="https://www.universaledition.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <UE />
            </a>
          </div>
          <div className="partner-icon">
            <a
              href="https://www.melbay.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MelBayLogo fill="#fff" />
            </a>
          </div>

          <div className="partner-icon">
            <a
              href="https://www.alfred.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Alfred />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurPartners;
