import React, { useContext, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import "../../pages/readerpage/chapter/ChapterReader.css";
import { Context } from "../auth/state/Store";

import "../../pages/readerpage/chapter/ChapterReader.css";

// let page = response.data[0].pages[i];
//             images.push(
//               `https://d24hdgytax3x03.cloudfront.net/${bookId}/pages/${page.contentImageLink}`
//             );
const ReaderDemo = (props) => {
  const { bookId } = useParams();

  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    dispatch({ type: "ENTER_READER" });
    // get the title of the tab / window so that when we navigate away we can restore it
    const oldTitle = document.title;

    // restore the old title
    return () => {
      document.title = oldTitle;
      dispatch({ type: "EXIT_READER" });
    };
  }, []);

  return Number(bookId) === 535 ||
    Number(bookId) === 538 ||
    Number(bookId) === 146 ? (
    <>
      <div className="header-and-reader">
        <iframe
          title="reader"
          allow="fullscreen"
          allowFullScreen
          src={`${process.env.REACT_APP_READER_URL}/?mode=testing&book=${bookId}&tutorial=true`}
        />
      </div>
    </>
  ) : (
    <Redirect to={{ pathname: "/pagenotfound" }} />
  );
};

export default ReaderDemo;
