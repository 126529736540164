import React, { useState, useEffect, useRef } from "react";
import { MdCheck } from "react-icons/md";
import WarningIcon from "../../../components/micro_components/WarningIcon";
import "./UnderlinedTextInput.css";

import PasswordTooltip from "../../micro_components/PasswordTooltip";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { BsInfoCircle } from "react-icons/bs";
import { useHistory } from "react-router-dom";

const UnderlinedTextInput = ({
  label,
  value,
  onValueChange,
  type,
  placeholder,
  func,
  autoFocus,
  tooltip,
  enableWarningIcon,
  showCheck,
  showWarning,
  warningMessage,
  passwordLengthValid,
  passwordCaseValid,
  passwordSpecialValid,
  displayForgotPassword,
  containerStyle,
  disabled,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [textType, setTextType] = useState(type || "text");
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const [warningEnabled, setWarningEnabled] = useState(false);

  const [warningTooltipVisible, setWarningTooltipVisible] = useState(false);

  const ref = useRef();

  const history = useHistory();

  const togglePasswordIcon = () => {
    setTextType(passwordVisible ? "password" : "text");
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    if (autoFocus) ref.current.focus();
  }, []);

  return (
    <div className={`label-input-container ${disabled ? "disabled" : ""}`} style={{ ...containerStyle }}>
      <div className="field-label text3">
        <span style={{ marginRight: label === "Password" ? "0.5rem" : 0 }}>{label}</span>
        {tooltip && (
          <div style={{ position: "relative" }}>
            <BsInfoCircle
              stroke="var(--light-gray)"
              onMouseEnter={() => {
                setTooltipVisible(true);
              }}
              onMouseLeave={() => {
                setTooltipVisible(false);
              }}
            />
            <PasswordTooltip
              visible={tooltipVisible}
              passwordLengthValid={passwordLengthValid}
              passwordCaseValid={passwordCaseValid}
              passwordSpecialValid={passwordSpecialValid}
            />
          </div>
        )}
        {displayForgotPassword && (
          <span
            className="forgot-password-click text2"
            onClick={() => {
              history.push("/forgotpassword");
            }}
          >
            Forgot password?
          </span>
        )}
      </div>
      <div className="input-container" id={label}>
        {/* <MdEmail className="form-icon" size={20} style={{}} /> */}
        <input
          ref={ref}
          className={`ut-form-input ${warningEnabled && showWarning ? "error" : ""}`}
          value={value}
          placeholder={placeholder}
          onChange={(e) => {
            onValueChange(e);
          }}
          onBlur={() => setWarningEnabled(true)}
          type={textType}
          onKeyDown={(e) => {
            if (e.key === "Enter") func();
          }}
          disabled={disabled}
        />
        {type === "password" &&
          (!passwordVisible ? (
            <AiFillEyeInvisible className={"password-icon"} size={20} onClick={togglePasswordIcon} />
          ) : (
            <AiFillEye className="password-icon" size={20} onClick={togglePasswordIcon} />
          ))}
        {enableWarningIcon && warningEnabled && (
          <div
            style={{ position: "relative", height: "1.5rem" }}
            onMouseEnter={() => {
              setWarningTooltipVisible(true);
            }}
            onMouseLeave={() => {
              setWarningTooltipVisible(false);
            }}
          >
            <WarningIcon
              label={warningMessage}
              position="left"
              absolute
              tooltipHeight="2rem"
              left="calc(100% + 5px"
              visible={showWarning}
              tooltipVisible={warningTooltipVisible}
              disableTooltip={!warningMessage}
            />
          </div>
        )}
        <div className={`signup-password-check ${showCheck ? "visible" : ""}`}>
          <MdCheck fill="#00b800" size={25} />
        </div>
      </div>
      {/* <span className="input-border" /> */}
    </div>
  );
};

export default UnderlinedTextInput;
