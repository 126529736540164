import React, { useState, useEffect, useLayoutEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import ArtistServices from "../../../services/ArtistService";
import FeaturedHomeArtistSlider from "./FeaturedHomeArtistSlider";
import ColoredButton from "../../../components/buttons/ColoredButton";
import { useHistory } from "react-router";

import "./featuredHome.css";
import ArtistService from "../../../services/ArtistService";

const Featured = () => {
  const [artistData, setArtistData] = useState([]);
  const [featuredArtists, setFeaturedArtists] = useState([]);
  const [loadingContent, setLoadingContent] = useState(true);

  const history = useHistory();

  useLayoutEffect(() => {
    ArtistServices.getArtsitByLastName().then((response) => {
      setArtistData(response.data);
    });
  }, []);

  useEffect(() => {
    const fetchFeaturedArtists = () => {
      ArtistService.getFeaturedArtists().then((response) => {
        setFeaturedArtists(response.data);
      });
    };

    fetchFeaturedArtists();
  }, [artistData]);

  const [loadingStatus, setLoadingStatus] = useState({
    // image: false,
    desc: false,
    books: false,
  }); //need 3

  useEffect(() => {
    if (loadingStatus.books && loadingStatus.desc) {
      setLoadingContent(false);
      setLoadingStatus((s) => ({ ...s, desc: true }));
    }
  }, [loadingStatus]);

  const navigateToArtistCatalog = () => {
    history.push({
      pathname: "/artistcatalog",
    });
  };

  return (
    //still need to add loading for featured artists and books
    <div className="featured-container">
      <h2>Featured Artists</h2>
      <h3>Learn from the best</h3>
      <Swiper
        className="featured-artist-home-slide"
        style={{
          minHeight: "300px",
          width: "100%",
          maxWidth: "1400px",
          height: "100%",
        }}
        slidesPerView={1}
        loop={true}
        threshold={10}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
      >
        {featuredArtists
          ? featuredArtists.map((artist, index) => {
              return (
                <SwiperSlide
                  key={index}
                  style={{
                    height: "100%",
                    minHeight: "300px",
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    padding: "25px",
                  }}
                >
                  <FeaturedHomeArtistSlider artist={artist} />
                </SwiperSlide>
              );
            })
          : ""}
      </Swiper>
      <ColoredButton
        text="browse all artists"
        onClick={navigateToArtistCatalog}
      />
    </div>
  );
};

export default Featured;
