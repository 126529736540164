import React from "react";
import { Link } from "react-router-dom";
import ColoredButton from "../../../components/buttons/ColoredButton";

const Membership = () => {
  return (
    <div style={{ width: "100vw" }}>
      <div className="screen-l">
        <div className="membership-container">
          <div className="membership-benefits">
            <div className="membership-header">
              <h2>Start your free trial today!</h2>
              <hr />
            </div>
            <div className="membership-body">
              <ul>
                <li>Learn from the world's top guitarists</li>
                <li>Build and expand your guitar repertoire</li>
                <li>Explore different guitar styles from around the world</li>
                <li>Access an expanding library of contents</li>
                <li>Enhance your practice!</li>
              </ul>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className="membership-item">
              <div className="header">
                <h3>Monthly</h3>
                <hr />
              </div>
              <div className="membership-item__body">
                <strong style={{ color: "green" }}>7-day free trial, then</strong>
                <h1>$6.99</h1>
                <p>per month</p>
              </div>
              <div className="footer">
                <Link to={{ pathname: "/login", fromReg: true }}>
                  <ColoredButton
                    text="LET'S GIVE IT A&nbsp;SHOT"
                    style={{
                      borderRadius: "10px",
                      padding: "5px 25px",
                      margin: "5px auto",
                      minWidth: "230px",
                      maxWidth: "unset",
                      width: "fit-content",
                      fontSize: "1rem",
                      height: "4rem",
                    }}
                  />
                </Link>
              </div>
            </div>
            <div className="membership-item">
              <div className="header">
                <h3>Annual</h3>
                <hr />
              </div>
              <div className="membership-item__body">
                <div>
                  <h3 className="strikethrough">$83.88</h3>
                  <h1>$59.99</h1>
                </div>
                <p style={{ marginBottom: "0.5em" }}>per year</p>
                <p style={{ color: "#aaa" }}>($4.99 / month, billed annually)</p>
              </div>
              <div className="footer">
                <Link to={{ pathname: "/login", fromReg: true }}>
                  <ColoredButton
                    text="I'M IN IT FOR THE&nbsp;LONG&nbsp;HAUL"
                    style={{
                      borderRadius: "10px",
                      margin: "5px auto",
                      width: "fit-content",
                      minWidth: "230px",
                      maxWidth: "unset",
                      fontSize: "1rem",
                      height: "4rem",
                      padding: ".5rem 1rem",
                    }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="screen-m screen-s">
        <div className="membership-container">
          <div className="membership-benefits">
            <div className="membership-header">
              <h2>Start your membership&nbsp;today!</h2>
              <hr />
            </div>
            <div className="membership-body">
              <ul>
                <li>Learn from the world's top guitarists</li>
                <li>Build and expand your guitar repertoire</li>
                <li>Explore different guitar styles from around the world</li>
                <li>Access an expanding library of contents</li>
                <li>Enhance your practice!</li>
              </ul>
            </div>
          </div>
          <h3>Monthly</h3>
          <div className="membership-item">
            <div className="body">
              <h1>$6.99</h1>
              <span>per month</span>
              <Link to={{ pathname: "/login", fromReg: true }}>
                <ColoredButton
                  text="CHOOSE"
                  style={{
                    height: "100%",
                  }}
                />
              </Link>
            </div>
          </div>
          <h3>Annual</h3>
          <div className="membership-item">
            <div className="body">
              <div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "auto",
                      maxWidth: "fit-content",
                    }}
                  >
                    <span className="strikethrough">$83.88</span>
                  </div>
                  <h1>$59.99</h1>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "0.5em" }}>per year</span>
                <span className="hide-on-tiny-screen" style={{ color: "#aaa" }}>
                  ($4.99/month,
                  <br />
                  billed annually)
                </span>
              </div>
              <Link to={{ pathname: "/login", fromReg: true }}>
                <ColoredButton
                  text="CHOOSE"
                  style={{
                    height: "100%",
                  }}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Membership;
