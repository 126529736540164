import React, { useState, useRef, useEffect, useCallback } from "react";

import { useHistory } from "react-router-dom";

import "./bookcard.css";

import BookCardMinimal from "./BookCardMinimal";
import BookCardFull from "./BookCardFull";

function BookCard({
  bookId,
  image,
  title,
  roles,
  tags,
  video,
  likedBooks,
  resetLikedBooks,
  openBanner,
}) {
  const [fadeInOpacity, setFadeInOpacity] = useState(0);
  const [fadeOutOpacity, setFadeOutOpacity] = useState(0);
  const [isLiked, setIsLiked] = useState(false);

  const scrollRef = useRef();
  const cardRef = useRef();
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 601);

  const updateMedia = () => {
    setIsMobileScreen(window.innerWidth < 601);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  let oldX;

  const history = useHistory();

  useEffect(() => {
    for (const book of likedBooks) {
      if (book.id === bookId) {
        setIsLiked(true);
        break;
      }
    }
  }, [bookId]);

  useEffect(() => {
    if (
      scrollRef.current &&
      scrollRef.current.clientWidth < scrollRef.current.scrollWidth
    ) {
      setFadeOutOpacity(1);
    }
  }, []);

  return (
    <div ref={cardRef} className="book-card-main-container">
      <BookCardFull
        bookId={bookId}
        image={image}
        title={title}
        roles={roles}
        tags={tags}
        video={video}
        likedBooks={likedBooks}
        resetLikedBooks={resetLikedBooks}
        openBanner={openBanner}
      />
    </div>
  );
}

export default BookCard;
