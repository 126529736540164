import React from "react";
import ColoredButton from "../../../components/buttons/ColoredButton";

import "./LinkSent.css";

import { IoIosArrowBack } from "react-icons/io";
import { useHistory } from "react-router-dom";

const FP_LinkSent = ({ email, setStage }) => {
  const history = useHistory();

  const signIn = () => {
    history.push("/login");
  };

  return (
    <div className="link-sent-container">
      <div className="link-sent__header">
        {/* <div
          className="link-sent__back-button"
          onClick={() => setStage("ENTER_EMAIL")}
        >
          <IoIosArrowBack size={30} />
        </div> */}
      </div>
      <div className="link-sent__message">
        An email to reset your password has been sent to{" "}
        <span className="link-sent__email">{email || "[email_address]"}</span>
      </div>
      <div className="link-sent__button">
        <ColoredButton text={"Sign In"} onClick={signIn} />
      </div>
    </div>
  );
};

export default FP_LinkSent;
