import React, { useState, useEffect, useRef } from 'react';
import StripeService from '../../services/stripe/StripeService';
import { toast } from "react-toastify";
import gsap from 'gsap';
import { useLocation, useHistory } from "react-router-dom";
import TokenService from '../../services/auth/TokenService';

const GiftCodeRedeem = () => {
    const [code, setCode] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const history = useHistory();
    const messageRef = useRef(null);
    const errorRef = useRef(null);
    let query = new URLSearchParams(useLocation().search);
    let giftCode = query.get("giftCode");
    useEffect(() => {
        if (giftCode) { setCode(giftCode) }
    }, [giftCode]);
    useEffect(() => {
        if (message) {
            gsap.to(messageRef.current, { opacity: 1, y: 150, duration: 0.5 });
        }
        if (error) {
            gsap.to(errorRef.current, { opacity: 1, y: 100, duration: 0.5 });
        }
    }, [message, error]);

    const handleInputChange = (e) => {
        setCode(e.target.value);
    };

    const handleRedeem = async (e) => {
        e.preventDefault();
        try {
            const checkResponse = await checkDiscountCode(code);

            if (checkResponse.message) {

                const redeemResponse = await redeemCode(code);
                setMessage(redeemResponse.message);
            } else {
                setMessage('Discount code is invalid.');
            }
        } catch (error) {
            console.log(error);
            setError(error.message);
        }
    };

    const redeemCode = async (code) => {
        try {
            const response = await StripeService.redeemGiftSubscription(code);
            toast.success(`Discount has been successfully applied to your account`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return response;
        } catch (error) {

            throw new Error('Failed to redeem gift code');
        }
    };

    const checkDiscountCode = async (code) => {
        try {
            const response = await StripeService.getDiscountStatus(code);
            const isValid = response.status === 200;
            const { name, duration, percentOff, durationInMonths } = response.data;

            return { isValid, message: `Discount code ${name} is valid. ${percentOff}% off for ${durationInMonths} months.` };
        } catch (error) {
            return { isValid: false };
        }
    }
    const redirectToLogin = () => {
        console.log("pathname from")
        history.push({
            pathname: `/login`,
            state: { from: history.location.pathname + `?giftCode=${code}` }
        });

    };
    return (
        <div className='redeem-code-wrapper'>
            <form className='redeem-code-container'>
                <input
                    type="text"
                    value={code}
                    onChange={handleInputChange}
                    placeholder="Enter gift code"
                />
                {TokenService.getUser() ? (<button type="submit" onClick={handleRedeem} className='redeem-code-container__button'>Redeem</button>) : (<button onClick={redirectToLogin} className='redeem-code-container__button'>Login First!</button>)}
            </form>
            {message && <p className='redeem-message' ref={messageRef}>{message}</p>}
            {error && <p className='redeem-error' ref={errorRef}>{error}</p>}
        </div>
    );
};

export default GiftCodeRedeem;
