import React, { useState, useEffect, useContext } from "react";
import { AccountPageButton } from "./AccountPageItemContainer";
import { Context } from "../../../components/auth/state/Store";
import ProgressBar from "../../../components/micro_components/ProgressBar";

import { BsInfoCircle } from "react-icons/bs";

import { ClipLoader } from "react-spinners";
import { css } from "@emotion/react";

// import { viewHistory } from "./AccountPageFunctions";
import { capitalizeFirstLetter, timeConverter, centsToString, daysUntil } from "../../../helpers/FormatFunctions";
import PaymentHistoryModal from "./PaymentHistoryModal";
import YesNoModal from "../../../components/loading/YesNoModal";
import UserService from "../../../services/UserService";
import TokenService from "../../../services/auth/TokenService";
import LargeModal from "../../../components/modal/LargeModal";
import TransactionHistoryModal from "./TransactionHistoryModal";
import UpgradeSubscriptionModal from "./UpgradeSubscriptionModal";

const AccountPageEmail = ({ email }) => {
  return (
    <div className="account-page__item-email">
      <div className="account-page-item__title">Account Details</div>
      <hr className={"account-page-item__hr"} />
      <div className="account-page-item__field">
        <div className="account-page-item__field-label">Email</div>
        <div className="account-page-item__field-value">{email}</div>
      </div>
    </div>
  );
};

const AccountPageSubscriptionDetails = ({
  tier,
  status,
  startDate,
  startDateUNIX,
  cancelDate,
  lastDay,
  trial,
  cancelAt,
  discount,
  pausedSubscription,
  upcomingInvoiceAmount,
  upcomingChargeDate, upcomingChargeAmount
}) => {
  // console.log(startDate);

  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [state, dispatch] = useContext(Context);

  const openUpgradeModal = () => {
    setUpgradeModalOpen(true);
    dispatch({ type: "DIMMER_ON" });
  };


  const calculateDaysOfSubLeft = (cancelDate, cancelAt) => {
    if (cancelAt >= cancelDate) {
      return cancelAt;
    } else {
      return cancelDate;
    }
  };


  const checkDiscountStartEnd = (discount) => {
    if (discount) {
      if (discount.start <= Date.now() / 1000 && discount.end >= Date.now() / 1000) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  const renderTrialmessage = () => {
    if (checkDiscountStartEnd(discount)) {
      return <>
        <span>

          Your discount code will be active from <strong>{timeConverter(discount.start * 1000, false)} </strong>
          to <strong>{timeConverter(discount.end * 1000, false)}</strong>.
          During this period, your upcoming charge will be ${upcomingInvoiceAmount === 0 || upcomingInvoiceAmount > 0 ? centsToString(upcomingInvoiceAmount) : centsToString(upcomingChargeAmount)}
        </span></>
    } else {
      <span>
        Your free trial will end on <strong>{timeConverter(lastDay * 1000, false)}</strong>. After that, your
        credit card will be charged {centsToString(upcomingChargeAmount)} and your monthly plan will begin.</span>
    }
  }
  return (
    <>
      <div className="account-page__item-container">
        <div className="account-page-item__title">Subscription Details</div>




        <hr className={"account-page-item__hr"} />

        {/* WHAT TIER? */}
        <div className="account-page-item__field">
          <div className="account-page-item__field-label">Tier</div>
          <div className="account-page-item__field-value" id="sub-tier">
            <span>{tier}</span>
            {tier === "Monthly" && (
              <div className="subscription-upgrade-option text3">
                Want to upgrade?{" "}
                <a href="/contact" style={{ color: "var(--primary-color)" }}>
                  Contact Us
                </a>
              </div>
            )}
          </div>
        </div>

        <hr className={"account-page-field__hr"} />

        {/* IS IT ACTIVE? (until?) */}
        <div className="account-page-item__field">
          <div className="account-page-item__field-label">Status</div>
          <div className="account-page-item__field-value ">
            <div className={`account-page-status ${cancelDate ? "cancelled" : ""}`}>
              {status && checkDiscountStartEnd(discount) ? "Active with Discount" : status === "Active" ? "Active" : "Free Trial"}
              {status === "Free Trial" && !checkDiscountStartEnd(discount) && (
                <span>
                  {" - "}
                  {daysUntil(Date.now(), upcomingChargeDate)}
                  {daysUntil(Date.now(), upcomingChargeDate) !== 1 ? " days " : " day "}
                  left
                </span>
              )}
            </div>
            <div className={`account-page-status-desc ${cancelDate ? "cancelled" : ""}`}>
              {cancelDate && (
                <span>
                  You can still take advantage of your membership until {timeConverter(calculateDaysOfSubLeft(cancelDate, cancelAt) * 1000)}, however there
                  will be no further charges to your payment method. <br />
                  <br />
                </span>
              )}
              {(status === "Active" || status === "Free Trial") && !cancelDate && (
                renderTrialmessage()
              )}

              {status === "Free Trial" && cancelDate && (
                <span>
                  {`Your free trial will end on ${timeConverter(calculateDaysOfSubLeft(cancelDate, cancelAt) * 1000)}, and since you canceled
                  your upcoming subscription, your credit card will not be
                  charged. Your access to our content will also end on that
                  date.`}
                </span>
              )}
            </div>

            {cancelDate && (
              <div className="text3">
                Need to reactivate?{" "}
                <a href="/contact" style={{ color: "var(--primary-color)" }}>
                  Contact Us
                </a>
              </div>
            )}
            {/* 6.048e5 is the milliseconds in 7 days */}
            {status === "Free Trial" && (
              <ProgressBar
                min={startDateUNIX + 6.048e5}
                max={startDateUNIX}
                current={Math.floor(Date.now() / 1000)}
                showDateLabels
              />
            )}
          </div>

        </div>
        {pausedSubscription && (<div className="account-page-item__field">
          <div className="account-page-item__field-label">Paused Subscription</div>
          <div className="account-page-item__field-value ">
            <div className={``}>
              {/* {pausedSubscription ? "Yes" : "No"} */}
              <span className="`account-page-status-desc">

                Your PayPal subscription has been temporarily paused as you've received a gifted Stripe subscription. Enjoy the benefits! We'll resume your PayPal subscription automatically once the gifted period ends.
                <br />
                <br />
              </span>
            </div>



          </div>
        </div>)}
        <hr className={"account-page-field__hr"} />
        {/* WHEN DID IT START */}
        <div className="account-page-item__field">
          <div className="account-page-item__field-label">Created on</div>
          <div className="account-page-item__field-value">{startDate}</div>
        </div>
        <hr className={"account-page-field__hr"} />

        {/* <div className="account-page-item__field">
          <AccountPageButton
            text="Upgrade Subscription"
            onClick={openUpgradeModal}
          />
        </div> */}
        {!cancelDate && (
          <AccountPageCancelSubscription activeUntil={upcomingChargeDate} cancelAt={cancelAt} />
        )}
      </div>
      {upgradeModalOpen && (
        <LargeModal
          isOpen={upgradeModalOpen}
          content={<UpgradeSubscriptionModal />}
          onX={() => setUpgradeModalOpen(false)}
        />
      )}
    </>
  );
};

const DiscountDetails = ({ discount }) => {
  return (<div className="account-page__item-container">
    {/* DISCOUNT */}
    <div className="flex-spacebetween ">
      <div className="account-page-item__title ">Discount</div> </div>
    <hr className={"account-page-item__hr"} />

    <div className="account-page-item__field">
      <div className="account-page-item__field-label">Promotion Code ID</div>
      <div className="account-page-item__field-value">{discount.promotionCode}</div>
    </div>
    <hr className={"account-page-field__hr"} />

    <div className="account-page-item__field">
      <div className="account-page-item__field-label">Discount Start Date</div>
      <div className="account-page-item__field-value">{timeConverter(discount.start * 1000)}</div>
    </div>
    <hr className={"account-page-field__hr"} />

    <div className="account-page-item__field">
      <div className="account-page-item__field-label">Discount End Date</div>
      <div className="account-page-item__field-value">{timeConverter(discount.end * 1000)}</div>
    </div>
    <hr className={"account-page-field__hr"} />

    <div className="account-page-item__field">
      <div className="account-page-item__field-label">Discount ID</div>
      <div className="account-page-item__field-value">{discount.id}</div>
    </div>
    <hr className={"account-page-field__hr"} />

    <div className="account-page-item__field">
      <div className="account-page-item__field-label">Linked Subscription ID</div>
      <div className="account-page-item__field-value">{discount.subscription}</div>
    </div></div>)
}
const AccountPageCancelSubscription = ({ activeUntil, cancelAt }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [error, setError] = useState(false);

  const cancelSubscription = () => {
    setCancelling(true);
    UserService.cancelSubscription(TokenService.getUser().email, TokenService.getUser().userToken)
      .then((response) => {
        setCancelled(true);
        setCancelling(false);
      })
      .catch((e) => {
        // console.log("error cnacelling subscription: ", e);
        setCancelling(false);
        setError(true);
      });
  };

  return (
    <>
      <div className="">
        <div className="account-page__button-label-container">
          {" "}
          {cancelAt ? <><div className="cancel-subscription">You subscription will automatically be canceled on  {timeConverter(cancelAt * 1000)}</div>
          </> : (<><div className="account-page-item__title cancel-subscription">Cancel Your Subscription</div>
            <AccountPageButton
              text="Cancel"
              onClick={() => {
                setModalOpen(true);
              }}
            /></>)}
        </div>
      </div>
      <YesNoModal
        isOpen={modalOpen}
        onYes={cancelSubscription}
        onNo={() => setModalOpen(false)}
        onClose={() => {
          setModalOpen(false);
          setCancelling(false);
        }}
        heading={"Cancel Subscription"}
        prompt={"Are your sure you want to cancel your subscription?"}
        errorMessage={"There was an error cancelling your subscription."}
        subErrorMessage={"Please contact support@guitarbymasters.com so our team can help cancel your subscription."}
        success={cancelled}
        successMessage={"Canceled Subscription Successfully!"}
        // subSuccessMessage={`Your account will still be active until ${ timeConverter(
        //   activeUntil * 1000
        // )}. No further payments will be processed.`}
        buttonMessage={"Close"}
        isLoading={cancelling}
        error={error}
      />
    </>
  );
};

const AccountPageBillingInfo = ({
  cardType,
  lastFour,
  upcomingChargeDate,
  upcomingChargeAmount,
  cancelled,
  pastItems,
  trial,
  startDate,
  upcomingInvoiceAmount,
  latestInvoice,
  tier,
}) => {
  const [historyModalOpen, setHistoryModalOpen] = useState(false);

  const openPaymentHistoryModal = () => {
    setHistoryModalOpen(true);
  };
  useEffect(() => {
    console.log("latest invoice: ", latestInvoice)
    console.log("upcoming invoice amount: ", upcomingInvoiceAmount)
  }, [latestInvoice]);
  return (
    <>
      <div className="account-page__item-container">
        <div className="flex-spacebetween ">
          <div className="account-page-item__title ">Billing Information</div> </div>
        <hr className={"account-page-item__hr"} />

        {/* NEXT DUE AND AMOUNT */}
        <div className="account-page-item__field">

          <div className="account-page-item__field-container">      <div className="account-page-item__field-label">Upcoming Invoice</div>     <div className="th-item__amount fw-bold">

          </div> </div>

          {cancelled ? (
            <div className="upcoming-charge__date">No Remaining Charges</div>
          ) : (
            <div className="account-page-item__field-value">
              <div className="account-page__upcoming-charge">
                <div className="upcoming-charge__amount">${upcomingInvoiceAmount === 0 || upcomingInvoiceAmount > 0 ? centsToString(upcomingInvoiceAmount) : centsToString(upcomingChargeAmount)}</div>
                <div className="upcoming-charge__date">
                  <span>{timeConverter(upcomingChargeDate * 1000, tier === "monthly" ? true : false)}</span>
                  <span className="upcoming-charge__days-until text3">
                    In {daysUntil(Date.now(), upcomingChargeDate)}{" "}
                    {daysUntil(Date.now(), upcomingChargeDate) !== 1 ? "days" : "day"}
                  </span>
                  <ProgressBar
                    min={upcomingChargeDate}
                    max={trial ? startDate : pastItems ? pastItems[0].date : 2.592e9}
                    current={Math.floor(Date.now() / 1000)}
                    showDateLabels
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <hr className={"account-page-field__hr"} />

        {/* CARD TYPE AND NUM */}
        <div className="account-page-item__field">
          <div className="account-page-item__field-label" >Payment Method</div>
          <div className="account-page-item__field-value ">
            <span className="text3" style={{ marginRight: "0.5rem" }}>
              {cardType ? capitalizeFirstLetter(cardType) : ""}
            </span>
            {cardType === "amex"
              ? `xxxx-xxxxxx-x${lastFour}`
              : cardType === "Paypal"
                ? ""
                : `xxxx-xxxx-xxxx-${lastFour ? lastFour : ""}`}
          </div>
        </div>
        <hr className={"account-page-field__hr"} />
        {latestInvoice && (<div className=" accountpage-latest-invoice ">      <div className="account-page-item__field-label">Latest Invoice</div>     <div className="th-item__amount fw-bold">
          <a href={latestInvoice} target="_blank" rel="noreferrer" className="link">View</a>
        </div> </div>)}
        <hr className={"account-page-field__hr"} />
        {/* HISTORY */}
        {!trial && (
          <div className="account-page-item__field">
            <AccountPageButton text="view payment history" onClick={openPaymentHistoryModal} />
          </div>
        )}

      </div>

      {historyModalOpen && (
        <LargeModal
          isOpen={historyModalOpen}
          title={"Transaction History"}
          subtitle={"All of your transactions with Guitar by Masters."}
          content={<TransactionHistoryModal pastItems={pastItems} cardType={cardType} />}
          onX={() => setHistoryModalOpen(false)}
        />
      )}
    </>
  );
};

const AccountPageNewsletter = ({ isSubscribed }) => {
  const [subscribed, setSubscribed] = useState(isSubscribed);
  const [loading, setLoading] = useState(false);

  const subscribeToNewsletter = () => {
    if (!subscribed) {
      setLoading(true);
      UserService.subscribeToNewsletter(TokenService.getUser().id)
        .then((r) => {
          setSubscribed(true);
          setLoading(false);
          // console.log("subscribed to newsletter: ", r.data);
        })
        .catch((e) => {
          console.log("error subscribing to newsletter");
        });
    }
  };

  const unsubscribeToNewsletter = () => {
    if (subscribed) {
      setLoading(true);
      UserService.unsubscribeToNewsletter(TokenService.getUser().id)
        .then(() => {
          setLoading(false);
          setSubscribed(false);
          // console.log("un-subscribed to newsletter");
        })
        .catch((e) => {
          console.log("error un-subscribing to newsletter");
        });
    }
  };

  const override = css`
      display: block;
      margin: 0 auto;
      `;

  return (
    <>
      <div className="account-page__item-container-cancel-newsletter">
        {loading ? (
          <ClipLoader color={"#fff"} loading={loading} css={override} size={20} />
        ) : (
          <div className="account-page__button-label-container">
            {" "}
            {subscribed ? (
              <>
                <div className="account-page-item__title cancel-subscription">Unsubscribe from our Newsletter</div>
                <AccountPageButton text="Unsubscribe" onClick={unsubscribeToNewsletter} />
              </>
            ) : (
              <>
                <div className="account-page-item__title cancel-subscription">
                  Subscribe to Guitar by Masters Newsletter
                </div>
                <AccountPageButton text="Subscribe" onClick={subscribeToNewsletter} />
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export {
  DiscountDetails,
  AccountPageEmail,
  AccountPageSubscriptionDetails,
  AccountPageCancelSubscription,
  AccountPageBillingInfo,
  AccountPageNewsletter,
};
