import React from "react";
import { useEffect } from "react";

function TextRef(props) {
  const phrase = "The quick brown fox jumps over the lazy dog.";

  useEffect(() => {
    console.log("textref page");
  }, []);

  return (
    <div style={{ marginLeft: "1rem" }}>
      <h1>&lt;h1&gt; {phrase}</h1>
      <hr style={{ border: "none", height: "5px" }} />
      <h2>&lt;h2&gt; {phrase}</h2>
      <hr style={{ border: "none", height: "5px" }} />
      <h3>&lt;h3&gt; {phrase}</h3>
      <hr style={{ border: "none", height: "5px" }} />
      <div className="label1">.label1: {phrase}</div>
      <hr style={{ border: "none", height: "5px" }} />
      <div className="label2">.label2: {phrase}</div>
      <hr style={{ border: "none", height: "5px" }} />
      <div className="text1">.text1: {phrase}</div>
      <hr style={{ border: "none", height: "5px" }} />
      <div className="text2">.text2: {phrase}</div>
      <hr style={{ border: "none", height: "5px" }} />
      <div className="text3">.text3: {phrase}</div>
      <hr style={{ border: "none", height: "5px" }} />
    </div>
  );
}

export default TextRef;
