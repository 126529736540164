import React from "react";
import { ReactComponent as Icon } from "../../assets/warning.svg";
import Tooltip from "./Tooltip";

import "./styles/WarningIcon.css";

function WarningIcon({
  label,
  width = "1.5rem",
  height = "1.5rem",
  tooltipHeight,
  position = "above",
  absolute,
  top,
  right,
  bottom,
  left,
  visible = true,
  tooltipVisible,
  disableTooltip = false,
}) {
  const style = {
    width: width,
    height: height,
    position: absolute ? "absolute" : "",
    top: top ? top : "",
    right: right ? right : "",
    bottom: bottom ? bottom : "",
    left: left ? left : "",
  };

  return (
    <div className="warning-icon-container" style={style}>
      <div style={{ position: "relative", height: "100%" }}>
        <Icon
          fill="#f03a3a"
          style={{
            visibility: visible ? "visible" : "hidden",
          }}
        />
        {!disableTooltip && (
          <Tooltip
            backgroundColor={"#e03232"}
            label={label}
            height={tooltipHeight}
            visible={tooltipVisible}
            opacity={1}
            position={position}
          />
        )}
      </div>
    </div>
  );
}

export default WarningIcon;
