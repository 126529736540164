import React from "react";
import { useSwiper } from "swiper/react";
import { ReactComponent as PrevSlide } from "../../../../assets/prevSlide.svg";
import { ReactComponent as NextSlide } from "../../../../assets/nextSlide.svg";

function SwiperNavButton({ type }) {
  const swiper = useSwiper();

  return (
    <>
      {type === "previous" && (
        <div
          className="swiper-nav-button"
          id="prev"
          onClick={() => {
            swiper.slidePrev();
          }}
        >
          <PrevSlide stroke="#fff" strokeWidth={50} />
        </div>
      )}
      {type === "next" && (
        <div
          className="swiper-nav-button"
          id="next"
          onClick={() => {
            swiper.slideNext();
          }}
        >
          <NextSlide stroke="#fff" strokeWidth={50} />
        </div>
      )}
    </>
  );
}

export default SwiperNavButton;
