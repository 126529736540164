import React from "react";

// CONTAINER AND LABEL
const AccountPageItemContainer = (props) => {
  return <div></div>;
};

const AccountPageContainerLabel = () => {
  return <div></div>;
};

const AccountPageItemLabel = () => {
  return <div></div>;
};

const AccountPageButton = ({ text, onClick }) => {
  return (
    <div className="account-page__button" onClick={onClick}>
      {text}
    </div>
  );
};

export { AccountPageItemContainer, AccountPageButton };
