import "./App.css";
import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Context } from "./components/auth/state/Store";
import { NotificationBarProvider } from "./context/NotificationBarContext.jsx";
import Navbar from "./components/navigation/Navbar";
import BookCatalog from "./pages/catalog/books/BookCatalog";
import Contact from "./pages/contactpage/Contact";
import ArtistCatalog from "./pages/catalog/artists/ArtistCatalog";
import Home from "./pages/homepage/Home";
import UserHome from "./pages/homepage/UserHome";
import Footer from "./components/navigation/Footer";
import Privacy from "./pages/privacypage/Privacy";
import Terms from "./pages/termspage/Terms";
import BookProfile from "./pages/catalog/books/BookProfile";
import ArtistProfile from "./pages/catalog/artists/ArtistProfile";
// import Refund from './pages/refundpage/Refund';
import Compliment from "./pages/complimentpage/Compliment";
import AboutUs from "./pages/aboutpage/AboutUs";
import ScrollToTop from "./helpers/ScrollToTop";
import ChapterReader from "./pages/readerpage/chapter/ChapterReader";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import ReaderDemo from "./components/reader-demo/ReaderDemo";
import Patentpage from "./pages/patentpage/Patentpage";
import Search from "./pages/search/search.jsx";
import Peppino from "./pages/misc/Peppino";
import NotificationBar from "./components/navigation/NotificationBar";
import LoginRegisterPage from "./pages/loginRegisterPage/LoginRegisterPage";
import RestrictedComponent from "./components/auth/RestrictedComponent";
import ChooseSubscription from "./pages/loginRegisterPage/chooseSubscription/ChooseSubscription";
import { useHistory } from "react-router-dom";

import TokenService from "./services/auth/TokenService";
import UserSideMenu from "./components/navigation/UserSideMenu";
import GiftComplete from "./pages/giftSubscription/GiftComplete.jsx";
// Logged in state
import ForgotPasswordParent from "./pages/forgotpasswordpage/ForgotPasswordParent";
import AccountPageV2 from "./pages/accountpage/AccountPageV2";

import "./styles/animations.css";
import "./styles/fonts.css";
import TextRef from "./pages/textrefPage/TextRef";
import MobileMenu from "./components/navigation/MobileMenu";
import NewsletterPopup from "./pages/homepage/homepageComponents/NewsletterPopup";
import GoogleCallback from "./components/auth/oauth2/google/GoogleCallback";
import FacebookCallback from "./components/auth/oauth2/facebook/FacebookCallback";
import GiftCenterComponent from "./components/giftSubscription/GiftCenterComponent";
// import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import AdLandingPage from "./pages/adpage/AdLandingPage";
import ThankYou from "./pages/thankyouPage/ThankYou";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

function App() {
  const [isPageOnReader, setIsPageOnReader] = useState(false);
  const [newsletterEnabled, setNewsletterEnabled] = useState(true);
  const [currentCategoryName, setCurrentCategoryName] = useState();
  const [visible, setVisible] = useState(false); //Newsletter popup

  let history = useHistory();
  const clearCurrentGroup = () => {
    console.log("cleared Current Group");
    setCurrentCategoryName("");
  };

  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    document.title = "Guitar by Masters";
    if (TokenService.getUser()) dispatch({ type: "LOG_IN" });
  }, []);

  return (
    <NotificationBarProvider>
      <div data-tut={state.inReader ? "tour__global" : null} id="app">
        <Router>
          {state.dimmer && (
            <div
              className="global-dimmer"
              onClick={() => {
                dispatch({ type: "DIMMER_OFF" });
              }}
            />
          )}
          <ScrollToTop />
          {!state.inReader && (
            <Route
              to={{ pathname: "/" }}
              render={(props) => (
                <Navbar
                  {...props}
                  currentCategoryName={currentCategoryName}
                  setCurrentCategoryName={setCurrentCategoryName}
                  clearCurrentGroup={clearCurrentGroup}
                />
              )}
            />
          )}
          <div
            className="main-content"
            style={{ marginTop: state.inReader ? 0 : "50px" }}
          >
            {/* {state.loggedIn && !state.inReader && state.showSidebar && (
              <>
                <UserSideMenu />
              </>
            )} */}
            <div className="main-content_container">
              {/* <NotificationBar /> */}
              <div className="main-content__contain-router">
                <Switch>
                  <RestrictedComponent
                    redirectPath="/login"
                    exact
                    path="/accountpage"
                    component={AccountPageV2}
                  />
                  <RestrictedComponent
                    redirectPath="/"
                    exact
                    path="/userhome"
                    component={UserHome}
                  />
                  <RestrictedComponent
                    redirectPath="/login"
                    exact
                    path="/reader/:bookId"
                    component={ChapterReader}
                  />
                  <RestrictedComponent
                    redirectPath="/login"
                    exact
                    path="/reader/book/:bookId/chapter/:chapterId"
                    component={ChapterReader}
                    setIsPageOnReader={setIsPageOnReader}
                  />
                  <Route exact path="/" component={Home} />
                  <Route exact path="/contact" component={Contact} />
                  <Route
                    exact
                    path="/artistcatalog"
                    component={ArtistCatalog}
                  />
                  <Route exact path="/privacy" component={Privacy} />
                  <Route exact path="/terms" component={Terms} />
                  <Route
                    exact
                    path="/bookprofile/:bookId"
                    component={BookProfile}
                    setIsPageOnReader={setIsPageOnReader}
                  />
                  <Route
                    exact
                    path="/artistprofile/:artistId"
                    component={ArtistProfile}
                  />
                  {/* <Route exact path="/refund" component={Refund} /> */}
                  <Route exact path="/testimonials" component={Compliment} />
                  <Route exact path="/about" component={AboutUs} />
                  <Route
                    exact
                    path="/forgotpassword"
                    component={ForgotPasswordParent}
                  />
                  <Route exact path="/try-gbm" component={AdLandingPage} />
                  <Route path="/login" component={LoginRegisterPage} />
                  <Route
                    path="/bookcatalog"
                    component={() => (
                      <BookCatalog
                        visible={visible}
                        setVisible={setVisible}
                        newsletterEnabled={newsletterEnabled}
                        setNewsletterEnabled={setNewsletterEnabled}
                      />
                    )}
                  />
                  <Route path="/patent" component={Patentpage} />
                  <Route path="/payment" component={ChooseSubscription} />
                  <Route path="/sample-book/:bookId" component={ReaderDemo} />
                  <Route path="/text-ref" component={TextRef} />
                  <Route
                    path="/auth/callback/google"
                    component={GoogleCallback}
                  />
                  <Route
                    path="/gift/giftCenter"
                    exact
                    component={GiftCenterComponent}
                  />
                  <Route path="/gift/complete" exact component={GiftComplete} />
                  <Route
                    path="/auth/callback/facebook"
                    component={FacebookCallback}
                  />
                  <Route
                    path="/search"
                    render={(props) => (
                      <Search
                        {...props}
                        currentCategoryName={currentCategoryName}
                        setCurrentCategoryName={setCurrentCategoryName}
                      />
                    )}
                  />
                  <Route path="/thankyou" component={ThankYou} />
                  <Route path="/peppino" component={Peppino} />
                  <Route path="*" component={PageNotFound} />
                  <Route path="/pagenotfound" component={PageNotFound} />
                </Switch>
              </div>
            </div>
          </div>
          <MobileMenu />
          {!state.inReader && (
            <Footer
              setNewsletterPopupVisible={() => {
                setNewsletterEnabled(true);
              }}
            />
          )}

          <NewsletterPopup
            newsletterEnabled={newsletterEnabled}
            setNewsletterEnabled={setNewsletterEnabled}
          />
        </Router>

        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </div>{" "}
    </NotificationBarProvider>
  );
}

export default App;
