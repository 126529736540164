import React, { useState, useEffect, useContext } from "react";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import UnderlinedTextInput from "../../../components/auth/underlinedText/UnderlinedTextInput";
import AuthService from "../../../services/auth/AuthService";
import { useHistory } from "react-router-dom";
import { Context } from "../../../components/auth/state/Store";
import CodePage from "../codePage/CodePage";
import TokenService from "../../../services/auth/TokenService";
import ColoredButton from "../../../components/buttons/ColoredButton";

import useLogin from "../../../helpers/customHooks/useLogin";
import OAuthSection from "../../../components/auth/oauth2/oauthsection/OAuthSection";

const Login = ({ setDisplayToggle, setLoading }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailInvalid, setEmailInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState("");

  //mode that will go to if email hasnt been verified
  const [codeMode, setCodeMode] = useState(false);

  const loginFunction = useLogin();

  const history = useHistory();

  // state
  const [state, dispatch] = useContext(Context);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const submit = () => {
    setLoading(true);

    if (checkEmail()) {
      setEmailInvalid(true);
      setInvalidMessage(checkEmail());
      setLoading(false);
      return;
    }
    if (checkPassword()) {
      setPasswordInvalid(true);
      setInvalidMessage(checkPassword());
      setLoading(false);
      return;
    }
    AuthService.login(email, password)
      .then((response) => {
        // console.log("logged in: ", response.data);
        setLoading(false);
        console.log("response.data: ", response.data);

        loginFunction(response.data, password);

      })
      .catch((e) => {
        setLoading(false);
        // console.log("error logging in: ", e.response.data);
        if (
          e.response &&
          e.response.data &&
          e.response.data === "Email not verified, email sent"
        ) {
          // console.log("EMAIL NOT VERIFIED");
          setCodeMode(true);
          setDisplayToggle(false);
        } else {
          //internal error or not found
          //   console.log("EMAIL AND PASSWORD NO MATCH");
          setInvalidMessage("Email and/or password not found.");
        }
        setEmailInvalid(true);
        setPasswordInvalid(true);
        //   console.log("error logging in pp: ", e);
      });
  };

  const checkEmail = () => {
    if (!(email && email.length > 1)) {
      return "Email is required.";
    } else {
      return false;
    }
  };

  const checkPassword = () => {
    if (!(password && password.length > 1)) {
      return "A password is required.";
    } else {
      return false;
    }
  };

  useEffect(() => {
    setEmailInvalid(false);
    setPasswordInvalid(false);
    setInvalidMessage("");
  }, [email, password]);

  return (
    <>
      {!codeMode ? (
        <>
          <div className="login-signup-form-container" id="login">
            <UnderlinedTextInput
              autoFocus={!history.location.fromReg ? true : false}
              label={"Email"}
              value={email}
              onValueChange={handleEmailChange}
              isInvalid={emailInvalid}
              func={submit}
            />
            <UnderlinedTextInput
              label={"Password"}
              isInvalid={passwordInvalid}
              value={password}
              onValueChange={handlePasswordChange}
              type={"password"}
              func={submit}
              displayForgotPassword
            />
            {invalidMessage && invalidMessage.length > 1 && (
              <div className="invalid-message-container">{invalidMessage}</div>
            )}
            <div className="login-button-container" id="login">
              <ColoredButton onClick={submit} text={"Log in"} />
              {/* <button className="login-button" onClick={submit}>
                Log In
              </button> */}
            </div>
            <div className="oauth-container">
              <div className="or-hr">
                <hr />
                <span>OR</span>
                <hr />
              </div>
              <OAuthSection />
            </div>
          </div>
        </>
      ) : (
        <CodePage email={email} password={password} />
      )}
    </>
  );
};

export default Login;
