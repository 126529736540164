import React, { useEffect, useState } from "react";
import BookService from "../../../../services/BookService";
import { Swiper, SwiperSlide } from "swiper/react";
import BookCard from "../../../../components/cards/BookCard";
import SwiperNavButton from "../components/SwiperNavButton";

const RecentlyAddedBooks = ({
  likedBooks,
  resetLikedBooks,
  isLiked,
  setIsLiked,
  openBanner,
  viewSingleCategory,
}) => {
  const [recentlyAddedBooks, setRecentlyAddedBooks] = useState([]);
  const category = "recently added";

  // GET RECENTLY ADDED BOOKS
  useEffect((_) => {
    const fetchData = async (_) => {
      try {
        const response = await BookService.getRecentlyAddedBooks(1);

        console.log("RAB");
        console.log(response.data);

        setRecentlyAddedBooks(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const bookCatalogSwiper = {
    position: "relative",
    padding: "0 1rem",
  };

  return (
    <div className="swiper-parent-container fadeInBottom animated">
      <Swiper
        spaceBetween={10}
        slidesPerView={"auto"}
        centeredSlides
        centeredSlidesBounds
        preventClicks
        threshold={10}
        style={bookCatalogSwiper}
      >
        <SwiperNavButton type="previous" />
        {recentlyAddedBooks.map((book) => {
          const image = BookService.getBookCoverSrc(
            book.id,
            book.bookCoverLink
          );

          return (
            <SwiperSlide style={{ width: "200px" }} key={book.id}>
              <BookCard
                bookId={book.id}
                image={image}
                title={book.title}
                roles={book.roles}
                tags={book.categories}
                video={book.video}
                likedBooks={likedBooks}
                resetLikedBooks={resetLikedBooks}
                isLiked={isLiked}
                setIsLiked={setIsLiked}
                openBanner={openBanner}
              />
            </SwiperSlide>
          );
        })}
        <SwiperNavButton type="next" />
      </Swiper>
    </div>
  );
};

export default RecentlyAddedBooks;
