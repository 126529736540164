import React, { useEffect, useState } from "react";
import "./OAuthButton.css";

const OAuthButton = ({ intent, text, message, source, icon, uri }) => {
  // Maybe we can handle an href redirect error?
  // maybe somehow we can make it a pop up?
  return (
    <a href={uri} className="external-login-button__container no-underline">
      <div className="external-login-button__content" id={source}>
        <div className="external-login-button__icon">{icon}</div>
        <span>Continue with {source}</span>
        <div></div>
      </div>
    </a>
  );
};

export default OAuthButton;
