/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useLayoutEffect } from "react";
import ArtistCardContainer from "../../catalog/artists/ArtistCardContainer";
import ArtistServices from "../../../services/ArtistService";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import ArtistLetterButton from "./ArtistLetterButton";
import FeaturedArtist from "./FeaturedArtist";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./artistcatalog.css";

const ArtistCatalog = () => {
  const [artistData, setArtistData] = useState([]);
  const [filter, setFilter] = useState("A"); // char code for A
  const [filteredArtists, setFilteredArtists] = useState([]);
  const [letterArray, setLetterArray] = useState([]);
  const [featuredArtists, setFeaturedArtists] = useState([]);
  const [searching, setSearching] = useState(true);
  const [swiperSlidesPerView, setSwiperSlidesPerView] = useState(1);
  const [width, setWidth] = useState(window.innerWidth);
  const [viewAll, setViewAll] = useState(false);

  SwiperCore.use([Autoplay, Navigation]);

  useLayoutEffect(() => {
    ArtistServices.getArtsitByLastName().then((response) => {
      setArtistData(response.data);
    })
    .catch((e) => {
      console.log(e)
    })

    // build list of letter buttons
    let temp = [];
    for (let x = 65; x < 91; x++) {
      temp.push(x);
    }
    setLetterArray(temp);
  }, []);

  useEffect(() => {
    if (window.innerWidth < 750) {
      setSwiperSlidesPerView(1);
    } else if (window.innerWidth < 1200) {
      setSwiperSlidesPerView(2);
    } else {
      setSwiperSlidesPerView(3);
    }
    setWidth(window.innerWidth);
  }, [width]);

  useEffect(() => {
    const fetchFeaturedArtists = () => {
      ArtistServices.getFeaturedArtists().then((response) => {
        setFeaturedArtists(response.data);
      });
    };

    fetchFeaturedArtists();
  }, [artistData]);

  useEffect(() => {
    filterArtists(filter);
  }, [filter, artistData]);

  const filterArtists = (arg) => {
    if (artistData && artistData.length > 0) {
      setSearching(false);
      setFilter(arg);
      const results = artistData.filter((artist) => {
        if (artist.lastName) {
          return artist.lastName.toUpperCase()[0] === arg;
        }
        return artist.name[0] === arg;
      });
      setFilteredArtists(results);
    }
  };

  const viewAllArtists = () => {
    setViewAll(!viewAll);
  };

  return (
    <div className="artist-page-container">
      <div className="artist-page">
        <FeaturedArtist featuredArtists={featuredArtists} />

        <div className="search-letter-button-container">
          <div className="letter-container">
            <div
              className="screen-l"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: "0.5rem",
              }}
            >
              {letterArray.map((letter, i) => {
                return (
                  <ArtistLetterButton
                    key={i}
                    i={i}
                    artistData={artistData}
                    filter={filter}
                    setFilter={setFilter}
                    letter={String.fromCharCode(letter)}
                    setViewAll={setViewAll}
                  />
                );
              })}
            </div>
            <div className="screen-m screen-s" style={{ width: "100%" }}>
              <div className="letter-range-container">
                {letterArray.slice(0, 8).map((letter, i) => {
                  return (
                    <ArtistLetterButton
                      key={i}
                      i={i}
                      artistData={artistData}
                      filter={filter}
                      setFilter={setFilter}
                      charCode={letter}
                      letter={String.fromCharCode(letter)}
                    />
                  );
                })}
              </div>
              <div className="letter-range-container">
                {letterArray.slice(8, 17).map((letter, i) => {
                  return (
                    <ArtistLetterButton
                      key={i}
                      i={i}
                      artistData={artistData}
                      filter={filter}
                      setFilter={setFilter}
                      charCode={letter}
                      letter={String.fromCharCode(letter)}
                    />
                  );
                })}
              </div>
              <div className="letter-range-container">
                {letterArray.slice(17, 26).map((letter, i) => {
                  return (
                    <ArtistLetterButton
                      key={i}
                      i={i}
                      artistData={artistData}
                      filter={filter}
                      setFilter={setFilter}
                      charCode={letter}
                      letter={String.fromCharCode(letter)}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="artist-btn-container">
          <h3 className="artist-by-lastname">{viewAll ? "all Artists" : "Artist by Last Name"}</h3>
          <h3 className="view-all-artist-btn" onClick={() => viewAllArtists()}>
            {!viewAll ? "View all" : "Return to Alphabetical"}
          </h3>
        </div>
        <hr style={{ width: "100%", border: "none", borderTop: "1px solid var(--dark-gray)" }} />

        <div className="artistCard-container">
          <ArtistCardContainer
            // page={page}
            artistData={artistData}
            viewAll={viewAll}
            filteredArtists={filteredArtists}
            searching={searching}
          />
        </div>
      </div>
    </div>
  );
};
export default ArtistCatalog;
