import React, { useEffect, useState, useContext, useCallback } from "react";
import Reader from "../../../components/reader/Reader";
import BookDataService from "../../../services/BookService";
import "./ChapterReader.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import ReaderHeader from "../../../components/reader/readerComponents/ReaderHeader";

import { Context } from "../../../components/auth/state/Store";
import LoadingContentSpinner from "../../../components/loading/LoadingContentSpinner";
import TokenService from "../../../services/auth/TokenService";
import AuthService from "../../../services/auth/AuthService";
import { NotificationBarContext } from "../../../context/NotificationBarContext";
// let page = response.data[0].pages[i];
//             images.push(
//               `https://d24hdgytax3x03.cloudfront.net/${bookId}/pages/${page.contentImageLink}`
//             );
const ChapterReader = (props) => {
  const [state, dispatch] = useContext(Context);

  const { bookId } = useParams();
  //video link
  const [videoLink, setVideoLink] = useState();

  const [bookInsides, setBookInsides] = useState([]);

  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [currentChapterIndex, setCurrentChapterIndex] = useState(0);

  const [bookJson, setBookJson] = useState({});

  const [chapterImages, setChapterImages] = useState();

  const [pointsDrawer, setPointsDrawer] = useState(false);

  const [chapterName, setChapterName] = useState("");

  const [bookName, setBookName] = useState("");

  const [menuVisible, setMenuVisible] = useState(false);

  const [allPoints, setAllPoints] = useState();

  const [languages, setLanguages] = useState([]);

  const [tourStepIndex, setTourStepIndex] = useState(0); // for the tour; it needs to live here for scope reasons

  const [audioLink, setAudioLink] = useState("");
  const { isVisible, hideNotificationBar, showNotificationBar } = useContext(
    NotificationBarContext
  ); // Use context

  useEffect(() => {
    dispatch({ type: "ENTER_READER" });

    // get the title of the tab / window so that when we navigate away we can restore it
    const oldTitle = document.title;
    setAppHeight();
    hideNotificationBar();

    // restore the old title
    return () => {
      document.title = oldTitle;

      dispatch({ type: "EXIT_READER" });
      showNotificationBar();
    };
  }, []);

  // fix for mobile browsers whose browser menu bars cause issues with page height
  const setAppHeight = useCallback(() => {
    const doc = document.documentElement;

    doc.style.setProperty("--app-height", `${window.innerHeight - 64}px`);
  }, []);

  //GET DATA
  useEffect(() => {
    if (bookId) {
      let arr = [];
      BookDataService.getBookChaptersAndPages(bookId).then((response) => {
        // console.log(response.data);
        setBookInsides(response.data); //set book insides
        setVideoLink(response.data[0].videoLink); //set video link
        setAudioLink(response.data[0].audioLink);

        // console.log("book insides: ", response.data);

        //CAMERON USAGE DATA BOOK VISITS
        // TOKENSERVICE USER ID & BOOK ID
        // ADD DOMAIN ID TO BOTH BOOK VISIT AND PAGE VISIT AND CHANGE CONTROLLER TO GET BY DOMAIN ID
        // console.log(bookInsides)
        BookDataService.logBookVisits(TokenService.getUser().id, bookId);

        for (let i = 0; i < response.data.length; i++) {
          if (i === 0) {
            setChapterName(response.data[0].name);
            setBookName(response.data[0].bookTitle);

            // set the tab / window title to the name of the book
            document.title = `${response.data[0].bookTitle} - GbM`;
          }
          arr[i] = [];
          //loop through insides
          let chapter = response.data[i];
          // console.log(chapter)
          for (let j = 0; j < chapter.pages.length; j++) {
            let page = chapter.pages[j];
            //add everything to JSON data and
            axios
              .get(
                `https://d24hdgytax3x03.cloudfront.net/${bookId}/pages/${page.jsonLink}`
              )
              .then((res) => {
                arr[i][j] = res.data;
                setBookJson([...arr]);
              })
              .catch((e) =>
                console.log("error getting chapters and pages: ", e)
              );
          }
        }
      });
    }
  }, [bookId]);

  // useEffect(() => {
  //   console.log("jsonData: ", jsonData);
  //   console.log("jsonPage: ", jsonPage);
  //   console.log("Pages: ", pages);
  //   console.log("chapter: ", chapter);
  //   console.log("IMAGE ARRAY: ", imageArray);
  //   console.log("jsonPages from chapter reader:", bookJson);
  // }, [bookJson]);
  // }, [chapterJsonData, jsonPage, pages, imageArray, chapter]);

  // this function combines all the coaching points in the chapter
  // into a single array for use in the side bar menu
  const concatenatePoints = () => {
    if (bookJson && bookJson[currentChapterIndex]) {
      let temp = [];

      // pages:
      for (let i = 0; i < bookJson[currentChapterIndex].length; i++) {
        // points:
        if (
          bookJson[currentChapterIndex][i] &&
          bookJson[currentChapterIndex][i].points
        ) {
          for (
            let j = 0;
            j < bookJson[currentChapterIndex][i].points.length;
            j++
          ) {
            temp.push(bookJson[currentChapterIndex][i].points[j]);
          }
        }
      }
      setAllPoints(temp);
    }
  };

  const evaluateLanguages = () => {
    let temp = [];

    // for each page of the json
    for (let i = 0; i < bookJson[0].length; i++) {
      // for each point in the page
      if (bookJson[0][i] && bookJson[0][i].points) {
        for (let j = 0; j < bookJson[0][i].points.length; j++) {
          // for each key in the titles object
          for (const [key] of Object.entries(bookJson[0][i].points[j].title)) {
            // TODO: remove the first condition in the if statement here; it's very dirty programming
            if (key.length > 2 && !temp.includes(key)) temp.push(key);
          }
        }
      }
    }
    setLanguages(temp);
  };

  useEffect(() => {
    if (bookJson[0] && bookJson[0].length) {
      concatenatePoints();
      evaluateLanguages();
    }
  }, [bookJson]);

  useEffect(() => {
    // video books
    if (
      bookInsides &&
      bookInsides[currentChapterIndex] &&
      bookInsides[currentChapterIndex].videoLink
    ) {
      concatenatePoints();
      setVideoLink(bookInsides[currentChapterIndex].videoLink);
      setAudioLink(bookInsides[currentChapterIndex].audioLink);
    }
    // audio books
    if (
      bookInsides &&
      bookInsides[currentChapterIndex] &&
      bookInsides[currentChapterIndex].audioLink
    ) {
      setAudioLink(bookInsides[currentChapterIndex].audioLink);
    }
    let arr = [];
    for (let i = 0; i < bookInsides.length; i++) {
      arr[i] = [];
      let chapter = bookInsides[i];
      for (let j = 0; j < chapter.pages.length; j++) {
        let page = chapter.pages[j];
        arr[i][
          j
        ] = `https://d24hdgytax3x03.cloudfront.net/${bookId}/pages/${page.contentImageLink}`;
        setChapterImages([...arr]);
      }
    }
    // console.log("chapter reader" + currentPageIndex);
  }, [currentChapterIndex, bookInsides]);

  //when a page changes, we just need to change index in JSON data

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const navigateTo = (chapterId, pageId) => {
    // console.log(chapterId, pageId);
    // console.log(bookInsides);
    for (let i = 0; i < bookInsides.length; i++) {
      if (bookInsides[i].id === chapterId) {
        for (let j = 0; j < bookInsides[i].pages.length; j++) {
          if (bookInsides[i].pages[j].id === pageId) {
            setCurrentChapterIndex(i);
            setChapterName(bookInsides[i].name);
            setCurrentPageIndex(j);
            // console.log(j);
            setVideoLink(bookInsides[i].videoLink);
            setAudioLink(bookInsides[i].audioLink);
          }
        }
      }
    }
  };

  // useEffect(() => {
  //   console.log("videoLink: ", videoLink);
  //   console.log("bookJson: ", bookJson);
  //   console.log("currentChapterIndex: ", currentChapterIndex);
  //   // console.log("chapterImages: ", chapterImages);
  //   // console.log("currentPageIndex: ", currentPageIndex);
  //   // console.log("pageimageArray: ", chapterImages);
  //   // console.log("bookInsides: ", bookInsides);
  //   console.log(
  //     "OPENING: ",
  //     bookInsides &&
  //       bookInsides[currentChapterIndex] &&
  //       bookInsides[currentChapterIndex].audio
  //   );
  // }, [
  //   videoLink,
  //   bookJson,
  //   currentChapterIndex,
  //   chapterImages,
  //   currentPageIndex,
  //   chapterImages,
  //   bookInsides,
  // ]);

  // console.log(process.env.REACT_APP_READER_URL);
  return (
    <>
      {!bookId ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
          }}
        >
          <LoadingContentSpinner />
        </div>
      ) : (
        <div className="header-and-reader" style={{ userSelect: "none" }}>
          <iframe
            title="reader"
            allow="fullscreen"
            allowFullScreen
            src={`${
              process.env.REACT_APP_READER_URL
            }?mode=gbm&book=${bookId}&token=${btoa(
              JSON.stringify(AuthService.getCurrentUser())
            )}`}

            /* FOR TESTING WITH LOCALHOST */
            // src={`http://10.0.0.18:3000/?mode=gbm&href=https://dpi-authoring-image.s3.us-west-1.amazonaws.com/${bookId}/bookData&book=${bookId}&token=${btoa(
            //   JSON.stringify(AuthService.getCurrentUser())
            // )}`}
          />
          {/* {bookInsides && bookInsides.length > 0 && (
            <ReaderHeader
              pointsDrawer={pointsDrawer}
              setPointsDrawer={setPointsDrawer}
              bookInsides={bookInsides}
              currentChapter={bookInsides[currentChapterIndex]}
              currentChapterIndex={currentChapterIndex}
              setCurrentChapterIndex={setCurrentChapterIndex}
              currentPageIndex={currentPageIndex}
              setCurrentPageIndex={setCurrentPageIndex}
              bookName={bookName}
              chapterName={chapterName}
              toggleMenu={toggleMenu}
            />
          )}

          <div className="chapter-reader-container">
            {chapterImages &&
              bookJson &&
              bookJson.length > 0 &&
              bookJson[0] &&
              bookJson[0][0] &&
              bookJson[currentChapterIndex] && (
                <Reader
                  videoLink={videoLink}
                  pageImageArray={chapterImages[currentChapterIndex]}
                  jsonPages={bookJson[currentChapterIndex]}
                  languageList={languages}
                  currentChapter={bookInsides[currentChapterIndex]}
                  currentChapterIndex={currentChapterIndex}
                  setCurrentChapterIndex={setCurrentChapterIndex}
                  chapterName={chapterName}
                  currentPage={currentPageIndex}
                  setCurrentPage={setCurrentPageIndex}
                  bookId={bookId}
                  allPoints={allPoints}
                  menuVisible={menuVisible}
                  bookInsides={bookInsides}
                  navigateTo={navigateTo}
                  isAudio={bookInsides[currentChapterIndex].audio}
                  audioLink={audioLink}
                  setChapterName={setChapterName}
                  videoPoster={bookInsides[currentChapterIndex].posterLink}
                />
              )}
          </div> */}
        </div>
      )}
    </>
  );
};

export default ChapterReader;
