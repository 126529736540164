import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "./compliment.css";

const ComplimentVideo = ({ videoId, thumbnail, src, artistId, artistName, handleVideoPlay, videoPlaying }) => {
  const vidRef = useRef();

  useEffect(() => {
    const playFunction = () => {
      if (videoPlaying === videoId) {
        vidRef.current.play();
      } else {
        vidRef.current.pause();
      }
    };

    playFunction();
  }, [videoPlaying]);

  return (
    <div className="compliment-video-mini-container">
      <video
        ref={vidRef}
        className="compliment-video"
        controls
        poster={thumbnail}
        src={src}
        onPlay={() => handleVideoPlay(videoId)}
      />
      {!artistId == "" ? (
        <Link to={`/artistprofile/${artistId}`} className="no-underline">
          <h3>{artistName}</h3>
        </Link>
      ) : (
        <h3>{artistName}</h3>
      )}
    </div>
  );
};

export default ComplimentVideo;
