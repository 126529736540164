/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ColoredButton from "../../../components/buttons/ColoredButton";
import "./NewsletterPopup.css";
import { ReactComponent as XButton } from "../../../assets/x.svg";
import { ReactComponent as LoadingSpinner } from "../../../assets/spinner.svg";
import { Context } from "../../../components/auth/state/Store";

import { ReactComponent as NewsletterIcon } from "../../../assets/newsletter.svg";
import UserService from "../../../services/UserService";
import { useLocation } from "react-router-dom";

function NewsletterPopup({ visible, setVisible }) {
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(false);
  // const [tooltipVisible, setTooltipVisible] = useState(false);
  const [name, setName] = useState();
  const [submitError, setSubmitError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [newsletterPopupSeen, setNewsletterPopupSeen] = useState(false);
  const [newsletterTimeoutID, setNewsletterTimeoutID] = useState();

  const [state, dispatch] = useContext(Context);

  const newsletterRef = useRef();

  const location = useLocation();

  useEffect(() => {
    if (visible) {
      document.body.style.overflowY = "hidden";
      document.addEventListener("mousedown", handleMouseDown);
      localStorage.setItem("userHasSeenNewsletterPopup", true);
    } else {
      document.body.style.overflowY = null;
      document.removeEventListener("mousedown", handleMouseDown);
    }
  }, [visible]);

  const handleMouseDown = useCallback((e) => {
    if (newsletterRef.current && !newsletterRef.current.contains(e.target)) {
      closePopup();
    }
  }, []);

  const closePopup = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (email !== null)
      setEmailError(
        !/[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}/.test(email)
      );
  }, [email]);

  const submitEmail = () => {
    if (!email || email.length <= 0)
      setEmailErrorMessage("Email address is required");
    else if (emailError) setEmailErrorMessage("Email address is invalid");
    else {
      setLoading(true);
      UserService.mailchimpSubscribe(email, name)
        .then((res) => {
          console.log("Successfully added email address to mailing list.");
          setLoading(false);
          setSuccess(true);
        })
        .catch((res) => {
          console.log("Error adding email address to mailing list:", res);
          setSubmitError(true);
          setLoading(false);
        });
    }
  };

  return (
    <div
      ref={newsletterRef}
      className={`newsletter-container ${visible ? "visible" : ""} ${
        success || submitError ? "" : "pre-submit"
      }`}
    >
      {success ? (
        <>
          <div className="newsletter-success-message-container">
            <h1>Success!</h1>
            <hr />
            <span>
              <span
                style={{
                  color: email.length > 0 ? null : "#12bd12",
                  fontWeight: email.length > 0 ? null : 800,
                  marginRight: "0.25rem",
                }}
              >
                {email && email.length > 0 ? email : "Your email"}
              </span>
              has been added to the GbM mailing list.
            </span>
            <hr />
            <h3>Look forward to some great stuff coming soon!</h3>
            <ColoredButton text="Close" onClick={closePopup} />
          </div>
        </>
      ) : submitError ? (
        <>
          <XButton
            className="newsletter-close-button"
            stroke="#fff"
            strokeWidth={60}
            onClick={closePopup}
          />
          <div className="newsletter-error-message-container">
            <h3>Oops!</h3>
            <span>
              We've encountered an error adding your email. Please try again in
              a minute, or{" "}
              <a href="mailto:support@guitarbymasters.com">contact us</a> and we
              can add it manually.
            </span>
            <ColoredButton
              text="Try again"
              onClick={() => {
                setEmail(null);
                setSubmitError(false);
                setEmailErrorMessage("");
              }}
            />
          </div>
        </>
      ) : (
        <>
          <XButton
            className="newsletter-close-button"
            stroke="#fff"
            strokeWidth={60}
            onClick={closePopup}
          />
          <div style={{ width: "100%" }}>
            <h3>Join our email list for exciting news!</h3>
            <hr />
          </div>
          <div className="newsletter-main-container">
            <div className="newsletter-main-half" id="image">
              <NewsletterIcon />
            </div>
            <div className="newsletter-main-half" id="text">
              <p className="text2">
                Stay in the know regarding upcoming books and artists, new
                features, and other exciting things to come.
              </p>
              <div className="newsletter-text-input-container">
                <span>First name (optional)</span>
                <input
                  type="text"
                  className="newsletter-text-input"
                  placeholder="John"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="newsletter-text-input-container">
                <span>
                  Email{" "}
                  <span
                    style={{
                      marginLeft: "1rem",
                      fontWeight: 800,
                      color: "red",
                    }}
                  >
                    {emailErrorMessage}
                  </span>
                </span>
                <div style={{ position: "relative", width: "100%" }}>
                  <input
                    type="email"
                    className={`newsletter-text-input ${
                      emailError ? "invalid" : ""
                    }`}
                    placeholder="john@smith.com"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") submitEmail();
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  alignSelf: "center",
                  position: "relative",
                }}
              >
                <ColoredButton
                  text="Sign up"
                  onClick={submitEmail}
                  disabled={loading}
                />
                {loading && (
                  <div className="newsletter-loading-spinner">
                    <LoadingSpinner
                      width={"1.5rem"}
                      height={"1.5rem"}
                      stroke={"#fff"}
                      strokeWidth={50}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default NewsletterPopup;
