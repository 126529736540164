export const FeaturedArtistText = [
    {
        artistId: "1",
        artistName: "Sergio",
        featuredText: "A Brazilian-born guitarist, composer, and arranger, Sérgio is a Latin Grammy Award winner. Many of Sérgio’s compositions are standards in the guitar repertoire. Often, he performs with his brother, Odair, in the Assad Brothers. He is on faculty at the San Francisco Conservatory of Music."
    },
    {
        artistId: "22",
        artistName: "Denis",
        featuredText: "Born in Bosnia, Denis is one of the most compelling classical guitar players today, maintaining a balance between his solo recitals, chamber music with the Cavatina Duo, and engagements as a soloist with orchestras. Currently, he is Head of Classical Guitar at Roosevelt University, in Chicago. "
    },
    {
        artistId: "57",
        artistName: "Dusan",
        featuredText: "A richly gifted Serbian-born American composer, improviser, and guitarist, Dušan has explored musical languages that are reflected in his style today- a unique synthesis of classical, jazz, and ethnic music. He is presently associated with the Geneva Conservatory in Switzerland."
    },
    {
        artistId: "53",
        artistName: "David",
        featuredText: "Classical guitarist David Russell is world renowned for his superb musicianship and inspired artistry. In recognition of his great talent and his international career, he was named a Fellow of The Royal Academy of Music in London in 1997. David continues to tour as a concert guitarist, playing in prestigious halls across the world."
    },
    {
        artistId: "3",
        artistName: "Yamandu",
        featuredText: "Recognized as one of the world’s greatest guitar virtuosos, Yamandu’s compositions consist of a fusion of the typical music of southern Brazil: choro and samba. He has recorded numerous albums, as a soloist, with duos, trios, quartets, and several orchestras worldwide."
    },
    {
        artistId: "38",
        artistName: "Antigoni",
        featuredText: "An internationally acclaimed soloist, recording artist, sought-after pedagogue, and festival organizer, Greece-born guitarist Antigoni Goni has played in practically every guitar society and guitar series, as well as in many of the most prominent guitar festivals, worldwide."
    },
    {
        artistId: "2",
        artistName: "Stephanie",
        featuredText: "Stephanie is a brilliant Australian guitarist and an active soloist and chamber musician, currently based in Germany and studying Konzertexamen at the University of Music Franz Liszt. She is also a member of the acclaimed Weimar Guitar Quartet. "
    },
    {
        artistId: "99",
        artistName: "William",
        featuredText: "A Grammy Award-winner with the Los Angeles Guitar Quartet, William is one of America’s finest classical guitarists. He has toured extensively throughout the world and recorded over a dozen CDs. He is a member of the guitar faculty at the Thornton School of Music (USC)."
    },
    {
        artistId: "58",
        artistName: "Edson",
        featuredText: "Having won in every guitar competition that he entered, Edson stands out as one of Brazil’s most talented guitar players. As a soloist and in ensembles he has performed throughout Brazil and numerous cities in Europe and the United States. He is currently professor at the Conservatory in Tatuí, Brazil."
    },
    {
        artistId: "117",
        artistName: "Marco",
        featuredText: "Born in São Paulo, Brazil, Marco is one of the most prolific composers and talented guitar players, today. His compositions are sublime amalgamations of Latin American, particularly Brazilian, and jazz music. He has performed with Tom Jobim, Milton Nascimento, Baden Powell, and Vicente Amigo, to name a few. "
    },
    {
        artistId: "110",
        artistName: "Peppino",
        featuredText: "Born in Sicily, Peppino helped redefine the instrument in the 1990s with his remarkable technique. Peppino’s most recent solo recording includes two compositions written for him by Sergio Assad. Peppino may well be the missing link between the classical and the acoustic guitar."
    },
]