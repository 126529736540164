import React, { useState } from "react";
import OAuthButton from "../button/OAuthButton";

import { ReactComponent as GoogleIcon } from "../../../../assets/google-icon.svg";
import { ReactComponent as FacebookIcon } from "../../../../assets/facebook-icon.svg";

const OAuthSection = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <OAuthButton
        intent={"signup"}
        source="Google"
        icon={<GoogleIcon />}
        uri={`https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/userinfo.email&include_granted_scopes=true&response_type=token&state=signup&redirect_uri=${process.env.REACT_APP_CURRENT_URL}/auth/callback/google&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
      />
    </>
  );
};

export default OAuthSection;
