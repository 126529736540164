import React, { useState, useRef, useEffect } from "react";
import ColoredButton from "../../components/buttons/ColoredButton";
import map from "../../assets/map.png";
import { MdLocationPin, MdOutlineEmail, MdPhone } from "react-icons/md";
import WarningIcon from "../../components/micro_components/WarningIcon";
import GenericSpinner from "../../components/micro_components/GenericSpinner";
import { SocialIcon } from "react-social-icons";

import "./contact.css";
import UserService from "../../services/UserService";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [timeoutID, setTimeoutID] = useState();
  const [sending, setSending] = useState(false);
  const [confirmationVisible, setConfirmationVisible] = useState(null);

  // These control visibility of the warnings, and whether the message can be sent.
  const [nameValid, setNameValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [messageValid, setMessageValid] = useState(true);

  // This state helps control when the tooltips are visible. A tooltip will
  // disappear if the user is currently focusing on its corresponding text box.
  const [currentFocus, setCurrentFocus] = useState(null);

  // These are used for checking against currentFocus
  const nameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();

  const handleSubmit = () => {
    if (
      nameValid &&
      name.length > 0 &&
      emailValid &&
      email.length > 6 &&
      messageValid &&
      message.length >= 10
    ) {
      setSending(true);
      UserService.submitContactMessage(name, email, message)
        .then(() => {
          setName("");
          setEmail("");
          setMessage("");
          setSending(false);
          setNameValid(true);
          setEmailValid(true);
          setMessageValid(true);

          showConfirmation("success");
        })
        .catch((e) => {
          console.log("Error sending message:", e);
          showConfirmation("error");
          setSending(false);
        });
    } else {
      setNameValid(checkValidity("name", name));
      setEmailValid(checkValidity("email", email));
      setMessageValid(checkValidity("message", message));
    }
  };

  const showConfirmation = (type) => {
    switch (true) {
      case type === "success": {
        setConfirmationVisible("success");
        setTimeoutID(
          setTimeout(() => {
            setConfirmationVisible(null);
          }, 2000)
        );
        break;
      }
      case type === "error": {
        setConfirmationVisible("error");
        setTimeoutID(
          setTimeout(() => {
            setConfirmationVisible(null);
          }, 5000)
        );
        break;
      }
      default:
        break;
    }
  };

  const checkValidity = (type, value) => {
    switch (true) {
      case type === "name": {
        return /^[a-zA-Z]+/.test(value);
      }
      case type === "email": {
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
      }
      case type === "message": {
        return value.length >= 10;
      }
      default:
        break;
    }
  };

  return (
    <div className="contact">
      <h1>Contact Us</h1>
      <p className="text2">
        We value your feedback and take your input very seriously.
      </p>
      <div className="screen-l">
        <div className="contact-container">
          <div className="contact-form">
            <h3>Have a quick question?</h3>
            <h3>Have you run into any issues?</h3>
            <h3>Please leave a brief message for us using the form below.</h3>
            <div className="text-field">
              <p className="text3" id="input-label">
                Name
              </p>
              <div className="text-warning">
                <input
                  ref={nameRef}
                  autoComplete="off"
                  type="text"
                  name="Contact-Name"
                  value={name}
                  className={`contact-input ${nameValid ? "" : "invalid"}`}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameValid(checkValidity("name", e.target.value));
                  }}
                  onFocus={() => {
                    setCurrentFocus(nameRef.current);
                    clearTimeout(timeoutID);
                    setConfirmationVisible(null);
                  }}
                  onBlur={(e) => {
                    setCurrentFocus(null);
                    setNameValid(checkValidity("name", e.target.value));
                  }}
                />
                <WarningIcon
                  label="Name must be at least one letter"
                  absolute
                  tooltipHeight="2rem"
                  left="101%"
                  visible={!nameValid}
                  tooltipVisible={
                    currentFocus !== nameRef.current && !nameValid
                  }
                />
              </div>
            </div>
            <div className="text-field">
              <p className="text3" id="input-label">
                E-mail
              </p>
              <div className="text-warning">
                <input
                  ref={emailRef}
                  autoComplete="off"
                  type="email"
                  name="Contact-Email"
                  value={email}
                  className={`contact-input ${emailValid ? "" : "invalid"}`}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailValid(checkValidity("email", e.target.value));
                  }}
                  onFocus={() => {
                    setCurrentFocus(emailRef.current);
                    clearTimeout(timeoutID);
                    setConfirmationVisible(null);
                  }}
                  onBlur={(e) => {
                    setCurrentFocus(null);
                    setEmailValid(checkValidity("email", e.target.value));
                  }}
                />
                <WarningIcon
                  label="Please enter a valid email"
                  absolute
                  tooltipHeight="2rem"
                  left="101%"
                  visible={!emailValid}
                  tooltipVisible={
                    currentFocus !== emailRef.current && !emailValid
                  }
                />
              </div>
            </div>
            <div className="text-field">
              <p className="text3" id="input-label">
                Message
              </p>
              <div className="text-warning" id="message">
                <textarea
                  ref={messageRef}
                  row="5"
                  name="Contact-Message"
                  className={`contact-input ${messageValid ? "" : "invalid"}`}
                  value={message}
                  maxLength={2000}
                  onChange={(e) => {
                    setMessage(e.target.value);
                    setMessageValid(checkValidity("message", e.target.value));
                  }}
                  onFocus={() => {
                    setCurrentFocus(messageRef.current);
                    clearTimeout(timeoutID);
                    setConfirmationVisible(null);
                  }}
                  onBlur={(e) => {
                    setCurrentFocus(null);
                    setMessageValid(checkValidity("message", e.target.value));
                  }}
                />
                <p
                  className="message-length text3"
                  id="input-label"
                >{`${message.length} / 2000`}</p>
                <WarningIcon
                  label="Message must be at least 10 characters"
                  absolute
                  tooltipHeight="2rem"
                  left="101%"
                  top="0.5rem"
                  visible={!messageValid}
                  tooltipVisible={
                    currentFocus !== messageRef.current && !messageValid
                  }
                />
              </div>
            </div>
            <div className="button-and-spinner">
              <ColoredButton
                style={{ padding: "1rem 2.2rem" }}
                text="Submit"
                disabled={sending}
                onClick={handleSubmit}
              />
              {sending && <GenericSpinner absolute left="110%" />}
            </div>
            <div className="confirmation-container">
              <p
                className={`message-sent-confirmation text3 ${
                  confirmationVisible === "success" ? "visible" : ""
                }`}
              >
                Message sent. Thank you!
              </p>
              <p
                className={`message-error-confirmation text3 ${
                  confirmationVisible === "error" ? "visible" : ""
                }`}
              >
                Error... please try again.
              </p>
            </div>
          </div>
          <div className="contact-details">
            <h3>More ways to contact us</h3>
            <img src={map} alt="map" />
            <div className="contact-icon">
              <MdLocationPin fill="var(--primary-color)" />
            </div>
            <p className="label2">12900 Preston Rd. Ste. 804</p>
            <p className="label2">Dallas, TX 75230</p>
            <div className="contact-icon">
              <MdOutlineEmail fill="var(--primary-color)" />
            </div>
            <p className="label2">support@guitarbymasters.com</p>
            {/* <div className="contact-icon">
            <MdPhone fill="var(--primary-color)" />
          </div>
          <p className="label2">+1 (607) 226-3282</p> */}
            <hr />
            <h3>Follow us on social media!</h3>
            <div className="social-media-links">
              <SocialIcon
                url="https://www.facebook.com/Guitar-by-Masters-109388588095895"
                target="/"
              />
              <SocialIcon
                url="http://www.instagram.com/guitarbymasters"
                target="/"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="screen-m screen-s" style={{ marginTop: "2rem" }}>
        <div className="contact-container">
          <div className="contact-form">
            <h3>Have a quick question?</h3>
            <h3>Have you run into any issues?</h3>
            <h3>Please leave a brief message for us using the form below.</h3>
            <div className="text-field">
              <p className="text3" id="input-label">
                Name
              </p>
              <div className="text-warning">
                <input
                  ref={nameRef}
                  autoComplete="off"
                  type="text"
                  name="Contact-Name"
                  value={name}
                  className={`contact-input ${nameValid ? "" : "invalid"}`}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameValid(checkValidity("name", e.target.value));
                  }}
                  onFocus={() => {
                    setCurrentFocus(nameRef.current);
                    clearTimeout(timeoutID);
                    setConfirmationVisible(null);
                  }}
                  onBlur={(e) => {
                    setCurrentFocus(null);
                    setNameValid(checkValidity("name", e.target.value));
                  }}
                />
                <WarningIcon
                  label="Name must be at least one letter"
                  absolute
                  tooltipHeight="2rem"
                  left="101%"
                  visible={!nameValid}
                  position="left"
                  tooltipVisible={
                    currentFocus !== nameRef.current && !nameValid
                  }
                />
              </div>
            </div>
            <div className="text-field">
              <p className="text3" id="input-label">
                E-mail
              </p>
              <div className="text-warning">
                <input
                  ref={emailRef}
                  autoComplete="off"
                  type="email"
                  name="Contact-Email"
                  value={email}
                  className={`contact-input ${emailValid ? "" : "invalid"}`}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailValid(checkValidity("email", e.target.value));
                  }}
                  onFocus={() => {
                    setCurrentFocus(emailRef.current);
                    clearTimeout(timeoutID);
                    setConfirmationVisible(null);
                  }}
                  onBlur={(e) => {
                    setCurrentFocus(null);
                    setEmailValid(checkValidity("email", e.target.value));
                  }}
                />
                <WarningIcon
                  label="Please enter a valid email"
                  absolute
                  tooltipHeight="2rem"
                  left="101%"
                  visible={!emailValid}
                  position="left"
                  tooltipVisible={
                    currentFocus !== emailRef.current && !emailValid
                  }
                />
              </div>
            </div>
            <div className="text-field">
              <p className="text3" id="input-label">
                Message
              </p>
              <div className="text-warning" id="message">
                <textarea
                  ref={messageRef}
                  row="5"
                  name="Contact-Message"
                  className={`contact-input ${messageValid ? "" : "invalid"}`}
                  value={message}
                  maxLength={2000}
                  onChange={(e) => {
                    setMessage(e.target.value);
                    setMessageValid(checkValidity("message", e.target.value));
                  }}
                  onFocus={() => {
                    setCurrentFocus(messageRef.current);
                    clearTimeout(timeoutID);
                    setConfirmationVisible(null);
                  }}
                  onBlur={(e) => {
                    setCurrentFocus(null);
                    setMessageValid(checkValidity("message", e.target.value));
                  }}
                />
                <p
                  className="message-length text3"
                  id="input-label"
                >{`${message.length} / 2000`}</p>
                <WarningIcon
                  label="Message must be at least 10 characters"
                  absolute
                  tooltipHeight="2rem"
                  left="101%"
                  top="0.5rem"
                  visible={!messageValid}
                  position="left"
                  tooltipVisible={
                    currentFocus !== messageRef.current && !messageValid
                  }
                />
              </div>
            </div>
            <div className="button-and-spinner">
              <ColoredButton
                style={{ marginBottom: "1rem", padding: "1rem 2.2rem" }}
                text="Submit"
                disabled={sending}
                onClick={handleSubmit}
              />
              {sending && <GenericSpinner absolute left="100%" />}
            </div>
            <div className="confirmation-container">
              <p
                className={`message-sent-confirmation text3 ${
                  confirmationVisible === "success" ? "visible" : ""
                }`}
              >
                Message sent. Thank you!
              </p>
              <p
                className={`message-error-confirmation text3 ${
                  confirmationVisible === "error" ? "visible" : ""
                }`}
              >
                Error... please try again.
              </p>
            </div>
          </div>
          <div className="contact-details">
            <h3>More ways to contact us</h3>
            <img src={map} alt="map" />
            <div className="contact-icon">
              <MdLocationPin fill="var(--primary-color)" />
            </div>
            <p className="label2">12900 Preston Rd. Ste. 804</p>
            <p className="label2">Dallas, TX 75230</p>
            <div className="contact-icon">
              <MdOutlineEmail fill="var(--primary-color)" />
            </div>
            <p className="label2">support@guitarbymasters.com</p>
            {/* <div className="contact-icon">
            <MdPhone fill="var(--primary-color)" />
          </div>
          <p className="label2">+1 (607) 226-3282</p> */}
            <hr />
            <h3>Follow us on social media!</h3>
            <div className="social-media-links">
              <SocialIcon url="https://www.facebook.com/Guitar-by-Masters-109388588095895" />
              <SocialIcon url="http://www.instagram.com/guitarbymasters" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
