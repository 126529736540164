import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import BookCard from "../../../components/cards/BookCard";
import bookServices from "../../../services/BookService";

import { ReactComponent as Arrow } from "../../../assets/arrow.svg";
import SwiperNavButton from "./components/SwiperNavButton";

function CustomSwiper({
  category,
  likedBooks,
  resetLikedBooks,
  isLiked,
  setIsLiked,
  openBanner,
  viewSingleCategory,
}) {
  const bookCatalogSwiper = {
    position: "relative",
    padding: "0 1rem",
  };

  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={"auto"}
      centeredSlides
      centeredSlidesBounds
      preventClicks
      threshold={10}
      style={bookCatalogSwiper}
    >
      <SwiperNavButton type="previous" />
      {category.books.map((book, j) => {
        const image = bookServices.getBookCoverSrc(book.id, book.bookCoverLink);
        return (
          <SwiperSlide style={{ width: "200px" }} key={j}>
            <BookCard
              bookId={book.id}
              image={image}
              title={book.title}
              roles={book.roles}
              tags={book.categories}
              video={book.video}
              likedBooks={likedBooks}
              resetLikedBooks={resetLikedBooks}
              isLiked={isLiked}
              setIsLiked={setIsLiked}
              openBanner={openBanner}
            />
          </SwiperSlide>
        );
      })}
      {category.numberOfBooks > category.books.length && (
        <SwiperSlide style={{ width: "6rem" }}>
          <div className="view-all-books-slide">
            <div
              className="view-all-books-button"
              onClick={() => {
                viewSingleCategory(category);
              }}
            >
              <span className="text2">View all</span>
              <Arrow stroke="var(--primary-color)" strokeWidth={60} />
            </div>
          </div>
        </SwiperSlide>
      )}
      <SwiperNavButton type="next" />
    </Swiper>
  );
}

export default CustomSwiper;
