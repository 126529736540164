import React from "react";
import "./styles/PasswordTooltip.css";
import { MdCheck, MdClose } from "react-icons/md";

function PasswordTooltip({ visible, passwordLengthValid, passwordCaseValid, passwordSpecialValid }) {
  return (
    <div className={`password-tooltip ${visible ? "visible" : ""}`}>
      <p>Your password must have:</p>
      <p>
        {passwordLengthValid ? <MdCheck fill="green" size={18} /> : <MdClose fill="red" size={18} />} At least 8
        characters
      </p>
      <p>
        {passwordCaseValid ? <MdCheck fill="green" size={18} /> : <MdClose fill="red" size={18} />} Uppercase and
        lowercase letters
      </p>
      <p>
        {passwordSpecialValid ? <MdCheck fill="green" size={18} /> : <MdClose fill="red" size={18} />} At least one
        special character
      </p>
    </div>
  );
}

export default PasswordTooltip;
