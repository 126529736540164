import React, { useState, useEffect } from "react";
import OAuth2Service from "../../../../services/auth/OAuth2Service";

// import "../ExternalLoginCallback.css";
import useLogin from "../../../../helpers/customHooks/useLogin";
import OAuthCallbackModal from "../callback/OAuthCallbackModal";
import TokenService from "../../../../services/auth/TokenService";
import axios from "axios";
const FacebookCallback = () => {
  const loginFunction = useLogin();

  const [error, setError] = useState(false);

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);

    let code = params.get("code");
    let intent = params.get("state");

    TokenService.setOAuthMethod("facebook");

    axios
      .get(
        `https://graph.facebook.com/v13.0/oauth/access_token?client_id=${process.env.REACT_APP_FACEBOOK_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_CURRENT_URL}/auth/callback/facebook&client_secret=${"075d04427ddcac465619ebfd65bbdc0c"}&code=${code}`
      )
      .then((r) => {
        TokenService.setOAuthRenewalToken(r.data.access_token);
        OAuth2Service.loginWithFacebookAccessToken(r.data.access_token)
          .then((response) => {
            // console.log("response from backend: ", response.data);
            loginFunction(response.data, "");
          })
          .catch((e) => {
            // console.log("error verifying code: ", e);
            setError(true);
          });
      });
  }, []);

  // Should be a loading screen
  return <OAuthCallbackModal error={error} />;
};

export default FacebookCallback;
