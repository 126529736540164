// App.js

import React from "react";
import "./GiftCenterComponent.scss";
import BookService from "../../services/BookService";
import PricingTable from "./PricingTable";
import { useEffect, useState, useLayoutEffect } from "react";
import gbmimage from "../../assets/gbm.png";
import gbmRedPresent from "../../assets/gbmPresent.png";
import gbmOrangePresent from "../../assets/gbmPrimaryPresent.png";
import gbmGiftCardDark from "../../assets/darkCard.png";
import gbmGiftCardBlue from "../../assets/blueCard.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Accordion from "./FAQAccordion";
import GiftCodeRedeem from "./GiftCodeRedeem";
import { useContext } from "react";
import { NotificationBarContext } from "../../context/NotificationBarContext";
import TokenService from "../../services/auth/TokenService";
gsap.registerPlugin(ScrollTrigger);

const GiftCenterComponent = () => {
  const textRef = React.useRef();

  const root = React.useRef();

  const [recentlyAddedBooks, setRecentlyAddedBooks] = useState([]);

  useEffect(() => {
    BookService.getRecentlyAddedBooksTop15().then((response) => {
      const formattedBookData = response.data.map((book) => {
        const imageLink = `https://d24hdgytax3x03.cloudfront.net/${book.id}/${book.bookCoverLink}`;

        return { ...book, bookCoverLink: imageLink };
      });

      const bookGroups = [];
      const groupSize = 3;

      for (let i = 0; i < formattedBookData.length; i += groupSize) {
        const bookGroup = formattedBookData.slice(i, i + groupSize);
        bookGroups.push(bookGroup);
      }
      setRecentlyAddedBooks(bookGroups);
    });
  }, []);
  useEffect(() => {
    const columns = gsap.utils.toArray(".column-gift");
    // Scroll animations
    const ctx = gsap.context(() => {
      columns.forEach((column, index) => {
        const direction = index % 2 === 0 ? -150 : 150;
        gsap.to(column, {
          y: direction,
          scrollTrigger: {
            trigger: column,
            start: "top bottom",
            end: "bottom top",
            scrub: 1,
            // stagger: 0.5,
          },
        });
      });
      gsap.fromTo(
        textRef.current,
        { scale: 0, opacity: 0, y: 30 }, // Starting properties
        { scale: 1, opacity: 1, y: 0, duration: 1, ease: "power2.out" }
      );
    });
    gsap.from(".gift-center-container__gift-membership", {
      y: 30,

      scrollTrigger: {
        trigger: ".gift-center-container__gift-membership",
        start: "top bottom",
        end: "bottom top",
        toggleActions: "play none none reverse",
      },
      duration: 1.5,
      ease: "power3.out",
    });
    return () => ctx.revert();
  }, [recentlyAddedBooks]);
  const { hideNotificationBar, showNotificationBar } = useContext(
    NotificationBarContext
  ); // Use context
  useEffect(() => {
    hideNotificationBar();
    return () => {
      showNotificationBar();
    };
  }, []);

  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 760);

  const updateMedia = () => {
    setIsMobileScreen(window.innerWidth < 760);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  return (
    <div style={{ minHeight: "90vh", minWidth: "100%" }} ref={root}>
      {recentlyAddedBooks && recentlyAddedBooks.length > 0 && (
        <div className="gift-center-wrapper">
          <section className="gift-center-container ">
            <div className="gift-center-container__header-wrapper "></div>
            <div
              className="gift-center-container__header-wrapper--left "
              ref={textRef}
            >
              <h1 className="title-shadow ">Gifting, Tuned to Perfection!</h1>
              <div className="gc-heading-text">
                {" "}
                <p>
                  Gift a 6-month or 1-year subscription to the guitar lover in
                  your life! This will unlock their access to an expanding
                  library of interactive guitar scores and tutorials.{" "}
                  <span id="hidden-on-mobile">
                    Simply enter the recipient's email and purchase the gift.
                    We'll handle the rest by sending your recipient a redeemable
                    code and a direct link to a world of guitar mastery.
                  </span>
                </p>
                <GiftCodeRedeem />
              </div>
              {/* {TokenService.getUser() && <GiftCodeRedeem />} */}
            </div>
            {!isMobileScreen && (
              <div className="gift-center-container__header-wrapper--right">
                <GridSection recentlyAddedBooks={recentlyAddedBooks} />
              </div>
            )}
            <section className="gift-center-container__gift-membership">
              <h1>Gift a Subscription</h1>
              <p className="hidden-on-mobile">
                Gift the joy of access to the Guitar by Masters library! Choose
                a 6-month or 1-year subscription gift for the guitar lover you
                know to fill their days with outstanding guitar music.
              </p>
              <div className="membership-options ">
                <PricingTable />
              </div>
            </section>
            <Accordion />
          </section>
        </div>
      )}
    </div>
  );
};

export default GiftCenterComponent;

// Grid section component
const GridSection = ({ recentlyAddedBooks }) => {
  return (
    <div className="grid-section">
      {recentlyAddedBooks && (
        <>
          <div className="column-gift grid-section__column-1">
            {recentlyAddedBooks[0]?.map((book, index) => {
              return (
                <>
                  <div
                    key={book.id}
                    className="grid-item margin-bottom-gift margin-bottom-gift"
                  >
                    <img
                      src={book.bookCoverLink}
                      className="grid-image book-card-image-container"
                      alt={`Book titled ${book.title}`}
                      loading="eager"
                    />
                  </div>
                  <div
                    key={`filler-${index}`}
                    className="grid-filler-image logo-gift-block margin-bottom-gift"
                    style={{ backgroundImage: `url(${gbmOrangePresent})` }}
                  ></div>
                </>
              );
            })}
          </div>
          <div className="column-gift grid-section__column-2">
            {recentlyAddedBooks[1]?.map((book, index) => {
              return (
                <>
                  <div
                    key={`filler-${index}`}
                    className="grid-filler-image logo-gift-block margin-bottom-gift"
                    style={{ backgroundImage: `url(${gbmGiftCardDark})` }}
                  ></div>{" "}
                  <div
                    key={`gift-${index}`}
                    className="logo-gift-block margin-bottom-gift"
                    style={{
                      backgroundImage: `url(${gbmimage})`,
                      backgroundColor: `${index % 2 === 0 ? "red" : "green"}`,
                    }}
                  ></div>{" "}
                  <div key={book.id} className="grid-item margin-bottom-gift">
                    <img
                      src={book.bookCoverLink}
                      className="grid-image"
                      alt={`Book titled ${book.title}`}
                    />
                  </div>
                </>
              );
            })}
          </div>
          <div className=" column-gift grid-section__column-3">
            {" "}
            {recentlyAddedBooks[2]?.map((book, index) => {
              return (
                <>
                  <div key={book.id} className="grid-item margin-bottom-gift">
                    <img
                      src={book.bookCoverLink}
                      className="grid-image book-card-image-container"
                      alt={`Book titled ${book.title}`}
                    />
                  </div>
                  <div
                    key={`filler-${index}`}
                    className="grid-filler-image logo-gift-block margin-bottom-gift"
                    style={{ backgroundImage: `url(${gbmRedPresent})` }}
                  ></div>
                </>
              );
            })}
          </div>{" "}
          <div className="column-gift grid-section__column-4">
            {recentlyAddedBooks[3]?.map((book, index) => {
              if (index === recentlyAddedBooks[3].length - 1) {
                return null;
              }
              return (
                <>
                  <div
                    key={`filler-${index}`}
                    className="grid-filler-image logo-gift-block margin-bottom-gift"
                    style={{ backgroundImage: `url(${gbmGiftCardBlue})` }}
                  ></div>
                  <div
                    key={`gift-${index}`}
                    className="logo-gift-block margin-bottom-gift"
                    style={{
                      backgroundImage: `url(${gbmimage})`,
                      backgroundColor: `${index % 2 === 0 ? "red" : "green"}`,
                    }}
                  ></div>
                  <div key={book.id} className="grid-item margin-bottom-gift">
                    <img
                      src={book.bookCoverLink}
                      className="grid-image margin-bottom-gift"
                      alt={`Book titled ${book.title}`}
                    />
                  </div>
                </>
              );
            })}{" "}
          </div>
        </>
      )}
      {/* {recentlyAddedBooks && (recentlyAddedBooks.map((book) => {
                return <div key={book.id} className="grid-item">
                    <img src={book.bookCoverLink} className='test-image' alt="" />
                </div>
            }))} */}
    </div>
  );
};
