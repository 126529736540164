import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import ImageLoader from "../../../components/image/ImageLoader";

import OutlinedButton from "../../../components/buttons/OutlinedButton";
import { FeaturedArtistText } from "./FeaturedArtistText";

import "./featuredArtist.css";

const FeaturedArtist = ({ featuredArtists }) => {
  const [loading, setLoading] = useState(true);

  return (
    <Swiper
      style={{ width: "100%", maxWidth: "1300px", margin: "25px 0" }}
      slidesPerView={1}
      loop={true}
      threshold={10}
      autoplay={{
        delay: 10000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
    >
      {featuredArtists
        ? featuredArtists.map((artist, i) => {
            const sampleImage = `https://da9nh4xe5cncw.cloudfront.net/${artist.id}/${artist.galleryImageURL}`;
            return (
              <SwiperSlide
                key={i}
                style={{
                  maxWidth: "1300px",
                  width: "100%",
                  height: "fit-content",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <div className="featured-artist-container">
                  <ImageLoader
                    src={sampleImage}
                    onLoad={() => {
                      setLoading(false);
                    }}
                    alt={`Guitar by Masters Classical Guitarist ${artist.name}`}
                    draggable={false}
                    style={{ width: "300px", height: "300px" }}
                  />
                  <div className="featured-artist-description">
                    <p className="label1">Learn With</p>

                    <div style={{ margin: "20px 0", maxWidth: "400px" }}>
                      {artist ? (
                        <h3
                        // style={{color: "var(--primary-color)"}}
                        >
                          {artist.name}
                        </h3>
                      ) : (
                        ""
                      )}
                      {FeaturedArtistText.map((description, i) => {
                        if (description.artistId == artist.id) {
                          return <p key={i}>{description.featuredText}</p>;
                        }
                      })}
                    </div>
                    <Link to={`/artistprofile/${artist.id}`} className="no-underline">
                      <OutlinedButton
                        text="see more"
                        style={{
                          padding: "10px 15px",
                          margin: "15px 0",
                          borderRadius: ".6em",
                        }}
                      />
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            );
          })
        : ""}
    </Swiper>
  );
};

export default FeaturedArtist;
