import React, { useEffect, useRef, useState } from "react";

import "./SongTable.css";

/*

WHEN THE DATA FOR THE TABLE CHANGE:
1) convert the csv to JSON
2) sort using the following function:

sort((a, b) => {
 if (a.piece.normalize("NFD").replace(/[\u0300-\u036f]/g, "") < b.piece.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
     return -1;
 } else if (a.piece.normalize("NFD").replace(/[\u0300-\u036f]/g, "") > b.piece.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
     return 1;
 } else return 0;
});

*/

function SongTable() {
  const data = require("../../../../data/pieces.json");
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [tableData, setTableData] = useState(data);
  const [page, setPage] = useState(0);
  const [range, setRange] = useState([0, rowsPerPage]);
  const [pieceFilter, setPieceFilter] = useState("");
  const [composerFilter, setComposerFilter] = useState("");
  const debounceTimeoutID = useRef();
  const pieceFilterRef = useRef();
  const composerFilterRef = useRef();
  const [sort, setSort] = useState({
    by: "piece",
    ascending: true,
  });

  const sortAscending = (
    <svg viewBox="0 0 24 24" style={{ width: "0.75rem", marginLeft: "0.25rem", fill: "var(--primary-color)" }}>
      <path d="m13.1 6.4 9 9c.4.4.6 1.1.3 1.6-.2.6-.8 1-1.4 1H3c-.6 0-1.2-.4-1.4-.9-.2-.6-.1-1.2.3-1.6l9-9c.3-.3.7-.5 1.1-.5.4 0 .8.2 1.1.4z" />
    </svg>
  );

  const sortDescending = (
    <svg viewBox="0 0 24 24" style={{ width: "0.75rem", marginLeft: "0.25rem", fill: "var(--primary-color)" }}>
      <path d="M12 18a1.51 1.51 0 0 1-1.06-.44l-9-9a1.5 1.5 0 0 1-.33-1.63A1.51 1.51 0 0 1 3 6h18a1.51 1.51 0 0 1 1.39.93 1.5 1.5 0 0 1-.33 1.63l-9 9A1.51 1.51 0 0 1 12 18Z" />
    </svg>
  );

  useEffect(() => {
    setPage(0);
    setRange([0, rowsPerPage]);
  }, [rowsPerPage]);

  // debounce filter change
  function changePieceFilter(value) {
    setPieceFilter(cleanText(value));
    setRange([0, rowsPerPage]);
    setPage(0);
    clearTimeout(debounceTimeoutID.current);

    // if the user is also filtering by book, retain the results of that filter
    let contents = data;
    if (composerFilter.length > 0) {
      contents = data.filter((row) => cleanText(row.composer).includes(cleanText(composerFilter)));
    }

    // if the user has cleared the filter, update immediately rather than debounce
    if (value === "") {
      setTableData(sortRows(contents, sort.by, sort.ascending));
    } else {
      debounceTimeoutID.current = setTimeout(() => {
        // filter for the current query (while normalizing accented letters)
        const temp = contents.filter((row) => cleanText(row.piece).includes(cleanText(value)));
        // sort the filtered result and update the state
        setTableData(sortRows(temp, sort.by, sort.ascending));
      }, 250);
    }
  }

  // debounce filter change
  function changeComposerFilter(value) {
    setComposerFilter(cleanText(value));
    setRange([0, rowsPerPage]);
    setPage(0);
    clearTimeout(debounceTimeoutID.current);

    let contents = data;

    // if the user is also filtering by piece, retain the results of that filter
    if (pieceFilter.length > 0) {
      contents = data.filter((row) => cleanText(row.piece).includes(cleanText(pieceFilter)));
    }

    // if the user has cleared the filter, update immediately rather than debounce
    if (value === "") {
      setTableData(sortRows(contents, sort.by, sort.ascending));
    } else {
      debounceTimeoutID.current = setTimeout(() => {
        // filter for the current query (while normalizing accented letters)
        const temp = contents.filter((row) => cleanText(row.composer).includes(cleanText(value)));
        // sort the filtered result and update the state
        setTableData(sortRows(temp, sort.by, sort.ascending));
      }, 250);
    }
  }

  function clearFilter(input) {
    if (input === "piece") {
      changePieceFilter("");
      pieceFilterRef.current.focus();
    } else if (input === "book") {
      changeComposerFilter("");
      composerFilterRef.current.focus();
    } else return;
  }

  function pageBack() {
    const newPage = Math.max(0, page - 1);
    setPage(newPage);
    const newStart = newPage * rowsPerPage;
    const newEnd = newStart + rowsPerPage;

    setRange([newStart, newEnd]);
  }

  function pageForward() {
    const newPage = Math.ceil(Math.min(tableData.length / rowsPerPage - 1, page + 1));
    setPage(newPage);
    const newStart = newPage * rowsPerPage;
    const newEnd = Math.min(tableData.length, newStart + rowsPerPage);

    setRange([newStart, newEnd]);
  }

  function goToPage(dest) {
    setPage(dest);
    const newStart = dest * rowsPerPage;
    const newEnd = Math.min(tableData.length, newStart + rowsPerPage);

    setRange([newStart, newEnd]);
  }

  function changeSort(e, by) {
    if (["INPUT", "BUTTON", "svg", "circle", "path"].includes(e.target.nodeName)) {
      return;
    }

    if (by !== sort.by) {
      // if the user clicked a different column to sort, always start with ascending
      setSort({
        by,
        ascending: true,
      });
      setTableData(sortRows(tableData, by, true));
    } else {
      // if the user clicked the same column, just reverse the direction
      setSort((s) => {
        return {
          ...sort,
          ascending: !s.ascending,
        };
      });
      setTableData(sortRows(tableData, by, !sort.ascending));
    }
  }

  function cleanText(text) {
    return text
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  }

  function sortRows(data, by, ascending) {
    let temp = data.slice();

    // sort tableData based on clicked column and chosen direction
    temp = temp.sort((a, b) => {
      // a[by] and b[by] are just the cell values for the row, e.g. row.piece, row.composer, etc.
      if (cleanText(a[by]) < cleanText(b[by])) {
        return ascending ? -1 : 1;
      } else if (cleanText(a[by]) > cleanText(b[by])) {
        return ascending ? 1 : -1;
      } else return 0;
    });

    return temp;
  }

  return (
    <div className="catalog-table">
      {tableData ? (
        <>
          <NavButtons
            page={page}
            goBack={pageBack}
            goForward={pageForward}
            maxPages={Math.ceil(tableData.length / rowsPerPage)}
            goToPage={goToPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />

          <div className="catalog-table-wrapper">
            <table>
              <thead>
                <tr>
                  <th
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      whiteSpace: "nowrap",
                    }}
                    className={sort.by === "piece" ? "sorting" : ""}
                    onClick={(e) => changeSort(e, "piece")}
                  >
                    <div style={{ display: "inline" }}>
                      Piece {sort.by === "piece" ? <>{sort.ascending ? sortAscending : sortDescending}</> : null}
                    </div>
                    <div
                      className="catalog-table__filter-container"
                      style={{ position: "relative", display: "inline" }}
                    >
                      <input
                        ref={pieceFilterRef}
                        type="text"
                        placeholder="Filter titles"
                        className="catalog-table__filter"
                        value={pieceFilter}
                        onChange={(e) => changePieceFilter(e.target.value)}
                      />
                      {pieceFilter.length > 0 ? (
                        <button
                          onClick={() => {
                            clearFilter("piece");
                          }}
                          aria-label="clear filter"
                        >
                          <svg
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                          >
                            <circle cx="12" cy="12" r="11" />
                            <path d="m7.816 7.816 8.368 8.368m-8.368 0 8.368-8.368" />
                          </svg>
                        </button>
                      ) : null}
                    </div>
                  </th>
                  <th
                    style={{ width: "25%", whiteSpace: "nowrap" }}
                    className={sort.by === "composer" ? "sorting" : ""}
                    onClick={(e) => changeSort(e, "composer")}
                  >
                    <div style={{ display: "inline" }}>
                      Composer {sort.by === "composer" ? <>{sort.ascending ? sortAscending : sortDescending}</> : null}
                    </div>
                    <div
                      className="catalog-table__filter-container"
                      style={{ position: "relative", display: "inline" }}
                    >
                      <input
                        ref={composerFilterRef}
                        type="text"
                        placeholder="Filter names"
                        className="catalog-table__filter"
                        value={composerFilter}
                        onChange={(e) => changeComposerFilter(e.target.value)}
                      />
                      {composerFilter.length > 0 ? (
                        <button
                          onClick={() => {
                            clearFilter("book");
                          }}
                          aria-label="clear filter"
                        >
                          <svg
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                          >
                            <circle cx="12" cy="12" r="11" />
                            <path d="m7.816 7.816 8.368 8.368m-8.368 0 8.368-8.368" />
                          </svg>
                        </button>
                      ) : null}
                    </div>
                  </th>
                  <th
                    style={{ width: "15%" }}
                    className={sort.by === "performer" ? "sorting" : ""}
                    onClick={(e) => changeSort(e, "performer")}
                  >
                    Performer {sort.by === "performer" ? <>{sort.ascending ? sortAscending : sortDescending}</> : null}
                  </th>
                  <th
                    style={{ width: "15%" }}
                    className={sort.by === "level" ? "sorting" : ""}
                    onClick={(e) => changeSort(e, "level")}
                  >
                    Level {sort.by === "level" ? <>{sort.ascending ? sortAscending : sortDescending}</> : null}
                  </th>
                  <th
                    style={{ width: "20%" }}
                    className={sort.by === "book" ? "sorting" : ""}
                    onClick={(e) => changeSort(e, "book")}
                  >
                    Book {sort.by === "book" ? <>{sort.ascending ? sortAscending : sortDescending}</> : null}
                  </th>
                </tr>
              </thead>
              {tableData.length > 0 ? (
                <tbody>
                  {tableData.slice(range[0], range[1]).map((row) => (
                    <React.Fragment key={row.bookNumber + " - " + row.composer + " - " + row.piece}>
                      <tr>
                        <td>{row.piece}</td>
                        <td>{row.composer}</td>
                        <td>{row.performer}</td>
                        <td>{row.level}</td>
                        <td>
                          <a href={`/bookprofile/${row.bookNumber}`}>{row.book}</a>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={5} className="book-catalog-table__no-results">
                      No results
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>

          <NavButtons
            page={page}
            goBack={pageBack}
            goForward={pageForward}
            maxPages={Math.ceil(tableData.length / rowsPerPage)}
            goToPage={goToPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </>
      ) : (
        <div className="book-catalog-table__loading-indicator">Loading table</div>
      )}
    </div>
  );
}

export default SongTable;

function NavButtons({ page, goBack, goForward, maxPages, goToPage, rowsPerPage, setRowsPerPage }) {
  function changeRowsPerPage(amount) {
    setRowsPerPage(Math.max(amount, 5));
  }

  return (
    <div style={{ width: "fit-content", margin: "0 auto", display: "flex", alignItems: "center", gap: "2rem" }}>
      <div className="book-catalog-table__pagination-container" title={`page ${page + 1} of ${maxPages}`}>
        <button disabled={page <= 0} onClick={goBack}>
          Prev
        </button>
        Page{" "}
        {/* if there's only one page it's pointless to render the select element, so
            I just return the "1" to make the component look a little more polished */}
        {maxPages === 1 ? (
          <span className="book-catalog-table__single-page-span">1</span>
        ) : (
          <label>
            <select value={page} onChange={(e) => goToPage(Number(e.target.value))}>
              {Array.from(Array(maxPages).keys()).map((page) => (
                <option key={page} value={page}>
                  {page + 1}
                </option>
              ))}
            </select>
          </label>
        )}{" "}
        of {maxPages}
        <button disabled={page >= maxPages - 1} onClick={goForward}>
          Next
        </button>
      </div>
      <div>
        Results per page:{" "}
        <input
          type="number"
          value={rowsPerPage}
          onChange={(e) => changeRowsPerPage(e.target.value)}
          min={5}
          max={50}
          onScroll={(e) => e.preventDefault()}
        />
      </div>
    </div>
  );
}
