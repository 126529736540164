import React, { useState, useRef, useEffect } from "react";
import PlaybackRateMenu from "./PlaybackRateMenu";
import PlaybackSettingsMenu from "./PlaybackSettingsMenu";
import WaitSettingsMenu from "./WaitSettingsMenu";

import "./ControlBarContainer.css";

import { ReactComponent as PlayIcon } from "../assets/play.svg";
import { ReactComponent as PauseIcon } from "../assets/pause.svg";
import { ReactComponent as RepeatIcon } from "../assets/repeat.svg";
import { ReactComponent as WaitSettingsIcon } from "../assets/waitSettings.svg";
import { ReactComponent as SettingsIcon } from "../assets/settings.svg";
import { ReactComponent as PlaybackRateIcon } from "../assets/playbackRate.svg";
import { ReactComponent as PrevPage } from "../assets/prevPage.svg";
import { ReactComponent as NextPage } from "../assets/nextPage.svg";

import { BsCheckLg } from "react-icons/bs";

function ControlBarContainer({
  isPlaying,
  setIsPlaying,
  playbackRate,
  setPlaybackRate,
  repeatIterations,
  setRepeatIterations,
  setWait,
  selectingRange,
  setSelectingRange,
  continuousPlay,
  setContinuousPlay,
  autoFlip,
  setAutoFlip,
  autoScroll,
  setAutoScroll,
  usePhraseDuration,
  setUsePhraseDuration,
  useTimeScale,
  setUseTimeScale,
  timerVisible,
  setTimerVisible,
  continueAfterRepeating,
  setContinueAfterRepeating,
  play,
  pause,
  waveSurfer,
  currentPage,
  setCurrentPage,
  pages,
  tourStepIndex,
  setTourStepIndex,
  currentChapterIndex,
  setCurrentChapterIndex,
  bookInsides,
  setRepeatRange,
  setChapterName,
  setPip,
  setNavigatedAway,
  activeRect,
  videoRef,
  repeatBoxRef,
}) {
  const waitSettingsButtonRef = useRef();
  const generalSettingsButtonRef = useRef();
  const playbackRateRef = useRef();

  const [playbackRateMenu, setPlaybackRateMenu] = useState(false);
  const [playbackSettingsMenu, setPlaybackSettingsMenu] = useState(false);
  const [waitSettingsMenu, setWaitSettingsMenu] = useState(false);

  useEffect(() => {
    if (waveSurfer.current) waveSurfer.current.setPlaybackRate(playbackRate);
  }, [playbackRate]);

  return (
    <>
      <div className="controlBar">
        <div className="controlBar__container">
          <div data-tut="tour__control-bar" className="controlBar__inner">
            <div
              className={`controlBar__chapterButton screen-l ${
                currentChapterIndex <= 0 ? "disabled" : ""
              }`}
              onClick={() => {
                setRepeatRange(-1, -1);
                setSelectingRange(false);
                setChapterName(bookInsides[currentChapterIndex - 1].name);
                setCurrentChapterIndex((c) => c - 1);
                setCurrentPage(0);
              }}
            >
              Prev Chapter
            </div>
            <div
              className={`controlBar__pageButton screen-l ${
                currentPage <= 0 ? "disabled" : ""
              }`}
              onClick={() => {
                if (currentPage === activeRect.page + 1)
                  setNavigatedAway(false);
                else setNavigatedAway(true);
                setCurrentPage((p) => p - 1);
              }}
            >
              <PrevPage fill="#000" stroke="#fff" />
            </div>
            <div
              data-tut="tour__playPauseButton"
              className="controlBar__largeButton"
              onClick={() => {
                if (isPlaying) {
                  setIsPlaying(false);
                  pause();
                } else {
                  setPip(false);
                  setCurrentPage(
                    activeRect.page ? activeRect.page : currentPage
                  );
                  setNavigatedAway(false);
                  setIsPlaying(true);
                  play();
                }
              }}
            >
              {isPlaying ? (
                <PauseIcon className="buttonSVG" fill="white" />
              ) : (
                <PlayIcon className="buttonSVG" fill="white" />
              )}
            </div>
            <div
              ref={playbackRateRef}
              className="controlBar__largeButton playbackRateButton"
              onClick={() => {
                setPlaybackRateMenu(true);
              }}
            >
              <div
                style={{
                  width: "1.5em",
                  textAlign: "center",
                  marginBottom: "-0.5em",
                }}
              >
                <PlaybackRateIcon fill="white" stroke="white" />
                <p style={{ fontSize: "medium", marginTop: "-0.25em" }}>
                  {playbackRate}x
                </p>
              </div>
            </div>
            {playbackRateRef.current && (
              <PlaybackRateMenu
                open={playbackRateMenu}
                setOpen={setPlaybackRateMenu}
                bottom={
                  window.innerHeight -
                  playbackRateRef.current.getBoundingClientRect().y +
                  15
                }
                left={
                  playbackRateRef.current.getBoundingClientRect().x +
                  playbackRateRef.current.getBoundingClientRect().width / 2 -
                  50
                }
                playbackRate={playbackRate}
                setPlaybackRate={setPlaybackRate}
                videoRef={videoRef}
              />
            )}
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                data-tut="tour__call-and-response"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="callAndResponseItem">
                  <span>Repeat</span>
                  <input
                    ref={repeatBoxRef}
                    data-tut="tour__repeatTextbox"
                    type="number"
                    min={0}
                    max={99}
                    style={{ width: "3.5em" }}
                    defaultValue={0}
                    onChange={(e) => {
                      if (e.target.value !== "")
                        setRepeatIterations(e.target.value);
                      else setRepeatIterations(0);
                    }}
                  />
                  <span>times</span>
                  <div
                    className="controlBar__smallButton"
                    onClick={() => {
                      if (tourStepIndex === 6) setTourStepIndex(7);
                      if (tourStepIndex === 8) setTourStepIndex(9);
                      setSelectingRange((r) => !r);
                      setIsPlaying(false);
                      pause();
                    }}
                  >
                    <div data-tut="tour__repeat-button" className="buttonIcon">
                      {!selectingRange ? (
                        <RepeatIcon
                          className="buttonSVG"
                          fill="white"
                          stroke="white"
                        />
                      ) : (
                        <BsCheckLg size={15} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="callAndResponseItem">
                  <span>Wait</span>
                  <input
                    data-tut="tour__waitTextbox"
                    type="number"
                    min={0}
                    max={99}
                    style={{ width: "3.5em" }}
                    defaultValue={0}
                    onChange={(e) => {
                      setWait(e.target.value === "" ? 0 : e.target.value);
                    }}
                  />
                  <span>sec</span>
                  <div
                    data-tut="tour__waitSettings"
                    className="controlBar__smallButton"
                    ref={waitSettingsButtonRef}
                    onClick={() => {
                      setWaitSettingsMenu(true);
                    }}
                  >
                    <div className="buttonIcon">
                      <WaitSettingsIcon className="buttonSVG" fill="white" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {waitSettingsButtonRef.current && (
              <WaitSettingsMenu
                open={waitSettingsMenu}
                setOpen={setWaitSettingsMenu}
                bottom={
                  window.innerHeight -
                  waitSettingsButtonRef.current.getBoundingClientRect().y +
                  15
                }
                left={
                  waitSettingsButtonRef.current.getBoundingClientRect().x +
                  waitSettingsButtonRef.current.getBoundingClientRect().width /
                    2 -
                  100
                }
                waitSettingsButtonRef={waitSettingsButtonRef}
                usePhraseDuration={usePhraseDuration}
                setUsePhraseDuration={setUsePhraseDuration}
                useTimeScale={useTimeScale}
                setUseTimeScale={setUseTimeScale}
                timerVisible={timerVisible}
                setTimerVisible={setTimerVisible}
                continueAfterRepeating={continueAfterRepeating}
                setContinueAfterRepeating={setContinueAfterRepeating}
              />
            )}
            <div
              ref={generalSettingsButtonRef}
              className="controlBar__largeButton"
              onClick={() => {
                setPlaybackSettingsMenu(true);
              }}
            >
              <SettingsIcon className="buttonSVG" fill="white" />
            </div>

            {generalSettingsButtonRef.current && (
              <PlaybackSettingsMenu
                open={playbackSettingsMenu}
                setOpen={setPlaybackSettingsMenu}
                bottom={
                  window.innerHeight -
                  generalSettingsButtonRef.current.getBoundingClientRect().y +
                  15
                }
                left={
                  generalSettingsButtonRef.current.getBoundingClientRect().x +
                  generalSettingsButtonRef.current.getBoundingClientRect()
                    .width /
                    2 -
                  100
                }
                continuousPlay={continuousPlay}
                setContinuousPlay={setContinuousPlay}
                autoFlip={autoFlip}
                setAutoFlip={setAutoFlip}
                autoScroll={autoScroll}
                setAutoScroll={setAutoScroll}
              />
            )}
            <div
              className={`controlBar__pageButton screen-l ${
                currentPage >= pages.length - 1 ? "disabled" : ""
              }`}
              onClick={() => {
                if (currentPage === activeRect.page - 1)
                  setNavigatedAway(false);
                else setNavigatedAway(true);
                setCurrentPage((p) => p + 1);
              }}
            >
              <NextPage fill="#000" stroke="#fff" />
            </div>
            <div
              className={`controlBar__chapterButton screen-l ${
                currentChapterIndex >= bookInsides.length - 1 ? "disabled" : ""
              }`}
              onClick={() => {
                setRepeatRange(-1, -1);
                setSelectingRange(false);
                setChapterName(bookInsides[currentChapterIndex + 1].name);
                setCurrentChapterIndex((c) => c + 1);
                setCurrentPage(0);
              }}
            >
              Next Chapter
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ControlBarContainer;
