class TokenService {
  parseJSON(item) {
    try {
      return JSON.parse(item);
    } catch (e) {
      return null;
    }
  }

  getLocalRefreshToken() {
    const user = this.parseJSON(localStorage.getItem("user"));
    return user?.refreshToken;
  }

  getLocalAccessToken() {
    const user = this.parseJSON(localStorage.getItem("user"));
    return user?.accessToken;
  }

  updateLocalAccessToken(token) {
    let user = this.parseJSON(localStorage.getItem("user"));
    if (user) {
      user.accessToken = token;
      localStorage.setItem("user", JSON.stringify(user));
    }
  }

  updateRefreshToken(token) {
    let user = this.parseJSON(localStorage.getItem("user"));
    if (user) {
      user.refreshToken = token;
      localStorage.setItem("user", JSON.stringify(user));
    }
  }

  getUser() {
    return this.parseJSON(localStorage.getItem("user"));
  }

  setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem("user");
  }

  clearLocalStorage() {
    localStorage.clear();
  }

  setOAuthRenewalToken(token) {
    localStorage.setItem("oauth_renewal", JSON.stringify(token));
  }

  setOAuthMethod(method) {
    localStorage.setItem("oauth_method", JSON.stringify(method));
  }

  getOAuthRenewalToken() {
    return this.parseJSON(localStorage.getItem("oauth_renewal"));
  }

  getOAuthMethod() {
    return this.parseJSON(localStorage.getItem("oauth_method"));
  }
}

export default new TokenService();
