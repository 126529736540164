import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BookDataService from "../../../services/BookService";

const FeaturedBook = ({ id }) => {
  const [book, setBook] = useState([]);
  const [bookCover, setBookCover] = useState([]);

  useEffect(() => {
    BookDataService.getBookbyId(id).then((response) => {
      setBook(response.data);
    });
  }, []);

  useEffect(() => {
    if (book && book.coverFileName)
      setBookCover(
        BookDataService.getBookCoverSrc(book.id, book.coverFileName)
      );
  }, [book]);

  return (
    <Link to={`/bookprofile/${book.id}`}>
      <img
        className="featured-book-home-bookImg"
        src={bookCover}
        alt="bookcover"
      />
    </Link>
  );
};

export default FeaturedBook;
