import React, { useEffect, useState } from "react";
import BookDataService from "../../../services/BookService";

const DemoBook = ({ id }) => {
  const [book, setBook] = useState([]);
  const [bookCover, setBookCover] = useState([]);

  useEffect(() => {
    BookDataService.getBookbyId(id).then((response) => {
      setBook(response.data);
    });
  }, []);

  useEffect(() => {
    if (book) setBookCover(BookDataService.getBookCoverSrc(book.id, book.coverFileName));
  }, [book]);

  return <img className="home-demo-book-cover" src={bookCover} alt="bookcover" />;
};

export default DemoBook;
